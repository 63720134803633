import { render, staticRenderFns } from "./EditNoteModal.vue?vue&type=template&id=18f5f879&scoped=true&"
import script from "./EditNoteModal.vue?vue&type=script&lang=js&"
export * from "./EditNoteModal.vue?vue&type=script&lang=js&"
import style0 from "./EditNoteModal.vue?vue&type=style&index=0&id=18f5f879&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f5f879",
  null
  
)

export default component.exports