import Vue from 'vue'
import Vuex from 'vuex'
import layouts from './modules/layouts'
import settings from './modules/settings'
//import pages from './modules/pages'
import users from './modules/users'
import modals from './modules/modals'
import ws from './modules/ws'
import toast from './modules/toast'
import tooltip from './modules/tooltip'
import ambiance from './modules/ambiance'
import gridtabs from './modules/gridtabs'
import elstates from './modules/elstates'
import clipboard from './modules/clipboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCover: false,
    isEditable: false,
    isLoadingBoard: false,
  },
  getters: {
  },
  mutations: {
    toggleEditable(state) {
      state.isEditable = !state.isEditable;
    },
    setLoadingBoard(state) {
      state.isLoadingBoard = true;
    },
    unsetLoadingBoard(state) {
      state.isLoadingBoard = false;
    },
    setGridType(state,payload) {
      state.gridType = payload;
    },
    unsetEditable(state) {
      state.isEditable = false;
    }
  },
  actions: {
    importExternal({commit}) {
      commit("layouts/setImportedPersonal");
      commit("gridtabs/clearAll");
      //localStorage.grsettings = JSON.stringify(prepareImport.settings);
      //commit("settings/set_settings");
    },
    toggleEditable({commit}) {
      commit("layouts/saveLayout");
      commit("settings/saveSettings");
      commit("toggleEditable");
    },
    editItem({commit}, payload) {
      commit("layouts/editItem", payload.id);
      commit("modals/showModal", payload.modal);
    },
    copyItem({commit}, id) {
      commit("layouts/copyItem", id);
      commit("modals/showModal", "copyTo");
    }
  },
  modules: {
    layouts,
    settings,
    //pages,
    users,
    modals,
    ws,
    toast,
    tooltip,
    ambiance,
    gridtabs,
    elstates,
    clipboard,
  }
})
