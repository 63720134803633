<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 300px">
        <header class="modal-card-head">
          <p class="modal-card-title">ToDo Settings</p>
        </header>
        <section class="modal-card-body">
          <section>
          <div class="separator mb-2">Options</div>
            <b-field>
              <p class="control">
                <span class="button is-static">Title:</span>
              </p>
              <b-input
                v-model="options.title"
                @input="titleChanged()"
                expanded
              ></b-input>
            </b-field>

            <div class="level">
              <div class="level-left">
                <div>Text Color:</div>
              </div>
              <div class="level-right"> 
                <b-colorpicker v-model="options.textColor" />
              </div>
            </div>
            <div class="level">
              <div class="level-left">
                <div>Background:</div>
              </div>
              <div class="level-right">
                <b-colorpicker v-model="options.bgColor" />
              </div>
            </div>

          </section>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()">Save</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
export default {
  name: "EditTodoModal",
  mixins: [globalMixin, toastMixin, sanitizeMixin],
  data() {
    return {
      options: {
        textColor: "#ffffff",       
        bgColor: "#000000",
        title: ""    
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "editTodo");
    },
    titleChanged() {
      if (this.options.title.length > 0) {
        this.options.showTitle = true;
      }else{
        this.options.showTitle = false;
      }
    },
    reset() {
      this.options = {
        textColor: "#ffffff",
        bgColor: "#000000",
        title:""
      };
    },
    saveItem(){         
      console.log("saving item..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      this.options.textColor = this.options.textColor.toString('hex');
      if(this.options.title){
        this.options.title = this.sanitizeQuotes(this.options.title);
      }
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
        ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  textColor: "#ffffff",       
                  bgColor: "#000000",
                  title:""
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editTodo;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editTodo");
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

