<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">iFrame Settings</p>
        </header>
        <section class="modal-card-body">

            <section>
            <div class="separator mb-2">iFrame Url</div>
          
              <b-field :type="iframe.status">
                <p class="control">
                  <span class="button is-static">URL:</span>
                </p>
                <b-input
                  v-model="iframe.data"
                  expanded
                ></b-input>
              </b-field>
              <b-field
                :message="iframe.message"
                :type="iframe.status"
              ></b-field>
      
          </section>

          <!-- <section>
          <div class="separator mb-2">Options</div>

            <div class="level">
              <div class="level-left">
                <div>Text Color:</div>
              </div>
              <div class="level-right">
                <b-colorpicker v-model="options.textColor" />
              </div>
            </div>
            <div class="level">
              <div class="level-left">
                <div>Background:</div>
              </div>
              <div class="level-right">
                <b-colorpicker v-model="options.bgColor" />
              </div>
            </div>

            <div class="field">
              <b-switch v-model="options.isNewTab">Open Links in a New Tab</b-switch>
            </div>
          </section> -->
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="setFeed()">Save</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import urlMixin from "@/mixins/urlMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "EditFrameModal",
  mixins: [globalMixin, urlMixin, toastMixin],
  data() {
    return {
      didAdd: false,
      options: {     
        bgColor: "#181d29",
        url: ""     
      },
      iframe: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "editFrame");
    },
    reset() {
      this.options = {
        bgColor: "#181d29",
        url: ""
      };
    },
    setFeed(){
      if(this.urlCheck()){
        this.options.url = this.iframe.data;
        this.saveItem();
      }
    },
    urlCheck() {
      if (!this.validURL(this.iframe.data)) {
        this.iframe.status = "is-danger";
        this.iframe.message = "Please enter a valid URL";
        return false;
      } else {
        this.iframe.status = null;
        this.iframe.status = "is-success";
        this.iframe.message = "";
        return true;
      }
    },
    saveItem(){         
      console.log("saving item..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      //this.options.textColor = this.options.textColor.toString('hex');
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
          ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {     
                  bgColor: "#181d29",
                  url: ""
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editFrame;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editFrame");
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
        this.iframe.data = this.iframe.feed;
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

