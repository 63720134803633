<template>
  <div class="ibox is-unselectable" :class="gridSettings.tiles.theme" :style="tileStyle"
    @mouseover="showEdited = true"
    @mouseleave="showEdited = false"
  >
    <div class="box-todo">
      <div v-if="options.title" class="title-todo" :style="{color: options.textColor, borderColor: borderColor}"><div class="title-todo-text">{{options.title}}</div></div>
      <section id="tinput">        
        <div @keyup.enter.exact="enterTask()">
          <b-field :type="taskForm.status">
            <b-input
              custom-class="todo-input"
              v-model="taskForm.data"
              placeholder="New Task"
              expanded
              @focus="$emit('isState', 'editing')"
              @blur="$emit('isState', 'saved')"          
            ></b-input>
            <p class="control">
              <b-button @click="saveTask(isEditing)" type="is-info" icon-right="floppy-disk" v-if="isEditing" :disabled="isEditable"/>
              <b-button @click="addTask()" type="is-success" icon-right="plus" v-if="!isEditing" :disabled="isEditable"/>
            </p>
          </b-field>
        </div>
      </section>
      <div class="list-todo scroll-item" :style="{color: options.textColor}">
      
        <draggable v-model="options.tasks" v-bind="dragOptions" group="todos" @start="drag=true" @end="drag=false" @change="checkMove()" handle=".handle">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <template v-for="item in options.tasks">
          <div v-if="!item.status" :key="item.id+item.task[0]" @mouseover="showByIndex = item.id" @mouseout="showByIndex = null" class="item-todo" :style="{borderColor: borderColor}">

            <div class="text-todo" :class="{'text-done':item.status}" @click="togleStatus(item.id)" >{{unescapeQuotes(item.task)}}</div>

            <div v-show="showByIndex === item.id && !drag" class="move-todo">
              <!-- <b-button @click="togleStatus(item.id)" type="is-success" size="is-small" icon-right="check"/> -->
              <b-button @click="editItem(item.id)" type="is-info" size="is-small" icon-right="pencil"/>         
              <b-button type="is-dark" size="is-small" icon-right="grip-dots" class="handle"/>
            </div>
            <!-- <div v-show="item.status & showByIndex === item.id" class="delete-todo">
              <b-button @click="deleteTask(item.id)" type="is-danger" size="is-small" icon-right="trash" />
            </div> -->
          </div>
        </template>
        </transition-group>
        </draggable>

        <template v-for="item in options.tasks">
          <div v-if="item.status" :key="item.id" @mouseover="showByIndex = item.id" @mouseout="showByIndex = null" class="item-todo" :style="{borderColor: borderColor}">
            <div :class="{'text-done':item.status}" @click="togleStatus(item.id)">{{unescapeQuotes(item.task)}}</div>
            <div v-show="item.status & showByIndex === item.id" class="delete-todo">
              <b-button @click="deleteTask(item.id)" type="is-danger" size="is-small" icon-right="trash" />
            </div>
          </div>
        </template>
    
      </div>
    </div>
    <div class="box-copy" v-show="showEdited">
        <div @mouseover="showEditedOn = true" @mouseleave="showEditedOn = false">
          <b-tag class="info-tag" v-if="options.lastEditBy && !showEditedOn" type="is-info">Edited by: <span class="name-tag">{{options.lastEditBy}}</span></b-tag>
          <b-tag class="info-tag" v-if="options.lastEditBy && showEditedOn" type="is-info">{{options.lastEdit}}</b-tag>
        </div>     
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import colorMixin from "@/mixins/colorMixin";
import genRandom from "@/mixins/genRandom";
export default {
  name: 'TodoItem',
  props: {
    options: Object,
    elementID: Number,
  },
  components: {
    draggable
  },
  mixins: [sanitizeMixin, colorMixin, genRandom],
  data(){
    return {
      showEdited: false,
      showEditedOn: false,
      taskForm: {
        data:"",
        status:"",
      },
      isEditing:null,
      showByIndex: null,
      drag: false,
      delayedDragging: false,
      order: [],
    }
  },
  methods:{
    editItem(id){
      this.isEditing = id;
      let found = this.options.tasks.indexOf(this.options.tasks.find(obj=>obj.id==id));
      this.taskForm.data = this.unescapeQuotes(this.options.tasks[found].task);
      //this.showSave = true;
    },
    saveTask(id){
      let found = this.options.tasks.indexOf(this.options.tasks.find(obj=>obj.id==id));
      if(this.taskForm.data!==""){
        this.options.tasks[found].task = this.taskForm.data;
        this.saveLayouts();
      }else{
        this.deleteTask(id)
      }
      this.isEditing = null;
      this.taskForm.data = "";
    },
    checkMove(){
      this.didMove = true;
      console.log("did move");
      let count = this.options.tasks.length;
      console.log(count);
      //reindexing
      this.options.tasks.forEach((element, inc) => {
        element.id = inc+1;
      });
      console.log(this.options.tasks);
      this.saveLayouts();
      this.didMove = false;
      //this.showSave = true;
    },
    togleStatus(id){
      //item.status = !item.status
      console.log("changig status task.."+id);
      let found = this.options.tasks.indexOf(this.options.tasks.find(obj=>obj.id==id));
      this.options.tasks[found].status = !this.options.tasks[found].status;
      this.saveLayouts();
    },
    deleteTask(id){
      console.log("deleting task.."+id);
      let found = this.options.tasks.indexOf(this.options.tasks.find(obj=>obj.id==id));
      if(this.options.tasks.length>=2){
        this.options.tasks.splice(found,1);
      }else{
        this.options.tasks.pop();
      }
      //reindexing
      this.options.tasks.forEach((element, inc) => {
        element.id = inc+1;
      });
      this.saveLayouts();
    },
    enterTask(){
      if(this.isEditing){
        this.saveTask(this.isEditing);
      }else{
        this.addTask();
      }
    },
    addTask(){
      if(this.formCheck()){
        let lowest = 1;
        if (
          (this.options.tasks !== undefined) &
          (this.options.tasks.length !== 0)
        ) {
          lowest = this.options.tasks.length + 1;
        }
        // let lowest = 0;
        // if(this.options.tasks !== undefined & this.options.tasks.length !== 0){
        //   //generating id
        //   let ids = [];
        //   this.options.tasks.forEach(item => ids.push(item.id));
        //   ids.sort(function(a, b) { return a-b; });   // To sort by numeric
        //   for (let i = 0;  i < ids.length;  ++i) {
        //     if (ids[i] != i) {
        //       lowest = i;
        //       break;
        //     }
        //   }
        //   if (lowest == 0) {
        //     console.log("ids.length "+ ids.length);
        //       lowest = ids[ids.length - 1] + 1;
        //   }
        // }
        console.log("lowest "+lowest);
        //---------------------------------------------------------------
        console.log("adding task..");
        //this.options.tasks.unshift({"id":lowest,"status":0,"task":this.sanitizeQuotes(this.taskForm.data)});
        this.options.tasks.unshift({"id":lowest,"status":0,"task":this.escapeQuotes(this.taskForm.data)});
        this.taskForm.data="";
        this.saveLayouts();
      } 
    },
    saveLayouts(){
      console.log("saving layout...");
      if(this.isType > 0){
        this.options.lastEdit = new Date().toLocaleString('en-US',{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
        this.options.lastEditBy = this.user.name;
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      }else{
        this.$store.commit("layouts/updateLocal");
      }
    },
    formCheck() {
      if (!this.taskForm.data) {
        this.taskForm.status = "is-danger";
        return false;
      } else {
        this.taskForm.status = "";
        return true;
      }
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("users", ["user"]),
     borderColor(){
      return this.convertHex(this.options.textColor, 10)
     },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch:{
    // options: {
    //   status(){
    //     console.log(new.status)
    //   }

    // },
    drag(nVal,oVal){
      console.log(nVal);
      if(nVal){
        this.showByIndex = null;
      }
      if(nVal && this.isEditing){
        this.isEditing = null;
        this.taskForm.data = "";
      }
      if(!nVal){
        this.showByIndex = null;
      }
    }

  }
}
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded{
  border-radius: 8px;
}
.border{
  border: solid 2px rgb(48, 48, 48);
}

.box-todo{
height: 100%;
}

.title-todo{
  padding:5px 10px 5px 10px;
  /* font-weight: bold; */
  font-size: small;
  border-bottom: 1px solid #222;
  background: rgba(0, 0, 0, 0.2);
}

.title-todo-text{
  opacity: .7;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.draggable{
  min-height: 20px;
}
.list-todo{
  padding:10px 10px 15px 10px;
  font-weight: 300;
  height: calc(100% - 50px);
}

.item-todo{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #222;
  position: relative;
  cursor: pointer;
}

.text-done{
  text-decoration: line-through;
  opacity: 0.5;
}
.delete-todo{
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}
.edit-todo{
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}
.text-todo{
  /* overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap; 
  position: relative;
  */
  transition: all 0.5s;
}
.text-todo:hover{
  opacity: .5;
  text-decoration: line-through;
}
/* .item-todo:hover .text-todo{
margin-left:40px;
} */
/* @keyframes strike{
  0%   { width : 0; }
  100% { width: 100%; }
} */



/* .text-todo:hover::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  background: rgb(255, 255, 255);
  animation-name: strike;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
} */
.text-todo-margin{
margin-left:40px;
}

.move-todo{
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}



.scroll-item {
  overflow: auto;
}

@-moz-document url-prefix() {
  .scroll-moz {
    overflow-y: scroll;
    scrollbar-color: #979797 rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
}

.scroll-item::-webkit-scrollbar {
  width: 4px;
}

.scroll-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-item::-webkit-scrollbar-thumb {
  background: #979797;
}

.box-copy {
  width: calc(100% - 10px);
  position: absolute;
  bottom: 3px;
  left: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: right;
}

.name-tag{
  text-transform: capitalize;
}
/*no select*/
.info-tag{
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>

<style>
.todo-input{
    color: #fff;
    background-color:#00000060;
    border: none;
}
.todo-input::placeholder {
  color: #ffffff85;
}
</style>
