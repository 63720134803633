export default {
  methods: {
    validURL(url) {
      if (typeof url !== "string" || url === "") {
        return false;
      }
      // const urlRegex = /^[a-zA-Z][a-zA-Z\d+.-]*:\/\/[^\s/]+(?::\d+)?(?:\/[^\s]*)?(?:\?[^\s]*)?(?:#[^\s]*)?$/;
      // if (!urlRegex.test(url)) {
      //   return false;
      // }
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(url);
    },
    extractHostname(url) {
      var hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname
      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }
      //find & remove port number
      hostname = hostname.split(":")[0];
      //find & remove "?"
      hostname = hostname.split("?")[0];
      if (hostname.split(".")[0] == "www") {
        hostname = hostname.split(".").slice(-2).join(".");
        return hostname;
      } else {
        return hostname;
      }
    },
    prepareURL(url) {
      //return !/^https?:\/\//i.test(link) ? `http://${link}` : link;
      if (typeof url !== "string" || url === "") {
        return "";
      }
      const protocolRegex = /^https?:\/\//;
      if (!protocolRegex.test(url)) {
        return "http://" + url;
      } else {
        return url;
      }
      //return (link.indexOf('://') === -1) ? 'http://' + link : link;
    },
    validYouTubeURL(url) {
      // First, we need to make sure that the URL is a string and is not empty.
      if (typeof url !== "string" || url === "") {
        return false;
      }

      // Next, we need to check if the URL matches the format of a YouTube URL.
      // YouTube URLs have the following format:
      //
      //   https://www.youtube.com/watch?v=<video-id>
      //
      // where <video-id> is a string of letters, numbers, and dashes.
      // We can use a regular expression to check if the URL matches this format.
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      if (!regExp.test(url)) {
        return false;
      }

      // Finally, we can check if the URL actually corresponds to a valid YouTube video
      // by making a request to the YouTube API. However, this would require an API key
      // and would require us to make an actual network request, so for the purposes of
      // this example, we will just return true without making an API request.
      return true;
    },
    extractVideoID(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    },
  },
};
