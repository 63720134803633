<template>
    <div class="clipboard-modal" v-if="items.length>0">
      <div class="clipboard-content">   
        <div class="mt-3">
          <div v-for="(item, index) in itemsByType" :key="index">
            <span>{{itemTypes[item.type]}}: {{item.count}}</span>
          </div>
        </div>
        <div class="buttons mt-3">
          <b-button @click="clearClipboard()" type="is-primary" size="is-small" outlined>Clear</b-button>
          <b-button @click="pasteItems()" type="is-primary" size="is-small">Paste</b-button>
        </div>    
      </div>     
    </div>
</template>

<script>
import { mapState } from "vuex";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "Clipboard",
  mixins: [toastMixin],
  data() {
    return {
      itemsByType: [],
      itemTypes: ["Link", "Clock", "Calculator", "Note", "Todo", "Wether", "Image", "Search", "Chrome Downloads", "Chrome History", "Chrome Bookmarks", "Calendar", "Link List", "RSS Feed", "Code Note", "Ambiance", "Frame", "Youtube List", "File Transfer"],
    }
  },
  computed: {
    ...mapState("clipboard", ["items"]),
    ...mapState("layouts", ["layouts", "isBoard", "isType", "isBoardCode"]),
    ...mapState("ws", ["wsStatus", "onlineUsers"]),
    ...mapState("users", ["user"]),
    ...mapState(["isEditable", "isLoadingBoard"]),
  },
  mounted() {
    this.calcItemsByType();
  },
  methods: {
    pasteItems() {
      //this.$store.dispatch("layouts/pasteItems");
      for(let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        this.addItem(item.type, item.options);
      }
      this.clearClipboard();
    },
    clearClipboard() {
      this.$store.commit("clipboard/clearClipboard");
    },
    addItem(type, options) {
      console.log("adding item..");     
      this.$store.dispatch("layouts/gridAction", {
        action: "addItem",
        data: { type: type, options: options },
      }).then(() => {
        if(this.isType == 1){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    calcItemsByType() {
      let items = this.items;
      this.itemsByType = [];
      if (items.length > 0) {
        items.forEach((item) => {
          let type = item.type;
          let index = this.itemsByType.findIndex((x) => x.type === type);
          if (index === -1) {
            this.itemsByType.push({ type: type, count: 1 });
          } else {
            this.itemsByType[index].count++;
          }
        });
      }
    },   
  },
  watch: {
    items: {
      handler: function (val, oldVal) {
        this.calcItemsByType();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

.clipboard-modal {
  /* background-color: #181d29; */
  background: #fff;
  color: #181d29;
  position: fixed;
  left: -1px;
  top: 50%;
  float: right;
  border-radius: 0 10px 10px 0;  
  transform: translate(0, -50%);
  z-index: 999;
  border-left: 5px solid #0278E8;
  box-shadow: inset -2px 0px 8px -1px rgba(168,168,168,1);
}

.clipboard-content{
  margin: 10px 20px;
}

</style>
