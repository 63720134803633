<template>
  <div 
    class="ibox" 
    :class="gridSettings.tiles.theme" :style="tileStyle"
  >
    <div v-if="isEditable" class="box-overlay"></div>
     <div class="box-image" v-bind:style="{backgroundColor: options.bgColor}" >
      
      <b-loading v-if="options.bgImage != 'images/px.png'" :is-full-page="false" v-model="imgLoading" :can-cancel="false"></b-loading>
      <img v-if="options.bgImage != 'images/px.png'" :src="options.bgImage" alt="image" class="image-item" @load="imgLoading = false" />
      <div class="foat-message" v-show="message">
        {{ message }}
      </div>

      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
      <b-field class="upload-field" v-if="!dropFile && options.bgImage == 'images/px.png'">
        <b-upload
          v-model="dropFile"
          :disabled="!user.isLogged"
          @input="checkFileSize"
          drag-drop
          accept="image/jpeg, image/jpg, image/png, image/gif, image/webp"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p v-if="user.isLogged">Drop your image here or click to upload</p>
              <p v-if="!user.isLogged">Plese Login to be able to upload Images</p>
            </div>
          </section>
        </b-upload>
      </b-field>
    </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
import colorMixin from "@/mixins/colorMixin";
import toastMixin from "@/mixins/toastMixin";
import randomMixin from "@/mixins/randomMixin";
export default {
  name: 'ImageItem',
  props: {
    options: Object,
    elementID: Number,
  },
  mixins: [colorMixin, toastMixin, randomMixin],
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("users", ["user"]),
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      style.color = this.contrastText(this.options.bgColor);
      return style;
    },
  },
  data() {
    return {
      isLoading: false,
      imgLoading: true,
      downloadUrl: null,
      dropFile: null,
      fileName: null,
      fileSize: "0 KB",
      validUntil: null,
      renameAttempt: 0,
      message: "",
    };
  },
  methods: {
    setErrorMessage(message) {
      this.message = message;
      setTimeout(() => {
        this.message = "";
      }, 5000);
    },
    clearUpload() {
      this.dropFile = null;
      this.fileName = null;
      this.fileSize = "0 KB";
      this.validUntil = null;
      this.isLoading = false;
      this.renameAttempt = 0;
    },
    checkFileSize() {
      //4 mb in bytes
      if(this.dropFile.size > 4 * 1024 * 1024){
        this.toast("File size must be less than 4 MB", "is-danger");
        this.setErrorMessage("File too large! (4 MB max.)");
        this.dropFile = null;
        return;
      }
      if(this.dropFile.name.length > 100){
        this.toast("File name must be less than 100 characters", "is-danger");
        this.setErrorMessage("File name too long! (100 characters max.)");
        this.dropFile = null;
        return;
      }
      this.uploadFile(this.dropFile, "bg");
    },
    uploadFile(file, type) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("action", type);
      formData.append("token", localStorage.grtoken);

      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: "wasabi/uploadimage_aws.php",
        data: formData,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            this.options.bgImage = response.data.url;
            this.saveLayouts();
          } else {
            this.dropFile = null;
            this.isLoading = false;
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    // uploadFile() {
    //   this.isLoading = true;

    //   let userID = "_" + this.user.id;

    //   let gotFileName = this.dropFile.name;

    //   const lastDot = gotFileName.lastIndexOf('.');
    //   const name = gotFileName.substring(0, lastDot);
    //   const ext = gotFileName.substring(lastDot + 1);

    //   this.fileName =  "image_" + this.makeRandomCode(10) + userID + "." + ext;
    //   console.log("Resulted fileName:", this.fileName);
    //   //axios post to get url
    //   this.axios
    //     .post("wasabi/manage_images_aws.php", {
    //       token: localStorage.grtoken,
    //       fileName: this.fileName,
    //       action: "url",
    //     })
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.data.success) {
    //         this.uploadToWasabi(response.data.url);
    //         //this.toast(response.data.message, "is-success");
    //       } else {
    //         this.isLoading = false;
    //         this.toast(response.data.message, "is-danger");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.toast("Something went wrong. Please try again.", "is-danger");
    //       this.isLoading = false;
    //     });
    // },
    // uploadToWasabi(uploadURL) {
    //   this.isLoading = true;
    //   const file = this.dropFile;

    //   this.axios
    //     .put(uploadURL, file, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         "x-amz-tagging": "UID=" + this.user.email,
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.isLoading = false;
    //       this.setItemOptions();
    //       this.saveLayouts();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.isLoading = false;
    //       this.toast("Something went wrong. Please try again.", "is-danger");
    //     });
    //   // this.isLoading = true;
    // },
    // setItemOptions() {
    //   this.options.bgImage = "https://gridsup-images.s3.eu-west-1.amazonaws.com/" + encodeURIComponent(this.fileName);
    // },
    clearItemOptiuons() {
      this.options.bgImage = "images/px.png";
    },
    saveLayouts() {
      console.log("saving layout...");
      if (this.isType > 0) {
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      } else {
        this.$store.commit("layouts/updateLocal");
      }
      this.clearUpload();
    },
  },
}
</script>
<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded{
  border-radius: 8px;
}
.border{
  border: solid 2px rgb(48, 48, 48);
}
.box-overlay{
  width: 100%;
  height: 100%;
  background-color: #00000050;
  z-index:1;
  position:absolute;
}
.box-image {
  background-color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: inherit;
}

.upload-field {
  height: inherit;
}

.upload-field .upload {
  height: inherit;
}

.foat-message {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  opacity: 1;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0px;
}

.image-item{
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* or object-fit: contain; */
  object-position: center center;
}
</style>
