<template>
  <div class="main-menu-btns" v-if="!this.$store.state.modals.sidebar">
    <b-button
      size="is-small"
      type="is-mydark"
      @click="toggleModal('sidebar')"
      icon-left="bars"
      label="Menu"
    />

<template v-for="(shorcut, index) in shortcutItems" v-if="!detectMobileDevice()">
  <b-tooltip
      v-if="gridSettings.interface[shorcut.name]"
      :label="shorcut.label"
      type="is-mydark"
      position="is-bottom"
      :delay="1000"
    >
      <b-button
        type="is-mydark"
        size="is-small"
        @click="shortcutAction(shorcut)"
        :icon-right="shorcut.icon"
      />
    </b-tooltip>
</template>
<ConnectionStatus />

    <!-- <b-button
      type="is-success"
      size="is-small"
      v-if="isEditable"
      @click="saveLayout()"
      icon-left="check"
      >Save Layout</b-button
    > -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConnectionStatus from "./ConnectionStatus.vue";

import ismobile from "@/mixins/ismobile";

export default {
  name: "MainNavigation",
  components: {
    ConnectionStatus,
  },
  mixins: [ismobile],
  data() {
    return {
      settings: {
        btAdd: true,
      },
      // interface:{
      //       webLink: true,
      //       linkList: true,
      //       youTubeList: true,
      //       note: false,
      //       codeNote: false,
      //       noteList: false,
      //       codeNoteList: false,
      //       todoList: false,
      //       calendar: false,
      //       clock: false,
      //       calculator: false,
      //       weather: false,
      //       image: false,
      //       search: false,
      //       ambiance: false,
      //       iframe: false,
      //       rss: false,
      //       isDark: false
      //   },
      shortcutItems: [
        {
          name: "webLink",
          modal: "addLink",
          label: "Add a Link",
          icon: "link",
          type: 0,
        },
        {
          name: "linkList",
          modal: "none",
          label: "Link List",
          icon: "list-timeline",
          type: 12,
        },
        {
          name: "note",
          modal: "none",
          label: "Note",
          icon: "note",
          type: 3,
        },
        {
          name: "noteList",
          modal: "none",
          label: "Note List",
          icon: "list",
        },
        {
          name: "codeNote",
          modal: "none",
          label: "Code Note",
          icon: "code",
          type: 14,
        },
        {
          name: "codeNoteList",
          modal: "none",
          label: "Code Note List",
          icon: "list",
        },
        {
          name: "todoList",
          modal: "none",
          label: "To Do List",
          icon: "clipboard-list",
          type: 4,
        },
        {
          name: "calendar",
          modal: "none",
          label: "Calendar",
          icon: "calendar",
          type: 11,
        },
        {
          name: "image",
          modal: "addImage",
          label: "Image",
          icon: "image",
          type: 6,
        },
        {
          name: "ambiance",
          modal: "none",
          label: "Ambiance",
          icon: "music",
          type: 15,
        },
        {
          name: "clock",
          modal: "addClock",
          label: "Clock",
          icon: "clock",
          type: 1,
        },
        {
          name: "calculator",
          modal: "none",
          label: "Calculator",
          icon: "calculator",
          type: 2,
        },
        {
          name: "weather",
          modal: "none",
          label: "Weather",
          icon: "cloud",
          type: 5,
        },
        {
          name: "search",
          modal: "none",
          label: "Search",
          icon: "magnifying-glass",
          type: 7,
        },
        {
          name: "iframe",
          modal: "addFrame",
          label: "Iframe",
          icon: "object-group",
          type: 16,
        },
        {
          name: "rss",
          modal: "addRSS",
          label: "RSS",
          icon: "square-rss",
          type: 13,
        },
        // {
        //   name: "isDark",
        //   modal: "none",
        //   label: "Dark Mode",
        //   icon: "moon",
        //   type: 8,
        // },
        {
          name: "youTubeList",
          modal: "none",
          label: "YouTube List",
          icon: "youtube",
          type: 17,
        },
        {
          name: "fileTransfer",
          modal: "none",
          label: "File Transfer",
          icon: "arrow-down-arrow-up",
          type: 18,
        },
      ],
    };
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState("users", ["user"]),
  },
  methods: {
    toggleModal(modal) {
      this.$store.commit("modals/toggleModal", modal);
      console.log("test:", modal);
    },
    // setBlack(val) {
    //   this.$store.commit("setBlack", val);
    // },
        // saveLayout() {
    //   this.$store.commit("layout/saveLayout");
    // },    
    addItem(type) {
      console.log("adding item..");
      
      // if(type == 18 && this.user.status == 0){
      //   this.toast("You can't add files to shared grids! Please Subscribe.", "is-danger");
      //   return;
      // }

     this.$store.dispatch("layouts/gridAction", {
       action: "addItem",
       data: { type: type, options: {} },
     }).then(() => {
       if(this.isType > 0){
         this.$store.dispatch("layouts/saveSharedGrid");
       }
     }).catch((err) => {
       console.log(err);
     });
    },
    openModal(modal) {
      if(modal=="addFrame" && this.isType > 0){
        this.toast("You can't add frames to shared grids!", "is-danger");
        return;
      }
      console.log("open " + modal);
      this.$store.commit("modals/showModal", modal);
    },

    shortcutAction(shorcut) {
      console.log("shortcutAction:", shorcut.type);
      let modal = shorcut.modal;
      let type = shorcut.type;
      if(modal == "addLink" || modal == "addFrame" || modal == "addRSS" || modal == "addImage" || modal == "addClock"){
        this.openModal(modal);
      }else{
        this.addItem(type);
      }
    },
  },
};
</script>

<style scoped>
.main-menu-btns {
  z-index: 39; /*997*/
  position: fixed !important;
  top: 5px;
  left: 5px;
}
.main-menu-btns button {
  margin-right: 5px;
}
</style>
