import { Howl, Howler } from "howler";

export default {
  namespaced: true,
  state: {
    volume: 0.5,
    howlerSet: false,
    sounds: [
      {
        name: "white",
        status: false,
        file: "./sounds/white.mp3",
        length: 10000,
      },
      {
        name: "rain",
        status: false,
        file: "./sounds/rain.mp3",
        length: 66000,
      },
      {
        name: "thunder",
        status: false,
        file: "./sounds/thunder.mp3",
        length: 74000,
      },
      {
        name: "storm",
        status: false,
        file: "./sounds/storm.mp3",
        length: 30000,
      },
      {
        name: "wind",
        status: false,
        file: "./sounds/wind.mp3",
        length: 58000,
      },
      {
        name: "windchimes",
        status: false,
        file: "./sounds/windchimes.mp3",
        length: 64000,
      },
      {
        name: "fire",
        status: false,
        file: "./sounds/fire.mp3",
        length: 30000,
      },
      {
        name: "summernight",
        status: false,
        file: "./sounds/summer.mp3",
        length: 52000,
      },
      {
        name: "birds",
        status: false,
        file: "./sounds/birds.mp3",
        length: 57000,
      },
      {
        name: "creek",
        status: false,
        file: "./sounds/creek.mp3",
        length: 30000,
      },
      {
        name: "winter",
        status: false,
        file: "./sounds/winter.mp3",
        length: 60000,
      },
      {
        name: "wolves",
        status: false,
        file: "./sounds/wolves.mp3",
        length: 66000,
      },
      {
        name: "forest",
        status: false,
        file: "./sounds/forest.mp3",
        length: 60000,
      },
      {
        name: "owl",
        status: false,
        file: "./sounds/owl.mp3",
        length: 57000,
      },
      {
        name: "sea",
        status: false,
        file: "./sounds/sea.mp3",
        length: 30000,
      },
      {
        name: "boat",
        status: false,
        file: "./sounds/onboat.mp3",
        length: 29000,
      },
      {
        name: "whale",
        status: false,
        file: "./sounds/whale.mp3",
        length: 63000,
      },
      {
        name: "bowl",
        status: false,
        file: "./sounds/bowl.mp3",
        length: 69000,
      },
      {
        name: "drum",
        status: false,
        file: "./sounds/drum.mp3",
        length: 35000,
      },
      {
        name: "flute",
        status: false,
        file: "./sounds/flute.mp3",
        length: 29000,
      },
      {
        name: "children",
        status: false,
        file: "./sounds/children.mp3",
        length: 29000,
      },
    ],
  },
  getters: {
    getIsPlayng(state) {
      let playngArray = state.sounds.filter((sound) => sound.status === true);
      if (playngArray.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getVolume(state) {
      return state.volume;
    },
  },
  mutations: {
    setSounds(state, payload) {
      state.sounds[payload.index].status = payload.status;
    },
    setHowler(state) {
      console.log("setHowler");
      if (!state.howlerSet) {
        Howler.volume(0.5);
        state.howlerSet = true;

        state.sounds.forEach((item) => {
          item.sound = new Howl({
            src: [item.file],
            // loop: true,
            volume: 0.5,
            sprite: {
              loop: [100, item.length-100, true],
            },
          });
        });
        state.howlerSet = true;
      }
    },
    setVolume(state, payload) {
      Howler.volume(payload);
      state.volume = payload;
    }
  },
  actions: {
    // setHowler(state) {
    //   console.log("setHowler");
    //   if (!state.howlerSet) {
    //     Howler.volume(0.5);
    //     state.howlerSet = true;

    //     state.sounds.forEach((item) => {
    //       item.sound = new Howl({
    //         src: [item.file],
    //         // loop: true,
    //         volume: 0.5,
    //         sprite: {
    //           loop: [0, item.length, true],
    //         },
    //       });
    //     });
    //     state.howlerSet = true;
    //   }
    // },
  },
  modules: {},
};
