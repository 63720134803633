<template>
  <section>
    <b-sidebar
      type="is-mydark"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :open.sync="isActive"
      :right="false"
      can-cancel
    >
      <div class="px-2 pt-4 has-text-light scroll unselectable">
        <svg
          version="1.1"
          id="logo"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 325 120"
          style="enable-background: new 0 0 325 120"
          xml:space="preserve"
        >
          <g id="text">
            <path
              fill="#FFFFFF"
              d="M114.7,79c1.1,0.8,2.4,1.5,4,2s3.1,0.7,4.6,0.7c2.9,0,5-0.7,6.6-2.1c1.5-1.4,2.3-3.5,2.3-6.3v-3.4
            c-0.5,0.8-1.2,1.6-2.1,2.4c-0.9,0.8-2,1.4-3.2,1.8c-1.2,0.5-2.5,0.7-3.9,0.7c-2.6,0-4.9-0.6-7-1.8c-2.1-1.2-3.7-2.9-4.9-5.1
            c-1.2-2.2-1.8-4.7-1.8-7.4c0-2.8,0.6-5.3,1.8-7.5c1.2-2.2,2.8-3.9,4.9-5.1s4.4-1.8,6.9-1.8c2.1,0,4,0.5,5.6,1.4s2.9,1.9,3.7,3v-3.9
            h5.4v26c0,3.3-0.7,6-2,8s-3.1,3.5-5.2,4.4c-2.2,0.9-4.5,1.4-7.1,1.4c-2,0-3.9-0.3-5.9-0.9s-3.5-1.4-4.7-2.4L114.7,79z M123.5,51
            c-1.7,0-3.2,0.4-4.5,1.2c-1.3,0.8-2.4,2-3.1,3.4c-0.8,1.4-1.1,3-1.1,4.8c0,1.8,0.4,3.4,1.1,4.8c0.8,1.4,1.8,2.5,3.1,3.4
            c1.3,0.8,2.8,1.2,4.5,1.2c1.8,0,3.3-0.4,4.6-1.2c1.3-0.8,2.4-1.9,3.1-3.4c0.7-1.4,1.1-3,1.1-4.8c0-1.8-0.4-3.5-1.1-4.9
            c-0.7-1.4-1.8-2.5-3.1-3.4C126.8,51.4,125.3,51,123.5,51z"
            />
            <path
              fill="#FFFFFF"
              d="M160.7,52.5c-0.4-0.2-0.8-0.3-1.3-0.4s-1-0.2-1.6-0.2c-1.1,0-2.2,0.3-3.3,0.9s-2,1.4-2.7,2.5s-1.1,2.4-1.1,3.8
            v15.3h-5.5V46.6h5.5v5.2c0.8-1.7,1.9-3.1,3.5-4.2s3.2-1.6,4.9-1.6c1.3,0,2.3,0.2,3,0.5L160.7,52.5z"
            />
            <path
              fill="#FFFFFF"
              d="M170.1,40.6c-1.2,0-2.1-0.3-2.7-1c-0.6-0.6-0.9-1.5-0.9-2.5c0-0.9,0.3-1.7,0.9-2.4c0.6-0.7,1.5-1.1,2.7-1.1
            s2.1,0.3,2.7,1c0.6,0.6,0.9,1.5,0.9,2.5c0,0.9-0.3,1.7-0.9,2.4C172.3,40.3,171.4,40.6,170.1,40.6z M172.9,74.4h-5.4V46.6h5.4V74.4z
            "
            />
            <path
              fill="#FFFFFF"
              d="M207.2,35.3v39.1h-5.4v-3.9c-0.8,1.1-2,2.1-3.6,3s-3.4,1.4-5.4,1.4c-2.5,0-4.8-0.6-6.8-1.8
            c-2-1.2-3.6-2.9-4.8-5.1s-1.7-4.7-1.7-7.4c0-2.8,0.6-5.3,1.7-7.5c1.2-2.2,2.7-3.9,4.7-5.1s4.2-1.8,6.7-1.8c2,0,3.8,0.4,5.4,1.2
            c1.6,0.8,2.9,1.8,3.8,2.9v-15H207.2z M193.5,70.2c1.7,0,3.2-0.4,4.4-1.3c1.3-0.8,2.3-2,3-3.5s1.1-3.1,1.1-5s-0.4-3.5-1.1-5
            c-0.7-1.5-1.7-2.6-3-3.5c-1.3-0.8-2.7-1.3-4.4-1.3s-3.1,0.4-4.4,1.3c-1.3,0.8-2.3,2-3,3.5s-1.1,3.1-1.1,5c0,1.8,0.4,3.5,1.1,5
            c0.7,1.5,1.7,2.6,3,3.5C190.4,69.8,191.8,70.2,193.5,70.2z"
            />
            <path
              fill="#FFFFFF"
              d="M230.7,53.9c-1-1-2.1-1.8-3.4-2.4c-1.3-0.6-2.4-1-3.5-1c-0.7,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.3,0.5-1.8,1
            s-0.7,1.2-0.7,2.1c0,1.3,0.6,2.2,1.7,2.7c1.1,0.5,2.5,1,4.1,1.4c1.4,0.4,2.9,0.9,4.2,1.5c1.4,0.6,2.5,1.4,3.4,2.5s1.3,2.7,1.3,4.7
            c0,1.7-0.5,3.2-1.5,4.4c-1,1.2-2.2,2.2-3.8,2.8c-1.5,0.7-3.1,1-4.8,1c-2,0-4-0.4-5.9-1.1c-2-0.7-3.6-2-5-3.7l3.7-3.2
            c1,1.1,2,1.9,3.2,2.6c1.2,0.6,2.7,0.9,4.5,0.9c0.7,0,1.3-0.1,2-0.3c0.7-0.2,1.2-0.6,1.7-1.1s0.7-1.2,0.7-2.1c0-0.8-0.3-1.5-0.8-2
            s-1.2-0.9-2-1.2c-0.8-0.3-1.8-0.6-2.8-0.8c-1.5-0.4-3-0.9-4.5-1.6c-1.5-0.6-2.7-1.5-3.6-2.6c-1-1.1-1.5-2.7-1.5-4.7
            c0-1.7,0.5-3.1,1.4-4.3c0.9-1.2,2.2-2.1,3.7-2.7c1.5-0.7,3.1-1,4.9-1c1.7,0,3.5,0.4,5.4,1.1s3.5,1.8,4.8,3.4L230.7,53.9z"
            />
            <path
              fill="#FFFFFF"
              d="M258.9,63.3V46.6h5.4v27.8h-5.4V70c-0.8,1.3-2,2.5-3.6,3.5s-3.4,1.5-5.6,1.5c-2.9,0-5.2-1-7-3
            s-2.7-4.7-2.7-8.2V46.6h5.4v15.6c0,1.4,0.2,2.7,0.7,3.9c0.4,1.2,1.1,2.2,2.1,2.9c1,0.7,2.2,1.1,3.7,1.1c1.9,0,3.5-0.6,4.9-1.8
            C258.2,67.2,258.9,65.5,258.9,63.3z"
            />
            <path
              fill="#FFFFFF"
              d="M286.4,46.2c2.5,0,4.7,0.6,6.6,1.8c2,1.2,3.5,2.9,4.6,5s1.7,4.6,1.7,7.5c0,2.8-0.6,5.3-1.7,7.4
            c-1.1,2.2-2.7,3.9-4.6,5.1s-4.1,1.8-6.5,1.8c-2,0-3.8-0.4-5.4-1.3c-1.6-0.8-2.9-1.8-3.8-2.9v15.3h-5.5V46.6h5.5v4.1
            c0.8-1.2,2-2.2,3.6-3.1C282.7,46.6,284.5,46.2,286.4,46.2z M285.7,51.2c-1.7,0-3.1,0.4-4.4,1.2c-1.3,0.8-2.3,1.9-3,3.3
            c-0.7,1.4-1.1,3-1.1,4.8c0,1.8,0.4,3.3,1.1,4.8c0.7,1.4,1.7,2.5,3,3.4s2.8,1.2,4.4,1.2s3.1-0.4,4.4-1.2c1.3-0.8,2.3-1.9,3-3.4
            c0.7-1.4,1.1-3,1.1-4.8c0-1.8-0.4-3.4-1.1-4.8s-1.7-2.5-3-3.3C288.8,51.6,287.3,51.2,285.7,51.2z"
            />
          </g>
          <linearGradient
            id="grad1"
            gradientUnits="userSpaceOnUse"
            x1="57.808"
            y1="27.5"
            x2="57.808"
            y2="92.5"
          >
            <stop offset="0" style="stop-color: #47f9cc" />
            <stop offset="1" style="stop-color: #0074e9" />
          </linearGradient>
          <path
            fill="url(#grad1)"
            d="M57.8,92.5c-18,0-32.6-14.6-32.6-32.6c0-16.2,12.2-30.1,28.4-32.3l0.5-0.1v9.3l-0.4,0.1
          c-11.1,2.1-19.2,11.7-19.2,23c0,12.9,10.5,23.3,23.3,23.3c12.9,0,23.3-10.5,23.3-23.3c0-8.2-4.2-15.8-11.1-20v19.1h-8.5V27.5
          l0.5,0.1C78.2,30.1,90.4,44,90.4,59.9c0,8.5-3.5,16.8-9.6,23C74.6,89.1,66.5,92.5,57.8,92.5z"
          />
        </svg>

        <div class="separator has-text-grey-light mb-1">Select Item to Add</div>
        <b-menu :activable="false">
          <b-menu-list>
            <b-menu-item
              @click="openModal('addLink')"
              icon="link"
              label="Website Link"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(12)"
              icon="list-timeline"
              label="Link List"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(3)"
              icon="note"
              label="Note"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(4)"
              icon="clipboard-list"
              label="ToDo List"
            ></b-menu-item>
            <b-menu-item
              @click="openModal('addClock')"
              icon="clock"
              label="Clock"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(2)"
              icon="calculator"
              label="Calculator"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(5)"
              icon="cloud"
              label="Weather"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(6)"
              icon="image"
              label="Image"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(7)"
              icon="magnifying-glass"
              label="Search"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(11)"
              icon="calendar-days"
              label="Calendar"
            ></b-menu-item>
            <b-menu-item
              @click="addItem(15)"
              icon="music-note"
              label="Ambiance"
            ></b-menu-item>
            <b-menu-item icon="bars" label="Advanced">
            <b-menu-item
              @click="addItem(18)"
              icon="arrow-down-arrow-up"
              label="File Transfer" 
            ></b-menu-item>
              <b-menu-item
              @click="addItem(17)"
              icon="youtube"
              label="YouTube List" 
            ></b-menu-item>
            <b-menu-item
              @click="addItem(14)"
              icon="code"
              label="Code Note"
            ></b-menu-item>
              <b-menu-item
              @click="openModal('addFrame')"
              icon="object-group"
              label="iFrame"
            ></b-menu-item>
            <b-menu-item
              @click="openModal('addRSS')"
              icon="square-rss"
              label="RSS Feed"
            ></b-menu-item>
            </b-menu-item>
            <!-- <b-menu-item icon="bars" label="Browser Shortcuts" v-if="$gridVars.browserType=='chrome'">
              <b-menu-item
                @click="addItem(8)"
                icon="download"
                label="Downloads"
              ></b-menu-item>
              <b-menu-item
                @click="addItem(9)"
                icon="clock-rotate-left"
                label="History"
              ></b-menu-item>
              <b-menu-item
                @click="addItem(10)"
                icon="bookmark"
                label="Bookmarks"
              ></b-menu-item>
            </b-menu-item> -->
          </b-menu-list>
        </b-menu>

        <div class="my-4">
          <div class="separator has-text-grey-light mb-4">Account</div>

          <div
            class="has-text-grey-light has-text-centered"
            v-if="user.isLogged"
          >
            <p>{{ user.email }}</p>
            <p>
              <a class="menu_link" @click="openModal('logout')"
                >Change account?</a
              >
            </p>

            <div class="my-3" v-if="user.status == 0">
              <b-button @click="openModal('pay')" type="is-success" expanded
                >Upgrade Subscribtion</b-button
              >
            </div>

          </div>
          <div class="px-2" v-if="!user.isLogged">
            <b-button @click="openLogin()" type="is-primary" expanded
              >Sign In</b-button
            >
            <div class="mt-2 level">
              <a @click="showRegister()" type="is-light">Create an account?</a>
              <b-tooltip
                position="is-left"
                type="is-primary"
                class="has-text-mydark-lighter"
                ><i class="circle-question"></i>
                <template v-slot:content>
                  <strong class="has-text-light"
                    >Features:</strong
                  ><br />
                  &bull; Cloud Syncronization<br />
                  &bull; Multiple Grids <br />
                  &bull; Shared Grids <br />
                  and more...
                </template>
              </b-tooltip>
            </div>
          </div>
        </div>

        <!-- <div class="my-4">
          <div class="separator has-text-grey-light mb-3">
            Local Export &amp; Import
          </div>

          <div class="columns px-2 my-3">
            <div class="column">
              <b-button
                @click="exportLayout()"
                size="is-small"
                type="is-success"
                expanded
                outlined
                ><i class="file-export"></i> Export</b-button
              >
            </div>

            <div class="column">
              <b-field>
                <b-upload
                  @input="warningImport()"
                  v-model="importData"
                  accept=".gridsup"
                >
                  <a class="button is-primary is-small is-outlined"
                    ><i class="file-import icom"></i> Import</a
                  >
                </b-upload>
              </b-field>
            </div>
          </div>
        </div> -->

        <div class="my-4">
          <div class="separator has-text-grey-light mt-2 mb-3">
            Settings &amp; Backups
          </div>
          <b-menu :activable="false">
            <b-menu-list>
              <b-menu-item
                @click="openModal('settings')"
                icon="gear"
                label="General Settings"
              ></b-menu-item>
              <b-menu-item
                @click="editBoards()"
                icon="files"
                label="Manage Boards"
              ></b-menu-item>
              <b-menu-item
                v-if="user.isLogged"
                @click="openModal('backups')"
                icon="cloud"
                label="Backups"
              ></b-menu-item>
              <b-menu-item
                v-if="user.isLogged"
                @click="openModal('account')"
                icon="user"
                label="Account Settings"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";

import { mapState } from "vuex";
export default {
  name: "Sidebar",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      // userStatus: this.$store.state.user.status,
      btStatus: {
        backup: false,
        restore: false,
      },
      selectedPattern: null,
      importData: null,
      customGrid: false,
    };
  },
  methods: {
    warningLogout() {
      this.$buefy.dialog.confirm({
        title: "Change Account",
        message:
          "Are you sure you want to Sign Out? <br/><small><strong>Note:</strong> <em>Your current layout will be still avalible until you reset it or load another layout.</em></small>",
        confirmText: "Sign Out",
        type: "is-warning",
        onConfirm: () => this.doLogout(),
      });
    },
    onLogout() {
      this.$store.commit("users/logoutUser");
      this.showLogin();
    },
    addItem(type) {
      console.log("adding item..");
      // if(type == 18 && this.user.status == 0){
      //   this.toast("You can't add files to shared grids! Please Subscribe.", "is-danger");
      //   return;
      // }
     
      this.$store.dispatch("layouts/gridAction", {
        action: "addItem",
        data: { type: type, options: {} },
      }).then(() => {
        if(this.isType == 1){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
      }).catch((err) => {
        console.log(err);
      });

      //this.$store.commit("layouts/addItem", { type: type, options: {} });
      this.closeSidebar();
    },
    openModal(modal) {
      if(modal=="addFrame" && this.isType > 0){
        this.toast("You can't add frames to shared grids!", "is-danger");
        this.closeSidebar();
        return;
      }
      console.log("open " + modal);
      this.$store.commit("modals/showModal", modal);
      this.closeSidebar();
    },
    closeSidebar() {
      this.$store.commit("modals/hideModal", "sidebar");
      //this.$store.commit("SAVE_SETTINGS");
    },
  
  
    editBoards() {
      this.openModal("pages");
      if(this.user.isLogged){
        if(this.user.status>0){
          this.openModal("pages");
        }else{
          this.openModal("pay");
        }
      }else{
        this.showLogin();
      }
    },
    openLogin() {
      this.$store.commit("modals/hideModal", "sidebar");
      this.showLogin();
    },
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["isType"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.sidebar;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "sidebar");
        return false;
      },
    },
  },
};
</script>
