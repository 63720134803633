export default {
  namespaced: true,
  state: {
    //elements: [{"grcode": "YzNjZmZjZDhkNmY3", "elements": [{"id": 4, "status": "editing", "user": "egoomni"}]}],
    elements: [],
    gridStates: [],
  },
  getters: {
    getElementStates: (state) => {
      return state.elements;
    },
    getGridStatus: (state) => {
      return state.gridStates;
    },
  },
  mutations: {
    //##############################################################################
    setElementStatus(state, payload) {
      //payload: {grcode: grcode, status: status, userID: userID, elementID: elementID}
      if (payload.status == "editing") {
        let username = payload.userID.split("@")[0];

        let index = _.findIndex(state.elements, { grcode: payload.grcode });
        if (index == -1) {
          state.elements.push({
            grcode: payload.grcode,
            elements: [
              { id: payload.elementID, status: payload.status, user: username },
            ],
          });
          return;
        }else{
          let itemIndex = _.findIndex(state.elements[index].elements, {
            id: payload.elementID,
          });
          if(itemIndex == -1){
            state.elements[index].elements.push({ id: payload.elementID, status: payload.status, user: username });
          }
        }
      } else if (payload.status == "saved") {
        let index = _.findIndex(state.elements, { grcode: payload.grcode });
        if (index == -1) return;

        let itemIndex = _.findIndex(state.elements[index].elements, {
          id: payload.elementID,
        });
        state.elements[index].elements.splice(itemIndex, 1);
        if (state.elements[index].elements.length == 0) {//????
          state.elements.splice(index, 1);
        }
      }
    },
    //##############################################################################
    setGridStatus(state, payload) {
      //payload: {grcode: grcode, status: status, userID: userID}
      if (payload.status == "editing") {
        let username = payload.userID.split("@")[0];

        let index = _.findIndex(state.gridStates, { grcode: payload.grcode });
        if (index == -1) {
          state.gridStates.push({
            grcode: payload.grcode,
            user: username
          });
          return;
        }
      } else if (payload.status == "saved") {
        let index = _.findIndex(state.gridStates, { grcode: payload.grcode });
        if (index == -1) return;
        state.gridStates.splice(index, 1);
      }
    },
    //##############################################################################
  },

  actions: {},
  modules: {},
};
