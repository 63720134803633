<template>
<div>
       <div class="is-size-5 has-text-weight-bold mb-2">Shared with You</div>

              <!-- <div class="spacer">
                      
              </div>            -->

              <div class="addcatbox mb-5 has-text-centered">
                <b-button icon-left="rotate-right" class="button is-primary" @click="reloadCommonGrids()" :loading="isLoading">Check Incoming Boards</b-button>          
              </div>
       
             <div class="scroll-grids">
              <div>
                  <div class="catbox mb-2" v-for="(item, index) in inBoards" :key="item.id" >
                    <div class="level level-mb">
                      <div class="level-left level50">
                        <span class="item-name">{{item.name}}</span>
                      </div>
                      <div class="level-right">
                        <b-tooltip
                          label="Show Tab"
                          position="is-left"
                          :delay="1500"
                        >
                          <b-field>
                            <b-switch
                              :value="isTab(item)"
                              @input="toggleTab(item)"
                              type="is-primary"
                              size="is-small"
                            />
                          </b-field>
                        </b-tooltip>
                        <div class="bicon-button" @click="showUsers(item)"><b-icon icon="user-group"></b-icon></div>
                        <div class="bicon-button" @click="warningSelfRemove(item)"><b-icon icon="arrow-right-from-bracket"></b-icon></div>
                      </div>                   
                    </div>
                  

    <div v-if="isShareID == item.id">
      <span class="is-size-7 has-text-grey-light">People with access to this grid:</span>
      <div v-for="user in isBoardUsers"><b-tag type="is-primary">{{user}}</b-tag></div>
    </div>
  </div>
      </div>
            </div>
  
  </div>
</template>

<script>
import toastMixin from "@/mixins/toastMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import { mapState } from "vuex";
export default {
  name: "IncomingGrids",
  mixins: [sanitizeMixin, toastMixin],
  data() {
    return {
      isLoading: false,
      isShareID: null,
      isBoardUsers: [],
      newBoard: null,
      drag: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        filter: '#home'
      }
    };
  },
  methods: {    
    showModal(modal) {
      this.$store.commit("modals/showModal", modal);
    },
    toggleTab(item) {
      let newTab = {
        id: item.id,
        name: item.name,
        type: 2,
        grcode: item.grcode,
      };
      this.$store.commit("gridtabs/toggleTab", newTab);
    },
    isTab(item) {
      let foundIndex = this.gridTabs.findIndex((x) => x.grcode == item.grcode);
      if (foundIndex > -1) {
        return true;
      } else {
        return false;
      }
      //this.gridTabs
      //return item.id == this.isHomepage;
    },
    showUsers(item){
      console.log("showUsers");
      this.isBoardUsers = [];
      if(this.isShareID == item.id){
        this.isShareID = null;
        return;
      };
      this.isShareID = item.id;
      if(item.users){
        this.isBoardUsers = item.users.split(',');
      }
    },
    selfRemove(item){
      console.log("self demove");
      let deleteTab = {
              id: item.id,
              name: item.name,
              type: 2,
              grcode: item.grcode,
            };
      this.$store.commit("gridtabs/removeTab", deleteTab);
      this.$store.dispatch("layouts/deleteCommonGrid", item.id);
      // this.axios
      // .post(`sharedgrids/get_grids.php`, {
      //     token: localStorage.grtoken,
      //     action: "selfRemove",
      //     itemID: id,
      //   })
      //   .then(response => {
      //     if (response.data.success) {
      //       this.toast(response.data.message, "is-success");
      //       let deleteTab = {
      //         id: item.id,
      //         name: item.name,
      //         type: 2,
      //         grcode: item.grcode,
      //       };
      //       this.$store.commit("gridtabs/removeTab", deleteTab);
      //       this.$store.dispatch("layouts/deleteCommonGrid");
      //       //this.$store.dispatch("layouts/loadCommonGrids");
      //     } else {
      //       this.toast(response.data.message, "is-danger");
      //     }
      //   })
      //   .catch(error => {
      //     this.toast(error, "is-danger");
      //   });
    },
    warningSelfRemove(item) {
      this.$buefy.dialog.confirm({
        title: "Leave Grid",
        message:
          "Are you sure you want to leave this grid?",
        confirmText: "Leave",
        type: "is-danger",
        hasIcon: true,
        icon: "arrow-right-from-bracket",
        onConfirm: () => this.selfRemove(item)
      });
    },
    reloadCommonGrids(){
      this.isLoading = true;
      this.$store.dispatch("layouts/loadCommonGrids");
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
  },
  computed: {
    //...mapState(["isLoged","isUser","layouts"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["layoutsCommon"]),
    ...mapState("gridtabs", ["gridTabs"]),
    inBoards: {
      get: function() {
        return this.$store.state.layouts.layoutsCommon;
      },
      // set: function() {
      //   return false;
      // }
      // setter
      set: function(val) {
        this.$store.commit("layouts/updateBoards", val);
        return false;
      }
    }
  },
  watch:{
    isActive: function(val){
      if(val){
        //general------------------------------------
        this.hasBoards = this.pages;
      }
    }
  }
};
</script>
<style scoped>
  .bg-preview {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    margin-top:10px;
  }
  .catbox{
    padding: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }
  .addcatbox{
    padding: 15px;
    border: 1px dashed #dfdfdf;
    border-radius: 5px;
  }

  /* .scroll-wrapp{
    height: 600px;
    overflow: hidden;
    overflow-y: scroll;
  } */


  .level50{
    width:50%;
  }

  .item-name{
    width:100%;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .scroll-grids {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    scrollbar-color: rgba(71, 249, 204);
    scrollbar-width: 4px;
  }
  .scroll-grids::-webkit-scrollbar-track {
    background-color: #e9e9e9 !important;
  }

  .scroll-grids::-webkit-scrollbar {
    width: 4px;
  }

  .scroll-grids::-webkit-scrollbar-thumb {
    background: linear-gradient(
      180deg,
      rgba(71, 249, 204, 1) 0%,
      rgba(2, 120, 232, 1) 100%
    ) !important;
  }

  .spacer{
    height: 95px;
  }

  .level-mb:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
</style>
