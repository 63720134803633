var emoji = require("node-emoji");

// function escapeHTML(str) {
//   let escapeChars = {
//     "¢": "cent",
//     "£": "pound",
//     "¥": "yen",
//     "€": "euro",
//     "©": "copy",
//     "®": "reg",
//     "<": "lt",
//     ">": "gt",
//     '"': "quot",
//     "&": "amp",
//     "'": "apos",
//   };

//   var regexString = "[";
//   for (var key in escapeChars) {
//     regexString += key;
//   }
//   regexString += "]";

//   var regex = new RegExp(regexString, "g");

//   return emoji.unemojify(str.replace(regex, function (m) {
//     return "&" + escapeChars[m] + ";";
//   }));

//   // return str.replace(regex, function (m) {
//   //   return "&" + escapeChars[m] + ";";
//   // });
// }

// // Decode ===================================================================================== //
// function unescapeHTML(str) {
//   str = emoji.emojify(str);
//   let htmlEntities = {
//     nbsp: " ",
//     cent: "¢",
//     pound: "£",
//     yen: "¥",
//     euro: "€",
//     copy: "©",
//     reg: "®",
//     lt: "<",
//     gt: ">",
//     quot: '"',
//     amp: "&",
//     apos: "'",
//   };

//   return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
//     var match;

//     if (entityCode in htmlEntities) {
//       return htmlEntities[entityCode];
//       /*eslint no-cond-assign: 0*/
//     } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
//       return String.fromCharCode(parseInt(match[1], 16));
//       /*eslint no-cond-assign: 0*/
//     } else if ((match = entityCode.match(/^#(\d+)$/))) {
//       return String.fromCharCode(~~match[1]);
//     } else {
//       return entity;
//     }
//   });
// }

export default {
  namespaced: true,
  state: {
    ws: null,
    wsStatus: false,
    clientID: null,
    grStateTimeouts: {},
    elStateTimeouts: {},
    onlineUsers: [],
  },
  getters: {
    getWSStatus: (state) => {
      return state.wsStatus;
    },
    getOnlineUsers: (state) => {
      return state.onlineUsers;
    },
    getOnlineUsersCount: (state) => {
      return state.onlineUsers.length;
    }
  },
  mutations: {
    //Sending data to the WS server===============================================//
    sendLayoutData(state, payload) {
      console.log("sending .. LayoutData");
      console.log(payload);
      let layout = JSON.stringify(payload.layout);
      layout = emoji.unemojify(layout);
      //sendData = encodeURIComponent(sendData);
      console.log("trying to send this layout: " + layout);
      if (state.wsStatus) {
        state.ws.send(
          JSON.stringify({
            layout: layout,
            redatcionType: payload.redatcionType,
            elementID: payload.elementID,
            type: "updateLayout",
            email: payload.email,
            grcode: payload.grcode,
            token: payload.token,
          })
        );
      }
    },
    sendElementData(state, payload) {
      console.log("sending .. ElementData");
      console.log(payload);

      let element = JSON.stringify(payload.element);
      element = emoji.unemojify(element);

      let layout = JSON.stringify(payload.layout);
      layout = emoji.unemojify(layout);

      console.log("trying to send this element: " + element);
      console.log("trying to send this layout: " + layout);
      if (state.wsStatus) {
        state.ws.send(
          JSON.stringify({
            element: element,
            layout: layout,
            type: "updateElement",
            email: payload.email,
            grcode: payload.grcode,
            token: payload.token,
          })
        );
      }
    },
    sendPong(state) {
      console.log("sending pong...");
      if (state.wsStatus) {
        state.ws.send(
          JSON.stringify({
            type: "pong",
          })
        );
      }
    },
    sendElState(state, payload) {
      console.log("send element state");
      console.log(payload);
      // console.log("element ID: ", payload.elementID);
      // console.log("user ID: ", payload.userID);
      // console.log("status: ", payload.status);
      // console.log("grcode: ", payload.grcode);
      if (state.wsStatus) {
        state.ws.send(
          JSON.stringify({
            type: "pushElementState",
            elementID: payload.elementID,
            userID: payload.userID,
            status: payload.status,
            grcode: payload.grcode,
          })
        );
      }
    },
    sendGridState(state, payload) {
      console.log("send grid state");
      console.log(payload);
      if (state.wsStatus) {
        state.ws.send(
          JSON.stringify({
            type: "pushGridState",
            userID: payload.userID,
            status: payload.status,
            grcode: payload.grcode,
          })
        );
      }
    },
    setUserList(state, payload) {
      console.log("set user list");
      //only dinstinct from the list
      let distinct = [...new Set(payload)];
      console.log(distinct);
      state.onlineUsers = distinct;
      console.log(state.onlineUsers);
    },
    //###########################################################################//
    //Receiving data from the WS server===============================================//
    //pushLayout(state, payload) {},

    //###########################################################################//
    clearElTimeouts(state) {
      // Cancel all timeouts
      Object.values(state.elStateTimeouts).forEach(clearTimeout);
      // Empty the object
      Object.keys(state.elStateTimeouts).forEach((key) => delete state.elStateTimeouts[key]);
      console.log("clearElTimeouts: cleared timeouts");
    },
    clearGrTimeouts(state) {
      // Cancel all timeouts
      Object.values(state.grStateTimeouts).forEach(clearTimeout);
      // Empty the object
      Object.keys(state.grStateTimeouts).forEach((key) => delete state.grStateTimeouts[key]);
      console.log("clearGrTimeouts: cleared timeouts");
    },
    clearOnlienUsers(state) {
      state.onlineUsers = [];
    },
  },
  actions: {
    connectWS({ commit, dispatch, state, getters, rootGetters}, payload) {
      console.log("connectWS");
      if (state.wsStatus) {
        console.log("already connected");
        commit("clearOnlienUsers");
        state.ws.send(
          JSON.stringify({
            clientID: state.clientID,
            type: payload.type,
            email: payload.email,
            grcode: payload.grcode,
            token: payload.token,
          })
        );
        return;
      } else {
        state.ws = new WebSocket("wss://gridsup.com/websocket/");
        //state.ws = new WebSocket("ws://localhost:1333/");
        state.ws.onopen = function () {
          console.log("open");
          state.wsStatus = true;
          console.log("Connected to Server");
          setTimeout(function () {
            state.ws.send(
              JSON.stringify({
                clientID: state.clientID,
                type: payload.type,
                email: payload.email,
                grcode: payload.grcode,
                token: payload.token,
              })
            );
          }, 500);

          // state.ws.send(
          //   JSON.stringify({
          //     clientID: state.clientID,
          //     type: payload.type,
          //     email: payload.email,
          //     grcode: payload.grcode,
          //     token: payload.token,
          //   })
          // );
          //commit("setWSStatus", true);
        };
        state.ws.onclose = function () {
          console.log("close");
          state.wsStatus = false;
          state.ws = null;
          state.clientID = null;
          console.log("Connection closed... refresh to try again!");
          commit("unsetLoadingBoard", null, { root: true });
          commit("clearOnlienUsers");
          //commit("setWSStatus", false);
        };
        state.ws.onmessage = function (event) {
          let inData = JSON.parse(event.data);
          state.clientID = inData.clientID;
      
          console.log("got message");

          if (inData.type == "pushLayout") {
            //the first time the user connects, the server sends the layout
            dispatch("pushLayout", inData);

          } else if (inData.type == "pushUpdate") {
            //the server sends full layout update
            dispatch("pushUpdate", inData);

          } else if (inData.type == "pushElementUpdate") {
            //the server sends full layout update
            dispatch("pushElementUpdate", inData);

          } else if (inData.type == "elementStateUpdate") {
            //the server sends element state update
            dispatch("elementStateUpdate", inData);

          } else if (inData.type == "gridStateUpdate") {
            //the server sends grid state update
            dispatch("gridStateUpdate", inData);

          }else if (inData.type == "pushUserList") {
            //the server sends users list update
            console.log("got user list");
            console.log(inData);
            commit("setUserList", inData.users);
            //dispatch("pushUserList", inData);
           }else if (inData.type == "ping") {

            console.log("got ping.. sending pong..");
            commit("sendPong");

          } else {

            console.log("got something else");

          }
        };
      }
    },
    disconnectWS({ commit, dispatch, state }) {
      state.ws.close();
      state.wsStatus = false;
      state.ws = null;
      state.clientID = null;
      console.log("Disconnected from Server");
    },
    sendLayout({ commit, state }, payload) {
      console.log("sendLayout");
      //console.log(payload);
      if (state.wsStatus) {
        commit("sendLayoutData", payload);
      } else {
        commit(
          "toast/showToast",
          {
            show: true,
            text: "Error. Your changes will not be saved. Please reconnect!",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    sendElement({ commit, state }, payload) {
      console.log("sendElement");
      //console.log(payload);
      if (state.wsStatus) {
        commit("sendElementData", payload);
      } else {
        commit(
          "toast/showToast",
          {
            show: true,
            text: "Error. Your changes will not be saved. Please reconnect!",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    // process received message
    pushLayout({ commit, dispatch, state, rootGetters }, payload) {
      let isType = rootGetters["layouts/getCurrentType"];
      let isGrCode = rootGetters["layouts/getCurrentGRcode"];
      let isEmail = rootGetters["users/isUserEmail"];
      //showMessage(`YOU: ${string}`);
      console.log("got layout");
      commit("unsetLoadingBoard", null, { root: true });
      console.log(payload);

      //first check if the page exists
      if (payload.layoutData.success == false) {
        console.log("page not found");
        commit("gridtabs/removeTab", { grcode: isGrCode }, { root: true });
        if (isType == 1) {
          commit(
            "layouts/deleteSharedGridMutateGRcode",
            { grcode: isGrCode },
            { root: true }
          );
        } else if (isType == 2) {
          commit(
            "layouts/deleteCommonGridMutateGRcode",
            { grcode: isGrCode },
            { root: true }
          );
        }
        commit(
          "toast/showToast",
          { show: true, text: "Board not found", type: "danger" },
          { root: true }
        );
        return;
      }

      //check if the user has access to the page
      if (isType == 2) {
        let users = payload.layoutData.users.split(",");
        if (!users.includes(isEmail)) {
          console.log("revokeAccess");
          commit(
            "gridtabs/removeTab",
            { grcode: payload.layoutData.grcode },
            { root: true }
          );
          commit(
            "layouts/deleteCommonGridMutateGRcode",
            { grcode: payload.layoutData.grcode },
            { root: true }
          );
          commit(
            "toast/showToast",
            { show: true, text: "Access Revoked", type: "danger" },
            { root: true }
          );
          return;
        } else {
          console.log("grantAccess");
          commit(
            "gridtabs/renameTab",
            {
              name: payload.layoutData.name,
              grcode: payload.layoutData.grcode,
            },
            { root: true }
          );
          commit(
            "layouts/setCommonGridNameGRcode",
            {
              name: payload.layoutData.name,
              grcode: payload.layoutData.grcode,
            },
            { root: true }
          );
        }
      }

      //update the layout

      let layout = emoji.emojify(payload.layoutData.layout);
      //console.log(layout);
      layout = JSON.parse(layout);

      commit(
        "layouts/firstUpdateSharedLayout",
        { layout: layout, grcode: payload.layoutData.grcode },
        {
          root: true,
        }
      );
      //update the grid state
      if(payload.layoutData.elementsState){
        console.log("elementsState",payload.layoutData.elementsState);
        //for each element in the state
        payload.layoutData.elementsState.forEach(element => {
          dispatch("elementStateUpdate", element);
        });
       // elementStateUpdate
        // commit("elstates/setElementStatus", payload, {
        //   root: true,
        // });
      }
      if(payload.layoutData.gridState){
        console.log("!!!gridState",payload.layoutData.gridState);
        dispatch("gridStateUpdate", payload.layoutData.gridState);
        //dispatch("elementStateUpdate", payload.layoutData.gridState);
        // commit("elstates/setElementStatus", payload, {
        //   root: true,
        // });
      }
    },
    // pushUpdate ===============================================================
    pushUpdate({ commit, state, rootGetters }, payload){
      //payload: {grcode: grcode, layout: layout}
      console.log("got new layout");
      let layout = emoji.emojify(payload.layout);
      layout = JSON.parse(layout);
      commit(
        "layouts/updateSharedLayout",
        { layout: layout, grcode: payload.grcode },
        {
          root: true,
        }
      );
    },
    // pushElementUpdate ========================================================
    pushElementUpdate({ commit, state, rootGetters}, payload){
      //payload: {grcode: grcode, element: element, last: last}
      console.log("got element update");
      let element = emoji.emojify(payload.element);
      element = JSON.parse(element);
      commit(
        "layouts/updateSharedElement",
        { element: element, grcode: payload.grcode, last: payload.last },
        {
          root: true,
        }
      );
    },
    // elementStateUpdate ======================================================
    elementStateUpdate({ commit, state, rootGetters }, payload){
      //payload: {grcode: grcode, status: status, userID: userID, elementID: elementID}
      console.log("got elementStateUpdate");
      console.log(payload);
      commit("elstates/setElementStatus", payload, {
        root: true,
      });
      // setTimeout(() => {
      //   payload.status = "saved";
      //   commit("elstates/setElementStatus", payload, {
      //     root: true,
      //   });
      // }, 60000);
      if(payload.status === "saved"){
        clearTimeout(state.elStateTimeouts[payload.elementID]);
      };
      if(payload.status === "editing"){
        state.elStateTimeouts[payload.elementID] = setTimeout(() => {
          payload.status = "saved";
          commit("elstates/setGridStatus", payload, {
            root: true,
          });
          clearTimeout(state.elStateTimeouts[payload.elementID]);
        }, 60000);
      }
    },
    // gridStateUpdate ======================================================
    gridStateUpdate({ commit, state, rootGetters }, payload){
      //payload: {grcode: grcode, status: status, userID: userID}
      console.log("got gridStateUpdate");
      console.log(payload);
      commit("elstates/setGridStatus", payload, {
        root: true,
      });
      if(payload.status === "saved"){
        clearTimeout(state.grStateTimeouts[payload.grcode]);
      };
      if(payload.status === "editing"){
        state.grStateTimeouts[payload.grcode] = setTimeout(() => {
          payload.status = "saved";
          commit("elstates/setGridStatus", payload, {
            root: true,
          });
          clearTimeout(state.grStateTimeouts[payload.grcode]);
        }, 60000);
      }
    }
  },
  modules: {},
};
