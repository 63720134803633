<template>
  <section>
    <b-sidebar
      type="is-mydark"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :open.sync="isActive"
      :right="true"
      can-cancel
    >
      <div class="scroll-pages-menu">
        <div class="separator has-text-grey-light mb-1 mt-2">
          Personal Boards
        </div>
        <div class="px-2 py-4 has-text-light unselectable">
          <b-menu :activable="true">
            <b-menu-list>
              <template v-for="(item, index) in layoutsPersonal">
                <b-menu-item
                  :active="isBoardCode == item.grcode && isType == 0"
                  :key="'persinal' + item.id"
                  @click="changeBoard(item, 0)"
                  :label="item.name"
                  class="menu-item"
                ></b-menu-item>
              </template>
            </b-menu-list>
          </b-menu>
        </div>

        <div class="separator has-text-grey-light mb-1">Your Shared Boards</div>
        <div class="px-2 py-4 has-text-light unselectable">
          <b-menu :activable="false">
            <b-menu-list>
              <template v-for="(item, index) in layoutsShared">
                <b-menu-item
                  :active="isBoardCode == item.grcode && isType == 1"
                  :key="'shared' + item.id"
                  @click="changeBoard(item, 1)"
                  :label="item.name"
                  class="menu-item"
                  :class="[isStatus()]"
                ></b-menu-item>
              </template>
            </b-menu-list>
          </b-menu>
          <div class="login-message" v-if="layoutsShared.length == 0">No Shared Boards</div>
        </div>

        <div class="separator has-text-grey-light mb-1">Shared With You</div>
        <div class="px-2 py-4 has-text-light unselectable">
          <b-menu :activable="false">
            <b-menu-list>
              <template v-for="(item, index) in layoutsCommon">
                <b-menu-item
                  :active="isBoardCode == item.grcode && isType == 2"
                  :key="'common' + item.id"
                  @click="changeBoard(item, 2)"
                  :label="item.name"
                  class="menu-item"
                  :class="[isStatus()]"
                ></b-menu-item>
              </template>
            </b-menu-list>
          </b-menu>
          <div class="login-message" v-if="layoutsCommon.length == 0">No Common Boards</div>
        </div>
        <div class="separator has-text-grey-light mb-3">Manage & Check</div>
        <div class="mb-2 mx-2" v-if="user.isLogged">
          <b-button
            @click="reloadCommonGrids()"
            icon-left="rotate-right"
            label="Check Incoming Boards"
            size="is-small"
            type="is-primary"
            expanded
            outlined
            :loading="isLoading"
          />
        </div>
        <div class="my-2 mx-2">
          <b-button
            @click="editBoards()"
            icon-left="files"
            label="Manage Boards"
            size="is-small"
            type="is-success"
            expanded
            outlined
          />
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
import ismobile from "@/mixins/ismobile";
import { mapState } from "vuex";
export default {
  name: "SidebarBoards",
  mixins: [globalMixin, toastMixin, ismobile],
  data() {
    return {
      // userStatus: this.$store.state.user.status,
      btStatus: {
        backup: false,
        restore: false,
      },
      selectedPattern: null,
      importData: null,
      customGrid: false,
      isLoading: false,
    };
  },
  methods: {
    changeBoard(item,type) {

      if(this.isLoadingBoard){
        this.toast("Connecting. Please wait...", "is-info", 1000);
        return;
      }
      if(type>0){
        this.$store.commit("setLoadingBoard");
      }

      if(this.isEditable){
        this.$store.commit("unsetEditable");
        if(this.isType>0){
          this.$store.commit("ws/sendGridState", {
            status: "saved",
            grcode: this.isBoardCode,
            userID: this.user.email,
          });
        };
      }
      
      let payload = {
        grcode: item.grcode,
        type: type,
        name: item.name,
        id: item.id
      };
      this.$store.dispatch("layouts/changeBoard", payload); //missing type !!!!
      // this.$store.commit("layouts/setBoard", page);
      // this.$store.commit("layouts/setGridType", gridType);
      // if(gridType > 0){
      //   this.$store.dispatch("layouts/wsConnect");
      // }
      //--------------------------------
      if(this.detectMobileDevice()){
        this.closeSidebar();
      }
    },
    closeSidebar() {
      this.$store.commit("modals/hideModal", "sidebarBoards");
    },
    openModal(modal) {
      console.log("open " + modal);
      this.$store.commit("modals/showModal", modal);
      this.closeSidebar();
    },
    editBoards() {
      this.openModal("pages");
      if (this.user.isLogged) {
        if (this.user.status > 0) {
          this.openModal("pages");
        } else {
          this.openModal("pay");
        }
      } else {
        this.showLogin();
      }
    },
    isStatus() {
      let style = "";
      if(this.isLoadingBoard){
        style += "is-connecting";
      }
      if (this.wsStatus && !this.isLoadingBoard) {
        style += "is-connected";
      }
      if (!this.wsStatus && !this.isLoadingBoard) {
        style += "is-disconnected";
      }

      return style;
    },
    reloadCommonGrids(){
      this.isLoading = true;
      this.$store.dispatch("layouts/loadCommonGrids");
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
  },
  computed: {
    ...mapState("ws", ["wsStatus"]),
    ...mapState("users", ["user"]),
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["layoutsPersonal", "layoutsShared", "layoutsCommon", "isBoard", "isType", "isBoardCode"]), //["layouts","pages","isBoard","isEditable"]personalGrid
    ...mapState(["isEditable", "isLoadingBoard"]),
  //...mapState("sharedlayouts", ["sharedGrid"]), //["layouts","pages","isBoard","isEditable"]sharedGrid
  //...mapState("sharedlayouts"),
  isActive: {
    get: function () {
      return this.$store.state.modals.sidebarBoards;
    },
      // setter
    set: function () {
      this.$store.commit("modals/hideModal", "sidebarBoards");
      return false;
    },
  },
  },
};
</script>
<style scoped>
.menu-item {
  text-transform: capitalize;
}

.menu-item.connecting .is-active {
  text-transform: capitalize;
}

.login-message{
  text-align: center;
    font-size: 12px;
    opacity: .5;
    padding: 5px;
    /* border: 1px solid #a0a0a0; */
    border-radius: 5px;
    margin-bottom: 20px;
    color: #a0a0a0;
}
</style>

<style>

.is-connected a.is-active{
  border-left: #00ad00 4px solid!important;
}

.is-disconnected a.is-active{
  border-left: #c40000 4px solid!important;
}

.is-connecting a.is-active{
  border-left: #fff 4px solid!important;
}


</style>
