export default{
  namespaced: true,
  state: {
      sidebar: false,
      sidebarBoards: false,

    //add items modals
      addLink: false,      
      addClock: false,
      addImage: false,
      addRSS: false,
      addFrame: false,

    //edit items modals
      editLink: false,
      editClock: false,
      editNote: false,
      editTodo: false,
      editWeather: false,
      editImage: false,
      editSearch: false,
      editCalendar: false,
      editLinks: false,
      editRSS: false,
      editCodeNote: false,
      editAmbiance: false,
      editFrame: false,
      editYouTube: false,
      editFileTransfer: false,

    //general modals
      settings: false,
      pages: false,
      copyTo: false,
      backups: false,
      closeGridsup: false,

    //account modals  
      contact: false,
      account: false,
      login: false,
      logout: false,
      register: false,
      restore: false,
      confirm: false,
      pay: false,
  },
  getters: {
  },
  mutations: {
    showModal(state, payload) {
      state[payload] = true;
    },
    hideModal(state, payload) {
      state[payload] = false;
    },
    toggleModal(state, payload) {
      state[payload] = !state[payload];
    },
  },
  actions: {
  },
  modules: {
  }
}
