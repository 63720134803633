<template>
  <div 
    class="ibox" 
    :class="gridSettings.tiles.theme" :style="tileStyle"
  >
     <div class="box-search" >   
      <div class="search" @keyup.enter.exact="doSearch()">
        <div style="width: 100%;" class="field">
          <!-- <b-field>
            <p class="control">
              <b-select v-model="options.engine" placeholder="Select">
                <option value="0">Google</option>
                <option value="1">Duckduckgo</option>
                <option value="2">Bing</option>
                <option value="3">Yandex</option>
                <option value="4">Wikipedia</option>
                <option value="5">You</option>
              </b-select>
            </p>
            <p class="control has-icons-left">
              <span class="icon is-small is-left">
                <img width="16px" height="16px" :src="icon">
              </span>
            </p> 
            <input class="input" :class="searchForm.status" type="text" v-model="searchForm.data" style="padding-left: 2.5em;">
            <p class="control">
                <b-button @click="doSearch()" icon-left="syntho magnifying-glass" />
            </p>
          </b-field> -->
          <b-field expanded>
            <p class="control">
              <b-select v-model="options.engine" placeholder="Select">
                <option value="0">Google</option>
                <option value="1">Duckduckgo</option>
                <option value="2">Bing</option>
                <option value="3">Yandex</option>
                <option value="4">Wikipedia</option>
                <option value="5">You</option>
              </b-select>
            </p>
            <p class="control has-icons-left">
              <span class="icon is-small is-left">
                <img width="16px" height="16px" :src="icon">
              </span>
            </p> 
            <b-input :class="searchForm.status" type="text" v-model="searchForm.data" expanded></b-input>
            <p class="control">
                <b-button @click="doSearch()" icon-left="syntho magnifying-glass" />
            </p>
          </b-field>
        </div>
      </div>    
     </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'SearchItem',
  props: {
    options: Object
  },
  data(){
    return {
      searchForm: {
        data: null,
        status: null,
        message: null
      },
      engines: [
        "https://www.google.com/search?q=",
        "https://duckduckgo.com/?q=",
        "https://www.bing.com/search?q=",
        "https://yandex.com/search/?text=",
        "https://en.wikipedia.org/wiki/",
        "https://you.com/search?q="
      ],
      engineNames: [
        "google.com",
        "duckduckgo.com",
        "bing.com",
        "yandex.com",
        "wikipedia.org",
        "you.com",
      ]
    }
  },
  methods:{
    doSearch(){
      if(!this.isEditable & this.formCheck()){
          window.location.replace(this.engines[this.options.engine]+this.searchForm.data);
      }
    },
    formCheck() {
      if (!this.searchForm.data) {
        this.searchForm.status = "is-danger";
        return false;
      } else {
        this.searchForm.status = "";
        return true;
      }
    }, 
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    icon(){
      return 'https://www.google.com/s2/favicons?domain='+this.engineNames[this.options.engine];
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      style.backgroundColor = this.options.bgColor;
      if(this.gridSettings.tiles.theme == "pseudo3d"){
        style.backgroundColor = this.options.bgColor;
      }
      
      if(this.gridSettings.tiles.theme == "classic"){
        style.backgroundColor = this.options.bgColor;
      }

      if(this.gridSettings.tiles.theme == "neumorphism"){
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
      } 
      
      if(this.gridSettings.tiles.theme == "glassmorphism"){
      }  
      return style;
    },
  }
}
</script>
<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded{
  border-radius: 8px;
}
.border{
  border: solid 2px rgb(48, 48, 48);
}
.box-search {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.search{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  overflow: hidden;
  line-height: 0.9;
  padding: 15px;
}
</style>
