<template>
  <div class="user-pages-btns">
    <div class="btn-manage-wrapper">
      <!-- <b-button v-if="!this.$store.state.modals.sidebarBoards" type="is-mydark" size="is-small" icon-left="bars" @click="openBoards()" /> -->
      <b-button
        type="is-mydark"
        size="is-small"
        icon-left="plus"
        @click="editBoards()"
      />
    </div>
    <draggable
      v-model="inGridTabs"
      v-bind="dragOptions"
      group="gridTabs"
      handle=".handle"
      class="tabs-wrapper"
      v-if="!detectMobileDevice()"
    >
      <template v-for="(item, index) in inGridTabs">
        <div
          :key="'tab-'+index"
          class="page-tab"
          :class="[
            isActive(item),
            {
              editing: isEditable,
              personal: item.type == 0,
              shared: item.type == 1,
              common: item.type == 2,
              'tab-loading': isLoadingBoard,
            },
          ]"
          @click="changeBoard(item)"
        >
          <div class="page-tab-text">{{ item.name }}</div>
          <div v-if="isEditable" class="handle">
            <b-icon icon="grip-dots"></b-icon>
          </div>
        </div>
      </template>
    </draggable>
    <div class="btn-wrapper">
      <!-- <b-button v-if="!this.$store.state.modals.sidebarBoards" type="is-mydark" size="is-small" icon-left="bars" @click="openBoards()" /> -->
      <b-button
        type="is-mydark"
        size="is-small"
        icon-left="bars"
        label="Boards"
        @click="openBoards()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import draggable from "vuedraggable";

import toastMixin from "@/mixins/toastMixin";
import ismobile from "@/mixins/ismobile";

export default {
  name: "BoardsNavigation",
  components: {
    draggable,
  },
  mixins: [toastMixin, ismobile],
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: "gridTabs",
        disabled: false,
        ghostClass: "ghost",
        filter: "#home",
      },
    };
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", [
      "layouts",
      "pages",
      "isBoard",
      "isType",
      "isBoardCode",
    ]),
    ...mapState("ws", ["wsStatus"]),
    ...mapState("gridtabs", ["gridTabs"]),
    ...mapState(["isEditable", "isLoadingBoard"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["isBoard", "isType", "isBoardCode"]),
    inGridTabs: {
      get: function () {
        return this.$store.state.gridtabs.gridTabs;
      },
      // set: function() {
      //   return false;
      // }
      // setter
      set: function (val) {
        //this.$store.commit("layouts/updatePersonalBoards", val);
        console.log("tabs");
        console.log(val);
        this.$store.commit("gridtabs/updateTabs", val);
        return false;
      },
    },
  },
  methods: {
    changeBoard(item) {
      if (this.isLoadingBoard) {
        this.toast("Connecting. Please wait...", "is-info", 1000);
        return;
      }
      if (item.type > 0) {
        this.$store.commit("setLoadingBoard");
      }
      if(this.isEditable){
        this.$store.commit("unsetEditable");
        if(this.isType>0){
          this.$store.commit("ws/sendGridState", {
            status: "saved",
            grcode: this.isBoardCode,
            userID: this.user.email,
          });
        };
      }
      this.$store.dispatch("layouts/changeBoard", item);
    },
    openBoards() {
      this.$store.commit("modals/showModal", "sidebarBoards");
    },
    editBoards() {
      //this.openModal("pages");
      this.$store.commit("modals/showModal", "pages");
      // if(this.user.isLogged){
      //   if(this.user.status>0){
      //     this.openModal("pages");
      //   }else{
      //     this.openModal("pay");
      //   }
      // }else{
      //   this.showLogin();
      // }
    },
    isActive(item) {
      let style = "";
      if (this.isBoardCode == item.grcode) {
        style = "active";
      }
      if (item.type > 0 && this.isBoardCode == item.grcode && this.wsStatus) {
        style += " connected";
      }
      if (item.type > 0 && this.isBoardCode == item.grcode && !this.wsStatus) {
        style += " disconnected";
      }
      return style;
    },
  },
};
</script>

<style scoped>
.user-pages-btns {
  z-index: 39; /*997*/
  position: fixed !important;
  top: 0px;
  right: 5px;
  display: flex;
  /*flex-direction: row-reverse; */
}
/* .user-pages-btns button {
  margin-right: 5px;
} */
.pages-button {
  text-transform: capitalize;
}

.btn-wrapper {
  margin-top: 5px;
}

.btn-manage-wrapper {
  margin-top: 5px;
  margin-right: 5px;
  width: 30px;
}

@keyframes border-pulse-animation-blue {
  0% {
    border-bottom: #008cff 4px solid;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  50% {
    border-bottom: #008cff00 4px solid;
  }
  100% {
    border-bottom: #008cff 4px solid;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
}

.tabs-wrapper {
  top: -5px;
  display: flex;
  /* height: 35px; */
  max-width: 80vw;
  justify-content: end;
  margin-right: 3px;
}

.page-tab {
  border-radius: 0 0 8px 8px;
  background-color: #181d29;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-right: 2px;
  padding: 10px 10px 0px 10px;
}

.page-tab-text {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.page-tab.editing {
  /* padding: 0 4px 0px 10px */
}

.page-tab.personal {
  background-color: #213050;
  color: #fff;
}


.page-tab.shared {
  background-color: #30446e;
  color: #fff;
}

.page-tab.common {
  background-color: #3959a0;
  color: #fff;
}
.page-tab.active {
  background-color: #fff;
  color: #181d29;
}

.page-tab.active .page-tab-text {
  color: #181d29;
}

.page-tab.connected {
  border-bottom: #00ad00 4px solid;
}

.page-tab.disconnected {
  border-bottom: #c40000 4px solid;
}

.page-tab.active.tab-loading {
  animation: border-pulse-animation-blue 0.5s infinite;
}

.handle {
  text-align: center;
  color: white;
  font-size: 12px;
}

.page-tab.active .handle {
  color: #181d29;
}
</style>
