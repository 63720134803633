<template>
  <div id="app" :style="styleObject" @click="checkEditable">
  <!-- <div id="app" :style="styleObject" @contextmenu.prevent="toggleEditable"></div> -->
    <router-view />

    <Sidebar />
    <SidebarBoards />
    <MainNavigation />
    <BoardsNavigation />
    <ToolTip />
    <Clipboard />

    <SettingsModal />
    <GridBoardsModal />
    <CopytoModal />
    <BackupModal />

    <PayModal />
    <LoginModal />
    <LogoutModal />
    <AccountModal />
    <RegisterModal />
    <RestoreModal />

    <EditCalendarModal />
    <EditClockModal />
    <EditImageModal />
    <EditLinkModal />
    <EditLinksModal />
    <EditNoteModal />
    <EditRSSModal />
    <EditNoteModal />
    <EditSearchModal />
    <EditTodoModal />
    <EditWeatherModal />
    <EditCodeNoteModal />
    <EditAmbianceModal />
    <EditFrameModal />
    <EditYouTubeModal />
    <EditFileTransferModal />

    <AddClockModal />
    <AddImageModal />
    <AddLinkModal />
    <AddRSSModal />
    <AddFrameModal />

  </div>
</template>

<script>

//q: do you like this project?
//a: yes, i like it very much

import { mapState } from "vuex";

import confirmMixin from "@/mixins/confirmMixin";
import urlMixin from "@/mixins/urlMixin";
import colorMixin from "@/mixins/colorMixin";
import randomMixin from "@/mixins/randomMixin";
import globalMixin from "./mixins/globalMixin";

import Sidebar from '@/components/Sidebar.vue';
import SidebarBoards from '@/components/SidebarBoards.vue';
import MainNavigation from "@/components/MainNavigation.vue";
import BoardsNavigation from "@/components/BoardsNavigation.vue";
import ToolTip from "@/components/ToolTip.vue";
import Clipboard from "@/components/Clipboard.vue";

import SettingsModal from '@/components/generalModals/SettingsModal.vue';
import GridBoardsModal from '@/components/generalModals/GridBoardsModal.vue';
import CopytoModal from '@/components/generalModals/CopytoModal.vue';
import BackupModal from '@/components/generalModals/BackupModal.vue';

import PayModal from '@/components/accountModals/PayModal.vue';
import LoginModal from '@/components/accountModals/LoginModal.vue';
import LogoutModal from '@/components/accountModals/LogoutModal.vue';
import AccountModal from '@/components/accountModals/AccountModal.vue';
import RegisterModal from '@/components/accountModals/RegisterModal.vue';
import RestoreModal from '@/components/accountModals/RestoreModal.vue';

import EditCalendarModal from '@/components/itemModals/EditCalendarModal.vue';
import EditClockModal from '@/components/itemModals/EditClockModal.vue';
import EditImageModal from '@/components/itemModals/EditImageModal.vue';
import EditLinkModal from '@/components/itemModals/EditLinkModal.vue';
import EditLinksModal from '@/components/itemModals/EditLinksModal.vue';
import EditNoteModal from '@/components/itemModals/EditNoteModal.vue';
import EditRSSModal from '@/components/itemModals/EditRSSModal.vue';
import EditSearchModal from '@/components/itemModals/EditSearchModal.vue';
import EditTodoModal from '@/components/itemModals/EditTodoModal.vue';
import EditWeatherModal from '@/components/itemModals/EditWeatherModal.vue';
import EditCodeNoteModal from '@/components/itemModals/EditCodeNoteModal.vue';
import EditAmbianceModal from '@/components/itemModals/EditAmbianceModal.vue';
import EditFrameModal from '@/components/itemModals/EditFrameModal.vue';
import EditYouTubeModal from '@/components/itemModals/EditYouTubeModal.vue';
import EditFileTransferModal from '@/components/itemModals/EditFileTransferModal.vue';

import AddClockModal from '@/components/itemModals/AddClockModal.vue';
import AddImageModal from '@/components/itemModals/AddImageModal.vue';
import AddLinkModal from '@/components/itemModals/AddLinkModal.vue';
import AddRSSModal from '@/components/itemModals/AddRSSModal.vue';
import AddFrameModal from '@/components/itemModals/AddFrameModal.vue';

var cssroot = document.querySelector(':root');
//import Confirm from '@/components/Confirm.vue';

export default {
  components: {
    Sidebar,
    SidebarBoards,
    MainNavigation,
    BoardsNavigation,
    ToolTip,
    Clipboard,
    //general modals
    SettingsModal,
    GridBoardsModal,
    CopytoModal,
    BackupModal,
    //account modals
    PayModal,
    LoginModal,
    LogoutModal,
    AccountModal,
    RegisterModal,
    RestoreModal,
    //edit modals
    EditCalendarModal,
    EditClockModal,
    EditImageModal,
    EditLinkModal,
    EditLinksModal,
    EditNoteModal,
    EditRSSModal,
    EditSearchModal,
    EditTodoModal,
    EditWeatherModal,
    EditCodeNoteModal,
    EditAmbianceModal,
    EditFrameModal,
    EditYouTubeModal,
    EditFileTransferModal,
    //add modals
    AddClockModal,
    AddImageModal,
    AddLinkModal,
    AddRSSModal,
    AddFrameModal,
  },
  mixins: [confirmMixin, urlMixin, colorMixin, randomMixin, globalMixin],
  computed: {
    ...mapState("settings", ["gridSettings"]),
    //...mapState("ws", ["personalGrid"]),
    ...mapState("modals", ["sidebar", "sidebarBoards", "backups"]),
    ...mapState(["isEditable", "isLoadingBoard"]),
    ...mapState("users", ["user"]),
    ...mapState("toast", ["toast"]),
    ...mapState("ws", ["wsStatus", "onlineUsers"]),

    styleObject() {

      // return {
      //       backgroundImage: 'url(/images/px.png)',
      //       backgroundColor: this.gridSettings.gridBG.color
      //     }

          let style = {};
      style.backgroundColor = this.gridSettings.gridBG.color;
      if(this.gridSettings.gridBG.type == "pattern"){
        style.backgroundImage ='url(https://gridsup.com/resurces/patterns/' + this.gridSettings.gridBG.imagePattern + ')';
      }
      if(this.gridSettings.gridBG.type == "wallpaper"){
        style.backgroundImage = 'url(https://gridsup.com/resurces/wallpapers/' + this.gridSettings.gridBG.imageWallpaper + ')';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center';
        style.backgroundSize = 'cover';
        style.backgroundAttachment = 'fixed';
      }
      if(this.gridSettings.gridBG.type == "custom"){
        style.backgroundImage = 'url('+this.gridSettings.gridBG.imageCustom+')';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center';
        style.backgroundSize = 'cover';
        style.backgroundAttachment = 'fixed';
      }
      return style;

        // if (this.gridSettings.gridBG.type == "pattern") {
        //   return {
        //     backgroundImage: 'url(/images/patterns/' + this.gridSettings.gridBG.pattern + ')',
        //     backgroundColor: this.gridSettings.gridBG.color
        //   }
        // } else if (this.gridSettings.gridBG.type == "custom") {
        //   return {
        //     backgroundImage: 'url(' + this.gridSettings.gridBG.image + ')',
        //     backgroundColor: this.gridSettings.gridBG.color,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundPosition: 'center',
        //     backgroundAttachment: 'fixed',
        //     backgroundSize: 'cover'
        //   }
        // } else {
        //   return {
        //     backgroundImage: 'url(/images/patterns/px.png)',
        //     backgroundColor: '#000000'
        //   }
        // }
      },

  },
  data(){
    return{
      instanceID: null,
    }
  },
  created(){
    document.addEventListener("visibilitychange", this.isfocusd);
    //this.setInstance();

    window.onbeforeunload = function(){
      document.removeEventListener("visibilitychange", this.isfocusd);
    }
  },
  // beforeDestroy(){
  //   document.removeEventListener("visibilitychange", this.isfocusd);
  //   window.onbeforeunload = function(){
  //     localStorage.grinstance--;
  //   }
  // },
  mounted() {
    //console.log("test vars", this.$store.state.modals.sidebar);
    //window.addEventListener("beforeunload", this.beforeUnloadHandler);
    console.log("APP MOUNTED!");
    console.log("Begin User Check!");
    this.checkSettings();
    if(localStorage.grpage == -1 || localStorage.grpage == undefined){
      localStorage.grpage = 0;
    }
    this.$store.commit("layouts/setFirstBoardCode");//setting Active Tab
    // let sampleObj = {
    //   "type": "getLayout",
    //   "email": "test@test.com",
    //   "grcode": "fssder",
    //   "token": ""
    // };
    // this.$store.dispatch("ws/connectWS", sampleObj);
    if (!this.$store.state.users.user.isLogged) {
      this.checkLogged();
      // this.checkBoard();
      //this.checkLayout();
      // this.checkIsBlack();
      // this.checkNew();
    }
    this.checkTabs();
    this.checkOldVersion();
  },
  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  // },
  methods: {
    // beforeUnloadHandler(event) {
    //   event.preventDefault();
    //   //this.isModalActive = true;
    //   //this.showModal("logout");
    //   event.returnValue = '';
    // },
    setInstance(){ //set instance id for each tab in browser and check if it is last tab
      this.instanceID = this.makeRandomCode(10);
      localStorage.grinstance = this.instanceID;
      console.log("Instance ID: " + this.instanceID);
    },
    isfocusd(){
      if (document.visibilityState == "visible") {
        console.log("isfocusd");
        this.checkSettings();
        this.checkLayout(); // this.checkNew(); after checkLayout
        this.checkTabs();
        this.setInstance();
        //remember lsat active page
        //localStorage.grpage = this.isBoard;
        if (localStorage.grtoken) {
          this.checkLogged();
        }else{
          this.$store.commit("layouts/clearSaredLayouts");
          this.$store.commit("users/clearUserData");
          //this.$store.dispatch("users/logoutUser");
        }
        //auto connect to ws
        this.connectWS();
      }    
    },
    connectWS(){
      if(!this.wsStatus && !this.isLoadingBoard){
        //this.$store.dispatch('ws/connectWS', {"grcode":this.isGRcode, "type": "getLayout"});
    
        console.log("trying to connect WS...");

        let isType = this.$store.getters['layouts/getCurrentType'];
        let isGRcode = this.$store.getters['layouts/getCurrentGRcode'];

        console.log("isType:", isType);
        console.log("isGRcode:", isGRcode);
        if(isType > 0){
          this.$store.dispatch('ws/connectWS', {"grcode":isGRcode, "type": "getLayout", "email":this.user.email});
        }
        //this.$store.dispatch("ws/connectWS");
      }
    },
    checkLogged: function () {
      if (localStorage.grtoken) {
        console.log("Validating Token..");
        //begin post --------------------------------------------
        this.axios
          .post("users/validate.php", {
            token: localStorage.grtoken,
            action: "validate",
          })
          .then((response) => {
            console.log("FASTRESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data);
              if(response.data.jwt){
                localStorage.grtoken = response.data.jwt;
                //console.log("has jwt");
              }
              console.log("Logged!");
              this.autoBackup();
              this.autoStatusCheck();
              this.checkLayout(); // this.checkNew(); after checkLayout
              //this.$store.dispatch("layouts/loadSharedGrids"); // is loded in watchers on user status change
              //console.log(response.data.data);
            }else{
              console.log("Did not pass validation!");
              this.$store.dispatch("users/logoutUser");
              this.showLogin();
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
        //clearSaredLayouts();
        this.$store.commit("layouts/clearSaredLayouts");
        this.$store.commit("users/clearUserData");
      }
    },
    checkSettings: function() {
      if (localStorage.grsettings) {
        this.$store.commit("settings/setLocalSettings");
        this.setCSSVars();        
      } else {
        //localStorage.grsettings = JSON.stringify(this.settings);
        this.$store.commit("settings/saveSettings");
      }
    },
    checkLayout: function() {
      if(localStorage.grpersonal){
        this.$store.commit("layouts/setLocalPersonalGrisLayout");
      };
      if(localStorage.grshared){
        this.$store.commit("layouts/setLocalSharedGrisLayout");
      }else{
        this.$store.dispatch("layouts/loadSharedGrids");
      }
      if(localStorage.grcommon){
        this.$store.commit("layouts/setLocalCommonGrisLayout");
      }else{
        this.$store.dispatch("layouts/loadCommonGrids");
      }
      if (localStorage.grpersonal) {
       
        console.log("did focus");
        this.$store.commit("layouts/setLocalLayout");
      } else {
        localStorage.grpersonal = JSON.stringify(this.layouts);
      }
      //===========
      this.checkNew();
    },
    checkTabs: function() {
      if (localStorage.grtabs) {
        this.$store.commit("gridtabs/setLocalTabs");      
      }
    },
    setCSSVars() {
      console.log("this.gridSettings.gridBG.color:", this.gridSettings.gridBG.color);
     let val = this.hexToHSL(this.gridSettings.gridBG.color);
      console.log("bgcolor:", val);
      cssroot.style.setProperty('--start-color', val.h + ',' + val.s);
      cssroot.style.setProperty('--l', val.l);
    },
    toggleEditable(){
      this.$store.dispatch("toggleEditable");

      if(this.sidebar){
        this.$store.commit("modals/hideModal", "sidebar");
      }
      if(this.sidebarBoards){
        this.$store.commit("modals/hideModal", "sidebarBoards");
      }
    },
    checkEditable(){
      // if(this.isEditable){
      //   this.toggleEditable();
      // }
    },
    autoBackup(){

      if(this.gridSettings.general.isAutoBackup && !this.isEditable && this.user.isLogged && !this.backups){
        //
        console.log("autoBackup is on");
        if(localStorage.grlastbk){
          let lastbk = localStorage.grlastbk;
          let now = new Date().getTime();
          let diff = now - lastbk;
          console.log("diff:", diff);
          //evry 24 hours
          if(diff > 24 * 60 * 60 * 1000){// 24 * 60 * 60 * 1000
            this.$store.dispatch("layouts/backupPersonalLayouts");
            localStorage.grlastbk = now;
          }
          // //evry 30 minutes
          // if(diff > 30 * 60 * 1000){// 30 * 60 * 1000
          //   this.$store.dispatch("layouts/backupPersonalLayouts");
          //   localStorage.grlastbk = now;
          // }
        } else {
          this.$store.dispatch("layouts/backupPersonalLayouts");
          localStorage.grlastbk = new Date().getTime();
          this.checkStatus();
        }
        // setTimeout(() => {
        //   this.$store.dispatch("layouts/backupPersonalLayouts");
        //   if(this.instanceID == localStorage.grinstance){
        //     this.autoBackup();
        //   }
        // }, 10000);//30 * 60 * 1000
        //this.$store.dispatch("layouts/backupPersonalLayouts");
      }
    },
    autoStatusCheck(){
      if(this.user.isLogged){
        //
        console.log("autoStatusCheck is on");
        if(localStorage.grst){
          let lastst = localStorage.grst;
          let now = new Date().getTime();
          let diff = now - lastst;
          console.log("diff:", diff);
          //evry 24 hours
          if(diff > 24 * 60 * 60 * 1000){// 24 * 60 * 60 * 1000
            localStorage.grst = now;
          }
        } else {
          localStorage.grst = new Date().getTime();
          this.checkStatus();
        }
      }
    },
    checkStatus() {
      console.log("checking status!");
      this.isLoading = true;
        //this.form.visible = false;
        //begin post --------------------------------------------
        this.axios
          .post("users/manageuser.php", {
            action: "checkStatus",
            token: localStorage.grtoken
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.success) {
              if (response.data.status == this.user.status){
                console.log("status is the same");
              } else {
                this.$store.commit("users/setStatus", response.data);               
              }
            }
          })
          .catch((error) => {
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
    },
    checkNew: function() {
      if (localStorage.gradd) {
        let links = localStorage.gradd;
        let arLinks = links.split("|");
        //console.table(arLinks);
        for(let i = 0; i < arLinks.length; i++){
          this.addNewLink(arLinks[i]);
        }
        localStorage.removeItem("gradd");
        console.log("hasNew");
      }
    },
    addNewLink(link){
      let options = {
        isText: true,
        hasCustom: false,
        text: null,
        sizeValue: 3,
        initSize: 3,
        textSize: 3,
        iconSize: 4,
        textColor: "#ffffff",
        bgColor: "#000000",
        iconColor: 0,
        bgImage: "images/px.png",
        icon: null,
        url: null,
        title: null,
      };
      options.url = link;
      options.bgColor = this.randomColor();
      options.title = this.extractHostname(link);
      this.$store
        .dispatch("layouts/gridAction", {
          action: "addItem",
          data: { type: 0, options: options },
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //###########################################################################################
    //###########################################################################################
    ////OLD VERSION CHECK
    checkOldVersion(){
      if(localStorage.grlayouts){

      this.$buefy.dialog.confirm({
        title: "Restoring Layout",
        message:
          "Gridsup has been updated! We've detected a local version of your grid. Would you like to import it?",
        confirmText: "Import Layout",
        type: "is-info",
        hasIcon: true,
        icon: "circle-exclamation",
        onConfirm: () => this.restoreOldLayout(),
        onCancel: () => this.deleteOldLayout(),
      });
      }
    },
    deleteOldLayout(){
      this.$buefy.dialog.confirm({
        title: "Deleting Autdated Layout",
        message:
          "Are you sure you want to delete the outdated layout?",
        confirmText: "Delete Layout",
        type: "is-danger",
        hasIcon: true,
        icon: "triangle-exclamation",
        onConfirm: () => this.clearOldLayout(),
      });
    },
    restoreOldLayout(){
      console.log("restoring old layout");
      localStorage.grpage = 0;
        let layouts = JSON.parse(localStorage.grlayouts);
        //deal wilth old type of layouts
        if(layouts.length>0) {
        console.log("setting grcode for old type of layouts");
        layouts.forEach((item) => {
          item.grcode = this.makeRandomCode(16); //same as makeGRCode imported from randomMixin
          item.layout.forEach(function (value, i) {
            value.i = i+1;
          });
        });
        localStorage.grpersonal = JSON.stringify(layouts);
        this.$store.commit("layouts/setLocalLayout");
        this.$store.commit("layouts/setLocalPersonalGrisLayout");
        }
        //proceed normal logic
      localStorage.removeItem("grhomepage");
      localStorage.removeItem("isblack");
      localStorage.removeItem("grpage");
      localStorage.removeItem("grlayouts");
    },
    clearOldLayout(){
      console.log("clearing old layout");
      localStorage.removeItem("grlayouts");
      localStorage.removeItem("grhomepage");
      localStorage.removeItem("isblack");
      localStorage.grpage = 0;
    },
    //###########################################################################################
    //###########################################################################################
  },//methods
  watch: {
    'user.isLogged': function(newVal, oldVal) {
      console.log("user changed", newVal);
      if(newVal){
        //this.$store.dispatch("layouts/loadSharedGrids");
        //this.$store.dispatch("layouts/loadCommonGrids");
        //this.$store.dispatch("layouts/restorePersonalLayouts");
      } 
    },
    'toast.show': function(newVal, oldVal) {
      if(newVal){
        this.$buefy.toast.open({
        duration: 5000,
        position: "is-bottom",
        message: this.toast.text,
        type: "is-" + this.toast.type
      });
        //this.$store.commit("toast/hideToast");
      } 
    },
  }
}//export default
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #181d29;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 300ms;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  /* font-weight: 300; */
}

@font-face {
  font-family: Pixel;
  src: url("./static/fonts/pixel/pixelsix00.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("./static/fonts/roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: RobotoBoldCondensed;
  src: url("./static/fonts/roboto/Roboto-BoldCondensed.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: RobotoBlack;
  src: url("./static/fonts/roboto/Roboto-Black.ttf");
  font-style: normal;
  font-weight: 800;
}


@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/roboto/Roboto-Italic.ttf) format('ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./static/fonts/roboto/Roboto-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/roboto/Roboto-BoldItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url(./static/fonts/roboto/Roboto-Condensed.ttf) format('ttf');
  font-style: normal;
  font-weight: 400;
}

@import 'static/wethericons/css/weather-icons.min.css';
@import "static/syntho/style.css";

// Import Bulma's core
@import "~bulma/sass/utilities/_all";


$family-primary: "Roboto", sans-serif;


//$scheme-main: $black;
// $scheme-invert: $white;
// etc.
// Set your colors
$primary: #0278E8; //#00bb8f; //#00bb8f; //00D1B2
$primary-invert: findColorInvert($primary);
$mydark: #181d29; //#00bb8f; //00D1B2
$mydark-light: #3e4f78;
$mydark-lighter: #5c667e;
//
//theme mod
$body-background-color: #000;
//$loading-background: #000;

$gublack: #0f0f0f;
$gudarck: #151718;
$guline: #242726;

$wsred: #c40000;
$wsgreen: #00ad00;


$gruidark: #0000003f;
$gruilight: #ffffff56;

$slider-track-background: $gruidark;


// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $primary-invert),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert),
  "mydark": ($mydark, $white),
  "mydark-light": ($mydark-light, $white),
  "mydark-lighter": ($mydark-lighter, $white),
  "wsred": ($wsred, $white),
  "wsgreen": ($wsgreen, $white),
  "gruidark": ($gruidark, $white),
  "gruilight": ($gruilight, $white),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// $modal-background-background-color: $mydark10;

// $modal-card-head-background-color:$gublack;
// $modal-card-head-border-bottom:$guline;
// $modal-card-foot-border-top:$guline;
// $modal-card-title-color: $light;
// $modal-card-body-background-color: $gudarck;


// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";


.dropdown-menu{
  z-index: 1000;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.separator {
  //font-family: Pixel;
  font-family: 'Roboto';
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c667e !important;
  //text-transform: uppercase;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #3e4f78;
}

.separator::before {
  margin-right: .25em;
}

.separator::after {
  margin-left: .25em;
}

:root {
  --start-color: 0, 100%;
  --l: 50%;
  --item-color: hsl(var(--start-color), var(--l));
  --item-color-t: hsla(var(--start-color), var(--l), 0.13);
  --item-darker: hsl(var(--start-color), calc(var(--l) - 10%));
  --item-lighter: hsl(var(--start-color), calc(var(--l) + 10%));
}

.vue-resizable-handle {
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJyZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6Ii8+PC9zdmc+)!important;
  background-position: 100% 100%!important;
  background-repeat: no-repeat!important;
  background-origin: content-box!important;
}

.vue-grid-placeholder {
  border: #000000 solid 1px;
  background: rgb(218, 218, 218)!important;
  opacity: .2;
}

.vue-grid-item>.vue-resizable-handle{
  z-index: 6!important;
}

.addborder>.vue-swatches__trigger__wrapper{
  border: 2px solid #b4b4b4;
  border-radius: 10px;
}

.pixelfont{
  font-size:8px !important;
  font-family: Pixel;
  -webkit-font-smoothing: antialiased;
}


.neumorphism {
  border: 1px solid var(--item-primary);
  transition: all .3s;
  box-shadow: 6px 6px 12px var(--item-darker),
             -6px -6px 12px var(--item-lighter);
}

.neumorphism .tile-icon{
  width: 93%;
  height: 93%;
  /* background-color: var(--item-darker)!important; */
}

.glassmorphism{
  backdrop-filter: blur(4px) saturate(100%);
  -webkit-backdrop-filter: blur(6px) saturate(100%);
  background-color: var(--item-color-t);
  /* background-color: rgba(17, 25, 40, 0.46); */
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: #FFFFFF;
  box-shadow: 6px 6px 12px #00000050;

}

.scroll {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar-track {
  background-color: #181d29 !important;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #181d29 !important;
}

.scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    rgba(71, 249, 204, 1) 0%,
    rgba(2, 120, 232, 1) 100%
  ) !important;
}

.menu-list a {
  color: #d6d6d6 !important;
}
.menu-list a:hover {
  color: #363636 !important;
}
.upload {
  width: 100% !important;
}
.upload .button {
  width: 100% !important;
}
.icom {
  margin-right: 5px;
}
.menu_link {
  color: #0278e8;
}
.menu_link:hover {
  color: #0278e8;
  text-decoration: underline;
}


// .scroll-pages-menu {
//   min-height: 45vh;
//   overflow-y: scroll;
//   overflow-x: hidden;
// }
// .scroll-pages-menu::-webkit-scrollbar-track {
//   background-color: #181d29 !important;
// }

// .scroll-pages-menu::-webkit-scrollbar {
//   width: 4px;
//   background-color: #181d29 !important;
// }

// .scroll-pages-menu::-webkit-scrollbar-thumb {
//   background: linear-gradient(
//     180deg,
//     rgba(71, 249, 204, 1) 0%,
//     rgba(2, 120, 232, 1) 100%
//   ) !important;
// }


.scroll-pages-menu {
  min-height: 45vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-pages-menu::-webkit-scrollbar {
  width: 4px;
}

.scroll-pages-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.scroll-pages-menu::-webkit-scrollbar-thumb {
  background: #979797;
}


textarea::-webkit-scrollbar {
   width: 4px;
}

textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

textarea::-webkit-scrollbar-thumb {
  background: #979797;
}

.bicon-button{
  cursor: pointer;
  color: #979797;
  margin-left: 5px;
  width: 30px;
}
.bicon-button:hover{
  color: #0278e8;
}

.bicon-button-notes{
  cursor: pointer;
  color: #ffffff;
  margin-left: 5px;
}
.bicon-button-notes:hover{
  color: #0278e8;
}

.handle{
  cursor: grab;
}
.handle:active{
  cursor: grabbing;
}

pre{
  color: unset;
}

.number{
    all: initial;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
}


.dropdown.is-bottom-left .dropdown-menu {
    right: 0;
    left: -55px;
}

.dropdown.is-top-left .dropdown-menu {
    right: 0;
    left: -55px;
}

.icon.is-large{
 font-size: 3rem;
}


.wi {
    font-family: weathericons!important;
}

.grid-settings .modal-background {
    background-color: hsla(0,0%,4%,.5);
}

.syntho.image{
  display: unset;
  position: unset;
}


body::-webkit-scrollbar {
  width: 3px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid darkgrey;
}
</style>
