<template>
  <div class="ibox is-unselectable" :class="gridSettings.tiles.theme" :style="tileStyle">
      <div class="clock-text" :style="{color: options.textColor, fontSize: options.textSize + 'rem'}">{{time}}</div>
      <div class="location-text" :style="{color: options.textColor}">{{options.location}}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import colorMixin from "@/mixins/colorMixin";
export default {
  name: 'ClockItem',
  props: {
    options: Object
  },
  mixins: [colorMixin],
  data(){
    return {
      time: ""
    }
  },
  methods:{
    checkTime(i) {
      if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
      return i;
    },
    startTime() {
      let format = "T";
      if(this.options){     
        if(this.options.hasSeconds){
          format = "TT";
        }else{
          format = "T";
        }
        if(!this.options.is24){
          format = format.toLowerCase();
        }
        if(this.options.zone!=="local"){
          this.time = DateTime.local().setZone(this.options.zone).toFormat(format);
        }else{
          this.time = DateTime.local().toFormat(format);
        }
      }
      setTimeout(this.startTime, 100);
    }
  },  
  created(){
    this.startTime();
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;
      style.backgroundImage = 'url(' + this.options.bgImage + ')';

      if(this.gridSettings.tiles.theme == "pseudo3d"){
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }
      
      if(this.gridSettings.tiles.theme == "classic"){
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if(this.gridSettings.tiles.theme == "neumorphism"){
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      } 
      
      if(this.gridSettings.tiles.theme == "glassmorphism"){
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      }  
      return style;
    },
  },
  }
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
  padding: 5px 10px 5px 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.location-text{
  color: #ffffff;
  font-size: smaller;
}
.clock-text{
  color: #cacaca;
  font-size: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  overflow: hidden;
}
</style>
