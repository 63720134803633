<template>
  <div class="ibox" :class="gridSettings.tiles.theme" :style="tileStyle">
    <div v-if="isEditable" class="box-overlay"></div>
    <div class="note-editor" v-if="isEditing">
      <textarea
        spellcheck="false"
        :style="noteOptions"
        class="textarea"
        placeholder="New Note"
        v-model="noteHolder"
        :disabled="isEditable"
        maxlength="400"
      ></textarea>
      <div class="box-cancel">
        <b-button
          class="is-small"
          type="is-dark"
          icon-left="xmark"
          @click="resetNote()"
        />
        <b-button
          class="is-small"
          type="is-danger"
          icon-left="trash"
          @click="deleteNote()"
        />
      </div>
      <div class="box-save">
        <b-field grouped>
          <b-radio
            v-model="selectedColor"
            size="is-small"
            native-value="yellow"
            type="is-warning"
          >
          </b-radio>

          <b-radio
            v-model="selectedColor"
            size="is-small"
            native-value="green"
            type="is-success"
          >
          </b-radio>

          <b-radio
            v-model="selectedColor"
            size="is-small"
            native-value="blue"
            type="is-info"
          >
          </b-radio>

          <b-radio
            v-model="selectedColor"
            size="is-small"
            native-value="red"
            type="is-danger"
          >
          </b-radio>
          <p class="control">
            <b-button
              class="is-small"
              type="is-info"
              icon-left="floppy-disk"
              @click="saveNote()"
            />
          </p>
        </b-field>
      </div>
    </div>
    <v-calendar
      :attributes="attributes"
      :first-day-of-week="2"
      title-position="left"
      is-expanded
      :is-dark="options.dark"
      @dayclick="onDayClick"
      @daymouseenter="onDayMouseEnter"
      @daymouseleave="onDayMouseLeve"
    ></v-calendar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sanitizeMixin from "@/mixins/sanitizeMixin";
export default {
  name: "CalendarItem",
  props: {
    options: Object,
    elementID: Number,
  },
  mixins: [sanitizeMixin],
  data() {
    return {
      isEditing: false,
      editId: -1,
      noteHolder: "",
      selectedDate: null,
      selectedColor: "red",
      noteOptions: {
        backgroundColor: "#1B202C",
        color: "#f1f1f1",
        fontSize: "16px",
      },
    };
  },
  methods: {
    onDayMouseEnter(day) {
      //console.log(day);
      const idx = this.options.notes.findIndex((d) => d.date === day.id);
      //console.log(idx);
      if (idx !== -1) {
        this.$store.commit("tooltip/showTooltip", {
          show: true,
          type: "text",
          data: { text: this.options.notes[idx].note },
        });
      }
    },
    onDayMouseLeve(day) {
      this.$store.commit("tooltip/hideTooltip");
    },
    onDayClick(day) {
      const idx = this.options.notes.findIndex((d) => d.date === day.id);
      if (idx !== -1) {
        // console.log(this.notes[idx].note);
        this.noteHolder = this.options.notes[idx].note;
        this.selectedColor = this.options.notes[idx].color;
        this.editId = idx;
      }
      this.selectedDate = day.id;
      this.isEditing = true;
      console.log("selseted date: " + this.selectedDate);
      console.log("editId: " + this.editId);
    },
    deleteOldestNote() {
      this.options.notes.shift();
      this.saveNote();
    },
    saveNote() {
      console.log("saving note....");
      if(this.options.notes.length>50){
        this.$buefy.dialog.confirm({
          title: 'Too many notes',
          message: `You have too many notes. Please delete some of them before adding new ones. Do you want to delete the oldest note?`,
          cancelText: 'Cancel',
          confirmText: 'Delete & Save',
          type: 'is-danger',
          onConfirm: () => this.deleteOldestNote()
          })
      return;
      }
      if (this.editId >= 0) {
        if (this.noteHolder !== "") {
          this.options.notes[this.editId].note = this.sanitizeQuotes(
            this.noteHolder
          );
          this.options.notes[this.editId].color = this.selectedColor;
          console.log(this.options);
          this.saveLayouts();
          this.resetNote();
        } else {
          this.deleteNote();
        }
      } else {
        this.addNote();
      }
    },
    addNote() {
      console.log("adding note....");
      if (this.noteHolder !== "") {
        let note = this.sanitizeQuotes(this.noteHolder);
        let color = this.selectedColor;
        let date = this.selectedDate;
        this.options.notes.push({ note: note, color: color, date: date });
        console.log(this.options);
        this.saveLayouts();
        this.resetNote();
      } else {
        this.resetNote();
      }
    },
    deleteNote() {
      console.log("deleting note.." + this.editId);
      if (this.options.notes.length >= 2) {
        this.options.notes.splice(this.editId, 1);
      } else {
        this.options.notes.pop();
      }
      this.saveLayouts();
      this.resetNote();
    },
    resetNote() {
      this.editId = -1;
      this.noteHolder = "";
      this.selectedDate = "";
      this.selectedColor = "red";
      this.isEditing = false;
    },
    saveLayouts() {
      console.log("saving layout...");
      if (this.isType > 0) {
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      } else {
        this.$store.commit("layouts/updateLocal");
      }
    },
  },
  mounted() {
    if (!this.options.dark) {
      this.noteOptions.backgroundColor = "#f1f1f1";
      this.noteOptions.color = "#1B202C";
    }
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    attributes() {
      return [
        // Todo attributes
        ...this.options.notes.map((note) => ({
          dates: new Date(note.date),
          bar: {
            color: note.color,
          },
          // popover: {
          //   label: note.note,
          //  },
        })),
        {
          key: "today",
          highlight: "green",
          dates: new Date(),
        },
      ];
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
  },
  watch: {
    options: {
      handler(val, oldVal) {
        console.log("Item Changed");
        console.log(val);
        if (!this.options.dark) {
          this.noteOptions.backgroundColor = "#f1f1f1";
          this.noteOptions.color = "#1B202C";
        } else {
          this.noteOptions.backgroundColor = "#1B202C";
          this.noteOptions.color = "#f1f1f1";
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 8px;
}
.border {
  border: solid 2px rgb(48, 48, 48);
}
.box-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000050;
  z-index: 5;
  position: absolute;
}
.box-image {
  background-color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.note-editor {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
}

.note-editor .textarea {
  font-size: 18px;

  max-height: 100%;
  min-height: 100%;
  height: -webkit-fill-available !important;
  border: 0;
  resize: none;
}

.note-editor .textarea[disabled] {
  cursor: pointer;
}

.box-save {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  display: inline;
}

.box-cancel {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.textarea::placeholder {
  color: #7a7f8b;
}
</style>
<style>
.vc-grid-container {
  grid-template-columns: auto !important;
}
</style>
