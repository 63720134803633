<template>
  <div
    class="ibox"
    :class="gridSettings.tiles.theme"
    :style="tileStyle"
    @mouseover="showCopy = true"
    @mouseleave="showCopy = false"
  >

  <div
    v-if="options.showTitle"
    class="title-notes"
    >
    <div class="title-notes-text">{{ options.title }}</div>
  </div>

    <div class="box-codenote" :class="options.showTitle ? 'has-title' : ''">
      <prism-editor
        class="my-editor"
        :style="editorStyle"
        v-model="noteholder"
        :highlight="highlighter"
        line-numbers
        @focus="update"
        @blur="saveNote"
        @input="streamSave"
      ></prism-editor>
    </div>

    <!-- <div class="box-copy level" v-if="!edited" v-show="showCopy">
      <div class="bicon-button-notes" @click="copyToClipboard()">
        <b-icon class="is-small copy-icon" icon="clone" />
      </div>

      <div v-show="didCopy" class="ml-2 copied-text">Copied</div>
    </div> -->

    <div class="box-copy" v-if="!edited" v-show="showCopy">
      <b-button
        class="is-small"
        type="is-info"
        icon-left="clone"
        @click="copyToClipboard()"
      />
      <div @mouseover="showEditedOn = true" @mouseleave="showEditedOn = false">
        <b-tag class="info-tag" v-if="options.lastEditBy && !showEditedOn" type="is-info">Edited by: <span class="name-tag">{{options.lastEditBy}}</span></b-tag>
        <b-tag class="info-tag" v-if="options.lastEditBy && showEditedOn" type="is-info">{{options.lastEdit}}</b-tag>
      </div>
    </div>


    <div class="box-save" v-if="edited">
      <b-button
        class="is-small"
        type="is-info"
        icon-left="floppy-disk"
        @click="saveNote()"
      />
    </div>
  </div>
</template>

<script>
import sanitizeMixin from "@/mixins/sanitizeMixin";
import colorMixin from "@/mixins/colorMixin";
import { mapState } from "vuex";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.css"; // import syntax highlighting styles

//function to escape quotes in a string
function escapeQuotes(str) {
  str = str.replace(/\"/g, ";;quot;;");
  return str.replace(/'/g, ";;apos;;");
}

//function to unescape quotes in a string
function unescapeQuotes(str) {
  str = str.replace(/;;quot;;/g, '"');
  return str.replace(/;;apos;;/g, "'");
}

//function to copy to clipboard
function copyToClipboard(data) {
  var copyText = document.createElement("textarea");
  copyText.value = data;
  document.body.appendChild(copyText);
  copyText.select();
  //copyText.setSelectionRange(0, 99999);/* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  copyText.remove();
}

export default {
  name: "CodeNoteItem",
  props: {
    options: Object,
    elementID: Number,
  },
  components: {
    PrismEditor,
  },
  mixins: [sanitizeMixin,colorMixin],
  data() {
    return {
      edited: false,
      noteholder: unescapeQuotes(this.options.note),
      showCopy: false,
      didCopy: false,
      code: 'console.log("Hello World")',
      showEditedOn: false,
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js, "html"); // languages.<insert language> to return html with markup
    },
    isfocusd() {
      this.noteholder = unescapeQuotes(this.options.note);
    },
    update: function () {
      this.edited = true;
      this.$emit("isState", "editing");
    },
    streamSave() {
      if(this.isType > 0 && this.options.streamSave){
        console.log("stream saving note....");
        this.options.note = escapeQuotes(this.noteholder);
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      }
    },
    saveNote() {
      console.log("saving note....");
      this.options.note = escapeQuotes(this.noteholder);
      this.edited = false;
      this.$emit("isState", "saved");
      this.saveLayouts();
    },
    copyToClipboard() {
      console.log("copying to clipboard....");
      copyToClipboard(this.noteholder);
      this.didCopy = true;
      setTimeout(() => {
        this.didCopy = false;
      }, 500);
    },
    saveLayouts(){
      console.log("saving layout...");
      if(this.isType > 0){
        this.options.lastEdit = new Date().toLocaleString('en-US',{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
        this.options.lastEditBy = this.user.name;
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      }else{
        this.$store.commit("layouts/updateLocal");
      }
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("users", ["user"]),
    borderColor() {
      return this.convertHex(this.options.textColor, 10);
    },
    editorStyle() {
      let style = {};
      style.background = this.options.bgColor;
      style.fontSize = this.options.textSize + "px";
      return style;
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
  },
  // created() {
  //   document.addEventListener("visibilitychange", this.isfocusd);
  // },
  watch: {
    "options.note": function (newVal, oldVal) {
      // watch it
      if (newVal != oldVal) {
        this.noteholder = unescapeQuotes(this.options.note);
      }
    },
  },
};
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.border {
  border: solid 2px rgb(48, 48, 48);
}
.box-codenote {
  /* background-color: rgb(0, 0, 0); */
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
}

.box-codenote .textarea[disabled] {
  cursor: pointer;
}

.box-save {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 1;
}

.box-copy {
  width: calc(100% - 10px);
  position: absolute;
  bottom: 3px;
  left: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copied-text {
  animation: color-change 2s infinite;
}

@keyframes color-change {
  0% {
    color: rgba(71, 249, 204, 1);
  }
  50% {
    color: rgba(2, 120, 232, 1);
  }
  100% {
    color: rgba(71, 249, 204, 1);
  }
}

.copy-icon {
  text-shadow: 1px 1px #585858;
}


.title-notes {
  color: #fff;
  padding: 5px 10px 5px 10px;
  /* font-weight: bold; */
  font-size: .8rem;
  border-bottom: 1px solid #2222221f;
  background: #222222;
  /* display: flex;
  flex-direction: row; */
  text-align: center;
}

.title-notes-text {
  opacity: 0.7;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.has-title{
  max-height: calc(100% - 24px) !important;
  height: calc(100% - 24px) !important;
}

.name-tag{
  text-transform: capitalize;
}
/*no select*/
.info-tag{
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}


</style>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  /* background: #2d2d2d; */
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  /* font-size: 14px; */
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
.prism-editor__textarea,
.prism-editor__container {
  min-height: 100%;
}

.comment:before {
  display: none;
}
</style>
