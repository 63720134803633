<template>
  <div class="ibox is-unselectable" :class="gridSettings.tiles.theme" :style="tileStyle">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="box-feed">
           
        <div v-if="hasFeed" class="feed-list scroll-rss" :style="{color: options.textColor}">
          <div>         
          <div class="feed-title is-size-6 has-text-weight-bold" :style="{borderColor: borderColor}">{{feed.channels.TITLE}}</div>
        <template v-for="item in feed.data">
          <div :key="item.id" class="feed-item" :style="{borderColor: borderColor}">
            <div class="feed-text" @click="goToURL(item.LINK)" v-html="item.TITLE">                       
            </div>
          </div>
        </template>
          </div>  
        </div>
        <div class="feed-error" v-if="!hasFeed">
          <div :style="{color: borderColor}"><i class="fas fa-exclamation-triangle fa-3x"></i></div>
          <div class="has-text-centered" :style="{color: borderColor}">
            Error loading feed!<br>
            <span class="is-size-7">Please check if your RSS Feed link is valid.</span>
          </div>
        </div>       
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import colorMixin from "@/mixins/colorMixin";
import { cacheAdapterEnhancer } from 'axios-extensions';
import axios from 'axios';
axios.defaults.baseURL = "/";
const http = axios.create({
    // cache will be enabled by default
    adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});
export default {
  name: 'RSSItem',
  props: {
    options: Object
  },
  mixins: [colorMixin],
  data(){
    return {
      isLoading: false,
      hasFeed: false,
      feed: {
        channels: null,
        data: null,
        timestamp: null
      },
      forceUpdate: false,
    }
  },
  methods:{
    goToURL(link){
      let target = "_self";
      if(!this.isEditable){
        if(this.options.isNewTab){
          target = "_blank";
        }
        window.open(link, target);
      }
    },
    loadFeed(){
        //
        // let test = 1610895429000;
        // let last_thetime = DateTime.fromMillis(test).toFormat("m");
        // console.log("last time",last_thetime);
        //---------------------------------------------------------------
        console.log("loading feed..");
        this.isLoading = true;
        // this.axios
        //   .post("getrss.php", {
        //     action: "gettitle",
        //     url: this.options.feed
        //   })
        http.get(`https://gridsup.com/bestapi/functions/getrss.php?action=getRSS&url=${this.options.feed}`, {forceUpdate: this.forceUpdate})
          .then(response => {
            this.isLoading = false;
            console.log(response.data);        
            if (response.data.success) {
              this.hasFeed=true;
              this.feed.channels = response.data.channels;
              this.feed.data = response.data.items;
              if(!this.forceUpdate){
                this.checkTime(response.data.timestamp);
              }
            }else{
              this.hasFeed=false;
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.hasFeed = false;
            console.log(error);
          });
      //end post-------------------------------------------------   
    },
    checkTime(intime){
        let time = DateTime.local().toUTC().ts;
        // console.log(time)
        let currentTime = DateTime.fromMillis(time).toFormat("H");
        console.log("current time",currentTime);
        var lastTime = DateTime.fromMillis(intime*1000).toFormat("H");
        console.log("last time",lastTime);
        if((currentTime-lastTime)>6){
          this.forceUpdate = true;
          this.loadFeed();
        }else{
          this.forceUpdate = false;
        }
    }
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
     borderColor(){
      return this.convertHex(this.options.textColor, 20)
     },
      tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
  },
  watch:{
    'options':function(nVal,oVal){
      if(nVal!==oVal){
        this.loadFeed();
      }
    }
  },
  mounted(){
    console.log("mounted");
    this.loadFeed();
  }
}
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded{
  border-radius: 8px;
}
.border{
  border: solid 2px rgb(48, 48, 48);
}
.box-feed{
height: 100%;
}

.feed-list{
  padding:10px 10px 15px 10px;
  height: 100%;
}

.feed-title{
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #222;
  font-weight: 300;
}

.feed-item{
  margin: 5px 0 15px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #222;
  position: relative;
  cursor: pointer;
}

.feed-item:hover{
  background: linear-gradient(0deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0) 10%);
}


.feed-text{
  /* font-size: 0.8em; */
}

.feed-error{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scroll-rss{
  overflow: auto;
}
.scroll-rss::-webkit-scrollbar-track
{
	background-color: #3636367c!important;
}

.scroll-rss::-webkit-scrollbar
{
	width: 4px;
	background-color: #36363681!important;
}

.scroll-rss::-webkit-scrollbar-thumb
{
	background-color: #00bb8f!important;
}

@-moz-document url-prefix() {
    .scroll-moz{
      overflow-y: scroll;
      scrollbar-color: #00bb8f #000;
      scrollbar-width: thin;
    }
}
</style>
