<template>
  <div
    class="ibox"
    :class="gridSettings.tiles.theme"
    :style="tileStyle"
    @mouseover="showCopy = true"
    @mouseleave="showCopy = false"
  >
  <div
    v-if="options.showTitle"
    class="title-notes"
    :style="{ color: options.textColor, borderColor: borderColor }"
    >
    <div class="title-notes-text">{{ options.title }}</div>
  </div>
    <div class="box-note" :class="options.showTitle ? 'has-title' : ''" :style="{ backgroundColor: options.bgColor }">
      <textarea
        spellcheck="true"
        @focus="update"
        @blur="saveNote"
        :style="{
          backgroundColor: options.bgColor,
          color: options.textColor,
          fontSize: options.textSize + 'px',
        }"
        class="textarea"
        placeholder="New Note"
        v-model="noteholder"
        :disabled="isEditable"
        maxlength="3000"
      ></textarea>
    </div>
    <div class="box-copy" v-if="!edited" v-show="showCopy">
      <!-- <div class="bicon-button-notes" @click="copyToClipboard()">
        <b-icon class="is-small copy-icon" icon="clone" />
      </div> -->
      <b-button
        class="is-small"
        type="is-info"
        icon-left="clone"
        @click="copyToClipboard()"
      />
      <!-- <div v-show="didCopy" class="ml-2 copied-text">Copied</div> -->
      <div @mouseover="showEditedOn = true" @mouseleave="showEditedOn = false">
        <b-tag class="info-tag" v-if="options.lastEditBy && !showEditedOn" type="is-info">Edited by: <span class="name-tag">{{options.lastEditBy}}</span></b-tag>
        <b-tag class="info-tag" v-if="options.lastEditBy && showEditedOn" type="is-info">{{options.lastEdit}}</b-tag>
      </div>
     
    </div>
    <div class="box-save" v-if="edited">
      <b-button
        class="is-small"
        type="is-info"
        icon-left="floppy-disk"
        @click="saveNote()"
      />
    </div>
  </div>
</template>

<script>
//function to copy to clipboard
function copyToClipboard(data) {
  var copyText = document.createElement("textarea");
  copyText.value = data;
  document.body.appendChild(copyText);
  copyText.select();
  //copyText.setSelectionRange(0, 99999);/* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  copyText.remove();
}
//function to copy to clipboard

import sanitizeMixin from "@/mixins/sanitizeMixin";
import colorMixin from "@/mixins/colorMixin";
import { mapState } from "vuex";

//function to escape quotes in a string
function escapeQuotes(str) {
  str = str.replace(/\"/g, ";;quot;;");
  return str.replace(/'/g, ";;apos;;");
}

//function to unescape quotes in a string
function unescapeQuotes(str) {
  str = str.replace(/;;quot;;/g, '"');
  return str.replace(/;;apos;;/g, "'");
}

export default {
  name: "NoteItem",
  props: {
    options: Object,
    elementID: Number,
  },
  mixins: [sanitizeMixin, colorMixin],
  data() {
    return {
      edited: false,
      noteholder: unescapeQuotes(this.options.note),
      showCopy: false,
      didCopy: false,
      showEditedOn: false,
    };
  },
  methods: {
    isfocusd() {
      this.noteholder = unescapeQuotes(this.options.note);
    },
    update: function () {
      this.edited = true;
      this.$emit("isState", "editing");
    },
    saveNote() {
      console.log("saving note....");
      this.options.note = escapeQuotes(this.noteholder);
      this.edited = false;
      this.$emit("isState", "saved");
      this.saveLayouts();
    },
    copyToClipboard() {
      console.log("copying to clipboard....");
      copyToClipboard(this.noteholder);
      // this.didCopy = true;
      // setTimeout(() => {
      //   this.didCopy = false;
      // }, 500);
      this.$store.commit("toast/showToast", {
        text: "Copied to clipboard",
        type: "info",
        show: true,
      });
    },
    saveLayouts(){
      console.log("saving layout...");
      if(this.isType > 0){
        this.options.lastEdit = new Date().toLocaleString('en-US',{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
        this.options.lastEditBy = this.user.name;
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      }else{
        this.$store.commit("layouts/updateLocal");
      }
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("users", ["user"]),
    borderColor() {
      return this.convertHex(this.options.textColor, 10);
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
  },
  // created() {
  //   //document.addEventListener("visibilitychange", this.isfocusd);
  // },
  watch: { 
    'options.note': function(newVal, oldVal) { // watch it
          if(newVal!=oldVal){
            this.noteholder = unescapeQuotes(this.options.note);
          }
        }
      }
};
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 8px;
}
.border {
  border: solid 2px rgb(48, 48, 48);
}
.box-note {
  background-color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
}

.box-note .textarea {
  font-size: 18px;

  max-height: 100%;
  min-height: 100%;
  height: -webkit-fill-available !important;
  border: 0;
  resize: none;
}

.has-title{
  max-height: calc(100% - 20px) !important;
  height: calc(100% - 20px) !important;
}

.box-note .textarea[disabled] {
  cursor: pointer;
}

.box-save {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 1;
}

.box-copy {
  width: calc(100% - 10px);
  position: absolute;
  bottom: 3px;
  left: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copied-text{
  animation: color-change 2s infinite;
}

@keyframes color-change {
  0% { color: rgba(71, 249, 204, 1); }
  50% { color: rgba(2, 120, 232, 1); }
  100% { color: rgba(71, 249, 204, 1); }
}

.copy-icon{
text-shadow: 1px 1px #585858;
}


.title-notes {
  padding: 5px 10px 5px 10px;
  /* font-weight: bold; */
  font-size: .8rem;
  border-bottom: 1px solid #2222221f;
  background: linear-gradient(
    169deg,
    rgba(0, 0, 0, 0.09987745098039214) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* display: flex;
  flex-direction: row; */
  text-align: center;
}

.title-notes-text {
  opacity: 0.7;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name-tag{
  text-transform: capitalize;
}
/*no select*/
.info-tag{
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

</style>
