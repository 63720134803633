export default{
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
  },
  mutations: {
    addToClipboard(state, payload) {
      state.items.push(payload);
    },
    clearClipboard(state) {
      state.items = [];
    },
  },
  actions: {
  },
  modules: {
  }
}
