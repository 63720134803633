<template>
  <div class="mainbox" ref="listel">
    <!-- <transition name="fade" mode="in-out">
      <div v-if="!options.showTitle" class="floater" v-show="showByIndex" :style="floaterStyle">{{ getTitle(lastIndex) }}</div>
    </transition> -->
    <div
      class="ibox is-unselectable"
      :class="gridSettings.tiles.theme"
      :style="tileStyle"
    >
      <div class="box-link">
        <div
          v-if="options.title"
          class="title-links"
          :style="{ color: options.textColor, borderColor: borderColor }"
        >
          <div class="title-links-text">{{ options.title }}</div>
        </div>
        <div class="video-player" v-if="(!isEditable && isPlaying)">
          <iframe
                width="100%" height="100%"
                :src="('https://youtube.com/embed/'+isPlaying)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="YouTube video player" 
              ></iframe>
        </div>
        <div class="foat-message" v-show="linkForm.message">
          {{ linkForm.message }}
        </div>
        <section id="tinput">
          <div @keyup.enter.exact="addLink()">
            <b-field :type="linkForm.status">
              <b-input
                custom-class="link-input"
                v-model="linkForm.data"
                placeholder="YouTube Link"
                expanded
                @focus="$emit('isState', 'editing')"
                @blur="$emit('isState', 'saved')"
              ></b-input>
              <p class="control">
                <b-button
                  @click="addLink()"
                  type="is-success"
                  icon-right="plus"
                  v-if="linkForm.data"
                  :disabled="isEditable"
                  :loading="isLoading"
                />
                <b-button
                  @click="addLinks()"
                  type="is-info"
                  icon-right="plus"
                  v-if="!linkForm.data"
                  :disabled="isEditable"
                  :loading="isLoading"
                />
              </p>
            </b-field>
          </div>
        </section>
        <div class="scroll-list">
          <div
            class="list-link scroll-item"
            :style="{ color: options.textColor }"
          >
            <div>
              <draggable
                v-model="options.links"
                v-bind="dragOptions"
                group="youtube"
                @start="drag = true"
                @end="drag = false"
                @change="checkMove()"
                handle=".handle"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <template v-for="item in options.links">
                    <div
                      :key="item.id"
                      @mouseover="setShowByIndex(item.id)"
                      @mouseout="unsetShowByIndex"
                      class="item-link"
                      :style="{ borderColor: borderColor }"
                    >
                      <div
                        class="link-wrapper"
                        @click="playVideo(item.videoID)"
                      >
                        <div class="link-text">
                          <b-icon icon="youtube" :type="item.videoID == isPlaying ? 'is-danger' :''"></b-icon> {{ item.title }}
                        </div>
                      </div>
                      <div
                        v-show="showByIndex === item.id && !drag"
                        class="move-link"
                      >
                        <b-button
                          v-show="!drag"
                          @click="deleteLink(item.id)"
                          type="is-danger"
                          size="is-small"
                          icon-right="trash"
                        />
                        <b-button
                          type="is-dark"
                          size="is-small"
                          icon-right="grip-dots"
                          class="handle"
                        />
                      </div>
                    </div>
                  </template>
                </transition-group>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import colorMixin from "@/mixins/colorMixin";
import urlMixin from "@/mixins/urlMixin";
export default {
  name: "YouTubeItem",
  props: {
    options: Object,
    elementID: Number,
  },
  components: {
    draggable,
  },
  mixins: [sanitizeMixin, colorMixin, urlMixin],
  data() {
    return {
      isLoading: false,
      linkForm: {
        data: "",
        status: null,
        message: null,
      },
      showByIndex: null,
      isPlaying: null,
      //lastIndex: null,
      drag: false,
      didMove: false,
      delayedDragging: false,
      order: [],
      floater: {
        text: null,
        display: false,
      },
    };
  },
  methods: {
    setShowByIndex(id) {
      this.showByIndex = id;
    },
    unsetShowByIndex() {
      this.showByIndex = null;
      //this.lastIndex = null;
    },
    playVideo(videoID) {
      console.log("play video", videoID);
      if(this.isPlaying == videoID){
        this.isPlaying = null;
      }else{
        this.isPlaying = videoID;
      }
    },
    goToVideo(videoID) {
      let target = "_self";
      if (this.options.isNewTab) {
        target = "_blank";
      }
      window.open(link, target);
    },
    checkMove() {
      this.didMove = true;
      console.log("did move");
      let count = this.options.links.length;
      console.log(count);
      this.options.links.forEach((element, inc) => {
        element.id = inc+1;
      });
      console.log(this.options.links);

      this.saveLayouts();
      this.didMove = false;
      //this.showSave = true;
    },
    deleteLink(id) {
      console.log("deleting link.." + id);
      let found = this.options.links.indexOf(
        this.options.links.find((obj) => obj.id == id)
      );
      if (this.options.links.length >= 2) {
        this.options.links.splice(found, 1);
      } else {
        this.options.links.pop();
      }
      //reindexing
      this.options.links.forEach((element, inc) => {
        element.id = inc+1;
      });
      this.saveLayouts();
    },
    addLink() {
      if (this.urlCheck()) {
        let lowest = 1;
        if (
          (this.options.links !== undefined) &
          (this.options.links.length !== 0)
        ) {
          lowest = this.options.links.length + 1;
        }
        // let lowest = 0;
        // if (
        //   (this.options.links !== undefined) &
        //   (this.options.links.length !== 0)
        // ) {
        //   //generating id
        //   let ids = [];
        //   this.options.links.forEach((item) => ids.push(item.id));
        //   ids.sort(function (a, b) {
        //     return a - b;
        //   }); // To sort by numeric
        //   for (let i = 0; i < ids.length; ++i) {
        //     if (ids[i] != i) {
        //       lowest = i;
        //       break;
        //     }
        //   }
        //   if (lowest == 0) {
        //     console.log("ids.length " + ids.length);
        //     lowest = ids[ids.length - 1] + 1;
        //   }
        // }
        console.log("lowest " + lowest);
        //---------------------------------------------------------------
        console.log("adding link..");
        this.isLoading = true;
        this.axios
          .post(
            "functions/gettitle.php",
            {
              action: "getTitleClean",
              url: this.linkForm.data,
            },
            { timeout: 5000 }
          )
          .then((response) => {
            this.isLoading = false;
            console.log(response.data);
            if (response.data.success) {
              let videoID = this.extractVideoID(this.linkForm.data);
              if(!videoID){
                this.setErrorMessage("Invalid URL");
                return;
              }
              if (response.data.title) {
                this.options.links.unshift({
                  id: lowest,
                  videoID: videoID,
                  title: response.data.title,
                });
              } else {
                this.options.links.unshift({
                  id: lowest,
                  videoID: videoID,
                  title: "youtube video",
                });
              }
            } else {
              this.options.links.unshift({
                id: lowest,
                videoID: videoID,
                title: "youtube video",
              });
            }
            this.linkForm.data = "";
              this.saveLayouts();
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            if (error.code === "ECONNABORTED") {
              let videoID = this.extractVideoID(this.linkForm.data);
              if(!videoID){
                this.setErrorMessage("Invalid URL");
                return;
              }
              this.options.links.unshift({
                id: lowest,
                videoID: videoID,
                title: "youtube video",
              });
              console.log("Request timed out");
                this.saveLayouts();
            } else {
              console.log("An error occurred:", error.message);
            }
          });
        //end post-------------------------------------------------
      }
    },
    addLinks() {
      this.warningAddAll();
    },
    warningAddAll() {
      // this.$buefy.dialog.confirm({
      //   title: "Add All Tabs",
      //   message: "Are you sure you want to add all current youtube tabs to this list?",
      //   confirmText: "Add All",
      //   type: "is-info",
      //   hasIcon: true,
      //   icon: "youtube",
      //   onConfirm: () => this.addAllTabs(),
      // });
      this.$buefy.dialog.alert({
        title: "Add All Tabs",
        message: "This action is not supported by the Online version of Gridsup",
        confirmText: "Close",
        type: "is-warning",
        hasIcon: true,
        icon: "youtube"
      });
    },
    addAllTabs() {
      if(this.$gridVars.browserType == "chrome"){
        chrome.windows.getAll({populate:true}, this.getAllOpenYoytubeWindows);
      }else{
        browser.windows.getAll({ populate: true }, this.getAllOpenYoytubeWindows);
      }
      //chrome.windows.getAll({populate:true}, this.getAllOpenYoytubeWindows);
      //browser.windows.getAll({ populate: true }, this.getAllOpenYoytubeWindows);
    },
    getAllOpenYoytubeWindows(winData) {
      var tabs = [];
      for (var i in winData) {
        if (winData[i].focused === true) {
          var winTabs = winData[i].tabs;
          var totTabs = winTabs.length;
          for (var j = 0; j < totTabs; j++) {
            if(this.validYouTubeURL(winTabs[j].url)){
              this.directLinkAdd(
                winTabs[j].url,
                winTabs[j].title
              );
            }
          }
        }
      }
      console.log(tabs);
      this.listID = null;
      this.saveLayouts();
    },
    directLinkAdd(link, title) {
        let videoID = this.extractVideoID(link);
        
        let lowest = 1;
        if (
          (this.options.links !== undefined) &
          (this.options.links.length !== 0)
        ) {
          lowest = this.options.links.length + 1;
        }

        console.log("lowest " + lowest);
        //---------------------------------------------------------------
        console.log("adding youtube link..");
        this.options.links.unshift({
          id: lowest,
          videoID: videoID,
          title: title
        });
    },
    saveLayouts() {
      console.log("saving layout...");
      if (this.isType > 0) {
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      } else {
        this.$store.commit("layouts/updateLocal");
      }
    },
    formCheck() {
      if (!this.linkForm.data) {
        this.linkForm.status = "is-danger";
        return false;
      } else {
        this.linkForm.status = "";
        return true;
      }
    },
    urlCheck() {
      if (!this.validYouTubeURL(this.linkForm.data)) {
        this.linkForm.status = "is-danger";
        this.setErrorMessage("Please enter a valid YouTube URL");
        return false;
      } else {
        this.linkForm.status = "";
        this.linkForm.message = "";
        return true;
      }
    },
    setErrorMessage(message) {
      this.linkForm.message = message;
      setTimeout(() => {
        this.linkForm.message = "";
      }, 3000);
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    borderColor() {
      return this.convertHex(this.options.textColor, 10);
    },
    floaterStyle() {
      return {
        backgroundColor: this.options.bgColor,
        color: this.options.textColor,
        borderTopRightRadius: this.gridSettings.tiles.rounded + "px",
        borderBottomRightRadius: this.gridSettings.tiles.rounded + "px",
      };
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "lghost",
      };
    },
  },
  watch: {
    drag(nVal, oVal) {
      console.log(nVal);
      if (nVal) {
        this.showByIndex = null;
      }
      if (!nVal) {
        this.showByIndex = null;
      }
    },
  },
};
</script>

<style scoped>
.mainbox {
  height: 100%;
}

.site-title {
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 1.3;
  opacity: 0.7;
}

.floater {
  position: absolute;
  right: 0px;
  top: 50px;
  color: #fff;
  transform: translate(100%, 0);
  width: 200px;
  padding: 10px;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  box-shadow: 20px 0px 20px -19px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 20px 0px 20px -19px rgba(0, 0, 0, 0.3) inset;
  -moz-box-shadow: 20px 0px 20px -19px rgba(0, 0, 0, 0.3) inset;
}

.ibox {
  height: 100%;
  overflow: hidden;
}

.rounded {
  border-radius: 8px;
}

.border {
  border: solid 2px rgb(48, 48, 48);
}

.box-link {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-links {
  padding: 5px 10px 5px 10px;
  /* font-weight: bold; */
  font-size: small;
  border-bottom: 1px solid #222;
  background: linear-gradient(
    169deg,
    rgba(0, 0, 0, 0.09987745098039214) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* display: flex;
  flex-direction: row; */
  text-align: center;
}

.title-links-text {
  opacity: 0.7;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dotmark {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: green;
  margin-right: 6px;
  margin-top: 2px;
}

.draggable {
  min-height: 20px;
}

.list-link {
  padding: 15px 10px 15px 10px;
  font-weight: 300;
  height: 100%;
}

.item-link {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #222;
  position: relative;
  cursor: pointer;
}

.text-done {
  text-decoration: line-through;
  opacity: 0.5;
}

.delete-link {
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}

.edit-link {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}

.link-text {
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.link-wrapper {
  transition: all 0.5s;
}

/* .item-link:hover{
  background: linear-gradient(0deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0) 10%);
} */
/* .item-link:hover .text-link{
margin-left:40px;
} */

/* .text-link-margin{
margin-left:40px;
} */

.move-link {
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
}

.sicon {
  vertical-align: middle;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.foat-message {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  opacity: 1;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0px;
}

.video-player {
  width: 100%;
  height: 60%;
}

video-player-outer{
  position: relative;
    width: 100%;
}

video-player-inner{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.scroll-list {
  height: 100%;
  /* position: relative; */
  overflow: hidden;
}
.scroll-item {
  overflow: auto;
}

@-moz-document url-prefix() {
  .scroll-moz {
    overflow-y: scroll;
    scrollbar-color: #979797 rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
}

.scroll-item::-webkit-scrollbar {
  width: 4px;
}

.scroll-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-item::-webkit-scrollbar-thumb {
  background: #979797;
}
</style>

<style>
.link-input {
  color: #fff;
  background-color: #00000060;
  border: none;
}

.link-input::placeholder {
  color: #ffffff85;
}
</style>
