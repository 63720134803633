<template>
  <div 
    class="ibox" 
    :class="gridSettings.tiles.theme" :style="tileStyle"
  >
    <!-- <div v-if="isEditable" class="box-overlay"></div> -->
     <div class="box-frame" v-bind:style="{backgroundColor: options.bgColor}" >
    <iframe v-if="isType==0" :src="options.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div v-if="isType > 0" class="error-wrap"><div class="error-message" >This Should not be here. Please revove this element.</div></div>
    </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'FrameItem',
  props: {
    options: Object
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
  },
}
</script>
<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded{
  border-radius: 8px;
}
.border{
  border: solid 2px rgb(48, 48, 48);
}
.box-overlay{
  width: 100%;
  height: 100%;
  background-color: #00000050;
  z-index:1;
  position:absolute;
}
.box-frame {
  background-color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.box-frame iframe {
  border:none;
  height: 100%;
  width: 100%;
  border:0px;
}

.error-wrap{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-message{
  color: rgb(214, 0, 0);
  font-size: 18px;
  text-align: center;
}
</style>
