<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Clock Settings</p>
        </header>
        <section class="modal-card-body">
            <section>
              <div class="columns">
                <div class="column">
                  <div class="separator mb-2">Preview</div>
       
                    <div class="box-clock" :style="{backgroundColor: options.bgColor}">
                    <div class="clock-text" :style="{color: options.textColor, fontSize: options.textSize + 'rem'}">{{time}}</div>
                    <div class="location-text" :style="{color: options.textColor}">{{options.location}}</div>
                    </div>
      
                </div>
                <!-- col1 -->
                <div class="column has-text-left options">
                  <div class="separator mb-2">Options</div>

                  <b-field>
                    <b-select @input="setTimezone()" placeholder="Select Timezone" v-model="selectedTimezone">
                      <option
                          class="optionGroup"
                          :value="{ val: 'local', name: 'Local Time' }"
                          >Local Time</option
                      >
                      <template v-for="(data, index) in selectTimezone">
                        <option
                          v-bind:key="'parent' + index"
                          :value="{ val: data.location, name: data.name }"
                          >{{ data.location }} {{ data.timezone }}</option
                        >
                      </template>
                    </b-select>
                  </b-field>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <b-switch v-model="options.is24">24 Hour Clock</b-switch>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <b-switch v-model="options.hasSeconds">Show Seconds</b-switch>
                      </div>
                    </div>
                  </div>

                  <b-field>
                    <p class="control">
                      <span class="button is-static">Location Name:</span>
                    </p>
                    <b-input
                      v-model="options.location"
                      maxlength = 30
                      expanded
                    ></b-input>
                  </b-field>

                  <p>Text Size:</p>
                  <b-field>
                    <b-slider
                      v-model="options.textSize"
                      :min="1"
                      :max="6"
                      :step="0.1"                      
                    ></b-slider>
                  </b-field>

                  <div class="level">
                    <div class="level-left">
                      <div>Text Color:</div>
                    </div>
                    <div class="level-right">
                      <b-colorpicker v-model="options.textColor" />
                    </div>
                  </div>
                  <div class="level">
                    <div class="level-left">
                      <div>Background Color:</div> 
                    </div>
                    <div class="level-right">
                      <b-colorpicker v-model="options.bgColor" />
                    </div>
                  </div>
                </div>
                <!-- col2 -->
              </div>
            </section>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()"
            >Save Clock</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
const timezones = require("@/data/timeZones.json");
import { mapState } from "vuex";
import { DateTime } from "luxon";
import globalMixin from "@/mixins/globalMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "EditClockModal",
  mixins: [globalMixin, sanitizeMixin, toastMixin],
  data() {
    return {
      selectTimezone: timezones,
      selectedTimezone: null,
      time: "",
      options: {
        textColor: "#ffffff",
        bgColor: "#000000",
        textSize: 3,
        location: "Local Time",
        zone: "local",
        is24: true,
        hasSeconds: false
      },
      result: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    startTime() {
      let format = "T";
      if(this.options){     
        if(this.options.hasSeconds){
          format = "TT";
        }else{
          format = "T";
        }
        if(!this.options.is24){
          format = format.toLowerCase();
        }
        if(this.options.zone!=="local"){
          this.time = DateTime.local().setZone(this.options.zone).toFormat(format);
        }else{
          this.time = DateTime.local().toFormat(format);
        }
      }
      setTimeout(this.startTime, 100);
    },
    closeModal() {
      this.$store.commit("modals/hideModal", "editClock");  
    },
    reset() {
      this.is24 = true;     
      this.selectedTimezone = null;
      this.options = {
        textColor: "#ffffff",
        bgColor: "#000000",
        textSize: 3,
        location: "Local Time",
        zone: "local",
        is24: true
      };
    },
    formCheck() {
      if (!this.result.data) {
        this.result.status = "is-danger";
        this.result.message = "Please enter a valid URL";
        return false;
      } else {
        return true;
      }
    },
    setTimezone() {
      this.options.zone = this.selectedTimezone.val;
      this.options.location = this.selectedTimezone.name;
    },
    saveItem(){         
      console.log("saving item..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      this.options.textColor = this.options.textColor.toString('hex');
      this.options.location = this.sanitizeQuotes(this.options.location);
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();

      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
        ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  textColor: "#ffffff",
                  bgColor: "#000000",
                  textSize: 3,
                  location: "Local Time",
                  offset: 0,
                  is24: true
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editClock;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editClock");
        return false;
      },
    },
  },
  filters: {
    initial(val) {
      if (val) return val[0];
    }
  },
  created(){
    this.startTime();
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
        this.selectedTimezone = {"val": this.options.zone, "name": this.options.location};
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}
.modal-card-body {
  overflow: visible !important;
}
.box-clock {
  background-color: rgb(39, 39, 39);
  padding: 5px 10px 5px 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 250px;
}
.location-text{
  color: #ffffff;
  font-size: smaller;
}
.clock-text{
  color: #cacaca;
  font-size: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  overflow: hidden;
}
.optionGroup {
  margin-top: 5px !important;
  font-weight: bold;
}
</style>

