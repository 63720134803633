<template>
  <div class="ibox is-unselectable" :class="gridSettings.tiles.theme" :style="tileStyle">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="box-wether" :style="{backgroundColor: bgDNcolor}">
      <div class="wether-text" v-if="hasData">
      <span class="icon"> <i :class="'wi '+forecast.weatherIcon"></i> </span> <span>{{ (scaleSymbol === 'C')? forecast.temperatureValue : fValue }}</span> <span class="scale" >&deg;{{ scaleSymbol }}</span>
      </div>          
      <div v-if="hasData" class="location-text" :style="{color: options.locationColor}">{{forecast.humidity}}<i class="minicon syntho droplet"></i> {{forecast.windSpeed}}<i class="minicon syntho wind"></i> {{ forecast.location }}</div>


    <div class="data-error" v-if="!hasData">
      <div><i class="fas fa-exclamation-triangle fa-2x"></i></div>
      <div class="has-text-centered is-size-7">
        Error loading forecast!
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { cacheAdapterEnhancer } from 'axios-extensions';
import axios from 'axios';
axios.defaults.baseURL = "/";
//full forcast!
//https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/Chisinau?unitGroup=metric&key=NN947Q8Y5RF57WULKE72QDH3J&contentType=json

const http = axios.create({
    // cache will be enabled by default
   adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});

export default {
  name: 'WeatherItem',
  props: {
    options: Object
  },
  data(){
    return {
      isLoading: false,
      hasData: false,
      isLocal: true,
      coordinates: {
        latitude: null,
        longitude: null
      },
      forecast: {
        cloudiness: 0,
        windSpeed: 0,
        humidity: 0,
        temperatureValue:'-',
        temperatureHigh: 0,
        temperatureLow: 0,
        location: ' ',
        description: 'Please connect to internet to fetch latest forecast :)',
        weatherIcon: "wi-day-sunny",
        timezone: '',
        sunrise: 0,
        sunset: 0
      },
      forceUpdate: false
    }
  },
  
  methods:{
    loadData(){
      console.log("wether");

     // return;
      let appId = '5b8fcd7f4732e51440b76d76d3c366d9';
      let endpoint = `https://api.openweathermap.org/data/2.5/weather?lat=${this.coordinates.latitude}&lon=${this.coordinates.longitude}&appid=${appId}&units=metric`;
      if (!this.isLocal){
        endpoint = `https://api.openweathermap.org/data/2.5/weather?q=${this.options.city}&appid=${appId}&units=metric`;
      }
      //---------------------------------------------------------------
      console.log("loading data..");
      this.isLoading = true;
      // this.axios({
      //   method:'get',
      //   url: endpoint,
      //   baseURL: 'https://api.openweathermap.org/data/2.5/',
      // })
      http.get(endpoint, {forceUpdate: this.forceUpdate})
        .then(response => {
          this.isLoading = false;
          console.log(response.data);        
          if (response) {
            this.hasData=true;
            this.forecast.timezone = response.data.timezone;
            this.forecast.sunrise = response.data.sys.sunrise;
            this.forecast.sunset = response.data.sys.sunset;
            this.forecast.cloudiness = response.data.clouds.all;
            this.forecast.windSpeed = response.data.wind.speed;
            this.forecast.humidity = response.data.main.humidity;
            this.forecast.temperatureValue = Math.round(response.data.main.temp);
            this.forecast.temperatureHigh = Math.round(response.data.main.temp_max);
            this.forecast.temperatureLow = Math.round(response.data.main.temp_min);
            this.forecast.location = this.formatLocation(response.data.name, response.data.sys.country);
            this.forecast.description = response.data.weather[0].description;
            this.forecast.weatherIcon = this.getWeatherIcon(response.data.weather[0].id);
            if(!this.forceUpdate){
              this.checkTime(response.data.dt);
            }
          }else{
            this.hasData=false;
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.hasData = false;
          console.log(error);
        });
      //end post-------------------------------------------------   
    },
    getLocal(){
      if (localStorage.grgeolocation) {
        this.coordinates = JSON.parse(localStorage.grgeolocation);
        this.loadData();      
      }else{        
        if (navigator.onLine) {
          navigator.geolocation.getCurrentPosition(position => {
            this.coordinates = position.coords;
            let geo = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
            localStorage.grgeolocation = JSON.stringify(geo);
            //console.log(geo);
            this.loadData();
          });
        }
      }
    },
    formatLocation(city, country) {
      if (city === null && country === null) {
        return '';
      }
      return `${city}, ${country}`;
    },
    period() {
      let time = DateTime.utc().toFormat("H");
      console.log("time",time);
      let localtime = parseInt(time) + Math.round(this.forecast.timezone / 3600);
      console.log("localtime",localtime);
      return (localtime > 5 && localtime < 18) ? 'wi-day-': 'wi-night-';
    },
    icons() {
      let nighticons = ["alt-lightning", "alt-showers", "snow", "alt-cloudy", "fog", "clear"];
      let dayicons = ["lightning", "showers", "snow", "cloudy", "fog", "sunny"];
      if(this.period()=="wi-day-"){
        return dayicons;
      } else {
        return nighticons;
      }   
    },
    getWeatherIcon(id) {       
      let icons = this.icons();
      //console.log("wether id:"+id);
      if(this.isThunderstorm(id)) {
          return this.period() + icons[0];
      }

      if(this.isDrizzle(id) || this.isRain(id)) {
          return this.period() + icons[1];
      }

      if(this.isSnow(id)) {
          return this.period() + icons[2];
      }

      if(this.isClouds(id)) {
          return this.period() + icons[3];
      }
    
      if(this.isAtmosphere(id)) {
          return this.period() + icons[4];
      }

      if(this.isClear(id)) {
          return this.period() + icons[5];
      }
      return this.period() + icons[5];
    },
    isThunderstorm(id) {
        return id > 199 && id < 233;
    },
    isDrizzle(id) {
        return id >299 && id < 322;
    },
    isRain(id) {
        return id > 499 && id < 532;
    },
    isSnow(id) {
        return id > 599 && id < 623;
    },
    isClear(id) {
        return id == 800;
    },
    isClouds(id) {
        return id > 800 && id < 805;
    },
    isAtmosphere(id) {
        return id > 700 && id < 782;
    },
    toFahrenheit(value) {
      return Math.floor((value * 1.8) + 32);
    },
    updateData(){
      if('city' in this.options){
      if(this.options.city){
        this.isLocal = false;
        this.loadData()
      }else{
        this.isLocal = true;
        this.getLocal()  
      }}else{
        this.isLocal = true;
        this.getLocal()  
      }
    },
    checkTime(intime){
        let time = DateTime.local().toUTC().ts;
        // console.log(time)
        let currentTime = DateTime.fromMillis(time).toFormat("H");
        console.log("current time",currentTime);
        var lastTime = DateTime.fromMillis(intime*1000).toFormat("H");
        console.log("last time",lastTime);
        if((currentTime-lastTime)>=1){
          this.forceUpdate = true;
          this.loadData();
        }else{
          this.forceUpdate = false;
        }
    }
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
            scaleSymbol() {
            return this.scale.charAt(0);
        },

        fValue() {
            return this.toFahrenheit(this.forecast.temperatureValue);
        },

        fHigh() {
            return this.toFahrenheit(this.forecast.temperatureHigh);
        },

        fLow() {
            return this.toFahrenheit(this.forecast.temperatureLow);
        },

        bgDNcolor() {
          if(this.forecast){
          let time = DateTime.utc().toFormat("H");
          let localtime = parseInt(time) + Math.round(this.forecast.timezone / 3600);
          return (localtime > 5 && localtime < 18)? '#00a6f6': '#181d29';
          }
        },

      scale(){
      if('units' in this.options){        
        return this.options.units;
      }else{
        return "C";
      }

    },
     tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
  },
  mounted(){
    this.updateData();
  },
  watch:{
    'options.city': function(nval,oval){
      if(nval!==oval){
        this.updateData();
        console.log("watcher", [nval,oval]);
      }
    },
    // deep: true
  }
}
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 8px;
}
.box-wether {
  background-color: rgb(39, 39, 39);
  padding: 5px 10px 5px 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.location-text{
  color: #ffffffdc;
  font-size: smaller;
}
.weather-icon {
    width: 6em;
}
.wether-text{
  color: #f0f0f0;
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  overflow: hidden;
}
.icon{
 margin: 0px 20px 0px 0px;
}
.minicon{
 margin: 0px 5px 0px 2px;
}
.scale{
  color:#ffffffdc;
}

.nobpadding{
  padding-bottom: 0px;
}
.nobpmargin{
  margin-bottom: 0px;
}
.wether-variation {
  font-size: 10px;  
  color: #cacaca;
  text-align: center;
}


.cloudiness img {
    width: 48px;
    height: 48px;
    vertical-align: middle;
}

.wind-speed img {
    width: 48px;
    height: 48px;
    vertical-align: middle;
}

.humidity img {
    width: 48px;
    height: 48px;
    vertical-align: middle;
}


.temperature__value {
    font-size: 7rem;
    color: rgba(255, 255, 255, 0.75);
}

.temperature__scale {
    padding-top: 5px;
    font-size: 2em;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75);
}

.location {
    text-transform: uppercase;
    font-weight: bold;
    color: #cacaca;
}

.weather__description {
    text-transform: lowercase;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
     color: #cacaca;
}

.weather__description:first-letter {
    text-transform: uppercase;
}

.data-error{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #373749;
}

</style>
