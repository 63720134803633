var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-pages-btns"},[_c('div',{staticClass:"btn-manage-wrapper"},[_c('b-button',{attrs:{"type":"is-mydark","size":"is-small","icon-left":"plus"},on:{"click":function($event){return _vm.editBoards()}}})],1),(!_vm.detectMobileDevice())?_c('draggable',_vm._b({staticClass:"tabs-wrapper",attrs:{"group":"gridTabs","handle":".handle"},model:{value:(_vm.inGridTabs),callback:function ($$v) {_vm.inGridTabs=$$v},expression:"inGridTabs"}},'draggable',_vm.dragOptions,false),[_vm._l((_vm.inGridTabs),function(item,index){return [_c('div',{key:'tab-'+index,staticClass:"page-tab",class:[
          _vm.isActive(item),
          {
            editing: _vm.isEditable,
            personal: item.type == 0,
            shared: item.type == 1,
            common: item.type == 2,
            'tab-loading': _vm.isLoadingBoard,
          },
        ],on:{"click":function($event){return _vm.changeBoard(item)}}},[_c('div',{staticClass:"page-tab-text"},[_vm._v(_vm._s(item.name))]),(_vm.isEditable)?_c('div',{staticClass:"handle"},[_c('b-icon',{attrs:{"icon":"grip-dots"}})],1):_vm._e()])]})],2):_vm._e(),_c('div',{staticClass:"btn-wrapper"},[_c('b-button',{attrs:{"type":"is-mydark","size":"is-small","icon-left":"bars","label":"Boards"},on:{"click":function($event){return _vm.openBoards()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }