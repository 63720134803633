export default {
  methods: {
    sanitizeQuotes(str){
      str = str.replace(/["`]/gim,""); //eslint-disable-line
      //str = str.replace(/[']/g,"\'"); //eslint-disable-line
      return str.trim();
    },
    sanitizeString(str){
      str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,""); //eslint-disable-line
      return str.trim();
    },
    sanitizeName(str){
      str = str.replace(/["`]/gim,""); //eslint-disable-line
      return str.trim();
    },
    escapeHtml(s) {
      return (s + '').replace(/[&<>"']/g, function (m) {
          return ({
              '&': '&amp;', '<': '&lt;', '>': '&gt;',
              '"': '&quot;', "'": '&#39;'
          })[m];
      })
    },
    //function to escape quotes in a string
    escapeQuotes(str) {
      str = str.replace(/\"/g, ";;quot;;");
      return str.replace(/'/g, ";;apos;;");
    },
    //function to unescape quotes in a string
    unescapeQuotes(str) {
      str = str.replace(/;;quot;;/g, '"');
      return str.replace(/;;apos;;/g, "'");
    },
  }
};
