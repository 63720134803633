<template>
  <div class="home pt" @contextmenu="toggleEditable">
    <div v-if="isCover == 1" class="mbt">
      <b-tooltip
        position="is-right"
        type="is-dark"
        label="Lights On"
        :delay="1000"
      >
        <b-button type="is-dark" @click="setBlack(0)" icon-left="lightbulb" />
      </b-tooltip>
    </div>
    <div class="cov" v-if="isCover"></div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <grid-layout
      :layout.sync="layouts[isBoard].layout"
      :col-num="parseInt(gridSettings.grid.wCell) || 12"
      :row-height="parseInt(gridSettings.grid.hCell) || 30"
      :is-draggable="isEditable"
      :is-resizable="isEditable"
      :responsive="false"
      :is-mirrored="gridSettings.grid.isMirrored"
      :vertical-compact="gridSettings.grid.isVertical"
      :prevent-collision="gridSettings.grid.isCollision"
      :margin="[gridSettings.grid.space, gridSettings.grid.space]"
      :use-css-transforms="false"
      :key="isBoard"
    >
      <grid-item
        v-for="item in layouts[isBoard].layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :minW="item.minW"
        :minH="item.minH"
        :maxW="item.maxW"
        :maxH="item.maxH"
        :static="false"
        @resized="movedEvent"
        @moved="movedEvent"
        class="box-item-conteiner"
      >
        <span class="box-edit" v-if="isEditable">
          <b-dropdown :triggers="['hover']" aria-role="list">
            <b-button
              class="button is-info is-small"
              slot="trigger"
              icon-left="bars"
            />

            <b-dropdown-item v-if="[6,18].includes(item.type)" aria-role="listitem" @click="downloadFile(item)"
              >Download</b-dropdown-item
            >
            <b-dropdown-item v-if="![2,6].includes(item.type)" aria-role="listitem" @click="editeItem(item)"
              >Settings</b-dropdown-item
            >
            <b-dropdown-item v-if="![16].includes(item.type)" aria-role="listitem" @click="addToClipboard(item)"
              >Copy</b-dropdown-item
            >
            <b-dropdown-item v-if="[0,1,3,4,5,7,12,14,17].includes(item.type)" aria-role="listitem" @click="duplicateItem(item)"
              >Duplicate</b-dropdown-item
            >
            <b-dropdown-item v-if="isType == 0" aria-role="listitem" @click="copyItem(item.i)"
              >Move to...</b-dropdown-item
            >
            <b-dropdown-item v-if="[3].includes(item.type)" aria-role="listitem" @click="transformItem(item)"
              >Create "To Do"</b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              v-if="item.type == 12"
              @click="addAllTabs(item.i)"
              >Add all tabs
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              v-if="item.type == 17"
              @click="addAllYoutube(item.i)"
              >Add all youtube tabs
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              v-if="item.type == 12"
              @click="openAllLinks(item.i)"
              >Open All Links
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              v-if="item.type == 16"
              @click="openFrameLink(item.options.url)"
              >Open in New Tab
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="warningItemDelete(item.i)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </span>

        <span class="box-delete" v-if="isEditable">
          <b-button
            class="is-small"
            type="is-danger"
            icon-right="trash"
            @click="warningItemDelete(item.i)"
          />
        </span>
        <!-- <div v-if="isType > 0 && item.status" class="element-status">{{item.status}}</div> -->
        <div v-if="isType > 0 && getElStatus(item.i)" class="element-status">{{getElStatus(item.i)}}</div>
        <!-- <div @mouseover="isOverElement(item.i)" class="gr-node" v-if="isEditable"></div> -->
        <BoxItem :class="isType > 0 && getElStatus(item.i) || getGridStatus()? 'no-edit' : ''" :options="item.options" :elementID="item.i" @isState="setElementState" :type="item.type" :style="boxOverlay"/>        
      </grid-item>
    </grid-layout>

    <!-- <ContextMenu ref="menu">
      <template slot-scope="{ contextData }">
        <ContextMenuItem @click.native="editeItem(contextData)">
          Settings
        </ContextMenuItem>
        <ContextMenuItem @click.native="$refs.menu.close">
          Action 2 
        </ContextMenuItem>
        <ContextMenuItem @click.native="$refs.menu.close">
          Action 3 
        </ContextMenuItem>
        <ContextMenuItem @click.native="$refs.menu.close">
          Action 4 
        </ContextMenuItem>
      </template>
    </ContextMenu> -->
    <div v-if="getGridStatusMessage()" class="edit-grid"><div>{{getGridStatusMessage()}} is editing the layout</div></div>
    <!-- <div class="connections">
      <svg>
        <path :d="calculatePath(10, 10, 200, 200)" stroke="white" fill="transparent" />
      </svg>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

import VueGridLayout from "vue-grid-layout";
import BoxItem from "@/components/BoxItem.vue";
import Dropdown from "@/components/Dropdown";

import globalMixin from "@/mixins/globalMixin";
import urlMixin from "@/mixins/urlMixin";
import ismobile from "@/mixins/ismobile";
import transformMixin from "@/mixins/transformMixin";
// import ContextMenu from "@/components/contextMenu/ContextMenu";
// import ContextMenuItem from "@/components/contextMenu/ContextMenuItem"; 


export default {
  name: "GridView",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Dropdown,
    BoxItem,
    // ContextMenu,
    // ContextMenuItem,
  },
  mixins: [globalMixin, urlMixin, ismobile, transformMixin],
  data() {
    return {
      // layout: testLayout,
      // count: testLayout.length,
      bShadow: 0,
      isLoading: false,
      styleGlow: null,
      wCell: 12,
      hCell: 30,
      overList: null,
      listID: null,
      //grids:["personalGrid","sharedGrid","commonGrid"],
      hadAction: false,
      tapedTwice: false,
      touchduration: 500,
      timer: null,
    };
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["isBoard", "layouts", "isType", "isBoardCode"]),//["isBoard", "layouts", "isCover", "isEditable"]
    ...mapState("elstates", ["elements", "gridStates"]),
    ...mapState(["isCover", "isEditable"]),
    boxOverlay() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
    //------
    // clonedLayout() {
    //   let newLayout = [];
    //   newLayout = _.cloneDeep(this.layouts[this.isBoard].layout);
    //   return newLayout;
    // },
    //------
    // limitWidth() {
    //   let style = {};
    //   if(this.gridSettings.grid.maxWidth){
    //     style.maxWidth = this.gridSettings.grid.maxWidth + "px"; 
    //   }
    //   if(this.gridSettings.grid.minWidth){
    //     style.minWidth = this.gridSettings.grid.minWidth + "px"; 
    //   }
    //   style.minWidth = this.gridSettings.grid.minWidth + "px"; 
    //   style.margin = "0 auto";
    //   return style;
    // },
  },
  created() {
    // document.addEventListener('touchstart', function (e) {
    //   if(e.touches.length > 1) {
    //     // ... do what you like here
    //     this.toggleEditable(event);
    //   }
    // });
    document.addEventListener('touchend', this.tapHandler);
  },
  mounted() {
    //console.log("test", this.this[this.currentStore].layouts[this.this[this.currentGrid].isBoard].layout);//
    if(this.detectMobileDevice()){
      this.$buefy.dialog.alert({
        title: "Mobile device detected",
        message: "At the moment, Gridsup does not offer complete support for mobile devices. To access this site, we recommend using a desktop or laptop computer.",
        confirmText: "Close",
        type: "is-primary",
      });
    }
  },
  methods: {
    isOverElement(id) {
      //this.overList = id;
      console.log("isOver");
      if(this.isEditable){
        console.log("isOverElement", id);
      }
    },
    calculatePath(startX, startY, endX, endY) {
      const controlPointX = (startX + endX) / 2;
      const controlPointY = startY;
      return `M ${startX} ${startY} Q ${controlPointX} ${controlPointY} ${endX} ${endY}`;
    },
    // closeContextMenu() {
    //   this.$refs.menu.close();
    // },
    restructureLayout(layout){
      // @breakpoint-changed="breakpointChangedEvent"
    let newLayouts = {
      md: [],
      sm: [],
      xs: [],
    };
      let newLayout = [];
      let i = 0;
      let x = 0;
      let y = 0;
      let w = 2;
      layout.forEach((item) => {
  
        item.w = w;
//        y++;
        newLayout.push(item);

      });

      newLayouts.md = newLayout;
      newLayouts.sm = newLayout;
      newLayouts.xs = newLayout;
      return newLayouts;
    },
    breakpointChangedEvent: function(newBreakpoint, newLayout){
      console.log("BREAKPOINT CHANGED breakpoint=", newBreakpoint, ", layout: ", newLayout );
    },
    tapHandler(event) {
        //detect double tap =============================
        if(!this.tapedTwice) {
          this.tapedTwice = true;
            setTimeout( ()=>{ this.tapedTwice = false; }, 300 );
            return false;
        }
        event.preventDefault();
        //action on double tap goes below
        //alert('You tapped me Twice !!!');
        this.toggleEditable(event);
        
        //detect long =============================

        // this.timer = setTimeout(() => {
        //   this.timer = null;
        //   alert('LONG HOLD !!!');
        // }, 500);
        
        // document.addEventListener('touchend', ()=>{clearTimeout(this.timer)});
        // document.addEventListener('touchmove', ()=>{clearTimeout(this.timer)});

    },
    toggleEditable(event){
      if(event.target.tagName.toUpperCase() == 'INPUT' || event.target.tagName.toUpperCase() == 'TEXTAREA'){
        return;
      }
      event.preventDefault();

      if(this.isType>0){
        if(!this.getGridStatus()){
          this.$store.dispatch("toggleEditable");
        }
      }else{
        this.$store.dispatch("toggleEditable");
      }

      if(this.sidebar){
        this.$store.commit("modals/hideModal", "sidebar");
      }
      if(this.sidebarBoards){
        this.$store.commit("modals/hideModal", "sidebarBoards");
      }

      // if(isType>0 && this.isEditable){
      //   //ws send is editing page to other users
      // }
      // if(isType>0 && !this.isEditable){
      //   //ws send is editing page to other users
      // }

      if(!this.isEditable){
        if(this.hadAction){
          this.saveLayouts();
        }
      }

      if(this.isType>0){
        if(!this.isEditable){
          this.setGridState("saved");
        }else{
          this.setGridState("editing");
        }
      }

    },
    detectm() {
      console.log("mouseover");
    },
    downloadFile(item) {
      console.log("downloadFile");
      if(item.options.bgImage){
        window.open(item.options.bgImage, "_blank");
      }
      if(item.options.fileURL){
        window.open(item.options.fileURL, "_blank");
      }
    },
    warningItemDelete(item) {
      this.$buefy.dialog.confirm({
        title: "Deleting Item",
        message:
          "Are you sure you want to <b>delete</b> this item?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: "trash",
        onConfirm: () => this.deleteItem(item),
      });
    },
    deleteItem(item) {
      //this.$store.commit("layouts/remItem", item);
      this.gridAction("remItem", item);
      this.hadAction = true;
    },
    duplicateItem(item) {
      console.log("duplicateItem");
      //this.$store.commit("layouts/duplicateItem", item);
      this.gridAction("duplicateItem", item);
      this.hadAction = true;
    },
    copyItem(id) {
      console.log("copyItem");
      this.$store.commit("layouts/copyItem", id);
      this.$store.commit("modals/showModal", "copyTo");
      //this.gridAction("copyItem", id);
    },
    addToClipboard(item) {
      console.log("addToClipboard");
      this.$store.commit("clipboard/addToClipboard", item);
    },
    transformItem(item) {
      console.log("transformItem");
      let text = item.options.note;
      console.log(text);
      let list = this.convertTextToList(text) || [];
      console.log(list);
      if(list.length > 0){
        let actionData = { type: 4, options: {title: item.options.title, textColor:"#ffffff", bgColor:"#181d29", tasks:list }};
        this.gridAction("addItem", actionData);
      }else{
        this.$buefy.toast.open({
          message: "No enumerated items found! Please use the following format: 1. 2. 3. etc. ",
          type: "is-danger",
          position: "is-bottom",
          duration: 5000,
        });
      }
      this.hadAction = true;
    },
    // saveLayout() {
    //   this.$store.dispatch("toggleEditable");
    // },
    editeItem(item) {
      //this.closeContextMenu();
      let editors = [
        "editLink",
        "editClock",
        "editCalc",
        "editNote",
        "editTodo",
        "editWeather",
        "editImage",
        "editSearch",
        null,
        null,
        null,
        "editCalendar",
        "editLinks",
        "editRSS",
        "editCodeNote",
        "editAmbiance",
        "editFrame",
        "editYouTube",
        "editFileTransfer",
      ];
      //this.$store.commit("SET_EDITING_ITEM", item);
      //console.log(this.$store.getters.getById(id));
      this.$store.dispatch("editItem", {
        modal: editors[item.type],
        id: item.i,
      });
    },
    // addItem(type) {
    //   console.log("adding item..");
    //   //this.$store.commit("layouts/addItem", { type: type, options: {} });
    //   this.$store.commit(`${this.gridStores[this.gridType]}/addItem`, { type: type, options: {} });
    // },
    // setBlack(state) {
    //   this.$store.commit("SET_BLACK", state);
    // },
    // changeBoard(page) {
    //   this.$store.commit("SET_PAGE", page);
    // },
    // editBoards() {
    //   this.$store.commit("showModal", "pages");
    // },
    addAllTabs(id) {
      this.$buefy.dialog.alert({
        title: "Add All Tabs",
        message: "This action is not supported by the Online version of Gridsup",
        confirmText: "Close",
        type: "is-warning",
        hasIcon: true,
        icon: "list-timeline"
      });
      // this.listID = id;
      // if(this.$gridVars.browserType == "chrome"){
      //   chrome.windows.getAll({populate:true}, this.getAllOpenWindows);
      // }else{
      //   browser.windows.getAll({ populate: true }, this.getAllOpenWindows);
      // }
      // //chrome.windows.getAll({populate:true}, this.getAllOpenWindows);
      // //browser.windows.getAll({ populate: true }, this.getAllOpenWindows);
    },
    addAllYoutube(id) {
      this.$buefy.dialog.alert({
        title: "Add All Tabs",
        message: "This action is not supported by the Online version of Gridsup",
        confirmText: "Close",
        type: "is-warning",
        hasIcon: true,
        icon: "youtube"
      });
      // this.listID = id;
      // if(this.$gridVars.browserType == "chrome"){
      //   chrome.windows.getAll({populate:true}, this.getAllOpenYoytubeWindows);
      // }else{
      //   browser.windows.getAll({ populate: true }, this.getAllOpenYoytubeWindows);
      // }
      // //chrome.windows.getAll({populate:true}, this.getAllOpenWindows);
      // //browser.windows.getAll({ populate: true }, this.getAllOpenYoytubeWindows);
    },
    getAllOpenWindows(winData) {
      var tabs = [];
      for (var i in winData) {
        if (winData[i].focused === true) {
          var winTabs = winData[i].tabs;
          var totTabs = winTabs.length;
          for (var j = 0; j < totTabs; j++) {
            this.addLink(
              winTabs[j].url,
              winTabs[j].title,
              //winTabs[j].favIconUrl
            );
          }
        }
      }
      console.log(tabs);
      this.saveElement(this.listID);
      this.listID = null;
      //this.saveLayouts();
    },

    getAllOpenYoytubeWindows(winData) {
      var tabs = [];
      for (var i in winData) {
        if (winData[i].focused === true) {
          var winTabs = winData[i].tabs;
          var totTabs = winTabs.length;
          for (var j = 0; j < totTabs; j++) {
            if(this.validYouTubeURL(winTabs[j].url)){
              this.addYoutubeLink(
                winTabs[j].url,
                winTabs[j].title
              );
            }
          }
        }
      }
      console.log(tabs);
      this.saveElement(this.listID);
      this.listID = null;
      //this.saveLayouts();
    },


    addLink(link, title, fav) {
      let urlPart = link.split(":");
      if (urlPart[0] == "http" || urlPart[0] == "https") {

      let initem = _.find(this.layouts[this.isBoard].layout, {
        i: this.listID,
      });

        let lowest = 1;
        if (
          (initem.options.links !== undefined) &
          (initem.options.links.length !== 0)
        ) {
          initem = this.options.links.length + 1;
        }

        console.log("lowest " + lowest);
        //---------------------------------------------------------------
        console.log("adding link..");
        initem.options.links.unshift({
          id: lowest,
          link: link,
          title: title,
          //fav: fav,
        });
      }
    },


    addYoutubeLink(link, title) {
        let videoID = this.extractVideoID(link);
        let initem = _.find(this.layouts[this.isBoard].layout, {
          i: this.listID,
        });

        let lowest = 1;
        if (
          (initem.options.links !== undefined) &
          (initem.options.links.length !== 0)
        ) {
          lowest = initem.options.links.length + 1;
        }
        
        console.log("lowest " + lowest);
        //---------------------------------------------------------------
        console.log("adding youtube link..");
        initem.options.links.unshift({
          id: lowest,
          videoID: videoID,
          title: title
        });
    },


    openAllLinks(id) {
      this.listID = id;
      //chrome.windows.getAll({populate:true}, this.getAllOpenWindows);
      let initem = _.find(this.layouts[this.isBoard].layout, {
        i: this.listID,
      });
      if (
        (initem.options.links !== undefined) &
        (initem.options.links.length !== 0)
      ) {
        for(let i = 0; i < initem.options.links.length; i++){
          this.openLink(initem.options.links[i].link);
        }
      }
    },
    openLink(link) {
      console.log("opening:", link);
      // chrome.tabs.create({ url: link });
      window.open(link, "_blank");
    },
    openFrameLink(url) {
      console.log("opening:", url);
      // chrome.tabs.create({ url: link });
      window.open(url, "_blank");
    },
    //grid events
    // moveEvent: function(id, newX, newY){
    //   if(_.find(this.layouts[this.isBoard].layout,{i:id}).type==0){
    //     //const msg = "MOVE i=" + id + ", X=" + newX + ", Y=" + newY;
    //     //console.log(msg);
    //     //console.log(this.hasLL);

    //       if(newX>=this.hasLL[0].x && newY>=this.hasLL[0].y && newX < (this.hasLL[0].x + this.hasLL[0].w) && newY < (this.hasLL[0].y + this.hasLL[0].h)){
    //         //console.log("Over!" + this.hasLL[0].i);
    //         this.overList = this.hasLL[0].i;
    //       }else if(newX>=this.hasLL[1].x && newY>=this.hasLL[1].y && newX < (this.hasLL[1].x + this.hasLL[1].w) && newY < (this.hasLL[1].y + this.hasLL[1].h)){
    //         //console.log("Over!" + this.hasLL[1].i);
    //         this.overList = this.hasLL[1].i;
    //       }else{
    //         this.overList = null;
    //       }

    //     //console.log(this.overList);
    //   }
    // },
    movedEvent(i, newX, newY){
      //const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
      //console.log(msg);
      console.log("movedEvent");
      this.hadAction = true;
      //this.saveLayouts();
    },
    saveLayouts(){
      console.log("saving layout...");
      this.hadAction = false;
      if(this.isType > 0){
        this.$store.dispatch("layouts/saveSharedGrid");
      }else{
        this.$store.commit("layouts/updateLocal");
      }
    },
    saveElement(elementID){
      console.log("saving element layout...");
      this.hadAction = false;
      if(this.isType > 0){
        //this.$store.dispatch("layouts/saveSharedGrid");
        this.$store.dispatch("layouts/saveSharedElement", elementID);
      }else{
        this.$store.commit("layouts/updateLocal");
      }
    },
    gridAction(doAction, actionData){
      this.$store.dispatch("layouts/gridAction", {
        action: doAction,
        data: actionData,
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }else{
          this.$store.commit("layouts/updateLocal");
        }
      }).catch((err) => {
        console.log(err);
      });
      this.hadAction = false;
    },
    //ws elements status update
    setElementState(data) {
      //this.$emit("isState", state);
      if(this.isType==0){return;}
      this.$store.commit("ws/sendElState", {
        elementID: data.elementID,
        status: data.status,
        grcode: this.isBoardCode,
        userID: this.user.email,
      });
    },
    getElStatus(id){
      let index = _.findIndex(this.elements, {'grcode': this.isBoardCode});
      if (index == -1) return "";
      let itemIndex = _.findIndex(this.elements[index].elements, {'id': id});
      console.log("found element: " + itemIndex);
      if (itemIndex == -1) return "";
      return this.elements[index].elements[itemIndex].user +" is "+ this.elements[index].elements[itemIndex].status;
    },
    setGridState(status) {
      //this.$emit("isState", state);
      this.$store.commit("ws/sendGridState", {
        status: status,
        grcode: this.isBoardCode,
        userID: this.user.email,
      });
    },
    getGridStatus(){
      let index = _.findIndex(this.gridStates, {'grcode': this.isBoardCode});
      if (index == -1) return false;
      console.log("found gridState: " + index);
      return true;
    },
    getGridStatusMessage(){
      let index = _.findIndex(this.gridStates, {'grcode': this.isBoardCode});
      if (index == -1) return false;
      console.log("found gridState: " + index);
      return this.gridStates[index].user;
    },
    // getElStatusMessage(id){
    //   return "";
    // },
  },
};
</script>

<style>

.connections {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.vue-grid-item {
  transition: none !important;
}

.home {
  position: relative;
  min-height: 100vh;
}

.cov {
  position: absolute;
  width: 100%;
  height: 110%;
  min-height: 100vh;
  z-index: 38;
  background: rgba(0, 0, 0, 0.582);
  text-align: right;
  padding: 20px 20px;
  margin-top: -30px;
  pointer-events: none;
}

.mbt {
  z-index: 999;
  position: fixed !important;
  top: 5px;
  left: 5px;
}

/* .lbt{
  z-index: 997;
  position: fixed!important;
  bottom:5px;
  left:5px;
}
.lbt button{
  margin-right:5px;
} */

.lbt {
  z-index: 997;
  position: fixed !important;
  top: 5px;
  right: 5px;
  /* display: flex;
  flex-direction: row-reverse; */
}

.lbt button {
  margin-right: 5px;
}

.rbt {
  z-index: 997;
  position: fixed !important;
  top: 5px;
  left: 5px;
}

.rbt button {
  margin-right: 5px;
}

.cbt {
  z-index: 998;
  position: fixed !important;
  top: 5px;
  margin: auto;
  text-align: center;
}

/* .rounded{
  border-radius: 8px;
}

.border{
  border: solid 2px rgb(63, 63, 63);
} */

.box-item-conteiner {
  position: relative;
  touch-action: none;
  /* overflow: hidden; */
}

.box-item-movable {
  border: solid 2px #00bb8f;
}

.box-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 6;
}

.box-edit {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 7;
}

.box-edit:hover {
  z-index: 8 !important;
}

.gr-node{
  position: absolute;
  background-color: #ffffff;
  width:10px;
  height:10px;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 9;
}

.button.is-dark[disabled] {
  cursor: pointer !important;
}

a.dropd-item {
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

a.dropd-item {
  color: #bebebe;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

a.dropd-item:hover {
  color: #4a4a4a !important;
  background-color: #ffffff;
}

/* .vue-grid-item>.vue-resizable-handle{} */

.pt {
  padding-top: 30px;
}


.element-status{
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, rgba(0,0,0,0.09) 0%, rgba(0,0,0,0) 100%);
  z-index: 39;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: .8rem;
  border-radius: 10px 10px 0 0;
}

.no-edit{
  pointer-events: none;
  opacity: 0.5;
}

/* .no-edit{
  pointer-events: none;
  animation: glow .5s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px #bb0000b6;
  }
  to {
    box-shadow: 0 0 3px 3px #e000008f;
  }
} */

.edit-grid{
  position:absolute;
  bottom: 0px;
  height: 100px;
  width: 100%;
  z-index: 40;
  background-color: #00000042;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
