import Vue from 'vue'
import VueRouter from 'vue-router'
import GridView from '../views/GridView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'grid',
    component: GridView
  },
  { path: "*", component: GridView }
]

const router = new VueRouter({
  
  mode: 'history',
  base: '/',
  routes
})

export default router
