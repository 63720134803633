import _ from 'lodash';
import axios from 'axios';
axios.defaults.baseURL = "https://gridsup.com/bestapi/";

//import DiffMatchPatch from 'diff-match-patch';
//import store from '..';

var emoji = require('node-emoji');

let forSrceen = 0;
if(screen.width){
if(screen.width <= 1024){
  forSrceen = 1;
}else if(screen.width <= 1280){
  forSrceen = 2;
}else if(screen.width <= 1440){
  forSrceen = 3;
}else{
  forSrceen = 0;
}}

const defaultLayout = require("@/data/defaultLayout"+forSrceen+".json");

function makeGRcode(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
}

function getLayouts() {
  if(localStorage.grpersonal){
    return JSON.parse(localStorage.grpersonal);
  }else{
    localStorage.grpersonal = JSON.stringify(defaultLayout.layouts);
    return defaultLayout.layouts;
  }
}

function getMainLayout() {
  if(localStorage.grpersonal){  
    let layouts = JSON.parse(localStorage.grpersonal);
    console.log("layouts",layouts);
    return layouts[0].layout;
  }else{
  return defaultLayout.layouts[0].layout;
  }
}


function findFreeSpace(elements, width, defaultWidth, defaultHeight) {
  const occupiedPositions = new Set();
  const freePositions = [];

  let lowestY = 0;  // Keep track of the lowest Y position of all the elements

  // Add the occupied positions to the Set and update the lowest Y position
  elements.forEach(element => {
    for (let x = element.x; x < element.x + element.w; x++) {
      for (let y = element.y; y < element.y + element.h; y++) {
        occupiedPositions.add(`${x},${y}`);
        lowestY = Math.max(lowestY, y + element.h);
      }
    }
  });

  // Check all the positions on the grid
  for (let x = 0; x < width - defaultWidth + 1; x++) {
    for (let y = 0; y < lowestY - defaultHeight + 1; y++) {
      // Check if the area is free
      let isFree = true;
      for (let i = x; i < x + defaultWidth; i++) {
        for (let j = y; j < y + defaultHeight; j++) {
          if (occupiedPositions.has(`${i},${j}`)) {
            isFree = false;
            break;
          }
        }
        if (!isFree) break;
      }
      // If the area is free, add it to the array of free positions
      if (isFree) {
        freePositions.push({ x, y });
      }
    }
  }

  return freePositions;
}


// function findFreeSpace(elements, width, height, defaultWidth, defaultHeight) {
//   const occupiedPositions = new Set();
//   const freePositions = [];

//   // Add the occupied positions to the Set
//   elements.forEach(element => {
//     for (let x = element.x; x < element.x + element.w; x++) {
//       for (let y = element.y; y < element.y + element.h; y++) {
//         occupiedPositions.add(`${x},${y}`);
//       }
//     }
//   });

//   // Check all the positions on the grid
//   for (let x = 0; x < width - defaultWidth + 1; x++) {
//     for (let y = 0; y < height - defaultHeight + 1; y++) {
//       // Check if the area is free
//       let isFree = true;
//       for (let i = x; i < x + defaultWidth; i++) {
//         for (let j = y; j < y + defaultHeight; j++) {
//           if (occupiedPositions.has(`${i},${j}`)) {
//             isFree = false;
//             break;
//           }
//         }
//         if (!isFree) break;
//       }
//       // If the area is free, add it to the array of free positions
//       if (isFree) {
//         freePositions.push({ x, y });
//       }
//     }
//   }

//   return freePositions;
// }

// function findFreeSpace(elements, width, height) {
//   const occupiedPositions = new Set();
//   const freePositions = [];

//   // Add the occupied positions to the Set
//   elements.forEach(element => {
//     for (let x = element.x; x < element.x + element.w; x++) {
//       for (let y = element.y; y < element.y + element.h; y++) {
//         occupiedPositions.add(`${x},${y}`);
//       }
//     }
//   });

//   // Check all the positions on the grid
//   for (let x = 0; x < width; x++) {
//     for (let y = 0; y < height; y++) {
//       // If the position is not occupied, add it to the array of free positions
//       if (!occupiedPositions.has(`${x},${y}`)) {
//         freePositions.push({ x, y });
//       }
//     }
//   }


// function deleteImage(image) {
//   if(image){
//     let imageId = image.split("/").pop();
//     console.log(imageId);
//     axios.post('deleteimage.php', {
//       token: localStorage.bstoken,
//       filename: imageId
//     })
//     .then(function (response) {
//       console.log(response);
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
//   }
// }

function deleteImage(image) {
  if(image){
    let imageId = image.split("/").pop();
    console.log("deleting image:", imageId);
    axios.post('wasabi/manage_images_aws.php', {
      token: localStorage.grtoken,
      fileName: imageId,
      action: "delete",
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}

function deleteFile(fileName) {
  if(fileName){
    console.log(fileName);
    axios
    .post(`wasabi/manage_aws.php`, {
      token: localStorage.grtoken,
      fileName: fileName,
      action: "delete",
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

// function updateDB(grcode, layout){
//   axios.post('sharedgrids/ws_grids_user.php',
//   {
//       action: "updateLayout",
//       grcode: grcode,
//       layout: layout,
//       token: localStorage.grtoken
//   })
//   .then((response)=>{
//       console.log(response.data);
//   })
//   .catch((error) =>{
//       let result = {
//           "success": false,
//           "message": error
//       };
//       sendLayout(result, ws);
//       console.log("could not update layout");
//   });
// }


export default{
  namespaced: true,
  state: {
      ws: null,
      wsStatus: false,
      isBoard: 0,
      isType: 0,
      isBoardCode: null,
      //allData: defaultLayout,
      layouts: getLayouts(),//defaultLayout.layouts,//getLayouts(),
      layoutsPersonal: getLayouts(), //getLayouts(),//defaultLayout.layouts,//getLayouts(),
      layoutsShared: [],
      layoutsCommon: [],
      //layout: getMainLayout(),//defaultLayout.layouts[0].layout,
      editingItemId: null,
      copyingItemId: null,
      itemValues: {
        free: null,
        lowest: null,
        type: null,
        options: null
      },
      localVars:["grpersonal","grshared","grcommon"],
      layoutTypes:["layoutsPersonal","layoutsShared","layoutsCommon"],

      //y - free
      //i - lowest
      //type - type
      //options - options
      itemTypes: [
        {"name":"site","type":0,"minW":1,"minH":3,"maxW":4,"maxH":8,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{}},
        {"name":"clock","type":1,"minW":1,"minH":3,"maxW":4,"maxH":6,"x":0,"y":0,"w":2,"h":3,"i":0,"status":"","options":{}},
        {"name":"calc","type":2,"minW":2,"minH":8,"maxW":2,"maxH":8,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{}},
        {"name":"note","type":3,"x":0,"y":0,"w":2,"h":3,"i":0,"status":"","options":{"textColor":"#000000","bgColor":"#ffff48","textSize":18,"note":"","lastEdit":"","lstEditBy":""}},
        {"name":"todo","type":4,"minW":2,"minH":3,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{"textColor":"#ffffff","bgColor":"#181d29","tasks":[{"id":1,"status":0,"task":"This is a task!"}]}},
        {"name":"wether","type":5,"minW":1,"minH":3,"maxW":4,"maxH":8,"x":0,"y":0,"w":2,"h":3,"i":0,"status":"","options":{"city":"","units":"C"}},
        {"name":"image","type":6,"minW":1,"minH":3,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"bgColor": "#000000","bgImage":"images/px.png"}},
        {"name":"search","type":7,"minW":3,"minH":2,"maxH":2,"x":0,"y":0,"w":3,"h":2,"i":0,"status":"","options":{"bgColor":"#181d29","engine":0}},
        {"name":"chrome-downloads","type":0,"minW":1,"minH":3,"maxW":4,"maxH":8,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"isText":false,"hasCustom":false,"text":"Downloads","sizeValue":4,"initSize":4,"textSize":4,"iconSize":40,"textColor":"#ffffff","bgColor":"#181d29","iconColor":2,"bgImage":"images/px.png","icon":"https://gridsup.com/siteicons/download.svg","url":"chrome://downloads/","title":"Downloads"}},
        {"name":"chrome-history","type":0,"minW":1,"minH":3,"maxW":4,"maxH":8,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"isText":false,"hasCustom":false,"text":"History","sizeValue":4,"initSize":4,"textSize":4,"iconSize":40,"textColor":"#ffffff","bgColor":"#181d29","iconColor":2,"bgImage":"images/px.png","icon":"https://gridsup.com/siteicons/history.svg","url":"chrome://history/","title":"History"}},
        {"name":"chrome-bookmarks","type":0,"minW":1,"minH":3,"maxW":4,"maxH":8,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"isText":false,"hasCustom":false,"text":"Bookmarks","sizeValue":4,"initSize":4,"textSize":4,"iconSize":40,"textColor":"#ffffff","bgColor":"#181d29","iconColor":2,"bgImage":"images/px.png","icon":"https://gridsup.com/siteicons/bookmark.svg","url":"chrome://bookmarks/","title":"Bookmarks"}},
        {"name":"calendar","type":11,"minW":2,"minH":7,"maxW":4,"maxH":7,"x":0,"y":0,"w":3,"h":7,"i":0,"status":"","options":{"dark":true,"notes":[]}},
        {"name":"link-list","type":12,"minW":2,"minH":3,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{"textColor":"#ffffff","bgColor":"#181d29","isNewTab":false,"showTitle":true,"links":[{"id":1,"link":"https://gridsup.com","title":"Gridsup | More than a Speed Dial","fav":""}]}},
        {"name":"rss-feed","type":13,"minW":2,"minH":3,"x":0,"y":0,"w":3,"h":8,"i":0,"status":"","options":{}},
        {"name":"codenote","type":14,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"bgColor":"#2d2d2d","textSize":14, "note":""}},
        {"name":"ambiance","type":15,"minW":1,"minH":3,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{"textColor":"#ffffff","bgColor":"#2d2d2d"}},
        {"name":"frame","type":16,"minW":1,"minH":3,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{}},
        {"name":"youtube-list","type":17,"minW":2,"minH":3,"x":0,"y":0,"w":2,"h":8,"i":0,"status":"","options":{"textColor":"#ffffff","bgColor":"#181d29","isNewTab":false,"links":[{"id":1,"videoID":"IB8KvHPnxTY","title":"Gridsup | More than a Speed Dial"}]}},
        {"name":"file-transfer","type":18,"minW":2,"minH":3,"maxW":4,"maxH":4,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"bgColor":"#181d29", "fileURL":null, "fileName":"null", "fileSize":null, "validUntil": null, "downloadCount": 0}},
        {"name":"chat","type":19,"minW":2,"minW":1,"minH":3,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"bgColor":"#181d29", "chatID":null}},
        {"name":"chatAI","type":20,"minW":2,"minW":1,"minH":3,"x":0,"y":0,"w":2,"h":4,"i":0,"status":"","options":{"bgColor":"#181d29", "chatID":null, "chatAPI":null}},
      ],
  },
  getters: {
    getById: (state) => (id) => {
      return state.layouts[state.isBoard].layout.find(item => item.i === id);
    },
    getCurrentType: (state) => {
      return state.isType;
    },
    getCurrentGRcode: (state) => {
      return state[state.layoutTypes[state.isType]][state.isBoard].grcode;
    },
    getPersonalLayoutsCount: (state) => {
      return state.layoutsPersonal.length;
    },
    getSharedLayoutsCount: (state) => {
      if(!state.layoutsShared.length){ return 0; }
      return state.layoutsShared.length;
    },
    getCommonLayoutsCount: (state) => {
      return state.layoutsCommon.length;
    }
  },
  mutations: {
    editItem(state, id) {
      state.editingItemId = id;  
      console.log("editing:"+id);
    },
    copyItem(state, id) {
      state.copyingItemId = id;   
      console.log("copying:" + id);
    },
    copyTo(state, payload) {
      console.log("copyTo:" + payload.item);
      let page = _.findIndex(state.layouts,{id:payload.item});
      let lowest = 1;
      if (
        (state.layouts[page].layout !== undefined) &
        (state.layouts[page].layout !== 0)
      ) {
        lowest = state.layouts[page].layout.length + 1;
      }
      console.log("lowest: "+lowest);
      //find free space in the first row
      let item = state.layouts[state.isBoard].layout.find(item => item.i === state.copyingItemId);
      let newItem = _.cloneDeep(item);
      let freeSlot = findFreeSpace(state.layouts[page].layout, payload.gridWidth, newItem.w, newItem.h);
      //console.log(freeSlot);
      newItem.i = lowest;
      // newItem.y = 0;
      // newItem.x = 0;
      if(freeSlot.length !== 0){
        newItem.y = freeSlot[0].y;
        newItem.x = freeSlot[0].x;
      }else{
        newItem.y = 0;
        newItem.x = 0;
      }
      console.log(newItem);
      state.layouts[page].layout.push(newItem);
      //localStorage.grpersonal = JSON.stringify(state.layouts);
      
      // REMOVE ORIGINAL
      let found = state.layouts[state.isBoard].layout.indexOf(state.layouts[state.isBoard].layout.find(obj=>obj.i==state.copyingItemId));
      console.log("found: "+found);
      if(state.layouts[state.isBoard].layout.length>=2){
        state.layouts[state.isBoard].layout.splice(found,1);
      }else{
        state.layouts[state.isBoard].layout.pop();
      }
      state.layouts[state.isBoard].layout.forEach(function (value, i) {
        value.i = i+1;
      });
      //localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      localStorage.grpersonal = JSON.stringify(state.layouts);
      state.layoutsPersonal = _.cloneDeep(state.layouts);
      //END REMOVE ORIGINAL

      state.copyingItemId = null;
      
    },
    duplicateItem(state, payload) {
      let freeSlot = findFreeSpace(state.layouts[state.isBoard].layout, payload.gridWidth, payload.item.w, payload.item.h);
      let lowest = 1;
      if (
        (state.layouts[state.isBoard].layout !== undefined) &
        (state.layouts[state.isBoard].layout !== 0)
      ) {
        lowest = state.layouts[state.isBoard].layout.length + 1;
      }
      console.log("original item:");
      console.log(payload.item);
      //find free space in the first row      
      let newItem = _.cloneDeep(payload.item);
      newItem.i = lowest;
      // newItem.y = 0;
      // newItem.x = 0;
      if(freeSlot.length !== 0){
        newItem.y = freeSlot[0].y;
        newItem.x = freeSlot[0].x;
      }else{
        newItem.y = 0;
        newItem.x = 0;
      }
      console.log("new item:");
      console.log(newItem);
      state.layouts[state.isBoard].layout.push(newItem);
      localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      //localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      //state.layouts = JSON.parse(localStorage[state.localVars[state.isType]]);
    },
    addItem(state, payload) {

      // let gridWidth = rootGetters["settings/getCurrentwCell"];
      console.log("wCell:", payload.gridWidth);

      //console.log(findFreeSpace(state.layouts[state.isBoard].layout, 15, state.itemTypes[payload.type].w, state.itemTypes[payload.type].h));
      let freeSlot = findFreeSpace(state.layouts[state.isBoard].layout, payload.gridWidth, state.itemTypes[payload.item.type].w, state.itemTypes[payload.item.type].h);
      let lowest = 1;
      if (
        (state.layouts[state.isBoard].layout !== undefined) &
        (state.layouts[state.isBoard].layout !== 0)
      ) {
        lowest = state.layouts[state.isBoard].layout.length + 1;
      }

      //find free space in the first row
      let free = 0;
      // if (ypos.length !== 0) {
      //   free = Math.max(...ypos)+1;
      // }
      //---------------------------------------------------------------
      let item = null;
      if(payload.item.type == 0 || payload.item.type){
        item = _.cloneDeep(state.itemTypes[payload.item.type]);
        //y - free
        //i - lowest
        //type - type
        //options - options
        item.i = lowest;
        //item.y = free;
        if(freeSlot.length !== 0){
          item.y = freeSlot[0].y;
          item.x = freeSlot[0].x;
        }else{
          item.y = 0;
        }
        // item.type = payload.type;
        //item.options = payload.options;
        if(!_.isEmpty(payload.item.options)){
          item.options = payload.item.options;
        }
      }else{
        console.log("No item type provided!");
      }
      
      if(item){
        state.layouts[state.isBoard].layout.push(item);
        localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      }
    },

    remItem(state, payload) {
      let found = state.layouts[state.isBoard].layout.indexOf(state.layouts[state.isBoard].layout.find(obj=>obj.i==payload.item));
      console.log("found: "+found);
      let hasImages = [0,6];
      if(hasImages.includes(state.layouts[state.isBoard].layout[found].type)){
        console.log("has images");
        //options.bgImage
        //options.icon
        //remove image from server
        if(state.layouts[state.isBoard].layout[found].options.bgImage){
          //let bgImage = state.layouts[state.isBoard].layout[found].options.bgImage.split("/").pop();
          deleteImage(state.layouts[state.isBoard].layout[found].options.bgImage);
        };
        if(state.layouts[state.isBoard].layout[found].options.icon){
         // let icon = state.layouts[state.isBoard].layout[found].options.icon.split("/").pop();
         deleteImage(state.layouts[state.isBoard].layout[found].options.icon);
        }; 
      }

      if(state.layouts[state.isBoard].layout[found].type == 18){
        //remove file from server
        if(state.layouts[state.isBoard].layout[found].options.fileURL){
          deleteFile(state.layouts[state.isBoard].layout[found].options.fileName);
        };
      }

      if(state.layouts[state.isBoard].layout.length>=2){
        state.layouts[state.isBoard].layout.splice(found,1);
      }else{
        state.layouts[state.isBoard].layout.pop();
      }
      state.layouts[state.isBoard].layout.forEach(function (value, i) {
        value.i = i+1;
      });
      localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
    },
    //required
    saveItem(state, payload) {
      let index = state.layouts[state.isBoard].layout.indexOf(state.layouts[state.isBoard].layout.find(obj=>obj.i==payload.item.id));
      state.layouts[state.isBoard].layout[index].options = _.cloneDeep(payload.item.options);
      localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      state[state.layoutTypes[state.isType]] = _.cloneDeep(state.layouts);
    },

    setLocalLayout(state) {
      //used on first lounch
      console.log("setting local layout");
      //change to reactive localstorage!
      state.layouts = JSON.parse(localStorage[state.localVars[state.isType]]);
      //if no common layouts in localstorage
      if(!state.layouts[state.isBoard]){
        state.isBoard = 0;
        localStorage.grpage = 0;
        state.layouts = JSON.parse(localStorage.grpersonal);
        state.layoutsPersonal = JSON.parse(localStorage.grpersonal);
      }
      //console.log("First layout: "+state.layouts[0].grcode);
    },

    // ---- personal local layouts  ----
    setLocalPersonalGrisLayout(state) {
      state.layoutsPersonal = JSON.parse(localStorage.grpersonal);
    },
    // ---- shared local layouts  ----
    setLocalSharedGrisLayout(state) {
      if(localStorage.grshared){
        state.layoutsShared = JSON.parse(localStorage.grshared);
      }else{
        state.layoutsShared = [];
      }
    },
    // ---- common local layouts  ----
    setLocalCommonGrisLayout(state) {
      if(localStorage.grcommon){
        state.layoutsCommon = JSON.parse(localStorage.grcommon);
      }else{
        state.layoutsCommon = [];
      }
    },

    // setStoreLayout(state) {
    //   console.log("setting store layout");
    //   //change to reactive localstorage!
    //   state.layouts = state[state.layoutTypes[state.isType]];
    // },

    setDefault(state) {
      console.log("setting default layout");
      state.isBoard = 0;
      state.layouts = defaultLayout.layouts;
      //state.layout = defaultLayout.layouts[0].layout;
      state.editingItemId =  null;
      state.copyingItemId =  null;
    },

    setImportedPersonal(state) {
      console.log("setting imported layout");
      state.layouts = JSON.parse(localStorage.grpersonal);
      state.isBoard = 0;
      localStorage.grpage = 0;
      state.isType = 0;
      //--------------------------------------//
      state.layoutsPersonal = JSON.parse(localStorage.grpersonal);
    },

    saveLayout(state) {
      // localStorage.grsettings = JSON.stringify(state.settings);
      localStorage.removeItem(state.localVars[state.isType]);
      localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
    },

    updateLocal(state) {
      localStorage.removeItem(state.localVars[state.isType]);
      localStorage[state.localVars[state.isType]] = JSON.stringify(state.layouts);
      state[state.layoutTypes[state.isType]] = _.cloneDeep(state.layouts);//???
      localStorage.grrefresh = true;
    },

    firstUpdateSharedLayout(state, payload) { //incoming from web socket
      console.log("first updating layout incoming from web socket");
      console.log("payload",payload);
      console.log("state.isBoardCode",state.isBoardCode);

      if(payload.grcode !== state.isBoardCode){
        console.log("grcode mismatch, abort.. abort..!");
        return;
      }
      if(payload){
 
      console.log("incoming data", payload);
      //state.layoutsShared[state.isBoard].layout = _.cloneDeep(payload);//for some reason this works
      state[state.layoutTypes[state.isType]][state.isBoard].layout = _.cloneDeep(payload.layout);

        console.log("after clone shared layouts");
        //console.log(state.layoutsShared);
        console.log(state[state.layoutTypes[state.isType]][state.isBoard]);
        localStorage[state.localVars[state.isType]] = JSON.stringify(state[state.layoutTypes[state.isType]]);
        //localStorage.grshared = JSON.stringify(state.layoutsShared);
        if(state.isType > 0){
          state.layouts = JSON.parse(localStorage[state.localVars[state.isType]]);
        }
      }else{
        console.log("no layout provided");
      }
    },

    updateSharedLayout(state, payload) { //incoming layout from web socket
      console.log("updating layout incoming from web socket");
      if(payload){
        console.log("incoming data", payload);
        let found = state[state.layoutTypes[state.isType]].indexOf(state[state.layoutTypes[state.isType]].find(obj=>obj.grcode==payload.grcode));
        if(found>-1){
          state[state.layoutTypes[state.isType]][found].layout = JSON.parse(JSON.stringify(payload.layout));
          localStorage[state.localVars[state.isType]] = JSON.stringify(state[state.layoutTypes[state.isType]]);
          // console.log("after clone shared layouts");
          // console.log(state[state.layoutTypes[state.isType]]);
        }

        //update state.layouts
        if(state.isType > 0){
          state.layouts = JSON.parse(localStorage[state.localVars[state.isType]]);
        }
      }else{
        console.log("no layout provided");
      }
    },

    updateSharedElement(state, payload) { //incoming element from web socket
      console.log("updating element incoming from web socket");
      //payload: {grcode: grcode, element: element, last: last}
      console.log(payload);
      if(payload){
        console.log("incoming data", payload);
        let foundLayout = state[state.layoutTypes[state.isType]].indexOf(state[state.layoutTypes[state.isType]].find(obj=>obj.grcode==payload.grcode));
        if(foundLayout>-1){

          let foundElement = state[state.layoutTypes[state.isType]][foundLayout].layout.indexOf(state[state.layoutTypes[state.isType]][foundLayout].layout.find(obj=>obj.i==payload.element.i));

          if(foundElement>-1){
            state[state.layoutTypes[state.isType]][foundLayout].layout[foundElement] = JSON.parse(JSON.stringify(payload.element));
       
            // console.log("after clone shared layouts");
            // console.log(state[state.layoutTypes[state.isType]]);
          }else{
            console.log("element not found");
            //push new element
            state[state.layoutTypes[state.isType]][foundLayout].layout.push(JSON.parse(JSON.stringify(payload.element)));
            
          }

          //save to local storage
          localStorage[state.localVars[state.isType]] = JSON.stringify(state[state.layoutTypes[state.isType]]);

          //update state.layouts
          if(state.isType > 0){
            state.layouts = JSON.parse(localStorage[state.localVars[state.isType]]);
          }
          //state[state.layoutTypes[state.isType]][foundLayout].layout = JSON.parse(JSON.stringify(payload.layout));
          //localStorage[state.localVars[state.isType]] = JSON.stringify(state[state.layoutTypes[state.isType]]);
          // console.log("after clone shared layouts");
          // console.log(state[state.layoutTypes[state.isType]]);
        }
      }else{
        console.log("no element provided");
      }
    },


    // ---- shared layouts mutations ----
    setSharedGrisLayout(state, payload) {
      //state.layoutsShared = payload;
      localStorage.grshared = emoji.emojify(JSON.stringify(payload));
      state.layoutsShared = JSON.parse(localStorage.grshared);
    },


    // ---- common layouts mutations ----
    setCommonGrisLayout(state, payload) {
      localStorage.grcommon = emoji.emojify(JSON.stringify(payload));
      state.layoutsCommon = JSON.parse(localStorage.grcommon);
    },

    setPersonalGrisLayout(state, payload) {
      //localStorage.grpersonal = emoji.emojify(JSON.stringify(payload));
      state.layoutsPersonal = JSON.parse(localStorage.grpersonal);
    },
    

    //pages all-----------------------------------------------------//

    setGridType(state,payload) {
      state.isType = payload;
      state.layouts = null;
      state.layouts = _.cloneDeep(state[state.layoutTypes[payload]]);
    },
    
    setBoard(state,page) {
      if(page<0){ 
        state.isBoard = 0;
        state.isType = 0; 
        state.layouts = _.cloneDeep(state[state.layoutTypes[state.isType]]);
        return;
      }
      state.isBoard = page;
      localStorage.grpage = page; 
      console.log("isBoard: "+state.isBoard);
    },

    setBoardCode(state,payload) {
      state.isBoardCode = payload;
      //localStorage.grpagecode = payload; 
      console.log("isBoard: "+state.isBoardCode);
    },
    setFirstBoardCode(state) {
      state.isBoardCode = state.layouts[0].grcode;
      console.log("isBoard: "+state.isBoardCode);
    },

    //common mutation for all layouts--------------------------------//
    updateBoards(state, payload) {
      if(state.isType == payload.type){
        state.layouts = _.cloneDeep(payload.val);
      }
      localStorage[state.localVars[payload.type]] = JSON.stringify(payload.val);
      state[state.layoutTypes[payload.type]] = _.cloneDeep(payload.val);
    },
    //---------------------------------------------------------------//

    // personal Grids
    setPersonalGridName(state,payload) {
      console.log(payload);
      let index = _.findIndex(state.layoutsPersonal, {'id':payload.id});
      console.log(index);
      state.layoutsPersonal[index].name = payload.name;
      localStorage.grpersonal = JSON.stringify(state.layoutsPersonal);
      if(state.isType == 0){
        state.layouts = _.cloneDeep(state.layoutsPersonal);
      }
    },

    addPersonalGrid(state,payload) {
      console.log(payload);     
      state.layoutsPersonal.push({"id":payload.id,"name":payload.name,"grcode":payload.grcode,"layout":[]});
      localStorage.grpersonal = JSON.stringify(state.layoutsPersonal);
      if(state.isType == 0){
        state.layouts = _.cloneDeep(state.layoutsPersonal);
      }
    },

    duplicatePersonalGrid(state,payload) {
      console.log(payload);     
      state.layoutsPersonal.push({"id":payload.id,"name":payload.name,"grcode":payload.grcode,"layout":payload.layout});
      localStorage.grpersonal = JSON.stringify(state.layoutsPersonal);
      if(state.isType == 0){
        state.layouts = _.cloneDeep(state.layoutsPersonal);
      }
    },

    deletePersonalGrid(state,id) {
      console.log(id);     
      let index = _.findIndex(state.layoutsPersonal, {'id': id});
      //find items with images and delete them
      let items = state.layoutsPersonal[index].layout;
      for(let i=0; i<items.length; i++){
        if(items[i].type == 0 || items[i].type == 6){
          if(items[i].options.bgImage){
            deleteImage(items[i].options.bgImage);
          };
          if(items[i].options.icon){
            deleteImage(items[i].options.icon);
          };
        }
      }

      state.layoutsPersonal.splice(index, 1);
      localStorage.grpersonal = JSON.stringify(state.layoutsPersonal);
      if(state.isType == 0){
        if((state.layoutsPersonal.length-1)==state.isBoard){
          state.isBoard = 0;
          state.isBoardCode = state.layoutsPersonal[0].grcode;
          localStorage.grpage = 0;
        }
        state.layouts = _.cloneDeep(state.layoutsPersonal);
      }
    },
    //---------------------------------------------------------------//

    //shared pages-----------------------------------------------------//

    addSharedGrid(state,payload) {
      console.log(payload);     
      state.layoutsShared.push(payload);
      localStorage.grshared = JSON.stringify(state.layoutsShared);
      if(state.isType == 1){
        state.layouts = _.cloneDeep(state.layoutsShared);
      }
    },

    setSharedGridName(state,payload) {
      console.log(payload);
      let index = _.findIndex(state.layoutsShared, {'id':payload.id});
      console.log(index);
      state.layoutsShared[index].name = payload.name;
      localStorage.grshared = JSON.stringify(state.layoutsShared);
      if(state.isType == 1){
        state.layouts = _.cloneDeep(state.layoutsShared);
      }
    },

    deleteSharedGridMutate(state, id) {
      if(state.isType == 1){
        state.isType = 0;
        state.isBoard = 0;
        localStorage.grpage = 0;
        state.layouts = _.cloneDeep(state.layoutsPersonal);
        state.isBoardCode = state.layoutsPersonal[0].grcode;
      }
      console.log(id);     
      let index = _.findIndex(state.layoutsShared, {'id': id});
      //find items with images and delete them
      let items = state.layoutsShared[index].layout;
      for(let i=0; i<items.length; i++){
        if(items[i].type == 0 || items[i].type == 6){
          if(items[i].options.bgImage){
            deleteImage(items[i].options.bgImage);
          };
          if(items[i].options.icon){
            deleteImage(items[i].options.icon);
          };
        }
      }
      //remove item from localStorage shared
      state.layoutsShared.splice(index, 1);
      localStorage.grshared = JSON.stringify(state.layoutsShared);
    },

    //if a shared page is deleted, remove it from shared pages // detected with WS connection 
    deleteSharedGridMutateGRcode(state, grcode) {
      if(state.isType == 1){
        state.isType = 0;
        state.isBoard = 0;
        localStorage.grpage = 0;
        state.layouts = _.cloneDeep(state.layoutsPersonal);
        state.isBoardCode = state.layoutsPersonal[0].grcode;
      }   
      let index = _.findIndex(state.layoutsShared, {'grcode': grcode});
      //remove item from localStorage common
      state.layoutsShared.splice(index, 1);
      localStorage.grshared = JSON.stringify(state.layoutsShared);
    },


    setCommonGridNameGRcode(state,payload) {
      console.log(payload);
      let index = _.findIndex(state.layoutsCommon, {'grcode': payload.grcode});
      console.log(index);
      state.layoutsCommon[index].name = payload.name;
      localStorage.grcommon = JSON.stringify(state.layoutsCommon);
    },

    deleteCommonGridMutate(state, id) {
      if(state.isType == 2){
        state.isType = 0;
        state.isBoard = 0;
        localStorage.grpage = 0;
        state.layouts = _.cloneDeep(state.layoutsPersonal);
        state.isBoardCode = state.layoutsPersonal[0].grcode;
      }
      //console.log(id); ==============================================//
      console.log(id);     
      let index = _.findIndex(state.layoutsCommon, {'id': id});
      //remove item from localStorage common
      state.layoutsCommon.splice(index, 1);
      localStorage.grcommon = JSON.stringify(state.layoutsCommon);
    },

    //if a shared page is deleted, remove it from shared pages // detected with WS connection 
    deleteCommonGridMutateGRcode(state, grcode) {
      if(state.isType == 2){
        state.isType = 0;
        state.isBoard = 0;
        localStorage.grpage = 0;
        state.layouts = _.cloneDeep(state.layoutsPersonal);
        state.isBoardCode = state.layoutsPersonal[0].grcode;
      }   
      let index = _.findIndex(state.layoutsCommon, {'grcode': grcode});
      //remove item from localStorage common
      state.layoutsCommon.splice(index, 1);
      localStorage.grcommon = JSON.stringify(state.layoutsCommon);
    },

    dropAllData(state){
      //localStorage.removeItem("grshared");
      state.layoutsShared = [];
      state.layoutsPersonal = [];
      state.layoutsCommon = [];
      localStorage.removeItem("grshared");
      localStorage.removeItem("grcommon");
      localStorage.removeItem("grpersonal");
      state.isBoard = 0;
      state.isType = 0;
      localStorage.grpage = 0;
      state.layouts = getLayouts();
      state.layoutsPersonal = JSON.parse(localStorage.grpersonal);
    },
    clearSaredLayouts(state){ //clear shared layouts if multiple windows were open and the user did logout
      //localStorage.removeItem("grshared");
      state.layoutsShared = [];
      state.layoutsCommon = [];
    },
    //experimental-----------------------------------------------------//
    //##############################################################################
    // setElementStatus(state, payload) {
    //   //"grcode": indata.grcode, "elementID": indata.elementID, "userID": indata.userID, "status": indata.status
    //   //let index = _.findIndex(state.layouts, {'id': payload.id});//
    //   //find by grcode
    //   if(state.isBoardCode!==payload.grcode){
    //     return;
    //   }

    //   console.log("setElementStatus in layouts.js");
    //   console.log("setElementStatus payload", payload);

    //   // let index = _.findIndex(state.layouts, {'grcode': payload.grcode});
    //   // if (index == -1) return;

    //   // if (payload.status == "editing") {

    //   //   let username = state.userID.split("@")[0];

    //   //   let itemIndex = _.findIndex(state.layouts[index].layout, {'id': payload.elementID});
    //   //   state.layouts[index].layout[itemIndex].status = payload.status;

    //   //   // if(payload.status){
    //   //   //   setTimeout(() => {
    //   //   //     state.layouts[index].layout[itemIndex].status = "";
    //   //   //   }, 10000);
    //   //   // }
    //   // }else{
    //   //   let itemIndex = _.findIndex(state.layouts[index].layout, {'id': payload.elementID});
    //   //   state.layouts[index].layout[itemIndex].status = payload.status;
    //   // }
    // }//setElementStatus
    //##############################################################################
  },
  actions: {
    gridAction({ commit, state, rootGetters }, payload) {  //addItem, duplicateItem, remItem, copyTo ?? saveItem, 
      let wsStatus = rootGetters["ws/getWSStatus"];
      let gridWidth = rootGetters["settings/getCurrentwCell"];
      let item = payload.data;
      if(state.isType > 0 && !wsStatus){
        commit("toast/showToast", {"show":true, "text":"You can't add items to shared grids when offline!", "type":"danger"}, { root: true });
        return;
      }
      return new Promise((resolve, reject) => {
        if(payload.action){
          commit(payload.action, {item, gridWidth});
          resolve();
        }else{
          reject("no action");
        }
        });
    },
    changeBoard({commit,dispatch,state,rootGetters},payload) {

      let userEmail = rootGetters["users/isUserEmail"];
      //find index of page by type
      let index = _.findIndex(state[state.layoutTypes[payload.type]], {'grcode':payload.grcode});

      console.log("changeBoard",payload);

      if(index<0){ 
        state.isBoard = 0;
        state.isType = 0; 
        state.layouts = _.cloneDeep(state[state.layoutTypes[state.isType]]);
        commit("toast/showToast", {"show":true, "text":"Something went wrong, please refresh!", "type":"danger"}, { root: true });
        commit("unsetLoadingBoard", "", { root: true });
        return;
      }
      
      commit("setBoard", index);
      commit("setGridType", payload.type);
      commit("setBoardCode", payload.grcode);
      //commit("setGridType", payload.gridType);

      //clear timeouts
      commit("ws/clearElTimeouts", "", { root: true });
      commit("ws/clearGrTimeouts", "", { root: true });
      
      //if shared page, connect to WS
      if(payload.type > 0){
        dispatch('ws/connectWS', {"grcode":payload.grcode, "type": "getLayout", "email": userEmail}, { root: true });
      }
    },

    loadSharedGrids({ commit }) {
      console.log("loading shared grids...");

      axios
      .post("sharedgrids/manage_grids.php", {
        token: localStorage.grtoken,
        action: "getLayouts",
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          //console.log("Layouts:" + response.data.layouts);
          commit("setSharedGrisLayout", response.data.layouts); // do i need to emoji?
        } else {
          console.log("Error getting data:", response.data.message);
          commit("setSharedGrisLayout", []);
        }
      })
      .catch((error) => {
        console.log(error);
        //this.errored = true;
      });
    //.finally(() => (this.loading = false)); 
    },

    saveSharedGrid({commit,dispatch,state},payload) {
      if(state.isType==0){return;}//failsafe to prevent saving personal grids to shared grids
      console.log("saving shared grid...");
      console.log("state.isType...",state.isType);

      dispatch('ws/sendLayout', {'grcode':state.layouts[state.isBoard].grcode, 'layout':state.layouts[state.isBoard].layout}, { root: true });
      commit("updateLocal"); //save to local storage and state

      //dispatch('ws/sendLayout', {'grcode':state.layouts[state.isBoard].grcode, 'layout':state.layouts[state.isBoard].layout, 'redactionType':payload.redactionType, 'elementID':payload.elementID}, { root: true });
      //dispatch('ws/sendLayout', {'grcode':state.layouts[state.isBoard].grcode, 'layout':state.layouts[state.isBoard].layout}, { root: true });
      // dispatch('ws/sendElement', {'grcode':state.layouts[state.isBoard].grcode, 'layout':state.layouts[state.isBoard].layout}, { root: true });

    },

    saveSharedElement({commit,dispatch,state},payload) {
      if(state.isType==0){return;}//failsafe to prevent saving personal grids to shared grids
      console.log("saving shared Element...");
      console.log("elementID: ",payload);

      let itemIndex = _.findIndex(state.layouts[state.isBoard].layout, {'i': payload});
      let item = state.layouts[state.isBoard].layout[itemIndex];
      dispatch('ws/sendElement', {'grcode':state.layouts[state.isBoard].grcode, 'element':item, 'layout':state.layouts[state.isBoard].layout}, { root: true });

      commit("updateLocal");
    },

    deleteSharedGrid({commit,dispatch,state},payload) {
      axios
      .post("sharedgrids/manage_grids.php", {
        token: localStorage.grtoken,
        action: "deleteItem",
        itemID: payload
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          commit("deleteSharedGridMutate", payload);
        } else {
          console.log("Error deleting shared grid:", response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        //this.errored = true;
      });
    },

    //commong grids actions
    loadCommonGrids({ commit }) {
      console.log("loading common grids...");
      axios
      .post("sharedgrids/get_grids.php", {
        token: localStorage.grtoken,
        action: "getLayouts",
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          //console.log("Layouts:" + response.data.layouts);
          commit("setCommonGrisLayout", response.data.layouts); // do i need to emoji?
        } else {
          console.log("Error getting data:", response.data.message);
          commit("setCommonGrisLayout", []);
        }
        setTimeout(() => {
          commit("gridtabs/checkTabs", "", { root: true });
        });
      })
      .catch((error) => {
        console.log(error);
        //this.errored = true;
      });
    //.finally(() => (this.loading = false)); 
    },

    deleteCommonGrid({commit,dispatch,state},payload) {
      console.log("deleteCommonGrid",payload);
      axios
      .post(`sharedgrids/get_grids.php`, {
          token: localStorage.grtoken,
          action: "selfRemove",
          itemID: payload,
        })
        .then(response => {
          if (response.data.success) {
            commit("deleteCommonGridMutate", payload);
            commit("toast/showToast",
            {
                show: true,
                text: response.data.message,
                type: "success",
              },
              { root: true }
            );
            //this.$store.dispatch("layouts/loadCommonGrids");
          } else {
            commit("toast/showToast",
            {
                show: true,
                text: response.data.message,
                type: "danger",
              },
              { root: true }
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
      //================================================//
    },
    //Backup Personal Grids
    backupPersonalLayouts({commit,dispatch,state}) {
      let timestamp = new Date().toLocaleString();
      console.log("did backup!", timestamp);
      let backupGrid = state.layoutsPersonal;
      backupGrid = JSON.stringify(backupGrid);
      backupGrid = emoji.unemojify(backupGrid);
      axios
      .post(`personalgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "backupGrids",
          grids: backupGrid,
        })
        .then(response => {
          console.log(response.data);
        //   if (response.data.success) {
        //     commit("toast/showToast",
        //     {
        //         show: true,
        //         text: response.data.message,
        //         type: "success",
        //       },
        //       { root: true }
        //     );
        //     //this.$store.dispatch("layouts/loadCommonGrids");
        //   } else {
        //     commit("toast/showToast",
        //     {
        //         show: true,
        //         text: response.data.message,
        //         type: "danger",
        //       },
        //       { root: true }
        //     );
        //   }
        })
        .catch(error => {
          console.log(error);
        });
      //================================================//
    },
    restorePersonalLayouts({commit,dispatch,state}) {
      // let timestamp = new Date().toLocaleString();
      // console.log("did backup!", timestamp);
      // let backupGrid = state.layoutsPersonal;
      // backupGrid = JSON.stringify(backupGrid);
      // backupGrid = emoji.unemojify(backupGrid);
      axios
      .post(`personalgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "restoreGrids",
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {

            let restoredGrids = response.data.item.grids;
            restoredGrids = JSON.stringify(restoredGrids);
            restoredGrids = emoji.emojify(restoredGrids);
            restoredGrids = JSON.parse(restoredGrids);
            localStorage.grpersonal = JSON.stringify(restoredGrids);
            commit("setPersonalGrisLayout", restoredGrids);
            state.layouts = _.cloneDeep(restoredGrids);
            
          } else {
            commit("toast/showToast",
            {
                show: true,
                text: response.data.message,
                type: "danger",
              },
              { root: true }
            );  
          }
        })
        .catch(error => {
          console.log(error);
        });
      //================================================//
    },
  //   updateSharedElement({commit,dispatch,state},payload) {

  //     commit("updateSharedElementMutate", payload);

  //     if(payload.last){
  //       console.log("last in update stream");
  //       setTimeout(() => {
  //         updateDB(grcode, state.layout);
  //       }, 1000);
  //     }
  //     // axios
  //     // .post(`sharedgrids/manage_grids.php`, {
  //     //     token: localStorage.grtoken,
  //     //     action: "updateItem",
  //     //     itemID: payload.itemID,
  //     //     item: payload.item,
  //     //   })
  //     //   .then(response => {
  //     //     if (response.data.success) {
  //     //       commit("toast/showToast",
  //     //       {
  //     //           show: true,
  //     //           text: response.data.message,
  //     //           type: "success",
  //     //         },
  //     //         { root: true }
  //     //       );
  //     //       //this.$store.dispatch("layouts/loadCommonGrids");
  //     //     } else {
  //     //       commit("toast/showToast",
  //     //       {
  //     //           show: true,
  //     //           text: response.data.message,
  //     //           type: "danger",
  //     //         },
  //     //         { root: true }
  //     //       );
  //     //     }
  //     //   })
  //     //   .catch(error => {
  //     //     console.log(error);
  //     //   });
  //     // //================================================//
  //   },
  },
  modules: {
  }
}
