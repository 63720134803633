<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Account Settings</p>
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <div class="columns">
            <div class="column column-separator">
              <b-field v-if="form.visible" label="Email" :type="form.email.status" :message="form.email.message">
                <b-input type="email" v-model="form.email.data" placeholder="Your email" disabled></b-input>
              </b-field>

              <b-field v-if="form.visible" label="Name" :type="form.name.status" :message="form.name.message">
                <b-input type="text" v-model="form.name.data" placeholder="Your Name" required></b-input>
              </b-field>

              <b-field v-if="form.visible" label="Password" :type="form.pass.status" :message="form.pass.message">
                <b-input type="text" v-model="form.pass.data" placeholder="Your Password" required></b-input>
              </b-field>

              <b-message :type="form.status" v-if="form.result">
                {{ form.message }}
              </b-message>
              <div class="has-text-centered">
                <b-button v-if="form.visible" type="is-primary" @click="updateUser()">
                  Save Changes
                </b-button>
              </div>
            </div>
            <div class="column">

              <p>Your current subscribtion:</p>

              <b-message :type="subscribtion.type">
                {{ subscribtion.name }}
              </b-message>

              <b-button v-if="subscribtion.status==0" type="is-success" expanded @click="openSubscriptions()">
                Subscribe
              </b-button>

              <b-button v-if="subscribtion.status==1" type="is-success" expanded @click="openSubscriptions()">
                Subscription Options
              </b-button>
            </div>
          </div>
          <div class="mt-3">
            <b-message :type="general.status" v-if="general.result">
              {{ general.message }}
            </b-message>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Close
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "AccountModal",
  data() {
    return {
      isLoading: false,
      userData: {
        name: "",
        email: "",
        pass: "",
        status: "",
      },
      countErrors: 0,
      subscribtion:{
        type: "",
        status: "",
        name: "",
      },
      form: {
        name: {
          data: "",
          status: "",
          message: "",
        },
        email: {
          data: "",
          status: "",
          message: "",
        },
        pass: {
          data: "",
          status: "",
          message: "",
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
      },
      general: {
        message: "",
        result: false,
        status: "",
      },
    };
  },
  methods: {
    openSubscriptions(){
      //open subscriptions page in new window
      window.open("https://gridsup.com/pricing/", "_blank");
      //window.location.href = "https://gridsup.com/pricing/";
      this.closeModal();
    },
    capitalizeWords(string) {
      let arr = string.split(" ");
      return arr.map(element => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
        }).join(" ");
    },
    getData() {
      console.log("gettiing user data!");
      this.isLoading = true;
      //this.form.visible = false;
      //begin post --------------------------------------------
      this.axios
        .post("users/manageuser.php", {
          action: "getUser",
          token: localStorage.grtoken
        })
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.userData = response.data.data;
            this.setUserData();
          } else {
            this.general.result = true;
            this.general.status = "is-danger";
            this.general.message = "Could not get user data, please try again later.";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.general.result = true;
          this.general.status = "is-danger";
          this.general.message = "Could not get user data, please try again later.";
          //this.hasError();
        });
      //end post-------------------------------------------------
    },
    updateUser() {
      if (
        this.formCheck("name") &
        this.passCheck("pass") &
        this.emailCheck("email") &
        this.humanCheck()
      ) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/manageuser.php", {
            action: "updateUser",
            email: this.form.email.data,
            name: this.capitalizeWords(this.form.name.data),
            pass: this.form.pass.data,
            token: localStorage.grtoken,
          })
          .then((response) => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    // passCheck(e) {
    //   let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    //   console.log(!this.form[e].data.match(paswd));
    //   if (this.form[e].data === "" || !this.form[e].data.match(paswd)) {
    //     this.form[e].message =
    //       "Please enter a Password [7 to 15 characters which contain at least one numeric digit and a special character]";
    //     this.form[e].status = "is-danger";
    //     return false;
    //   } else {
    //     this.form[e].message = null;
    //     this.form[e].status = "is-success";
    //     return true;
    //   }
    // },
    passCheck() {
      //let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
      //console.log(!this.form[e].data.match(paswd));
      if (this.form.pass.data === "" || this.form.pass.data.length < 6) {
        this.form.pass.message = "Please enter a Password [min 6 characters]";
        this.form.pass.status = "is-danger";
        return false;
      } else {
        this.form.pass.message = null;
        this.form.pass.status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].message = "";
        this.form[e].status = "is-success";
        return true;
      }
    },
    humanCheck() {
      if (this.form.human.data == this.form.human.summ) {
        this.form.human.status = "is-success";
        this.form.human.message = "Human, eh?";
        return true;
      } else {
        this.form.human.status = "is-danger";
        this.form.human.message = "You did not pass the human test.";
        return false;
      }
    },
    setUserData() {
      this.form.name.data = this.userData.name;
      this.form.email.data = this.userData.email;
      //this.form.pass.data = this.userData.pass;
      this.subscribtion.status = this.userData.status;
      if(this.userData.status == 1){
        this.subscribtion.type ="is-success";
        this.subscribtion.name = "Basic Subscription";
      }else if(this.userData.status == 2){
        this.subscribtion.type ="is-success";
        this.subscribtion.name = "Pro Subscription";
      }else if(this.userData.status == 3){
        this.subscribtion.type ="is-success";
        this.subscribtion.name = "Lifetime License";
      }else{
        this.subscribtion.type ="is-danger";
        this.subscribtion.name = "Not Subscribed";
      }
    },
    resetForm() {
      this.form.name.data = "";
      this.form.name.status = "";
      this.form.name.message = "";
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.visible = true;
    },
    closeModal() {
      this.resetForm();
      this.$store.commit("modals/hideModal", "account");
    },
  },
  computed: {
    ...mapState("users", ["user"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.account;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "account");
        return false;
      },
    },
  },
  watch: {
    isActive: function (val) {
      if (val) {
        this.resetForm();
        this.getData();
      }
    },
  },
  mounted() {
    console.log("account mounted");
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: flex-end;
  /* space-between; */
}

.column-separator {
  border-right: 1px solid #e6e6e6;
  /* //margin-right: 1rem;
  padding-right: 1rem; */
}
</style>
