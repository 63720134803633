<template>
<div>
      <div class="is-size-5 has-text-weight-bold mb-2">Shared Boards</div>
                <div class="addcatbox mb-5 has-text-centered"  @keyup.enter.exact="add()">
                  <b-field>
                      <b-input v-model="newBoard" placeholder="Board Name" type="text" expanded></b-input>
                      <p class="control">
                        <b-button class="button is-primary" @click="add()">Add Board</b-button>
                      </p>
                  </b-field>            
                </div>
             <div class="scroll-grids">
                <draggable v-model="inBoards" v-bind="dragOptions" :list="inBoards.position" group="shared" @start="drag=true" @end="drag=false" :move="checkMove" handle=".handle">
                   <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div v-for="(item, index) in inBoards" :key="item.id" :id="item.id">
            <div class="catbox mb-2">
              <div
                class="b-editor mb-2"
                v-if="isEditingID == item.id"
                @keyup.enter.exact="save(item.grcode)"
              >
                <b-field>
                  <p class="control">
                    <b-button
                      class="button is-light"
                      @click="cancel()"
                      icon-left="xmark"
                    />
                  </p>
                  <b-input
                    v-model="isBoardName"
                    placeholder="Board Name"
                    type="text"
                    expanded
                  ></b-input>
                  <p class="control">
                    <b-button
                      class="button is-primary"
                      @click="save(item.grcode)"
                      icon-left="floppy-disk"
                    />
                  </p>
                </b-field>
              </div>
              <div class="b-item level level-mb" v-if="isEditingID !== item.id">
                <div class="level-left level40">
                  <div class="item-name">{{ item.name }}</div>
                </div>
                <div class="level-right buttons">
                  <b-tooltip
                    label="Show Tab"
                    position="is-left"
                    :delay="1500"
                  >
                    <b-field>
                      <b-switch
                        :value="isTab(item)"
                        @input="toggleTab(item)"
                        type="is-primary"
                        size="is-small"
                      />
                    </b-field>
                  </b-tooltip>
                  
                  <div
                    class="bicon-button"
                    @click="showShareForm(item)"
                  >
                    <b-icon icon="user-plus"></b-icon>
                  </div>

                  <div
                    class="bicon-button"
                    @click="toggleMenu(item.id, item.name)"
                  >
                    <b-icon icon="bars"></b-icon>
                  </div>

                  <div class="bicon-button handle">
                    <b-icon icon="grip-dots"></b-icon>
                  </div>
                </div>
              </div>
              <div class="drop-menu submenu mt-2" v-show="isSubmenuID == item.id">
                <b-button
                  type="is-primary"
                  size="is-small"
                  outlined
                  expanded
                  @click="edit(item.id, item.name)"
                  >Rename</b-button
                >
                <b-button
                  type="is-success"
                  size="is-small"
                  outlined
                  expanded
                  @click="showShareForm(item)"
                  >Add/Remove Users</b-button
                >
                <b-button
                  type="is-danger"
                  size="is-small"
                  outlined
                  expanded
                  @click="warningDelete(item)"
                  >Delete</b-button
                >
              </div>
              <div class="drop-menu" v-if="isShareID == item.id">
                <!-- <div class="is-size-7 has-text-centered has-text-grey-light">Add email addresses of users you want to share this grid with.</div> -->
                <span class="is-size-7 has-text-grey-light">Add email addresses:</span>
                <b-field
                  position="is-centered"
                  :type="shareForm.type"
                  :message="shareForm.message"
                >
                    <b-input size="is-small" v-model="shareForm.email" placeholder="Email" icon="at">
                    </b-input>
                    <p class="control">
                        <b-button size="is-small" label="Add User" type="is-primary" @click="addUser()"/>
                    </p>
                </b-field>
                <div v-for="user in isBoardUsers">
                  <!-- <b-tag type="is-primary">{{user}}</b-tag></div> -->
                <b-field>
                  <b-tag
                      type="is-primary"
                      closable
                      aria-close-label="Remove User"
                      @close="warningRemoveUser(user)">
                      {{user}}
                  </b-tag>
                </b-field>
              </div>

                <!-- <b-field
                  :type="shareForm.type"
                  :message="shareForm.message"
                >
                  <b-taginput
                      @input="resetShareForm"
                      @add="shareForm.showSave = true"
                      @remove="shareForm.showSave = true"
                      v-model="isBoardUsers"
                      ellipsis
                      icon="at"
                      placeholder="Add Users"
                      aria-close-label="Delete User"
                      maxtags="10"
                      >
                  </b-taginput>
              </b-field>
              <div class="submenu button-row">
                <b-button
                  size="is-small"
                  outlined
                  @click="hideShareForm()"
                  >Cancel</b-button
                >
                <b-button
                  v-if="shareForm.showSave"
                  type="is-primary"
                  size="is-small"
                  @click="saveUsers(item)"
                  >Save</b-button
                ></div> -->

              </div>
            </div>
          </div>
        </transition-group>
                </draggable>
            </div>
  
  </div>
</template>

<script>
import draggable from "vuedraggable";
import toastMixin from "@/mixins/toastMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import { mapState } from "vuex";
import { find } from "node-emoji/lib/emoji";
export default {
  name: "SharedGrids",
  components: {
    draggable
  },
  mixins: [sanitizeMixin, toastMixin],
  data() {
    return {
      isLoading: false,
      isEditingID: null,
      isSubmenuID: null,
      isShareID: null,

      isBoardName: "",
      isBoardUsers: [],
      isBoardOldUsers: [],
      newBoard: null,
      drag: false,
      dragOptions: {
        animation: 200,
        group: "sharedGrid",
        disabled: false,
        ghostClass: "ghost",
        filter: '#home'
      },
      timeoutId: null,
      shareForm:{
        email: null,
        type: "",
        message: "",
        showSave: false
      },   
    };
  },
  methods: {    
    showModal(modal) {
      this.$store.commit("modals/showModal", modal);
    },
    toggleTab(item) {
      let newTab = {
        id: item.id,
        name: item.name,
        type: 1,
        grcode: item.grcode,
      };
      this.$store.commit("gridtabs/toggleTab", newTab);
    },
    isTab(item) {
      let foundIndex = this.gridTabs.findIndex((x) => x.grcode == item.grcode);
      if (foundIndex > -1) {
        return true;
      } else {
        return false;
      }
      //this.gridTabs
      //return item.id == this.isHomepage;
    },
    // setTab(tab) {
    //   this.$store.commit("modals/setTab", tab);
    // },
    toggleMenu(id, page) {
      this.isShareID = null;
      console.log("show menu");
      if (this.isSubmenuID == id) {
        this.isSubmenuID = null;
      } else {
        this.isSubmenuID = id;
      }
    },
    showShareForm(item){
      this.isSubmenuID = null;
      console.log("showShareForm");
      this.isBoardUsers = [];
      this.isBoardOLDUsers = [];
      if(this.isShareID == item.id){
        this.isShareID = null;
        return;
      };
      this.isShareID = item.id;
      if(item.users){
        this.isBoardUsers = item.users.split(',');
        this.isBoardOLDUsers = item.users.split(',');
      }
    },
    hideShareForm(){
      this.isShareID = null;
      this.isBoardUsers = [];
      this.isBoardOLDUsers = [];
      this.shareForm.showSave = false;
      this.resetShareForm();
    },
    edit(id, page) {
      console.log("edit");
      this.isEditingID = id;
      this.isBoardName = page;
      this.isSubmenuID = null;
    },
    add(){
      if (!this.user.isLogged) {
        this.showModal("login");
        return;
      }
      // if (!this.user.isLogged) {
      //   this.showModal("login");
      //   return;
      // }
      if (this.user.status == 0 && this.layoutsShared.length > 0) {
        this.showModal("pay");
        return;
      }  
      if(this.$store.getters['layouts/getSharedLayoutsCount']>50 && this.user.status !== 4){
        this.toast("You can't create more than 50 pages!", "is-danger");
        return;
      }
      this.doAdd();
    },
    doAdd(){
      if(this.newBoard == "" || this.newBoard == null){
        this.toast("Provide a valid Board Name!", "is-danger");
        return;
      }
      this.axios
      .post(`sharedgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "addItem",
          name: this.sanitizeName(this.newBoard),
          position: this.layoutsShared.length, 
        })
        .then(response => {
          console.log(response);
          if (response.data.success) {
              //this.$store.commit("layouts/addSharedGrid", {"name":data.name,"id":data.id, "layout":[], "users":[], "position": data.position});
            this.newBoard = null;
            this.toast(response.data.message, "is-success");
            this.$store.dispatch("layouts/loadSharedGrids");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.toast(error, "is-danger");
        });
    },
    // doAdd(){
    //   if(this.newBoard == "" || this.newBoard == null){
    //     this.toast("Provide a valid Board Name!", "is-danger");
    //     return;
    //   }
    //   console.log("add");
    //   let ids = _.map( this.sharedGrid.layouts, "id");
    //   let lowest = 0;
    //   if(ids.length>0){
    //     ids.sort(function(a, b) { return a-b; });
    //     lowest = _.min(ids);
    //     if (lowest == 0) {
    //       lowest = ids[ids.length - 1] + 1;
    //     }
    //   }else{
    //     lowest = 0;
    //   }
      
    //   let name = "";
    //   name = this.sanitizeName(this.newBoard);
    //   if(name!=""){
    //     this.$store.commit("sharedlayouts/addBoard", {"name":name,"id":lowest, "layout":[], "users":[], order: this.sharedGrid.layouts.length});
    //     this.newBoard = null;
    //   }else{
    //     this.toast("Provide a valid Board Name!","is-danger");
    //     return;
    //   }
    // },
    delete(item){
        console.log("deleteing shared grid...");
        let deleteTab = {
        id: item.id,
        name: item.name,
        type: 1,
        grcode: item.grcode,
      };
      this.$store.commit("gridtabs/removeTab", deleteTab);
      this.$store.dispatch("layouts/deleteSharedGrid", item.id);
    },
    save(grcode){
      console.log(grcode);
      if(this.isBoardName=="" || this.isBoardName==null){
        this.toast("Provide a valid Board Name!","is-danger");
        return;
      }
      this.axios
      .post(`sharedgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "updateName",
          name: this.sanitizeName(this.isBoardName),
          itemID: this.isEditingID, 
        })
        .then(response => {
          if (response.data.success) {
            //this.$store.commit("layouts/setSharedGridName", {"name":this.isBoardName,"id":this.isEditingID});
            this.toast(response.data.message, "is-success");
            this.$store.dispatch("layouts/loadSharedGrids");
            if(grcode){
              let payload = {
                name: this.sanitizeName(this.isBoardName),
                grcode: grcode,
              };
              this.$store.commit("gridtabs/renameTab", payload);
            }
            this.isEditingID = null;
            this.isBoardName = null;
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.toast(error, "is-danger");
        });
    },
    savePosition(){
      let ids = _.map( this.layoutsShared, "id");
      this.axios
      .post(`sharedgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "updatePosition",
          ids: ids,
        })
        .then(response => {
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.toast(error, "is-danger");
        });
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (e === "" || !re.test(e)) {
        return false;
      } else {
        return true;
      }
    },
    resetShareForm(){
      this.shareForm.message = "";
      this.shareForm.type = "";
      this.shareForm.email = "";
    },
    addUser(){
      if(this.isBoardUsers.length>10){
        this.toast("You can only share with 10 users at a time!","is-danger");
        return;
      }
      if(!this.emailCheck(this.shareForm.email)){
        this.toast("Provide a valid email address!","is-danger");
        this.shareForm.message = "Provide a valid email address!";
        this.shareForm.type = "is-danger";
        return;
      }
      let index = this.isBoardUsers.indexOf(this.shareForm.email);
      if(index>-1){
        this.toast("Already shared with this user!", "is-danger");
        return;
      }
      this.isBoardUsers.push(this.shareForm.email);
      this.resetShareForm();
      //save users
      this.saveUsers(this.shareForm.email);
    },
    removeUser(user){
      let index = this.isBoardUsers.indexOf(user);
      if(index>-1){
        this.isBoardUsers.splice(index, 1);
      }
      //save users
      this.saveUsers();
    },
    saveUsers(user){
      //this.resetShareForm();
      //_.difference([2, 1], [2, 3]);
//      let notofyUsers = _.difference(this.isBoardUsers, this.isBoardOLDUsers);

      this.axios
      .post(`sharedgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "updateUsers",
          users: this.isBoardUsers.join(','),
          nitify: user,
          itemID: this.isShareID,
        })
        .then(response => {
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            this.$store.dispatch("layouts/loadSharedGrids");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.toast(error, "is-danger");
        });
    },
    // save(){
    //   if(this.isBoardName=="" || this.isBoardName==null){
    //     this.toast("Provide a valid Board Name!","is-danger");
    //     return;
    //   }
    //   console.log("save");    
    //   let name = "";
    //   name = this.sanitizeName(this.isBoardName);
    //   if(name!=""){
    //     this.$store.commit("sharedlayouts/setBoardName", {"name":name,"id":this.isEditingID});
    //     this.isEditingID = null;
    //     this.isBoardName = null;

    //   }else{
    //     this.toast("Provide a valid Board Name!","is-danger");
    //     return;
    //   }
    // },
    cancel(){
      console.log("cancel");
      this.isEditingID = null;
      this.isBoardName = null;
      
    },
    checkMove(){
      console.log("did move");
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.savePosition();
      }, 2000);
      //this.showSave = true;
    },
    warningDelete(item) {
      this.$buefy.dialog.confirm({
        title: "Delete Shared Grid",
        message:
          "Are you sure you want to <b>delete</b> this grid and all of its content? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: "trash",
        onConfirm: () => this.delete(item)
      });
    },
    warningRemoveUser(user) {
      this.$buefy.dialog.confirm({
        title: "Remove User",
        message:
          "Are you sure you want to <b>remove</b> this user from this grid?",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        icon: "users-slash",
        onConfirm: () => this.removeUser(user)
      });
    },
  },
  computed: {
    //...mapState(["isLoged","isUser","layouts"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["layoutsShared"]),
    ...mapState("gridtabs", ["gridTabs"]),
    inBoards: {
      get: function() {
        return this.$store.state.layouts.layoutsShared;
      },
      // set: function() {
      //   return false;
      // }
      // setter
      set: function(val) {
        //this.$store.commit("layouts/updateSharedBoards", val);
        this.$store.commit("layouts/updateBoards", {"val":val, "type":1});
        return false;
      }
    }
  },
  watch:{
    isActive: function(val){
      if(val){
        //general------------------------------------
        this.hasBoards = this.pages;
      }
    }
  }
};
</script>
<style scoped>
.bg-preview {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  margin-top:10px;
}
.catbox{
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.addcatbox{
  padding: 15px;
  border: 1px dashed #dfdfdf;
  border-radius: 5px;
}

/* .scroll-wrapp{
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
} */


.level40{
  width:40%;
}

.item-name{
  width:100%;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-grids {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  scrollbar-color: #0278e8;
  scrollbar-width: 4px;
}
.scroll-grids::-webkit-scrollbar-track {
  background-color: #e9e9e9 !important;
}

.scroll-grids::-webkit-scrollbar {
  width: 4px;
}

.scroll-grids::-webkit-scrollbar-thumb {
  background: #0278e8 !important;
}

.submenu{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.submenu .button:not(:last-child){
  margin-bottom: 5px;
} 
.submenu.button-row{
  justify-content: space-between;
  margin-top: 25px;
}

.level-mb:not(:last-child) {
    margin-bottom: 0.3rem!important;
}
</style>
