<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 300px">
        <header class="modal-card-head">
          <p class="modal-card-title">Calendar Settings</p>
        </header>
        <section class="modal-card-body">
          <section>
          <div class="separator mb-2">Options</div>

            <div class="field">
              <b-switch v-model="options.dark">Dark Mode</b-switch>
            </div>

            <div class="separator mb-2">Clear Calendar</div>
            
            <div class="has-text-centered">
              <b-button @click="warningDelete" type="is-danger">Delete All Notes</b-button>
            </div>

          </section>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()"
            >Save</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "EditCalendarModal",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      options: {
        dark: true    
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "editCalendar");  
    },
    reset() {
      this.options = {
        dark: true
      };
    },
    warningDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting Calendar Notes",
        message:
          "Are you sure you want to <b>delete</b> all notes? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteNotes()
      });
    },
    deleteNotes(){         
      console.log("saving item..");
      this.options.notes = [];
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Notes Deleted", "is-success");
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Notes Deleted", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
      this.closeModal();
    },
    saveItem(){         
      console.log("saving item..");
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
        ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  dark: true 
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editCalendar;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editCalendar"); 
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

