import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VCalendar from 'v-calendar';

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://gridsup.com/bestapi/";

Vue.prototype.$gridVars = {
  api: "https://gridsup.com/bestapi/",
  iconsURL: "https://gridsup.com/usericons/",
  filesURL: "https://gridsup.com/files/?f=", //"https://gridsup.s3.eu-west-1.amazonaws.com/",
  imagesURL: "https://gridsup-images.s3.eu-west-1.amazonaws.com/",
  browserType: "chrome",
  wsURL:"wss://gridsup.com:8082",
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});
Vue.use(VueAxios, axios);
Vue.use(VCalendar);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
