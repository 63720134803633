<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">Weather Settings</p>
        </header>
        <section class="modal-card-body">

            <section>
            <div class="separator mb-2">Location</div>
          
              <b-field :type="city.status">
                <p class="control">
                  <span class="button is-static">City:</span>
                </p>
                <b-input
                  v-model="city.data"
                  expanded
                ></b-input>
              </b-field>
              <b-field
                :message="city.message"
                :type="city.status"
              ></b-field>
      
          </section>

          <section>
          <div class="separator mb-2">Options</div>
            <b-field>
              <b-radio-button v-model="options.units"
                  native-value="C"
                  type="is-primary" expanded>
                  <span>Celius</span>
              </b-radio-button>
              <b-radio-button v-model="options.units"
                  native-value="F"
                  type="is-primary" expanded>
                  <span>Fahrenheit</span>
              </b-radio-button>
            </b-field>
          </section>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()">Save</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "EditWeatherModal",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      didAdd: false,
      options: {
        city: null,       
        units: "c"
      },
      city: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    catchTextColor(e) {
      this.options.textColor = e;
    },
    catchBgColor(e) {
      this.options.bgColor = e;
    },
    closeModal() {
      this.$store.commit("modals/hideModal", "editWeather");
    },
    reset() {
      this.options = {
        city: null,       
        units: "c"
      };
    },
    // saveItem(){
    //   if(this.city.data){
    //     this.options.city=this.city.data;  
    //   }   
    //   console.log("saving item..");
    //   //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
    //   this.toast("Item Saved", "is-success");
    //   this.closeModal();
    // },
    saveItem(){         
      console.log("saving item..");
      if(this.city.data){
        this.options.city=this.city.data;  
      }   
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
          ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  city: null,       
                  units: "c"    
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editWeather;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editWeather");
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
        this.city.data = this.options.city;
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

