export default {
  methods: {
    convertTextToList(text) {
      // Match lines starting with numbers followed by ']', ')' or '.'
      const regex = /(\d+)[\].)]\s(.*)/g;
      
      let match;
      const result = [];
      
      // While there are matches
      while ((match = regex.exec(text)) !== null) {
        // Add match to the result array
        result.push({
          id: parseInt(match[1]), // Parse the number as integer
          status: 0,
          task: match[2] // The rest of the line after the number and ')'
        });
      }
      
      // Sort the result array based on the id
      result.sort((a, b) => a.id - b.id);
      
      return result;
    }
  },
};
