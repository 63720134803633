import { render, staticRenderFns } from "./FileTransferItem.vue?vue&type=template&id=83a563a0&scoped=true&"
import script from "./FileTransferItem.vue?vue&type=script&lang=js&"
export * from "./FileTransferItem.vue?vue&type=script&lang=js&"
import style0 from "./FileTransferItem.vue?vue&type=style&index=0&id=83a563a0&prod&scoped=true&lang=css&"
import style1 from "./FileTransferItem.vue?vue&type=style&index=1&id=83a563a0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a563a0",
  null
  
)

export default component.exports