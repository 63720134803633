<template>
  <div>
    <div class="is-size-5 has-text-weight-bold mb-2">Personal Boards</div>
    <div class="addcatbox mb-5 has-text-centered" @keyup.enter.exact="add()">
      <b-field>
        <b-input
          v-model="newBoard"
          placeholder="Board Name"
          type="text"
          expanded
        ></b-input>
        <p class="control">
          <b-button class="button is-primary" @click="add()">Add Board</b-button>
        </p>
      </b-field>
    </div>
    <div class="scroll-grids">
      <draggable
        v-model="inBoards"
        v-bind="dragOptions"
        group="personal"
        @start="drag = true"
        @end="drag = false"
        :move="checkMove"
        handle=".handle"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div v-for="(item, index) in inBoards" :key="item.id" :id="item.id">
            <div class="catbox mb-2">
              <div
                class="b-editor mb-2"
                v-if="isEditingID == item.id"
                @keyup.enter.exact="save(item.grcode)"
              >
                <b-field>
                  <p class="control">
                    <b-button
                      class="button is-light"
                      @click="cancel()"
                      icon-left="xmark"
                    />
                  </p>
                  <b-input
                    v-model="isBoardName"
                    placeholder="Board Name"
                    type="text"
                    expanded
                  ></b-input>
                  <p class="control">
                    <b-button
                      class="button is-primary"
                      @click="save(item.grcode)"
                      icon-left="floppy-disk"
                    />
                  </p>
                </b-field>
              </div>
              <div class="b-item level" v-if="isEditingID !== item.id">
                <div class="level-left level50">
                  <div class="item-name">{{ item.name }}</div>
                </div>
                <div class="level-right buttons">
                  <b-tooltip
                    label="Show Tab"
                    position="is-left"
                    :delay="1500"
                  >
                    <b-field>
                      <b-switch
                        :value="isTab(item)"
                        @input="toggleTab(item)"
                        type="is-primary"
                        size="is-small"
                      />
                    </b-field>
                  </b-tooltip>

                  <div
                    class="bicon-button"
                    @click="toggleMenu(item.id, item.name)"
                  >
                    <b-icon icon="bars"></b-icon>
                  </div>

                  <div class="bicon-button handle">
                    <b-icon icon="grip-dots"></b-icon>
                  </div>
                </div>
              </div>
              <div class="drop-menu submenu mt-2" v-if="isSubmenuID == item.id">
                <b-button
                  type="is-primary"
                  size="is-small"
                  outlined
                  expanded
                  @click="edit(item.id, item.name)"
                  >Rename</b-button
                >
                <b-button
                  type="is-primary"
                  size="is-small"
                  outlined
                  expanded
                  @click="warningDuplicate(item)"
                  >Duplicate</b-button
                >
                <b-button
                  type="is-success"
                  size="is-small"
                  outlined
                  expanded
                  @click="warningShare(item)"
                  >Share</b-button
                >
                <b-button
                  type="is-danger"
                  size="is-small"
                  outlined
                  expanded
                  @click="warningDelete(item)"
                  >Delete</b-button
                >
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import toastMixin from "@/mixins/toastMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
var emoji = require('node-emoji');
import { mapState } from "vuex";
export default {
  name: "PersonalGrids",
  components: {
    draggable,
  },
  mixins: [sanitizeMixin, toastMixin],
  data() {
    return {
      isLoading: false,
      isEditingID: null,
      isSubmenuID: null,
      isBoardName: "",
      newBoard: null,
      drag: false,
      dragOptions: {
        animation: 200,
        group: "personalGrid",
        disabled: false,
        ghostClass: "ghost",
        filter: "#home",
      },
    };
  },
  methods: {
    showModal(modal) {
      this.$store.commit("modals/showModal", modal);
    },
    makeGRcode(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    toggleTab(item) {
      let newTab = {
        id: item.id,
        name: item.name,
        type: 0,
        grcode: item.grcode,
      };
      this.$store.commit("gridtabs/toggleTab", newTab);
    },
    isTab(item) {
      let foundIndex = this.gridTabs.findIndex((x) => x.grcode == item.grcode);
      if (foundIndex > -1) {
        return true;
      } else {
        return false;
      }
      //this.gridTabs
      //return item.id == this.isHomepage;
    },

    toggleMenu(id, page) {
      console.log("show menu");
      if (this.isSubmenuID == id) {
        this.isSubmenuID = null;
      } else {
        this.isSubmenuID = id;
      }
    },
    edit(id, page) {
      console.log("edit");
      this.isEditingID = id;
      this.isBoardName = page;
      this.isSubmenuID = null;
    },
    add() {
      if (!this.user.isLogged) {
        this.showModal("login");
        return;
      }
      if (this.user.status == 0 && this.layoutsPersonal.length > 4) {
        this.showModal("pay");
        return;
      }  
      if(this.$store.getters['layouts/getPersonalLayoutsCount']>50 && this.user.status !== 4){
        this.toast("You can't create more than 50 pages!", "is-danger");
        return;
      }
      this.doAdd();
    },
    doAdd() {
      if (this.newBoard == "" || this.newBoard == null) {
        this.toast("Provide a valid Grid Name!", "is-danger");
        return;
      }
      console.log("add");
      let ids = _.map(this.layoutsPersonal, "id");
      ids.sort(function (a, b) {
        return a - b;
      });
      let lowest = _.min(ids) - 1;
      if (lowest == 0) {
        lowest = ids[ids.length - 1] + 1;
      }
      let name = "";
      let grcode = this.makeGRcode(10);
      name = this.sanitizeName(this.newBoard);
      if (name != "") {
        this.$store.commit("layouts/addPersonalGrid", {
          name: name,
          id: lowest,
          grcode: grcode,
        });
        this.newBoard = null;
      } else {
        this.toast("Provide a valid Grid Name!", "is-danger");
        return;
      }
    },
    doDuplicate(item) {
      this.newBoard = item.name + " copy";
      console.log("duplicate");
      let ids = _.map(this.layoutsPersonal, "id");
      ids.sort(function (a, b) {
        return a - b;
      });
      let lowest = _.min(ids) - 1;
      if (lowest == 0) {
        lowest = ids[ids.length - 1] + 1;
      }
      let grcode = this.makeGRcode(10);
      this.$store.commit("layouts/duplicatePersonalGrid", {
          name: newBoard,
          id: lowest,
          grcode: grcode,
          layout: item.layout,
        });
    },
    prepareShareLayout(item) {
      //delete all image and file links from layout
      let layoutCopy = JSON.stringify(item.layout);
      layoutCopy = emoji.unemojify(layoutCopy);
      layoutCopy = JSON.parse(layoutCopy);
      let newLayout = [];
      for (let i = 0; i < layoutCopy.length; i++) {
        if (layoutCopy[i].type == 6) {
          layoutCopy[i].options.bgImage = "images/px.png";
          newLayout.push(layoutCopy[i]);
        }
        if (layoutCopy[i].type == 0) {
          layoutCopy[i].options.bgImage = "images/px.png";
          layoutCopy[i].options.icon = null;
          layoutCopy[i].options.isText = true;
          newLayout.push(layoutCopy[i]);
        }
        if (layoutCopy[i].type == 18) {
          layoutCopy[i].options.downloadCount = 0
          layoutCopy[i].options.fileName = null;
          layoutCopy[i].options.fileSize = null;
          layoutCopy[i].options.fileURL = null;
          layoutCopy[i].options.validUntil = null;
          newLayout.push(layoutCopy[i]);
        }
      }
      return JSON.stringify(newLayout);
    },
    doShare(item) {
      let newBoard = item.name + " copy";
      console.log("copying to shared grids");
      // this.$store.commit("layouts/sharePersonalGrid", item.layout);
        if(item.layout.length==0){
          this.toast("You can't share an empty grid!", "is-danger");
          return;
        }
       let layoutCopy = this.prepareShareLayout(item);
      this.axios
      .post(`sharedgrids/manage_grids.php`, {
          token: localStorage.grtoken,
          action: "addPersonalItem",
          name: this.sanitizeName(newBoard),
          personalLayout: emoji.unemojify(layoutCopy),
          position: this.$store.getters['layouts/getSharedLayoutsCount'], 
        })
        .then(response => {
          if (response.data.success) {
            console.log(response);
            this.toast(response.data.message, "is-success");
            this.$store.dispatch("layouts/loadSharedGrids");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.toast(error, "is-danger");
        });
    },
    delete(item) {
      if (this.layoutsPersonal.length > 1) {
        console.log("delete");
        let newTab = {
        id: item.id,
        name: item.name,
        type: 0,
        grcode: item.grcode,
      };
      this.$store.commit("gridtabs/removeTab", newTab);
      //do delete
      console.log("deleting page");
      this.$store.commit("layouts/deletePersonalGrid", item.id);

      } else {
        this.toast("Can't delete last grid.", "is-danger");
      }
    },
    save(grcode) {
      if (this.isBoardName == "" || this.isBoardName == null) {
        this.toast("Provide a valid Grid Name!", "is-danger");
        return;
      }
      console.log("save");
      let name = "";
      name = this.sanitizeName(this.isBoardName);
      if (name != "") {
        this.$store.commit("layouts/setPersonalGridName", {
          name: name,
          id: this.isEditingID,
        });
        if(grcode){
              let payload = {
                name: this.sanitizeName(this.isBoardName),
                grcode: grcode,
              };
              this.$store.commit("gridtabs/renameTab", payload);
        }
        this.isEditingID = null;
        this.isBoardName = null;
      } else {
        this.toast("Provide a valid Grid Name!", "is-danger");
        return;
      }
    },
    cancel() {
      console.log("cancel");
      this.isEditingID = null;
      this.isBoardName = null;
    },
    checkMove() {
      console.log("did move");
      setTimeout(() => {
        this.$store.commit("layouts/setFirstBoardCode");
      }, 1000);
      //this.showSave = true;
    },
    warningDelete(item) {
      this.$buefy.dialog.confirm({
        title: "Delete Grid",
        message:
          "Are you sure you want to <b>delete</b> this grid and all of its content? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: "trash",
        onConfirm: () => this.delete(item),
      });
    },
    warningDuplicate(item) {
      this.$buefy.dialog.confirm({
        title: "Duplicate Grid",
        message:
          "Are you sure you want to <b>duplicate</b> this grid and all of its content?",
        confirmText: "Duplicate",
        type: "is-primary",
        hasIcon: true,
        icon: "copy",
        onConfirm: () => this.doDuplicate(item),
      });
    },
    warningShare(item) {
      this.$buefy.dialog.confirm({
        title: "Share Grid",
        message:
          "Are you sure you want to <b>share</b> this grid and all of its content?",
        confirmText: "Share",
        type: "is-success",
        hasIcon: true,
        icon: "share-from-square",
        onConfirm: () => this.doShare(item),
      });
    },
  },
  computed: {
    //...mapState(["isLoged","isUser","layouts"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["layoutsPersonal"]),
    ...mapState("gridtabs", ["gridTabs"]),
    inBoards: {
      get: function () {
        return this.$store.state.layouts.layoutsPersonal;
      },
      // set: function() {
      //   return false;
      // }
      // setter
      set: function (val) {
        //this.$store.commit("layouts/updatePersonalBoards", val);
        this.$store.commit("layouts/updateBoards", { val: val, type: 0 });
        return false;
      },
    },
  },
  watch: {
    isActive: function (val) {
      if (val) {
        //general------------------------------------
        this.hasBoards = this.pages;
      }
    },
  },
};
</script>
<style scoped>
.bg-preview {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  margin-top: 10px;
}
.catbox {
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.addcatbox {
  padding: 15px;
  border: 1px dashed #dfdfdf;
  border-radius: 5px;
}

/* .scroll-wrapp{
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
} */

.level50{
  width:50%;
}

.item-name{
  width:100%;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-grids {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  scrollbar-color: #0278e8;
  scrollbar-width: 4px;
}
.scroll-grids::-webkit-scrollbar-track {
  background-color: #e9e9e9 !important;
}

.scroll-grids::-webkit-scrollbar {
  width: 4px;
}

.scroll-grids::-webkit-scrollbar-thumb {
  background: #0278e8 !important;
}

.submenu{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.submenu .button:not(:last-child){
  margin-bottom: 5px;
}
</style>
