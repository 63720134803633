<template>
  <section class="grid-settings">
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card settings-width">
        <header class="modal-card-head">
          <p class="modal-card-title">General Settings</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">


            <div class="column mr-3">
<!-- Begin Tiels -->
              <div class="separator has-text-grey-light mb-3">Tiels</div>

              <div class="theme-field">
                <b-field expanded>
                <b-select placeholder="Select Theme" v-model="gridSettings.tiles.theme" expanded>
                  <option class="optionGroup" value="classic">Classic</option>
                  <option class="optionGroup" value="pseudo3d">Pseudo3D</option>
                  <option class="optionGroup" value="neumorphism">
                    Neumorphism
                  </option>
                  <option class="optionGroup" value="glassmorphism">
                    Glassmorphism
                  </option>
                </b-select>
              </b-field>

              <b-tooltip
                position="is-bottom"
                type="is-primary"
                ><div class="tooltip-icon has-text-mydark-lighter"><i class="circle-question"></i></div>
                <template v-slot:content>
                <span class="has-text-light is-size-5"
                    >Pseudo 3D</span
                  ><br />Works best with "Titles" turend on.<br /><br />
                 <span class="has-text-light is-size-5"
                    >Neumorphism</span
                  ><br />For a better look use a plane color background<br />without wallpapers.<br /><br />
                 <span class="has-text-light is-size-5"
                    >Glassmorphism</span
                  ><br />Upload or select a vivid background image <br /> to enhance the desired effect.
 
                </template>
              </b-tooltip>
</div>

              <b-field>
                <b-switch v-model="gridSettings.tiles.title">
                  Show Titles
                </b-switch>
              </b-field>

              <b-field v-if="gridSettings.tiles.title">
                <b-switch v-model="gridSettings.tiles.pixelfont">
                  Pixel Font
                </b-switch>
              </b-field>

              <p>Rounded Corners:</p>
              <b-field>
                <b-slider size="is-medium" :min="0" :max="20" v-model="gridSettings.tiles.rounded">
                </b-slider>
              </b-field>
<!-- end Tiels -->

              <div class="separator has-text-grey-light mb-3">Background</div>
              <div class="level">
                <div class="level-left">
                  <div>Background Color:</div>
                </div>
                <div class="level-right">
                  <b-colorpicker v-model="gridBGcolor" />
                </div>
              </div>

              <b-field>
                <b-radio-button v-model="gridSettings.gridBG.type" @input="swichType('pattern')" native-value="pattern" type="is-primary" expanded>
                  <span>Pattern</span>
                </b-radio-button>
                <b-radio-button v-model="gridSettings.gridBG.type" @input="swichType('wallpaper')" native-value="wallpaper" type="is-primary" expanded>
                  <span>Wallpaper</span>
                </b-radio-button>
                <b-radio-button v-model="gridSettings.gridBG.type" @input="swichType('custom')" native-value="custom" type="is-primary" expanded>
                  <span>Custom</span>
                </b-radio-button>
              </b-field>

              <div class="bg-selector" v-if="gridSettings.gridBG.type=='pattern'">
                <b-button type="is-primary" icon-left="angle-left" @click="patternSelect('back')"/>
                <div v-if="patternID!==0">Pattern {{patternID}}</div>
                <div v-if="patternID==0">No Pattern</div>
                <b-button type="is-primary" icon-right="angle-right" @click="patternSelect('next')"/>
              </div>

              <div class="bg-selector" v-if="gridSettings.gridBG.type=='wallpaper'">
                <b-button type="is-primary" icon-left="angle-left" @click="wallpaperSelect('back')"/>
                <div v-if="wallpaperID!==0">Wallpaper {{wallpaperID}}</div>
                <div v-if="wallpaperID==0">No Wallpaper</div>
                <b-button type="is-primary" icon-right="angle-right" @click="wallpaperSelect('next')"/>
              </div>


                              <!-- <b-field>
                  <b-select placeholder="Select Pattern" v-model="gridSettings.gridBG.pattern" expanded>
                    <option class="optionGroup" :value="'/images/px.png'">
                      No pattern
                    </option>
                    <template v-for="(data, index) in wallpapers">
                      <option :value="data.file" v-bind:key="index">
                        {{ data.name }}
                      </option>
                    </template>
                  </b-select>
                </b-field> -->

              <!-- bg uploader  -->
              <div v-if="gridSettings.gridBG.type == 'custom'">
                <b-field v-if="!customBG">
                  <b-upload v-model="customBG" @input="showCustomBG()" accept="image/jpeg, image/jpg, image/png">
                    <a class="button is-primary">
                      <b-icon icon="upload"></b-icon>
                      <span>Upload Background</span>
                    </a>
                  </b-upload>
                </b-field>
                <span v-if="customBG" class="tag is-primary">
                  {{ customBG.name | cutName
                  }}<button class="delete is-small" type="button" @click="deleteBg()"></button>
                </span>
              </div>

               <div :style="backgroundStyle" class="bg-preview">
                <div class="tile-preview" :class="gridSettings.tiles.theme" ref="tile" :style="tileStyle">
                  <div class="tile-icon" :style="tileIconStyle">
                    <div class="icon"><img class="sample-icon" src="@/static/img/twitter-icon.svg" alt="" :class="['neumorphism','glassmorphism'].indexOf(gridSettings.tiles.theme) !==  -1 ? contrastDetect(gridSettings.gridBG.color) : ''"></div>
                  </div>
                  <div class="site-title" :class="gridSettings.tiles.pixelfont ? 'pixelfont' : ''" v-if="gridSettings.tiles.title">twitter.com</div>
                </div>
              </div>

              <!-- <div v-if="gridSettings.gridBG.type == 'pattern'" :style="{
                backgroundColor: gridSettings.gridBG.color,
                backgroundImage:
                  'url(/images/wallpapers/' + gridSettings.gridBG.pattern + ')',
              }" class="bg-preview">
                <div class="tile-preview" :class="gridSettings.tiles.theme" ref="tile" :style="tileStyle">
                  <div class="tile-icon" :style="tileIconStyle">
                    <div class="icon"><img class="sample-icon" src="@/static/img/twitter-icon.svg" alt="" :class="['neumorphism','glassmorphism'].indexOf(gridSettings.tiles.theme) !==  -1 ? contrastDetect(gridSettings.gridBG.color) : ''"></div>
                  </div>
                  <div class="site-title" :class="gridSettings.tiles.pixelfont ? 'pixelfont' : ''" v-if="gridSettings.tiles.title">twitter.com</div>
                </div>
              </div> -->
              <!-- <div v-if="gridSettings.gridBG.type == 'custom'" :style="{
                backgroundColor: gridSettings.gridBG.color,
                backgroundImage: 'url(' + imageBG + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }" class="bg-preview">
                <div class="tile-preview" :class="gridSettings.tiles.theme" ref="tile" :style="tileStyle">
                  <div class="tile-icon" :style="tileIconStyle">
                    <div class="icon"><img class="sample-icon" src="@/static/img/twitter-icon.svg" alt="" :class="['neumorphism','glassmorphism'].indexOf(gridSettings.tiles.theme) !==  -1 ? contrastDetect(gridSettings.gridBG.color) : ''"></div>
                  </div>
                  <div class="site-title" :class="gridSettings.tiles.pixelfont ? 'pixelfont' : ''" v-if="gridSettings.tiles.title">twitter.com</div>
                </div>
              </div> -->
            </div>

            <div class="column">
              <div class="separator has-text-grey-light mb-3">Grid</div>
              <b-field>
                <b-switch v-model="gridSettings.grid.isCollision">
                  Fixed Grid
                </b-switch>
              </b-field>
              <b-field>
                <b-switch v-model="gridSettings.grid.isVertical">
                  <sup>*</sup>Glue to Top
                </b-switch>
              </b-field>
              <b-field>
                <b-switch v-model="gridSettings.grid.isMirrored">
                  Mirrored
                </b-switch>
              </b-field>
              <p>Horizontal Cells:</p>
              <b-field>
                <b-slider size="is-medium" :min="6" :max="30" v-model="gridSettings.grid.wCell">
                </b-slider>
              </b-field>
              <p>Cell Height:</p>
              <b-field>
                <b-slider size="is-medium" :min="10" :max="60" v-model="gridSettings.grid.hCell">
                </b-slider>
              </b-field>
              <p><sup>*</sup>Cell Space:</p>
              <b-field message="* - Refresh required">
                <b-slider size="is-medium" :min="0" :max="60" v-model="gridSettings.grid.space">
                </b-slider>
              </b-field>

              <div class="separator has-text-grey-light mb-3">Other</div>
              <b-field>
                <b-switch v-model="gridSettings.general.isNewTab">
                  Open links in new tab
                </b-switch>
              </b-field>
              <b-field>
                <b-switch v-model="gridSettings.general.isAutoBackup">
                  Auto Backup Personal Grid
                </b-switch>  
              </b-field>

              <b-button type="is-primary" @click="wResetTabs()" expanded>Reset Tabs</b-button>

            </div>

            <div class="column mr-3" v-if="!detectMobileDevice()">
              <div class="separator has-text-grey-light mb-3">Sortcuts</div>

                <template v-for="sortcut in shortcutItems">
                  <b-field>
                    <b-switch v-model="gridSettings.interface[sortcut.name]">
                     <i :class="'syntho '+sortcut.icon"></i> {{sortcut.label}}
                    </b-switch>
                  </b-field>
                </template>

            </div>

          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>
          <button class="button is-primary" @click="beforeSave()">Save</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import _ from "lodash";
const wallpapers = require("@/data/wallpapers.json");
const patterns = require("@/data/patterns.json");
import { mapState } from "vuex";

import colorMixin from "@/mixins/colorMixin";
import toastMixin from "@/mixins/toastMixin";
import ismobile from "@/mixins/ismobile";

var cssroot = document.querySelector(':root');

export default {
  name: "SettingsModal",
  mixins: [colorMixin, toastMixin, ismobile],
  data() {
    return {
      isLoading: false,
      wallpaperID: 0,
      patternID: 0,
      currentSettings: null,
      wallpapers: wallpapers,
      patterns: patterns,
      dropFile: null,
      fileName: null,
      customBG: null,
      imageBG: null,
      tileBG: "#ffffff",
      gridBGcolor: "#000000",
      interfaceItems:"",
      showWarning: false,
      ignoreFirstWatch: true,
      shortcutItems: [
        {
          name: "webLink",
          modal: "addLink",
          label: "Website Link",
          icon: "link",
        },
        {
          name: "linkList",
          label: "Link List",
          icon: "list-timeline",
        },
        {
          name: "note",
          label: "Note",
          icon: "note",
        },
        {
          name: "codeNote",
          label: "Code Note",
          icon: "code",
        },
        {
          name: "todoList",
          label: "To Do List",
          icon: "clipboard-list",
        },
        {
          name: "calendar",
          label: "Calendar",
          icon: "calendar",
        },
        {
          name: "youTubeList",
          label: "YouTube List",
          icon: "youtube",
        },
        {
          name: "fileTransfer",
          label: "File Transfer",
          icon: "arrow-down-arrow-up",
        },
        {
          name: "image",
          modal: "addImage",
          label: "Image",
          icon: "image",
        },
        {
          name: "ambiance",
          label: "Ambiance",
          icon: "music",
        },
        {
          name: "clock",
          modal: "addClock",
          label: "Clock",
          icon: "clock",
        },
        {
          name: "calculator",
          label: "Calculator",
          icon: "calculator",
        },
        {
          name: "weather",
          label: "Weather",
          icon: "cloud",
        },
        {
          name: "search",
          label: "Search",
          icon: "magnifying-glass",
        },
        {
          name: "iframe",
          modal: "addFrame",
          label: "Iframe",
          icon: "object-group",
        },
        {
          name: "rss",
          modal: "addRSS",
          label: "RSS",
          icon: "square-rss",
        }
        // {
        //   name: "isDark",
        //      label: "Dark Mode",
        //   icon: "moon",
        //   type: 8,
        // },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.ignoreFirstWatch = false;
    });
  },
  methods: {
    swichType() {
      let wIndex = this.wallpapers.indexOf(this.wallpapers.find(obj=>obj.filename==this.gridSettings.gridBG.imageWallpaper));
        if(wIndex !== -1){
          this.wallpaperID = wIndex;
        }else{
          this.wallpaperID = 0;
        }
      let pIndex = this.patterns.indexOf(this.patterns.find(obj=>obj.filename==this.gridSettings.gridBG.imagePattern));
        if(pIndex !== -1){
          this.patternID = pIndex;
        }else{
          this.patternID = 0;
        }
        console.log("wIndex",wIndex);
        console.log("pIndex",pIndex);
    },
    wallpaperSelect(direction){
      if(direction == "next"){
        this.wallpaperID++;
        if(this.wallpaperID > this.wallpapers.length-1){
          this.wallpaperID = 0;
          //this.currentSettings
        }
      }else{
        this.wallpaperID--;
        if(this.wallpaperID < 0){
          this.wallpaperID = this.wallpapers.length-1;

        }
      }
      this.gridSettings.gridBG.imageWallpaper = this.wallpapers[this.wallpaperID].filename;
      this.gridSettings.gridBG.type = "wallpaper";
    },
    patternSelect(direction){
      if(direction == "next"){
        this.patternID++;
        if(this.patternID > this.patterns.length-1){
          this.patternID = 0;
          //this.currentSettings
        }
      }else{
        this.patternID--;
        if(this.patternID < 0){
          this.patternID = this.patterns.length-1;

        }
      }
      this.gridSettings.gridBG.imagePattern = this.patterns[this.patternID].filename;
      this.gridSettings.gridBG.type = "pattern";
    },
    formatter (color) {
      return color.toString('hsl');
    },
    deleteBg() {
      this.customBG = null;
      this.showCustomBG();
      //localStorage.removeItem("grbg");
    },
    beforeSave(){
      if(this.showWarning){
        this.warningSave();
      }else{
        this.checkUpload();
      }
    },
    warningSave() {
      this.$buefy.dialog.confirm({
        title: "Save Advanced Settings",
        message:
          "Some of this settings change your layout. Are you sure you wish to continue?",
        confirmText: "Save",
        type: "is-danger",
        hasIcon: true,
        icon: "triangle-exclamation",
        onConfirm: () => this.checkUpload(),
      });
    },
    checkUpload() {
      if (this.customBG) {
        if(this.gridSettings.gridBG.imageCustom){
          this.deleteImage(this.gridSettings.gridBG.imageCustom);
        }
        this.uploadFile(this.customBG, "mainbg");
      } else {
        this.saveSettings();
      }
    },
    saveSettings() {
      this.clearUpload();
      this.$store.commit("settings/saveSettings");
      this.$store.commit("modals/hideModal", "settings");
      this.currentSettings = null;
      this.showWarning = false;
    },
    showFile(index) {
      if (index) {
        return URL.createObjectURL(index);
      }
    },
    showCustomBG() {
      if (this.customBG) {
        this.imageBG = this.showFile(this.customBG);
      } else {
        this.imageBG = "";
      }
    },
    clearUpload() {
      this.customBG = null;
      this.imageBG = null;
    },
    checkFileSize() {
      //4 mb in bytes
      if(this.dropFile.size > 4 * 1024 * 1024){
        this.toast("File size must be less than 4 MB", "is-danger");
        this.dropFile = null;
        return;
      }
      if(this.dropFile.name.length > 100){
        this.toast("File name must be less than 100 characters", "is-danger");
        this.dropFile = null;
        return;
      }
      this.uploadFile(this.dropFile, "bg");
    },
    uploadFile(file, type) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("action", type);
      formData.append("token", localStorage.grtoken);

      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: "wasabi/uploadimage_aws.php",
        data: formData,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            this.gridSettings.gridBG.imageCustom = response.data.url;
            this.saveSettings();
          } else {
            this.clearUpload();
            this.isLoading = false;
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    deleteImage(fileURL) {
      // let fileURL = this.options.icon.split("/");
      // let image = fileURL[fileURL.length - 1];
      let image = fileURL.split("/").pop();
      if(image){
        //let imageId = image.split("/").pop();
        console.log("deleting image:", image);
        this.axios.post('wasabi/manage_images_aws.php', {
          token: localStorage.grtoken,
          fileName: image,
          action: "delete",
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    closeModal() {
      this.clearUpload();
      //this.gridSettings = _.clone(this.currentSettings);
      console.log(this.currentSettings);
      this.$store.commit("settings/restoreSettings", this.currentSettings);
      this.$store.commit("modals/hideModal", "settings");
      //this.$store.commit("settings/saveSettings");
    },
    //=========================================================
    resetTabs() {
      this.$store.commit("gridtabs/clearAll");
    },
    wResetTabs() {
      this.$buefy.dialog.confirm({
        title: "Reset Tabs",
        message:
          "This action will swich off all tabs. Are you sure you wish to continue?",
        confirmText: "Reset",
        type: "is-warning",
        hasIcon: true,
        icon: "triangle-exclamation",
        onConfirm: () => this.resetTabs(),
      });
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("users", ["user"]),
    //   ...mapState(["settings", "isLoged", "isUser"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.settings;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "settings");
        return false;
      },
    },
    //=========================================================
    backgroundStyle(){
      let style = {};
      style.backgroundColor = this.gridSettings.gridBG.color;
      if(this.gridSettings.gridBG.type == "pattern"){
        style.backgroundImage ='url(https://gridsup.com/resurces/patterns/' + this.gridSettings.gridBG.imagePattern + ')';
      }
      if(this.gridSettings.gridBG.type == "wallpaper"){
        style.backgroundImage = 'url(https://gridsup.com/resurces/wallpapers/' + this.gridSettings.gridBG.imageWallpaper + ')';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center';
        style.backgroundSize = 'cover';
      }
      if(this.gridSettings.gridBG.type == "custom" && !this.imageBG){
        style.backgroundImage = 'url('+this.gridSettings.gridBG.imageCustom+')';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center';
        style.backgroundSize = 'cover';
      }else if(this.gridSettings.gridBG.type == "custom" && this.imageBG){
        style.backgroundImage = 'url('+this.imageBG+')';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = 'center';
        style.backgroundSize = 'cover';
      }
      return style;
    },
    //=========================================================
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      if(this.gridSettings.tiles.theme == "pseudo3d"){
        style.backgroundColor = this.contrastVolume(this.tileBG);
        style.color = this.contrastText(style.backgroundColor);
      }
      
      if(this.gridSettings.tiles.theme == "classic"){
        style.backgroundColor = this.tileBG;
        style.color = this.contrastText(style.backgroundColor);
      }

      if(this.gridSettings.tiles.theme == "neumorphism"){
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      } 
      
      if(this.gridSettings.tiles.theme == "glassmorphism"){
        // style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      }  
      return style;
    },
    tileIconStyle(){
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      if(this.gridSettings.tiles.theme == "pseudo3d"){
        style.backgroundColor = this.tileBG;
      }else{
        style.backgroundColor = "transparent";
      }
      return style;
    },
  },
  watch: {
    gridBGcolor: function(val){
      //let getHSL = "";
      let getHSL = val.toString('hsl').slice(4, -1);
      getHSL = getHSL.split(",");
      let L = getHSL.pop();
      getHSL = getHSL.join(",");
      //.split(',').pop().join(',');
      //console.log(getHSL);
      //console.log(val);
      this.gridSettings.gridBG.color = val.toString('hex');
      cssroot.style.setProperty('--start-color', getHSL);
      cssroot.style.setProperty('--l', L);
    },
    isActive: function (val) {
      if (val) {
        this.currentSettings = _.cloneDeep(this.gridSettings);
        console.log("did clone settings");
        this.gridBGcolor = this.$store.state.settings.gridSettings.gridBG.color;
        let wIndex = this.wallpapers.indexOf(this.wallpapers.find(obj=>obj.filename==this.gridSettings.gridBG.imageWallpaper));
        if(wIndex !== -1){
          this.wallpaperID = wIndex;
        }else{
          this.wallpaperID = 0;
        }
      let pIndex = this.patterns.indexOf(this.patterns.find(obj=>obj.filename==this.gridSettings.gridBG.imagePattern));
        if(pIndex !== -1){
          this.patternID = pIndex;
        }else{
          this.patternID = 0;
        }
      }
    },
    'gridSettings.grid': {
      handler: function (val, oldVal) {
        if(!this.ignoreFirstWatch){
          this.showWarning = true;
          console.log("showWarning:", this.showWarning);
        }
      },
      deep: true
    },
  },
  filters: {
    cutName: function (value) {
      if (value.length > 17) {
        return value.substring(0, 14) + "...";
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: flex-end;
  /* flex-end; */
}

.theme-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.tooltip-icon{
  margin-top:-5px;
  font-size: 1.2em;
  cursor: pointer;
}

.bg-preview {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-preview {
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
}

.tile-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-title {
  /* padding-top:2px;
  padding-bottom:2px;  */
  font-size:12px;
  }

.pixelfont{
  font-size:8px;
  font-family: Pixel;
}

.neumorphism {
  border: 1px solid var(--item-primary);
  transition: all .3s;
  box-shadow: 6px 6px 12px var(--item-darker),
             -6px -6px 12px var(--item-lighter);
}

.neumorphism .tile-icon{
  width: 93%;
  height: 93%;
  /* background-color: var(--item-darker)!important; */
}

.glassmorphism{
  backdrop-filter: blur(4px) saturate(100%);
  -webkit-backdrop-filter: blur(6px) saturate(100%);
  background-color: var(--item-color-t);
  /* background-color: rgba(17, 25, 40, 0.46); */
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: #FFFFFF;
  box-shadow: 6px 6px 12px #00000050;

}

/* .glassmorphism .sample-icon{
filter:brightness(200%);
} */

/* .glassmorphism .sample-icon {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.neumorphism .sample-icon {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
} */

.filter-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.filter-white {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.icon{
  width:80px;
}

.bg-selector{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

/* .settings-width{
  width: 100%;
} */

@media screen and (min-width: 900px) {
  .settings-width{
    width: 900px;
  }
}
</style>
