<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Manage Boards</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <PersonalGrids />
            </div>
            <div class="column">
              <SharedGrids />
            </div>
            <div class="column">
              <IncomingGrids />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Done
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import toastMixin from "@/mixins/toastMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import { mapState } from "vuex";

import PersonalGrids from "@/components/generalModals/gridsLists/PersonalGrids.vue";
import SharedGrids from "@/components/generalModals/gridsLists/SharedGrids.vue";
import IncomingGrids from "@/components/generalModals/gridsLists/IncomingGrids.vue";

export default {
  name: "GridBoardsModal",
  components: {
    draggable,
    PersonalGrids,
    SharedGrids,
    IncomingGrids,
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "pages");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.pages;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "pages");
        return false;
      },
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: flex-end; /* flex-end; */
}
</style>
