<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">File Transfer Settings</p>
        </header>
        <section class="modal-card-body">
              <div class="columns">
                <!-- col1 -->
                <div class="column has-text-left options">
                  <div class="separator mb-2">Options</div>
   
                  <div class="mb-2">
                    <div class="level">
                      <div class="level-left">
                        <div>Background Color:</div> 
                      </div>
                      <div class="level-right">
                        <b-colorpicker v-model="options.bgColor" />
                      </div>
                    </div>
                  </div>

                </div>
                <!-- col2 -->
              </div>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="cancelEdit()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()">Save</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "editFileTransferModal",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      id: null,
      showBg: "images/px.png",
      addBg: null,
      addBgURL: null,
      options: {       
        bgColor: "#000000",      
      },
      result: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "editFileTransfer");          
    },
    cancelEdit(){
      this.closeModal();
    },
    reset() {
      this.options = {
        bgColor: "#000000",
      };
    },
    saveItem(){         
      console.log("saving image..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    }
  },
  computed: {
        ...mapState("layouts", ["layouts", "editingItemId", "isType"]),
        ...mapState("users", ["user"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  bgColor: "#000000",
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editFileTransfer;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editFileTransfer"); 
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.options{
  min-width: 250px;
}
.upload {
  width: 100% !important;
}
.upload .button {
  width: 100% !important;
} 
.preview {
  min-width: 240px;
  min-height: 240px;
  width: 240px;
  height: 240px;
  border: 2px solid #fff;
  outline: 2px solid #7957d5;
  padding: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

