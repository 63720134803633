export default {
  methods: {
    hexToHSL(H) {
      let r = 0,
      g = 0,
      b = 0;
    if (H.length === 4) {
      r = "0x" + H[1] + H[1];
      g = "0x" + H[2] + H[2];
      b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
      r = "0x" + H[1] + H[2];
      g = "0x" + H[3] + H[4];
      b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;
  
    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;
  
    h = Math.round(h * 60);
  
    if (h < 0) h += 360;
  
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
  
    //return "hsl(" + h + "," + s + "%," + l + "%)";
    return {"h":h, "s":s + "%","l":l + "%"};
      },
    RGBToHSL(r, g, b) {
      // Make r, g, and b fractions of 1
      r /= 255;
      g /= 255;
      b /= 255;

      // Find greatest and smallest channel values
      let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
      if (delta == 0)
        h = 0;
      // Red is max
      else if (cmax == r)
        h = ((g - b) / delta) % 6;
      // Green is max
      else if (cmax == g)
        h = (b - r) / delta + 2;
      // Blue is max
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      // Make negative hues positive behind 360°
      if (h < 0)
        h += 360;
      l = (cmax + cmin) / 2;

      // Calculate saturation
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

      // Multiply l and s by 100
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);

      return "hsl(" + h + "," + s + "%," + l + "%)";

    },
    convertHex(hex, opacity) {
      hex = hex.replace('#', '');
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);
      let result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
      return result;
    },
    convertRGBA(color) {
      if (color.indexOf('a') == -1) {
        return color.replace(')', ', 0.75)').replace('rgb', 'rgba');
      }
    },
    generateShade(color, amount) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    },
    detectDark(c) {
      let original = c;
      c = c.substring(1);      // strip #
      let rgb = parseInt(c, 16);   // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff;  // extract red
      let g = (rgb >> 8) & 0xff;  // extract green
      let b = (rgb >> 0) & 0xff;  // extract blue

      let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      if (luma < 40) {
        return this.generateShade(original, 80);
      } else {
        return original;
      }
    },
    contrastText(c) {
      c = c.substring(1);      // strip #
      let rgb = parseInt(c, 16);   // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff;  // extract red
      let g = (rgb >> 8) & 0xff;  // extract green
      let b = (rgb >> 0) & 0xff;  // extract blue
      let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      const textColour = (luma > 170) ? '#000000' : '#ffffff';
      return textColour;
    },

    contrastDetect(c) {
      c = c.substring(1);      // strip #
      let rgb = parseInt(c, 16);   // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff;  // extract red
      let g = (rgb >> 8) & 0xff;  // extract green
      let b = (rgb >> 0) & 0xff;  // extract blue
      let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      const classname = (luma > 170) ? 'filter-black' : 'filter-white';
      console.log(classname);
      return classname;
    },
    contrastVolume(c) {
      return this.generateShade(c, -50);
    },
    pSBC(p, c0, c1, l) {
      let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
      if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
      if (!this.pSBCr) this.pSBCr = (d) => {
        let n = d.length, x = {};
        if (n > 9) {
          [r, g, b, a] = d = d.split(","), n = d.length;
          if (n < 3 || n > 4) return null;
          x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
        } else {
          if (n == 8 || n == 6 || n < 4) return null;
          if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
          d = i(d.slice(1), 16);
          if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
          else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
        } return x
      };
      h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
      if (!f || !t) return null;
      if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
      else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
      a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
      if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
      else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
    },
    //rgbToHex(r, g, b)================================
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    //end rgb to hex===================================
    newShade(hexColor, magnitude){
      hexColor = hexColor.replace(`#`, ``);
      if (hexColor.length === 6) {
          const decimalColor = parseInt(hexColor, 16);
          let r = (decimalColor >> 16) + magnitude;
          r > 255 && (r = 255);
          r < 0 && (r = 0);
          let g = (decimalColor & 0x0000ff) + magnitude;
          g > 255 && (g = 255);
          g < 0 && (g = 0);
          let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
          b > 255 && (b = 255);
          b < 0 && (b = 0);
          return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
      } else {
          return hexColor;
      }
    },
    randomColor() {
      let colors = [
        "#C2185B",
        "#E91E63",
        "#FF4081",
        "#7B1FA2",
        "#9C27B0",
        "#512DA8",
        "#673AB7",
        "#303F9F",
        "#3F51B5",
        "#1976D2",
        "#2196F3",
        "#0288D1",
        "#0097A7",
        "#00BCD4",
        "#00796B",
        "#009688",
        "#388E3C",
        "#4CAF50",
        "#689F38",
        "#8BC34A",
        "#AFB42B",
        "#CDDC39",
        "#FBC02D",
        "#FFEB3B",
        "#FBC02D",
        "#FFA000",
        "#FFEB3B",
        "#F57C00",
        "#FF9800",
        "#E64A19",
        "#FF5722",
        "#5D4037",
        "#795548",
        "#616161",
        "#9E9E9E",
        "#455A64",
        "#607D8B",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  }
};
