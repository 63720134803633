<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Image</p>
        </header>
        <section class="modal-card-body">

              <div class="columns">
                <div class="column">
                  <div class="separator mb-2">Preview</div>
                  <div
                    class="preview"
                    :style="{
                      backgroundColor: options.bgColor,
                      backgroundImage: 'url(' + showBg + ')',
                    }"
                  >
                  </div>
                </div>
                <!-- col1 -->
                <div class="column has-text-left options">                 
                  <div class="separator mb-2">Options</div>
                  <div class="mb-2">
                    <div class="level">
                      <div class="level-left">
                        <div>Background Color:</div> 
                      </div>
                      <div class="level-right">
                        <b-colorpicker v-model="options.bgColor" />
                      </div>
                    </div>
                  </div>
             
                  <b-field v-if="!addBg" type="is-danger" :message="notLogedMessage">
                    <b-upload  @input="addBgFromUpload()" v-model="addBg" accept="image/jpeg, image/jpg, image/png" :disabled="!user.isLogged">
                      <a class="button is-primary" :disabled="!user.isLogged">
                        <b-icon icon="upload"></b-icon>
                        <span>Select Image</span>
                      </a>
                    </b-upload>
                  </b-field>
                  <span v-if="addBg" class="tag is-primary">
                    {{ addBg.name | cutName
                    }}<button
                      class="delete is-small"
                      type="button"
                      @click="deleteBg()"
                    ></button>
                  </span>
                <div class="separator mb-2" v-if="!addBg">OR</div>          
                <!-- BG from URL -->
                <b-field v-if="!addBg" label="Image Link" label-position="on-border">
                <b-input
                  v-model="addBgURL"
                  placeholder="Paste Link"
                  expanded
                ></b-input>
                <p class="control">
                  <b-button @click="addBgFromURL()" class="button is-primary">Set</b-button>
                </p>
              </b-field>
              </div>
                <!-- col2 -->
              </div>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveImage()"
            >Save Image</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "AddImageModal",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      showBg: "images/px.png",
      addBg: null,
      addBgURL: null,
      options: {
        bgColor: "#000000",
        bgImage: "images/px.png"
      },
      result: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "addImage"); 
    },
    reset() {
      this.addBg = null;
      this.addBgURL = null;
      this.options = {
        bgColor: "#000000",
        bgImage: "images/px.png",
      };
    },
    deleteBg() {
      this.addBg = null;
    },
    showFile(index) {
      if (index) {
        return URL.createObjectURL(index);
      }
    },
    addBgFromUpload(){
      this.showBg = this.showFile(this.addBg);
    },
    addBgFromURL(){
      console.log();
      this.options.bgImage = this.addBgURL;
      this.showBg = this.addBgURL;
    },
    uploadBg() {
      console.log("uploading background");
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.addBg);
      formData.append("action", "bg");
      formData.append("token", localStorage.grtoken);
      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data"
        },
        method: "post",
        url: "uploadimage.php",
        data: formData
      })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.options.bgImage = "images/px.png";
            this.options.bgImage = response.data.filename;
            this.toast(response.data.message, "is-success");
            //
          } else {
            this.options.bgImage = "images/px.png"; 
            this.result.visible = true;
            this.result.message = response.data.message;
            this.result.status = "is-danger";
            console.log(response.data);
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.result.visible = true;
          this.result.message = "Sorry, something went wrong, please try again.";
        })
        .finally(() => {
            this.saveItem();
        });
    },
    saveImage() {
      console.log("checking uploads ..");
      console.log(this.options);
      if(this.addBg){
        this.uploadBg();
      }else{
        this.saveItem();
      }
    },
    saveItem(){         
      console.log("saving site..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      //this.$store.commit("layouts/addItem", { type: 6, options: this.options });
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "addItem",
        data: { type: 6, options: this.options },
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["isType"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.addImage;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "addImage"); 
        return false;
      },
    },
    notLogedMessage(){
      if(this.user.isLogged){ 
          return "";
        } else {
          return "Login to be able to Upload Images";
        };  
    }
  },
  filters: {    
    cutName: function (value) {
      if (value.length > 17) {
        return value.substring(0, 14) + "...";
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}
.modal-card-body {
  overflow: visible !important;
}
.options{
  min-width: 250px;
}
.upload {
  width: 100% !important;
}
.upload .button {
  width: 100% !important;
} 
.preview {
  min-width: 240px;
  min-height: 240px;
  width: 240px;
  height: 240px;
  border: 2px solid #fff;
  outline: 2px solid #7957d5;
  padding: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
}
</style>

