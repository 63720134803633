<template>
  <div class="ibox" :class="gridSettings.tiles.theme" :style="tileStyle">
    <div v-if="isEditable" class="box-overlay"></div>
    <div class="box-file" v-bind:style="{ backgroundColor: options.bgColor }">
      <!-- <div class="file-icon">
          <b-icon icon="folder-arrow-up" size="is-large" />
          Ulpoad File
        </div> -->
      <div class="foat-message" v-show="message">
        {{ message }}
      </div>
      <b-field class="upload-field" v-if="!dropFile && !options.fileURL">
        <b-upload
          v-model="dropFile"
          :disabled="!user.isLogged"
          @input="checkFileSize"
          drag-drop
        >
          <section class="section">
            <div class="content has-text-centered">
              <p v-if="user.isLogged">Drop your file here or click to upload</p>
              <p v-if="!user.isLogged">Plese Login to be able to upload Files</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <div v-if="dropFile && !options.fileURL">
        <div class="mb-2">
          <span class="tag is-primary">
            {{ dropFile.name | cutName }}
            <button
              class="delete is-small"
              type="button"
              @click="clearUpload()"
            ></button>
          </span>
        </div>
        <b-button
          type="is-primary"
          size="is-small"
          @click="uploadFile()"
          :disabled="!dropFile"
          icon-left="arrow-up-from-bracket"
          expanded
          :loading="isLoading"
          >Upload</b-button
        >
      </div>

      <div v-if="options.fileURL">
        <div class="has-text-centered mb-2">
          {{ displayName }}
        </div>
        <div class="file-buttons">
          <b-tooltip type="is-danger" label="Delete">
          <b-button
            class="mr-1"
            type="is-danger"
            size="is-small"
            @click="deleteFile()"
            :disabled="!options.fileURL"
            icon-left="trash-xmark"
            :loading="isLoading"
          ></b-button>
          </b-tooltip>
          <b-tooltip type="is-success" label="Copy Link">
          <b-button
            class="mr-1"
            type="is-success"
            size="is-small"
            @click="copyLink()"
            :disabled="!options.fileURL"
            icon-left="link"
          ></b-button>
          </b-tooltip>
          <!-- <span v-if="options.fileSize">[{{options.fileSize}}]</span> -->
          <b-tooltip type="is-primary" :label="options.fileSize">
          <b-button
            @click="downloadFile()"
            type="is-primary"
            size="is-small"
            :disabled="!options.fileURL"
            icon-left="arrow-down-to-bracket"
            expanded
            >Download</b-button
          >
          </b-tooltip>
        </div>

        <div class="file-avalible">
          <div class="file-avalible-text"><b-icon icon="clock" size="is-small" /> Expires: {{ getDate() }}</div>
          <div v-if="isType>0" class="file-avalible-text"><b-icon icon="download" size="is-small" /> {{ options.downloadCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import colorMixin from "@/mixins/colorMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "FileTransferItem",
  props: {
    options: Object,
    elementID: Number,
  },
  mixins: [colorMixin, toastMixin],
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("users", ["user"]),
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      style.color = this.contrastText(this.options.bgColor);
      return style;
    },
    displayName() {
      let fileName = this.options.fileName;//this.options.fileURL.split("/?f=").pop();
      // Get the file extension
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
        
        // Remove the file extension from the file name
        const fileNameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));
        
        // Replace non-word characters (\W) and underscores (_) with hyphens (-)
        let transformedName = fileNameWithoutExtension.replace(/[\W_]+/g, '-');

        // If the transformed name is longer than 20 characters, shorten it and add ellipsis
        if (transformedName.length > 18) {
          transformedName = transformedName.slice(0, 14) + '..';
        }

        // Add the file extension back to the transformed name
        transformedName += fileExtension;

        return transformedName;

      // if (fileName.length > 20) {

      //   const parts = fileName.split(".");
      //   if (parts.length > 1) {
      //     parts[0] = parts[0].substring(0, 15) + "..";
      //     return fileName = parts.join(".");
      //   } else {
      //     return fileName.substring(0, 19) + "...";
      //   }
      // } else {
      //   return fileName;
      // }
    },
  },
  data() {
    return {
      isLoading: false,
      downloadUrl: null,
      dropFile: null,
      fileName: null,
      fileSize: "0 KB",
      validUntil: null,
      renameAttempt: 0,
      message: "",
    };
  },
  mounted() {
    if(this.options.validUntil !== null){
      let today = new Date();
      let validUntil = new Date(this.options.validUntil);
      if (today.getTime() === validUntil.getTime()) {
        // delete file
        this.deleteFile();
      }
      if (today.getTime() > validUntil.getTime()) {
        // delete file
        this.deleteFile();
      }
    }
  },
  methods: {
    getDate(){
      if(this.options.validUntil !== null){
       const valid = new Date(this.options.validUntil);
       const dateString = valid.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });
      return dateString;
    }else{
      return "Never";
    }

    },
    setValidUntil(){
      const today = new Date();
      var futureDay = today.getDate() + 6;
      today.setDate(futureDay);
      return today;
    },
    formatFileSize(file) {
      let size = file.size;

      // Convert to kilobytes
      size /= 1024;

      // Determine units
      let units;
      if (size >= 1024) {
        // Convert to megabytes
        size /= 1024;

        if (size >= 1024) {
          // Convert to gigabytes
          size /= 1024;
          units = 'GB';
        } else {
          units = 'MB';
        }
      } else {
        units = 'KB';
      }

      // Return formatted file size
      return `${size.toFixed(2)} ${units}`;
    },
    makeRandomCode(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    setErrorMessage(message) {
      this.message = message;
      setTimeout(() => {
        this.message = "";
      }, 5000);
    },
    clearUpload() {
      this.dropFile = null;
      this.fileName = null;
      this.fileSize = "0 KB";
      this.validUntil = null;
      this.isLoading = false;
      this.renameAttempt = 0;
    },
    checkFileSize() {
      if(this.user.status == 1 && this.dropFile.size > 2 * 1024 * 1024 * 1024){
        this.toast("File size must be less than 2 GB", "is-danger");
        this.setErrorMessage("File too large! (2 GB max.)");
        this.dropFile = null;
      }else if(this.user.status == 2 && this.dropFile.size > 4 * 1024 * 1024 * 1024){
        this.toast("File size must be less than 4 GB", "is-danger");
        this.setErrorMessage("File too large! (4 GB max.)");
        this.dropFile = null;
      }else if(this.user.status == 3 && this.dropFile.size > 6 * 1024 * 1024 * 1024){
        this.toast("File size must be less than 6 GB", "is-danger");
        this.setErrorMessage("File too large! (6 GB max.)");
        this.dropFile = null;
      }else{
        this.fileSize = this.formatFileSize(this.dropFile);
      }
      // if (this.dropFile.size > 2 * 1024 * 1024 * 1024) {
      //   // 2 GB in bytes
      //   this.toast("File size must be less than 2 GB", "is-danger");
      //   this.setErrorMessage("File too large! (2 GB max.)");
      //   this.dropFile = null;
      // }else{
      //   this.fileSize = this.formatFileSize(this.dropFile);
      // }
      if(this.dropFile.name.length > 100){
        this.toast("File name must be less than 100 characters", "is-danger");
        this.setErrorMessage("File name too long! (100 characters max.)");
        this.dropFile = null;
      }
    },
    // checkIfExists(fileName) {
    //   this.axios
    //     .get(`https://gridsup.s3.eu-west-1.amazonaws.com/${fileName}`)
    //     .then((response) => {
    //       let status = response.status;
    //       console.log(status);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    uploadFile() {
      if(this.user.status == 0){
        this.toast("You must subscribed to be able to upload files", "is-danger");
        return;
      }
      this.isLoading = true;
      this.fileName = this.dropFile.name;
      this.fileName = this.fileName.replace(/[^a-zA-Z0-9.]/g, "_");
      const parts = this.fileName.split(".");
      if (parts.length > 1) {
        parts[0] = parts[0] + "_" + this.makeRandomCode(10);
        this.fileName = parts.join(".");
        console.log("File has an extension.", this.fileName);
      } else {
        this.fileName = this.fileName + "_" + this.makeRandomCode(10);
        console.log("File does not have an extension.", this.fileName);
      }
      this.validUntil = this.setValidUntil();

      //axios post to get url
      this.axios
        .post("wasabi/manage_aws.php", {
          token: localStorage.grtoken,
          fileName: this.fileName,
          action: "url",
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.uploadToWasabi(response.data.url);
            //this.toast(response.data.message, "is-success");
          } else {
            this.isLoading = false;
            if (response.data.reason == "filename") {
              //rename atempt
              if (this.renameAttempt < 3) {
                this.renameAttempt++;
                this.uploadFile();
              } else {
                this.toast(response.data.message, "is-danger");
              }
            }else{
              this.toast(response.data.message, "is-danger");
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.toast("Something went wrong. Please try again.", "is-danger");
          this.isLoading = false;
        });
    },
    uploadToWasabi(uploadURL) {
      this.isLoading = true;
      const file = this.dropFile;

      this.axios
        .put(uploadURL, file, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-amz-tagging": "UID=" + this.user.email,
          },
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.setItemOptions();
          this.saveLayouts();
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.toast("Something went wrong. Please try again.", "is-danger");
        });
      // this.isLoading = true;
    },
    deleteFile() {
      let fileName = this.options.fileName;//this.options.fileURL.split("/?f=").pop();
      this.isLoading = true;

      this.axios
        .post(`wasabi/manage_aws.php`, {
          token: localStorage.grtoken,
          fileName: fileName,
          action: "delete",
        })
        .then((response) => {
          console.log(response.data);
          //console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            // this.options.fileURL = null;
            this.clearItemOptiuons();
            this.saveLayouts();
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.toast("Something went wrong. Please try again.", "is-danger");
        });
    },
    downloadFile() {
      //open urls in new tab
      this.options.downloadCount++;
      this.saveLayouts();
      window.open(this.options.fileURL, "_blank");
      //chrome
      // chrome.downloads.download({
      //   url: this.options.fileURL,
      //   filename: this.options.fileURL.split("/").pop() // Optional
      // });
      //firefox
      // browser.downloads.download({
      //   url: this.options.fileURL,
      //   filename: this.options.fileURL.split("/").pop() // Optional
      // });
    },
    copyLink() {
      var copyText = document.createElement("textarea");
      copyText.value = this.options.fileURL;
      document.body.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      copyText.remove();
    },
    setItemOptions() {
      this.options.fileURL = this.$gridVars.filesURL + encodeURIComponent(this.fileName);
      this.options.validUntil = this.validUntil;
      this.options.fileSize = this.fileSize;
      this.options.fileName = this.fileName;
    },
    clearItemOptiuons() {
      this.options.fileURL = null;
      this.options.validUntil = null;
      this.options.fileSize = null;
      this.options.fileName = null;
      this.options.downloadCount = 0;
    },
    saveLayouts() {
      console.log("saving layout...");
      if (this.isType > 0) {
        this.$store.dispatch("layouts/saveSharedElement", this.elementID);
      } else {
        this.$store.commit("layouts/updateLocal");
      }
      this.clearUpload();
    },
  },
  filters: {
    cutName: function (value) {
      if (value.length > 17) {
        return value.substring(0, 14) + "...";
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 8px;
}
.border {
  border: solid 2px rgb(48, 48, 48);
}
.box-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000050;
  z-index: 1;
  position: absolute;
}
.box-file {
  background-color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.file-icon {
  color: #fff;
  font-size: 2rem;
}

.upload-field {
  height: inherit;
}

.upload-field .upload {
  height: inherit;
}
.foat-message {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  opacity: 1;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0px;
}

.file-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-avalible {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* opacity: 0.6; */
}

.file-avalible-text{
  opacity: 0.4;
  font-size: 12px;
}
</style>

<style>
.upload-field .upload-draggable {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-field .section {
  padding: unset;
}
</style>
