export default{
  namespaced: true,
  state: {
    user: {
      isLogged: false,
      name: null,
      email: null,
      status: null,
      id: null,
    },
  },
  getters: {
    isUserEmail: (state) => {
      return state.user.email;
    }
  },
  mutations: {
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
      //local
      localStorage.grtoken = payload.jwt;
      localStorage.gremail = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
      //local
      localStorage.removeItem("grtoken");
      localStorage.removeItem("gremail");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.grtoken;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
    },
    setStatus(state, payload) {
      localStorage.removeItem("grtoken");
      localStorage.grtoken = payload.jwt;
      state.user.token = payload.jwt
      state.user.status = payload.status;
    },
    clearUserData(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
    },
  },
  actions: {
    // SET_USERDATA({commit}, payload) {
    //   console.log("action payload" + JSON.stringify(payload));
    //   commit("DO_LOGIN", true);
    //   commit("SET_USER", payload);
    //   commit("SET_TOKEN", payload);
    // },
    loginUser({commit,dispatch}, payload) {
      commit("loginUser", payload);
      dispatch("layouts/loadSharedGrids","", { root: true });
      dispatch("layouts/loadCommonGrids","", { root: true });
      dispatch("layouts/restorePersonalLayouts","", { root: true });
      commit('gridtabs/clearAll', null, { root: true });
     //commit('layouts/dropAllData', null, { root: true });
    },
    logoutUser({commit}) {
      commit("logoutUser");
      commit('layouts/dropAllData', null, { root: true });
      commit('settings/dropAllSettings', null, { root: true });
      commit('gridtabs/clearAll', null, { root: true });
    },
  },
  modules: {
  }
}
