export default{
  namespaced: true,
  state: {
    tooltip: {
      show: false,
      type: 'text',
      data: {
        text: '',
        title: '',
        url: '',
      },
    }
  },
  getters: {
  },
  mutations: {
    showTooltip(state, payload) {
      state.tooltip.show = payload.show;
      state.tooltip.data = payload.data;
      state.tooltip.type = payload.type;
      // setTimeout(() => {
      //   state.tooltip.show = false;
      // },100);
    },
    hideTooltip(state) {
      state.tooltip.show = false;
      state.tooltip.type = 'text';
      state.tooltip.data.text = "";
      state.tooltip.data.title = "";
      state.tooltip.data.url = "";
    },
  },
  actions: {
  },
  modules: {
  }
}
