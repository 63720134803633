<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card pay" style="width: 500px">
        <header class="modal-card-head">
          <div class="level" style="width: 100%">
            <div class="lefel-left">
              <div style="width: 220px" class="mt-2">
                <svg
                  version="1.1"
                  id="logo"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 312 70.9"
                  style="enable-background: new 0 0 312 70.9"
                  xml:space="preserve"
                >
                  <g id="text">
                    <path
                      fill="#FFFFFF"
                      d="M82.8,48.5c0.8,0.6,1.7,1,2.8,1.4c1.1,0.3,2.1,0.5,3.2,0.5c2,0,3.4-0.5,4.6-1.4c1-1,1.6-2.4,1.6-4.3v-2.3
                      c-0.3,0.6-0.8,1.1-1.4,1.7c-0.6,0.6-1.4,1-2.2,1.2c-0.8,0.3-1.7,0.5-2.7,0.5c-1.8,0-3.4-0.4-4.8-1.2s-2.6-2-3.4-3.5
                      c-0.8-1.5-1.2-3.2-1.2-5.1c0-1.9,0.4-3.7,1.2-5.2c0.8-1.5,1.9-2.7,3.4-3.5c1.4-0.8,3-1.2,4.8-1.2c1.4,0,2.8,0.3,3.9,1
                      s2,1.3,2.6,2.1v-2.7h3.7v17.9c0,2.3-0.5,4.1-1.4,5.5c-0.9,1.4-2.1,2.4-3.6,3c-1.5,0.6-3.1,1-4.9,1c-1.4,0-2.7-0.2-4.1-0.6
                      c-1.4-0.4-2.4-1-3.2-1.7L82.8,48.5z M88.9,29.2c-1.2,0-2.2,0.3-3.1,0.8c-0.9,0.6-1.7,1.4-2.1,2.3c-0.6,1-0.8,2.1-0.8,3.3
                      c0,1.2,0.3,2.3,0.8,3.3c0.6,1,1.2,1.7,2.1,2.3c0.9,0.6,1.9,0.8,3.1,0.8c1.2,0,2.3-0.3,3.2-0.8c0.9-0.6,1.7-1.3,2.1-2.3
                      c0.5-1,0.8-2.1,0.8-3.3c0-1.2-0.3-2.4-0.8-3.4S93,30.6,92.1,30C91.2,29.5,90.1,29.2,88.9,29.2z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M114.5,30.3c-0.3-0.1-0.6-0.2-0.9-0.3s-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.2-2.3,0.6c-0.8,0.4-1.4,1-1.9,1.7
                      c-0.5,0.8-0.8,1.7-0.8,2.6v10.5h-3.8V26.2h3.8v3.6c0.6-1.2,1.3-2.1,2.4-2.9s2.2-1.1,3.4-1.1c0.9,0,1.6,0.1,2.1,0.3L114.5,30.3z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M121,22.1c-0.8,0-1.4-0.2-1.9-0.7c-0.4-0.4-0.6-1-0.6-1.7c0-0.6,0.2-1.2,0.6-1.7c0.4-0.5,1-0.8,1.9-0.8
                      s1.4,0.2,1.9,0.7c0.4,0.4,0.6,1,0.6,1.7c0,0.6-0.2,1.2-0.6,1.7C122.5,21.9,121.9,22.1,121,22.1z M122.9,45.4h-3.7V26.2h3.7V45.4z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M146.6,18.4v27h-3.7v-2.7c-0.6,0.8-1.4,1.4-2.5,2.1c-1.1,0.6-2.3,1-3.7,1c-1.7,0-3.3-0.4-4.7-1.2
                      s-2.5-2-3.3-3.5c-0.8-1.5-1.2-3.2-1.2-5.1c0-1.9,0.4-3.7,1.2-5.2c0.8-1.5,1.9-2.7,3.2-3.5c1.4-0.8,2.9-1.2,4.6-1.2
                      c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,2,1.2,2.6,2V18.4C142.9,18.4,146.6,18.4,146.6,18.4z M137.1,42.5c1.2,0,2.2-0.3,3-0.9
                      c0.9-0.6,1.6-1.4,2.1-2.4s0.8-2.1,0.8-3.4c0-1.3-0.3-2.4-0.8-3.4c-0.5-1-1.2-1.8-2.1-2.4c-0.9-0.6-1.9-0.9-3-0.9s-2.1,0.3-3,0.9
                      c-0.9,0.6-1.6,1.4-2.1,2.4c-0.5,1-0.8,2.1-0.8,3.4c0,1.2,0.3,2.4,0.8,3.4c0.5,1,1.2,1.8,2.1,2.4C135,42.2,136,42.5,137.1,42.5z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M162.8,31.2c-0.7-0.7-1.4-1.2-2.3-1.7c-0.9-0.4-1.7-0.7-2.4-0.7c-0.5,0-1,0.1-1.5,0.2
                      c-0.5,0.1-0.9,0.3-1.2,0.7c-0.3,0.3-0.5,0.8-0.5,1.4c0,0.9,0.4,1.5,1.2,1.9s1.7,0.7,2.8,1c1,0.3,2,0.6,2.9,1c1,0.4,1.7,1,2.3,1.7
                      s0.9,1.9,0.9,3.2c0,1.2-0.3,2.2-1,3c-0.7,0.8-1.5,1.5-2.6,1.9c-1,0.5-2.1,0.7-3.3,0.7c-1.4,0-2.8-0.3-4.1-0.8
                      c-1.4-0.5-2.5-1.4-3.4-2.6l2.6-2.2c0.7,0.8,1.4,1.3,2.2,1.8c0.8,0.4,1.9,0.6,3.1,0.6c0.5,0,0.9-0.1,1.4-0.2
                      c0.5-0.1,0.8-0.4,1.2-0.8s0.5-0.8,0.5-1.4c0-0.6-0.2-1-0.6-1.4c-0.3-0.3-0.8-0.6-1.4-0.8s-1.2-0.4-1.9-0.6c-1-0.3-2.1-0.6-3.1-1.1
                      c-1-0.4-1.9-1-2.5-1.8c-0.7-0.8-1-1.9-1-3.2c0-1.2,0.3-2.1,1-3c0.6-0.8,1.5-1.4,2.6-1.9c1-0.5,2.1-0.7,3.4-0.7
                      c1.2,0,2.4,0.3,3.7,0.8s2.4,1.2,3.3,2.3L162.8,31.2z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M182.2,37.7V26.2h3.7v19.2h-3.7v-3c-0.6,0.9-1.4,1.7-2.5,2.4c-1.1,0.7-2.3,1-3.9,1c-2,0-3.6-0.7-4.8-2.1
                      c-1.2-1.4-1.9-3.2-1.9-5.7V26.2h3.7V37c0,1,0.1,1.9,0.5,2.7c0.3,0.8,0.8,1.5,1.4,2c0.7,0.5,1.5,0.8,2.6,0.8c1.3,0,2.4-0.4,3.4-1.2
                      C181.8,40.4,182.2,39.2,182.2,37.7z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M201.2,25.9c1.7,0,3.2,0.4,4.6,1.2c1.4,0.8,2.4,2,3.2,3.4c0.8,1.4,1.2,3.2,1.2,5.2c0,1.9-0.4,3.7-1.2,5.1
                      c-0.8,1.5-1.9,2.7-3.2,3.5s-2.8,1.2-4.5,1.2c-1.4,0-2.6-0.3-3.7-0.9c-1.1-0.6-2-1.2-2.6-2v10.5h-3.8V26.2h3.8V29
                      c0.6-0.8,1.4-1.5,2.5-2.1C198.6,26.2,199.9,25.9,201.2,25.9z M200.7,29.4c-1.2,0-2.1,0.3-3,0.8c-0.9,0.6-1.6,1.3-2.1,2.3
                      c-0.5,1-0.8,2.1-0.8,3.3c0,1.2,0.3,2.3,0.8,3.3c0.5,1,1.2,1.7,2.1,2.3c0.9,0.6,1.9,0.8,3,0.8c1.1,0,2.1-0.3,3-0.8
                      c0.9-0.6,1.6-1.3,2.1-2.3c0.5-1,0.8-2.1,0.8-3.3c0-1.2-0.3-2.3-0.8-3.3c-0.5-1-1.2-1.7-2.1-2.3C202.8,29.7,201.8,29.4,200.7,29.4z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M215.7,45.8c-0.8,0-1.4-0.3-1.9-0.8c-0.4-0.5-0.6-1.2-0.6-2c0-0.7,0.2-1.3,0.6-1.9c0.4-0.6,1-0.8,1.9-0.8
                      c0.8,0,1.4,0.3,1.9,0.8c0.4,0.5,0.6,1.2,0.6,2c0,0.7-0.2,1.3-0.6,1.9C217.2,45.5,216.6,45.8,215.7,45.8z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M224.9,35.8c0,1.2,0.3,2.4,0.8,3.4s1.3,1.8,2.3,2.3s1.9,0.8,3,0.8c1.2,0,2.3-0.3,3.2-1c0.9-0.6,1.7-1.3,2.2-2
                      l2.1,2.6c-0.5,0.7-1.1,1.2-1.9,1.9c-0.8,0.6-1.7,1-2.8,1.4c-1,0.3-2.1,0.6-3.2,0.6c-1.7,0-3.3-0.4-4.8-1.3
                      c-1.4-0.9-2.6-2.1-3.3-3.6c-0.8-1.5-1.2-3.2-1.2-5c0-1.9,0.4-3.6,1.2-5.1s1.9-2.7,3.3-3.6c1.4-0.9,3-1.3,4.8-1.3
                      c1.8,0,3.4,0.3,4.8,1.1c1.4,0.7,2.4,1.7,3.1,2.8l-2.1,2.6c-0.6-0.8-1.4-1.5-2.4-2.1c-1-0.6-2-0.9-3-0.9c-1.2,0-2.2,0.3-3.1,0.8
                      c-0.9,0.6-1.7,1.4-2.2,2.3C225.2,33.4,224.9,34.6,224.9,35.8z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M260.3,35.8c0,1.9-0.4,3.7-1.3,5.2c-0.9,1.5-2,2.7-3.5,3.5c-1.4,0.8-3.1,1.2-5,1.2c-1.8,0-3.4-0.4-5-1.2
                      c-1.4-0.8-2.6-2-3.5-3.5c-0.9-1.5-1.3-3.2-1.3-5.2c0-1.9,0.4-3.7,1.3-5.2c0.8-1.5,2-2.7,3.5-3.5c1.4-0.8,3.1-1.2,5-1.2
                      c1.8,0,3.4,0.4,5,1.2c1.4,0.8,2.6,2,3.5,3.5C259.9,32.1,260.3,33.9,260.3,35.8z M256.5,35.8c0-1.3-0.2-2.4-0.8-3.4
                      c-0.6-1-1.2-1.7-2.1-2.3c-0.9-0.6-1.9-0.8-3-0.8c-1.1,0-2.1,0.3-3,0.8c-0.9,0.6-1.7,1.3-2.1,2.3c-0.6,1-0.8,2.1-0.8,3.4
                      s0.2,2.4,0.8,3.4s1.2,1.7,2.1,2.3c0.9,0.6,1.9,0.8,3,0.8c1.1,0,2.1-0.3,3-0.8c0.9-0.6,1.6-1.3,2.1-2.3S256.5,37,256.5,35.8z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M274.1,25.9c1.6,0,2.8,0.3,3.8,1.1c1,0.7,1.7,1.7,2.1,3c0.3-0.6,0.8-1.2,1.5-1.9c0.7-0.6,1.4-1.1,2.3-1.5
                      c0.9-0.4,1.8-0.6,2.8-0.6c1.7,0,3,0.3,3.9,1.1c0.9,0.7,1.6,1.7,1.9,2.9c0.3,1.2,0.6,2.6,0.6,4v11.4h-3.8V34.1
                      c0-1.4-0.3-2.6-0.8-3.4c-0.6-0.9-1.5-1.3-3-1.3c-1.4,0-2.6,0.4-3.4,1.3s-1.4,1.9-1.4,3.2v11.4h-3.8V34.1c0-1.4-0.3-2.5-0.9-3.4
                      c-0.6-0.9-1.5-1.3-2.9-1.3c-0.9,0-1.7,0.2-2.5,0.6c-0.8,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.6,1.4-0.6,2.2v11.5h-3.7V26.2h3.8v3
                      c0.6-0.9,1.4-1.7,2.5-2.3C271.6,26.3,272.8,25.9,274.1,25.9z"
                    />
                  </g>
                  <path
                    fill="#FFFFFF"
                    d="M38.9,63c-15.2,0-27.6-12.4-27.6-27.6c0-13.7,10.3-25.5,24-27.3L35.8,8v7.9l-0.3,0.1
                    c-9.4,1.8-16.2,9.9-16.2,19.4c0,10.9,8.8,19.7,19.7,19.7c10.9,0,19.7-8.8,19.7-19.7c0-7-3.6-13.4-9.4-16.9v16.1h-7.2V8L42.5,8
                    c13.6,2.1,24,13.9,24,27.3c0,7.2-2.9,14.2-8.1,19.5C53.2,60.1,46.2,63,38.9,63z"
                  />
                </svg>
              </div>
            </div>
            <div class="level-right has-text-white is-size-4">Subscription</div>
          </div>

          <!-- <div class="modal-card-title">                    
            Gridsup Lifetime License
            </div> -->
        </header>
        <section class="modal-card-body">
        <div v-html="subData"></div>
        <b-loading :is-full-page="false" v-model="isLoadingInfo" :can-cancel="true"></b-loading>
        <div class="box mt-3">
          <p>If you already have purchased a <strong>Subscription</strong>, please check for a valid subscription.</p>
          <div v-if="statusCheck.visible" class="has-text-centered mt-4"><b-button @click="checkStatus()" type="is-primary is-light" size="is-small" :loading="isLoading">Check Subscribtion</b-button></div>
          <div v-if="statusCheck.result" class="mt-4">
            <b-message :type="statusCheck.status">
              {{statusCheck.message}}
            </b-message>
          </div>
        </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Close
          </button>
          <b-button
            type="is-success"
            @click="goToURL()"
            >Subscribe</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
function htmlspecialchars_decode(string, quoteStyle) {

  let optTemp = 0;
  let i = 0;
  let noquotes = false;

  if (typeof quoteStyle === "undefined") {
    quoteStyle = 2;
  }
  string = string.toString().replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  const OPTS = {
    ENT_NOQUOTES: 0,
    ENT_HTML_QUOTE_SINGLE: 1,
    ENT_HTML_QUOTE_DOUBLE: 2,
    ENT_COMPAT: 2,
    ENT_QUOTES: 3,
    ENT_IGNORE: 4,
  };
  if (quoteStyle === 0) {
    noquotes = true;
  }
  if (typeof quoteStyle !== "number") {
    // Allow for a single string or an array of string flags
    quoteStyle = [].concat(quoteStyle);
    for (i = 0; i < quoteStyle.length; i++) {
      // Resolve string input to bitwise e.g. 'PATHINFO_EXTENSION' becomes 4
      if (OPTS[quoteStyle[i]] === 0) {
        noquotes = true;
      } else if (OPTS[quoteStyle[i]]) {
        optTemp = optTemp | OPTS[quoteStyle[i]];
      }
    }
    quoteStyle = optTemp;
  }
  if (quoteStyle & OPTS.ENT_HTML_QUOTE_SINGLE) {
    // PHP doesn't currently escape if more than one 0, but it should:
    string = string.replace(/&#0*39;/g, "'");
    // This would also be useful here, but not a part of PHP:
    // string = string.replace(/&apos;|&#x0*27;/g, "'");
  }
  if (!noquotes) {
    string = string.replace(/&quot;/g, '"');
  }
  // Put this in last place to avoid escape being double-decoded
  string = string.replace(/&amp;/g, "&");

  return string;
}

import toastMixin from "@/mixins/toastMixin";
import { mapState } from "vuex";
export default {
  name: "PayModal",
  mixins: [toastMixin],
  data() {
    return {
      isLoadingInfo: false,
      isLoading: false,
      subData: "",
      statusCheck: {
        visible: true,
        message: "",
        result: false,
        status: "",
      },
      price: 10,
    };
  },
  methods: {
    loadInfo() {
      console.log("loading info!");
      this.isLoadingInfo = true;
      //begin post --------------------------------------------
      this.axios
        .get("payments/price.php")
        .then((response) => {
          this.isLoadingInfo = false;
          console.log(response.data);
          if (response.data.success) {
            this.subData = htmlspecialchars_decode(response.data.text);
          }
        })
        .catch((error) => {
          this.isLoadingInfo = false;
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    checkStatus() {
      console.log("checking status!");
      this.isLoading = true;
        //this.form.visible = false;
        //begin post --------------------------------------------
        this.axios
          .post("users/manageuser.php", {
            action: "checkStatus",
            token: localStorage.grtoken
          })
          .then((response) => {
            this.isLoading = false;
            console.log(response.data);
            this.statusCheck.message = response.data.message;
            if (response.data.success) {
              this.statusCheck.result = true;
              if (response.data.status == 0){
                this.statusCheck.status = "is-danger";
                this.statusCheck.message = "Your subscription has expired! Please renew your subscription.";
              } else {
                this.statusCheck.status = "is-success";
                this.statusCheck.message = "Your subscription is active!";                
              }
              this.$store.commit("users/setStatus", response.data);
              this.statusCheck.visible = false;
            } else {
              this.statusCheck.result = true;
              this.statusCheck.status = "is-danger";
              this.statusCheck.message = "Error checking subscription status! Try again later.";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.statusCheck.result = true;
            this.statusCheck.status = "is-danger";
            this.statusCheck.message = "Error checking subscription status! Try again later.";
            //this.hasError();
          });
        //end post-------------------------------------------------
    },
    closeModal() {
      this.statusCheck.visible = true;
      this.$store.commit("modals/hideModal", "pay");
    },
    goToURL() {
      window.location.href = "https://gridsup.com/pricing";
    },
  },
  mounted() {
    //this.loadInfo();
  },
  computed: {
    ...mapState(["isLoged"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.pay;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "pay");
        return false;
      },
    },
  },
  watch: {
    isActive: function (val) {
      if (val) {
        this.loadInfo();
        this.statusCheck.result = false;
        this.statusCheck.status = "";
        this.statusCheck.message = "";
        //this.checkStatus();
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
.list {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
}

.pay .modal-card-head {
  background: #48c78e !important;
}
</style>
