<template>
  <div
    class="ibox is-unselectable"
    :class="gridSettings.tiles.theme"
    :style="tileStyle"
    @click="goToURL(options.url)"
    @auxclick="middleClick($event)"
  >
    <!-- <div class="link-tile" :class="gridSettings.tiles.theme" :style="tileStyle"> -->
    <div class="gradient">
      <div class="tile-icon" :style="tileIconStyle">
        <div v-if="!options.isText">
          <div v-if="options.icon">
            <img
              class="site-icon"
              :class="{
                'filter-white': options.iconColor == 2,
                'filter-black': options.iconColor == 1,
              }"
              :width="options.iconSize + 'rem'"
              :src="options.icon"
            />
          </div>
        </div>
        <div v-if="options.isText">
          <div
            v-if="options.hasCustom"
            class="typeText"
            :style="{
              color: options.textColor,
              fontSize: options.textSize + 'rem',
            }"
          >
            {{ options.text | cutName }}
          </div>
          <div
            v-if="!options.hasCustom"
            class="typeInitial is-uppercase"
            :style="{
              color: options.textColor,
              fontSize: options.initSize * 1.4 + 'rem',
            }"
          >
            {{ options.title | initial }}
          </div>
        </div>
      </div>
      <div
        class="site-title"
        :class="gridSettings.tiles.pixelfont ? 'pixelfont' : ''"
        v-if="
          gridSettings.tiles.title || gridSettings.tiles.theme == 'pseudo3d'
        "
      >
        <div v-if="gridSettings.tiles.title" class="s-tit">
          {{ options.title }}
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import colorMixin from "@/mixins/colorMixin";
import ismobile from "@/mixins/ismobile";
export default {
  name: "LinkItem",
  props: {
    options: Object,
  },
  mixins: [colorMixin, ismobile],
  data() {
    return {
      hasGlow: false,
      tileBG: "#ffffff",
      gridBGcolor: this.$store.state.settings.gridSettings.gridBG.color,
      timer: null,
    };
  },
  methods: {
    goToURL() {
        let link = this.options.url;
        let urlPart = link.split(":");
        
        if (!this.isEditable) {
          if (urlPart[0] == "chrome") {
            chrome.tabs.create({ url: link }); //eslint-disable-line
          } else {
            if (this.gridSettings.general.isNewTab) {
              window.open(link, "_blank");
            } else {
              window.location.href = link;
            }
          }
        }
    },
    middleClick(event) {
      event.preventDefault();
      if (event.button === 1) {
        // let win = window.open(this.options.url, '_blank');
        // win.focus();        
        if (!this.isEditable) {
          let link = this.options.url;
          let urlPart = link.split(":");
          if (urlPart[0] == "chrome") {
            chrome.tabs.create({ url: link }); //eslint-disable-line
          } else {
            let win = window.open(link, '_blank');
            win.focus();
          }
        }
      }
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    glowColor() {
      if (this.options) {
        return this.detectDark(this.options.bgColor);
      } else {
        return "#FFFFFF";
      }
    },

    iboxStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;
      //style.backgroundImage = 'url(' + this.options.bgImage + ')';

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.contrastVolume(this.options.bgColor);
        style.color = this.contrastText(this.options.bgColor);
      } else {
        style.backgroundImage = "url(" + this.options.bgImage + ")";
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
    tileIconStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundImage = "url(" + this.options.bgImage + ")";
        style.backgroundColor = this.options.bgColor;
        style.boxShadow = "inset 0px -10px 25px -10px #ffffff1a";
      } else {
        style.backgroundColor = "transparent";
      }
      return style;
    },
  },
  filters: {
    shortName: function (val) {
      if (!val) return;
      if (val.length > 20) {
        return val.substring(0, 17) + "...";
      } else {
        return val;
      }
    },
    initial(val) {
      if (val) return val[0];
    },
    cutName: function (value) {
      if (value.length > 24) {
        return value.substring(0, 21) + "..";
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped>
.gradient {
  /* background: linear-gradient(169deg, rgba(0,0,0,0.09987745098039214) 0%, rgba(255,255,255,0) 100%); */
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ibox {
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.link-tile {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;
  background-color: #303030;
}

.tile-icon {
  /* width: 100%;
  height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  line-height: 1;
  width: 100%;
  /* box-shadow: inset 0px -10px 25px -10px #ffffff1a; */
}

.site-title {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.75em;
  /* font-weight: 600; */
  /* background-color: #303030; */
  /* color: #ffffff; */
  margin-top: auto;
  width: 100%;
  text-align: center;
  box-shadow: inset 0px -15px 20px -8px #ffffff0d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
  line-height: 16px;
}

.s-tit {
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.filter-white {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.typeText {
  font-family: RobotoBoldCondensed;
  font-weight: 700;
  text-align: center;
  word-wrap: break-word;
  /* font-weight: 600; */
}

.typeInitial {
  font-family: RobotoBlack;
  font-weight: 800;
  text-align: center;
  /* font-size: 7em; */
  /* transform: rotate(341deg) translate(-4px, 11px); */
}

.site-icon {
  margin-top: 2px;
}

.filter-white {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.filter-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}
</style>
