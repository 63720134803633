<template>
    <div ref="tooltip" class="gr-tooltip" v-show="tooltip.show">
      <div class="container" v-if="tooltip.type=='text'">
        {{tooltip.data.text}}
      </div>
      <div class="container" v-if="tooltip.type=='link'">
        <div class="site-domain">{{tooltip.data.title}}</div>
        <div class="site-title">{{tooltip.data.text}}</div>
        <div class="site-url">{{tooltip.data.url}}</div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "ToolTip",
  data() {
    return {};
  },
  mounted() {
    // on scroll
    let scrolled = window.scrollY;//window.pageYOffset
    window.onscroll = () => {
      scrolled = window.scrollY;
    };
    let tooltip = this.$refs.tooltip;
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;
    window.onmousemove = (e) => {
      let x = e.clientX,
      y = e.clientY;
      if(this.tooltip.show){
        if(y>(screenHeight-200)){
          tooltip.style.top = y - tooltip.offsetHeigh + scrolled + "px";
        }else{
          tooltip.style.top = y + 5 + scrolled + 'px';
        }
        if(x>(screenWidth-200)){
          tooltip.style.left = x - tooltip.offsetWidth + "px";
        }else{
          tooltip.style.left = x + 5 + 'px';
        }
      }
    };

    //writen by AI################################
      // let tooltip = this.$refs.tooltip;
      // let screenWidth = window.innerWidth;
      // let screenHeight = window.innerHeight;
      // window.onmousemove = (e) => {
      //   let x = e.clientX,
      //   y = window.pageYOffset;
      //   if(this.tooltip.show){
      //     if(y > screenHeight - 200){
      //       tooltip.style.top = (y - tooltip.offsetHeight - 5) + "px";
      //     }else{
      //       tooltip.style.top = (y + 5) + 'px';
      //     }
      //     if(x > screenWidth - 200){
      //       tooltip.style.left = (x - tooltip.offsetWidth - 5) + "px";
      //     }else{
      //       tooltip.style.left = (x + 5) + 'px';
      //     }
      //   }
      // };
    //###########################################
  },
  computed: {
    ...mapState("tooltip", ["tooltip"]),
  }
};
</script>

<style scoped>
.gr-tooltip {
  max-width: 250px;
  padding: 20px;
  background-color: #181d29;
  border-radius: 5px;
  text-decoration: none;
  position: absolute;
  color: #fff;
  z-index: 99;
  word-wrap: break-word;
}

.site-url {
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 1.3;
  opacity: 0.7;
}
.site-domain {
  font-weight: bold;
}
.site-title {
  font-size: 1rem;
  line-height: 1.3;
  margin-top: 5px;
}

</style>
