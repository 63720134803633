<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 300px;">
        <header class="modal-card-head">
          <p class="modal-card-title">Search Settings</p>
        </header>
        <section class="modal-card-body">
          <div class="separator mb-2">Options</div>
          <b-field>
            <b-select placeholder="Select Search Engine" v-model="options.engine" expanded>
              <template v-for="(name, index) in engineNames">
                <option
                  class="optionGroup"
                  v-bind:key="index"
                  :value="index"
                >{{ name }}</option
                >
              </template>
            </b-select>
          </b-field>
            <div class="level">
              <div class="level-left">
                <div>Background:</div>
              </div>
              <div class="level-right">
                <b-colorpicker v-model="options.bgColor" />
              </div>
            </div>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="closeModal()">Cancel</b-button>
          <b-button type="is-primary" @click="saveItem()"
            >Save</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "EditSearchModal",
  mixins: [globalMixin, toastMixin],
  data() {
    return {
      options: {    
        bgColor: "#000000",
        engine:0
      },
      engineNames: [
        "google.com",
        "duckduckgo.com",
        "bing.com",
        "yandex.com",
        "wikipedia.org",
        "you.com",
      ]
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "editSearch");
    },
    reset() {
      this.options = {
        bgColor: "#000000",
        engine:0
      };
    },
    saveItem(){         
      console.log("saving item..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
          ...mapState("layouts", ["layouts", "editingItemId", "isType"]),        
          inOptions: {
             get: function () {
              if(this.editingItemId){
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  bgColor: "#000000",
                  engine:0
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editSearch;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editSearch");
        return false;
      },
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>

