export default {
  methods: {
    toast(message, type, duration = 5000) {
      this.$buefy.toast.open({
        duration: duration,
        message: message,
        position: "is-bottom",
        type: type,
      });
    },
  },
};