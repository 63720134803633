<template>
  <div class="ibox" :class="gridSettings.tiles.theme" :style="tileStyle">
    <div class="thecalculator">
      <div class="display">{{current || '0' | cutReult}}<span class="operation">{{operationText}}</span></div>
      <div @click="clear" class="btn is-unselectable"><strong>c</strong></div>
      <div @click="sign" class="btn plusminus is-unselectable"><i class="plus"></i><i class="minus ico-margin"></i></div>
      <div @click="percent" class="btn percentage is-unselectable"><i class="percent"></i></div>
      <div @click="divide" class="btn operator is-unselectable"><i class="divide"></i></div>
      <div @click="append('7')" class="btn is-unselectable">7</div>
      <div @click="append('8')" class="btn is-unselectable">8</div>
      <div @click="append('9')" class="btn is-unselectable">9</div>
      <div @click="times" class="btn operator is-unselectable"><i class="xmark"></i></div>
      <div @click="append('4')" class="btn is-unselectable">4</div>
      <div @click="append('5')" class="btn is-unselectable">5</div>
      <div @click="append('6')" class="btn is-unselectable">6</div>
      <div @click="minus" class="btn operator is-unselectable"><i class="minus"></i></div>
      <div @click="append('1')" class="btn is-unselectable">1</div>
      <div @click="append('2')" class="btn is-unselectable">2</div>
      <div @click="append('3')" class="btn is-unselectable">3</div>
      <div @click="add" class="btn operator is-unselectable"><i class="plus"></i></div>
      <div @click="append('0')" class="btn zero is-unselectable">0</div>
      <div @click="dot" class="btn is-unselectable">.</div>
      <div @click="equal" class="btn operator is-unselectable"><i class="equals"></i></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CalcItem",
  data() {
    return {
      previous: null,
      current: '',
      operator: null,
      operatorClicked: false,
      operationText: '',
      recalcPercent: false,
    }
  },
  methods: {
    clear() {
      this.current = '';
      this.operationText = '';
      this.recalcPercent = false;
    },
    sign() {
      this.current = this.current.charAt(0) === '-' ? 
        this.current.slice(1) : `-${this.current}`;
    },
    percent() {
      console.log("percent", this.recalcPercent);
      if(this.recalcPercent){
        this.current = this.previous * (parseFloat(this.current) / 100);
      }else{
        this.current = `${parseFloat(this.current) / 100}`;
      }
    },
    append(number) {
      if (this.current == 'NaN') {
        this.current = '';
      }
      if (this.operatorClicked) {
        this.current = '';
        this.operatorClicked = false;
      }
      this.current = `${this.current}${number}`;
      this.operationText = '';
      this.equalText = '';
    },
    dot() {
      if (this.current.indexOf('.') === -1) {
        this.append('.');
      }
    },
    setPrevious() {
      this.previous = this.current;
      this.operatorClicked = true;
    },
    divide() {
      this.operator = (a, b) => b / a;
      this.setPrevious();
      this.operationText = '/';
      this.recalcPercent = true;
    },
    times() {
      this.operator = (a, b) => a * b;
      this.setPrevious();
      this.operationText = '*';
      this.recalcPercent = true;
    },
    minus() {
      this.operator = (a, b) => (b - a).toFixed(1);
      this.setPrevious();
      this.operationText = '-';
      this.recalcPercent = true;
    },
    add() {
      this.operator = (a, b) => (a + b).toFixed(1);
      this.setPrevious();
      this.operationText = '+';
      this.recalcPercent = true;
    },
    equal() {
      this.current = `${this.operator(
        parseFloat(this.current), 
        parseFloat(this.previous)
      )}`;
      this.previous = null;
      this.operationText = '';
      this.recalcPercent = false;
    },
  },
  computed: {
        ...mapState("settings", ["gridSettings"]),
        ...mapState(["isEditable"]),
        tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
  },
  filters: {
    cutReult: function(value) {
      if (value.length > 12) {
        return value.substring(0, 11) + "..";
      } else {
        return value;
      }
    }
  },
}
</script>


<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
  background-color: #333;
}
.rounded{
  border-radius: 8px;
}
.thecalculator {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(30px, auto);
}
.display {
  font-size: 2.5rem;
  grid-column: 1 / 5;
  background-color: #333;
  color: white;
  text-align: right;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
}
.zero {
  grid-column: 1 / 3;
}
.btn {
  background-color: #F2F2F2;
  border-bottom: 1px solid #beac9c;
  border-right: 1px solid #beac9c;
  text-align: center;
  font-size: 1.75vw;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  background-color: rgb(226, 226, 226);
}
.operator {
  background-color: orange;
  color: white;
  font-size: 1.3vw;
}
.operator:hover {
  background-color: rgb(216, 140, 0);
  color: white;
}
.percentage{
  font-size: 1.3vw;
}
.plusminus{
  font-size: 1vw;
}
.ico-margin{
  margin-left:5px;
}
</style>
