var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ibox is-unselectable",class:_vm.gridSettings.tiles.theme,style:(_vm.tileStyle),on:{"click":function($event){return _vm.goToURL(_vm.options.url)},"auxclick":function($event){return _vm.middleClick($event)}}},[_c('div',{staticClass:"gradient"},[_c('div',{staticClass:"tile-icon",style:(_vm.tileIconStyle)},[(!_vm.options.isText)?_c('div',[(_vm.options.icon)?_c('div',[_c('img',{staticClass:"site-icon",class:{
              'filter-white': _vm.options.iconColor == 2,
              'filter-black': _vm.options.iconColor == 1,
            },attrs:{"width":_vm.options.iconSize + 'rem',"src":_vm.options.icon}})]):_vm._e()]):_vm._e(),(_vm.options.isText)?_c('div',[(_vm.options.hasCustom)?_c('div',{staticClass:"typeText",style:({
            color: _vm.options.textColor,
            fontSize: _vm.options.textSize + 'rem',
          })},[_vm._v(" "+_vm._s(_vm._f("cutName")(_vm.options.text))+" ")]):_vm._e(),(!_vm.options.hasCustom)?_c('div',{staticClass:"typeInitial is-uppercase",style:({
            color: _vm.options.textColor,
            fontSize: _vm.options.initSize * 1.4 + 'rem',
          })},[_vm._v(" "+_vm._s(_vm._f("initial")(_vm.options.title))+" ")]):_vm._e()]):_vm._e()]),(
        _vm.gridSettings.tiles.title || _vm.gridSettings.tiles.theme == 'pseudo3d'
      )?_c('div',{staticClass:"site-title",class:_vm.gridSettings.tiles.pixelfont ? 'pixelfont' : ''},[(_vm.gridSettings.tiles.title)?_c('div',{staticClass:"s-tit"},[_vm._v(" "+_vm._s(_vm.options.title)+" ")]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }