<template>
  <div
    class="ibox is-unselectable"
    :class="gridSettings.tiles.theme"
    :style="tileStyle"
  >
    <div class="box-ambiance">
      <div class="mx-3">
        <div class="anim" :class="isPaying ? 'animate': ''">
          <div class="anim-wrap">
            <div class="anim-bars" :style="{ height: currentVolume + 'px' }">
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
          <div class="anim-bar" :style="animBarsStyle"></div>
        </div>
      </div>

      </div>
      <div>
        <b-field>
            <b-slider type='is-gruilight' v-model="currentVolume">
            </b-slider>
        </b-field>
      </div></div>
     
      <div class="list-ambiance scroll-item" :style="listStyle">
        <div>
          <template v-for="(item, index) in sounds">
            <div
              :key="'am' + index"
              class="item-ambiance"
              :style="{ borderColor: borderColor }"
            >
              <div class="item-ambiance-wrap"  :class="item.status? 'is-playing':''">
                <div class="ambiance-name" @click="togleStatus(index)" >{{ item.name }} </div>
                <div> 
                  <b-field>
                    <b-switch 
                    passive-type='is-gruidark'
                    type='is-gruilight'
                    size="is-small" :value="item.status" @input="togleStatus(index)"></b-switch>
                  </b-field>
                </div>
              </div>
            </div>
          </template>

          <!-- <template v-for="(item, index) in sounds">
            <div
              :key="'am' + index"
              class="item-ambiance"
              :style="{ borderColor: borderColor }"
              v-if="!item.status"
            >
              <div class="item-ambiance-wrap" :class="item.status? 'is-playing':''">
                <div @click="togleStatus(index)" class="ambiance-name">{{ item.name }} </div>
                <div>                   
                  <b-field>
                    <b-switch @click="togleStatus(index)" :value="item.status"></b-switch>
                  </b-field>
                </div>
              </div>
            </div>
          </template> -->

        </div>
        <div class="list-end"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import colorMixin from "@/mixins/colorMixin";
export default {
  name: "AmbianceItem",
  props: {
    options: Object,
  },
  mixins: [colorMixin],
  data() {
    return {
      tempVolume: 50,
    };
  },
  mounted() {
    this.$store.commit("ambiance/setHowler");
  },
  methods: {
    togleStatus(index){
      //item.status = !item.status
      console.log("changig status sound.."+index);
      if(this.sounds[index].status){
        this.sounds[index].sound.stop();
        //this.sounds[index].status = false;
        this.$store.commit("ambiance/setSounds", {index: index, status: false});
      }else{
        this.sounds[index].sound.play('loop');
        //this.sounds[index].status = true;
        this.$store.commit("ambiance/setSounds", {index: index, status: true});
      }
    },
    // setVolume(){
    //   console.log("set volume.."+this.volume/100);
    //   this.$store.commit("ambiance/setVolume", this.volume/100);
    // },
    saveLayouts() {
      console.log("saving layout...");
      if (this.isType > 0) {
        this.$store.dispatch("layouts/saveSharedGrid");
      } else {
        this.$store.commit("layouts/updateLocal");
      }
    },
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState("layouts", ["isType"]),
    ...mapState(["isEditable"]),
    ...mapState("ambiance", ["sounds", "isPaying"]),
    currentVolume: {
      // getter
      get() {
        return this.$store.getters['ambiance/getVolume'] * 100;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.$store.commit("ambiance/setVolume", newValue/100);
      }
    },
    isPaying () {
      return this.$store.getters['ambiance/getIsPlayng'];
    },
    borderColor() {
      return this.convertHex(this.options.textColor, 10);
    },
    tileStyle() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";

      //style.backgroundColor = this.options.bgColor;

      if (this.gridSettings.tiles.theme == "pseudo3d") {
        style.backgroundColor = this.options.bgColor;
        //style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "classic") {
        //style.backgroundColor = this.tileBG;
        style.backgroundColor = this.options.bgColor;
        // style.color = this.contrastText(this.options.bgColor);
      }

      if (this.gridSettings.tiles.theme == "neumorphism") {
        style.backgroundColor = this.gridSettings.gridBG.color;
        style.border = "1px solid " + this.gridSettings.gridBG.color;
        // style.color = this.contrastText(this.gridSettings.gridBG.color);
      }

      if (this.gridSettings.tiles.theme == "glassmorphism") {
        //style.backgroundColor = this.gridSettings.gridBG.color;
        // style.border = "1px solid " + this.gridSettings.gridBG.color;
        //style.color = this.contrastText(this.gridSettings.gridBG.color);
      }
      return style;
    },
    listStyle(){
      let style = {};
      if (this.gridSettings.tiles.theme !== "neumorphism") {
        style.backgroundColor = "#0000002f";
      }
      style.color = this.options.textColor; 
      return style;
    },

    animBarsStyle(){
      let style = {};
      style.backgroundColor = this.options.textColor; 
      return style;
    },
  },
};
</script>

<style scoped>
.ibox {
  height: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 8px;
}
.border {
  border: solid 2px rgb(48, 48, 48);
}

.box-ambiance {
  height: 100%;
}
.list-ambiance {
  padding: 10px 10px 15px 10px;
  font-weight: 300;
  height: calc(100% - 100px);
}

.item-ambiance {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #222;
  position: relative;
  cursor: pointer;
}

.item-ambiance-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ambiance-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}
/* .item-todo:hover .text-todo{
margin-left:40px;
} */

.scroll-item {
  overflow: auto;
}

@-moz-document url-prefix() {
  .scroll-moz {
    overflow-y: scroll;
    scrollbar-color: #979797 rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
}

.scroll-item::-webkit-scrollbar {
  width: 4px;
}

.scroll-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-item::-webkit-scrollbar-thumb {
  background: #979797;
}

.is-playing{
  font-weight: bold;
}

/*css animation*/
.anim{

}

.anim-wrap{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.anim-bars{
  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.anim .anim-wrap .anim-bar{
  width: 3px;
  height: 30%;
  background: rgba(255, 255, 255);
  opacity: 0.5;
}

.animate .anim-wrap .anim-bar{
  animation: anim 2s linear infinite;
}

.anim-wrap .anim-bar:nth-child(1){
  animation-delay: 0.25s;
}
.anim-wrap .anim-bar:nth-child(2){
  animation-delay: 0.5s;
}
.anim-wrap .anim-bar:nth-child(3){
  animation-delay: 0.75s;
}
.anim-wrap .anim-bar:nth-child(4){
  animation-delay: 1s;
}
.anim-wrap .anim-bar:nth-child(5){
  animation-delay: 1.25s;
}
.anim-wrap .anim-bar:nth-child(6){
  animation-delay: 1.5s;
}
.anim-wrap .anim-bar:nth-child(7){
  animation-delay: 1.75s;
}
.anim-wrap .anim-bar:nth-child(8){
  animation-delay: 2s;
}
.anim-wrap .anim-bar:nth-child(9){
  animation-delay: 2.25s;
}
.anim-wrap .anim-bar:nth-child(10){
  animation-delay: 2.5s;
}
.anim-wrap .anim-bar:nth-child(11){
  animation-delay: 2.75s;
}
.anim-wrap .anim-bar:nth-child(12){
  animation-delay: 3s;
}
.anim-wrap .anim-bar:nth-child(13){
  animation-delay: 3.25s;
}
.anim-wrap .anim-bar:nth-child(14){
  animation-delay: 3.5s;
}
.anim-wrap .anim-bar:nth-child(15){
  animation-delay: 3.75s;
}
.anim-wrap .anim-bar:nth-child(16){
  animation-delay: 4s;
}
.anim-wrap .anim-bar:nth-child(17){
  animation-delay: 4.25s;
}
.anim-wrap .anim-bar:nth-child(18){
  animation-delay: 4.5s;
}
.anim-wrap .anim-bar:nth-child(19){
  animation-delay: 4.75s;
}
.anim-wrap .anim-bar:nth-child(20){
  animation-delay: 5s;
}
.anim-wrap .anim-bar:nth-child(21){
  animation-delay: 5.25s;
}
.anim-wrap .anim-bar:nth-child(22){
  animation-delay: 5.5s;
}
.anim-wrap .anim-bar:nth-child(23){
  animation-delay: 5.75s;
}
.anim-wrap .anim-bar:nth-child(24){
  animation-delay: 6s;
}


@keyframes anim{
  0%{
    transform: scaleY(1);
  }
  10%{
    transform: scaleY(.2);
  }
  20%{
    transform: scaleY(1);
  }
  30%{
    transform: scaleY(.2);
  }
  40%{
    transform: scaleY(1.5);
  }
  50%{
    transform: scaleY(.2);
  }
  60%{
    transform: scaleY(1);
  }
  70%{
    transform: scaleY(1.8);
  }
  80%{
    transform: scaleY(1);
  }
  100%{
    transform: scaleY(1);
  }
}
</style>
