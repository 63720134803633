<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Link Settings</p>
        </header>
        <section class="modal-card-body">
          <section>
            <div @keyup.enter.exact="addURL()">
              <b-field :type="result.status">
                <p class="control">
                  <span class="button is-static">Link:</span>
                </p>
                <b-input
                  v-model="result.data"
                  expanded
                ></b-input>
              </b-field>
              <b-field
                :message="result.message"
                :type="result.status"
              ></b-field>
            </div>
          </section>
          <transition-expand>
            <section>
              <b-loading
                :is-full-page="false"
                :active.sync="isLoading"
                :can-cancel="true"
              ></b-loading>
              <b-field>
                <p class="control">
                  <span class="button is-static">Title:</span>
                </p>
                <b-input
                  v-model="options.title"
                  expanded
                ></b-input>
              </b-field>
              <div class="columns">
                <div class="column">
                  <div class="separator mb-2">Preview</div>
                  <div
                    class="option optionText"
                    :style="{
                      backgroundColor: options.bgColor,
                      backgroundImage: 'url(' + showBg+ ')',
                    }"
                  >
                    <div v-if="!options.isText">
                      <div>
                        <img
                          :class="{'filter-white': options.iconColor==2,'filter-black': options.iconColor==1}"
                          :width="options.iconSize + 'rem'"
                          :src="showIcon"
                        />
                      </div>
                    </div>
                    <div v-if="options.isText">
                      <div
                        v-if="options.hasCustom"
                        class="typeText"
                        :style="{
                          color: options.textColor,
                          fontSize: options.textSize + 'rem',
                        }"
                      >
                        {{ options.text | cutName }}
                      </div>
                      <div
                        v-if="!options.hasCustom"
                        class="typeInitial is-uppercase"
                        :style="{
                          color: options.textColor,
                          fontSize: options.initSize + 'rem',
                        }"
                      >
                        {{ options.title | initial }}
                      </div>
                    </div>
                  </div>
                  <div class="site-title is-unselectable">
                    {{options.title | cutName}}
                  </div>
                </div>
                <!-- col1 -->
                <div class="column has-text-left options">
                  <div class="separator mb-2">Options</div>
                  <div class="field">
                    <b-switch v-model="options.isText">Show Text</b-switch>
                  </div>
                  <div v-if="options.isText" class="field">
                    <b-switch v-model="options.hasCustom"
                      >Use Custom Text</b-switch
                    >
                  </div>

                  <div
                    v-if="options.hasCustom && options.isText"
                    class="mb-3"
                  >
                    <p>Custom Text:</p>
                    <b-field>
                      <b-input v-model="options.text" expanded></b-input>
                      <p class="control">
                        <EmojiPicker @emoji="gotEmoji"/>
                      </p>
                    </b-field>
                  </div>
                  <p>Text/Icon Size:</p>
                  <b-field>
                    <b-slider
                      @input="reSize()"
                      v-model="options.sizeValue"
                      :min="0.5"
                      :max="20"
                      :step="0.1"                      
                    ></b-slider>
                  </b-field>

                  <div class="mb-2" v-if="options.isText">
                    <div class="level">
                      <div class="level-left">
                        <div>Text Color:</div>
                      </div>
                      <div class="level-right">
                        <b-colorpicker v-model="options.textColor" />
                      </div>
                    </div>
                  </div>

                  <div class="mb-2" v-if="!options.isText">
                    <div class="level">
                      <div class="level-left">
                        <div>Icon Filter:</div>
                      </div>
                      <div class="level-right">
                        <b-field>
                          <b-select v-model="options.iconColor">
                            <option value="0">None</option>
                            <option value="1">Black</option>
                            <option value="2">White</option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2">
                    <div class="level">
                      <div class="level-left">
                        <div>Background:</div>
                      </div>
                      <div class="level-right">
                        <b-colorpicker v-model="options.bgColor" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- col2 -->
              </div>

              

                <div v-if="!user.isLogged" class="uploadOverlay">
                  <p>Please Login to be able to upload images and icons.</p><br/>
                  <b-button @click="notLoged()" type="is-primary">Login</b-button>
                </div>

                <div v-if="user.isLogged" class="uploadContainer">

                <div class="separator mb-3">Uploads</div>

                <div class="columns">
                  
                <div v-if="options.bgImage!=='images/px.png'" class="column has-text-centered">
                  <b-button @click="warningDeleteBg()" type="is-danger" icon-left="xmark">Remove Backround</b-button>
                </div>
  
                <div v-if="options.bgImage=='images/px.png'" class="column has-text-centered">
                    <b-field v-if="!addBg">
                      <b-upload  @input="addBgFromUpload()" v-model="addBg" accept="image/jpeg, image/jpg, image/png, image/gif, image/webp">
                        <a class="button is-primary">
                          <b-icon icon="arrow-up-from-bracket"></b-icon>
                          <span>Upload Background</span>
                        </a>
                      </b-upload>
                    </b-field>
                    <span v-if="addBg" class="tag is-primary">
                      {{ addBg.name | cutName
                      }}<button
                        class="delete is-small"
                        type="button"
                        @click="deleteBg()"
                      ></button>
                    </span>

                  </div>
  
  
  
                <div v-if="options.icon" class="column has-text-centered">
                  <b-button @click="warningDeleteIcon()"  type="is-danger" icon-left="xmark">Remove Icon</b-button>
                </div>
  
                  <div v-if="!options.icon" class="column has-text-centered">
                    <b-field v-if="!addIcon">
                      <b-upload @input="addIconFromUpload()"
                        v-model="addIcon"
                        accept=".png,.svg"
                      >
                        <a class="button is-primary">
                          <b-icon icon="arrow-up-from-bracket"></b-icon>
                          <span>Upload Icon</span>
                        </a>
                      </b-upload>
                    </b-field>
  
                    <span v-if="addIcon" class="tag is-primary">
                      {{ addIcon.name | cutName
                      }}<button
                        class="delete is-small"
                        type="button"
                        @click="deleteIcon()"
                      ></button>
                    </span>

                  </div>
                </div>
              </div>
            </section>
          </transition-expand>
        </section>
        <footer class="modal-card-foot">
          <b-button type="button" @click="cancelEdit()">Cancel</b-button>
          <b-button type="is-primary" @click="checkUploadStatus()"
            >Save Link</b-button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import globalMixin from "@/mixins/globalMixin";
import sanitizeMixin from "@/mixins/sanitizeMixin";
import urlMixin from "@/mixins/urlMixin";
import toastMixin from "@/mixins/toastMixin";
import TransitionExpand from "@/components/TransitionExpand.vue";
//import { EmojiPicker } from 'vue-emoji-picker';
import EmojiPicker from "@/components/EmojiPicker.vue";
import randomMixin from "@/mixins/randomMixin";
export default {
  name: "editLinkModal",
  components: {
    TransitionExpand,
    EmojiPicker
  },
  mixins: [globalMixin, sanitizeMixin, urlMixin, toastMixin, randomMixin],
  data() {
    return {
      isLoading: false,
      id: null,
      didAdd: true,
      showBg: "images/px.png",
      showIcon: null,
      addIcon: null,
      addBg: null,
      addIconURL: null,
      addBgURL: null,
      options: {
        isText: true,
        hasCustom: false,
        text: null,
        sizeValue: 3,
        initSize: 3,
        textSize: 3,
        iconSize: 4,
        textColor: "#ffffff",
        bgColor: "#000000",
        iconColor: 0,
        bgImage: "images/px.png",
        icon: null,
        url: null,
        title: null,
      },
      result: {
        data: "",
        status: null,
        message: null,
        visible: true,
      },
    };
  },
  methods: {
    gotEmoji(emoji) {
      this.options.text = this.options.text + emoji;
    },
    reSize() {
      this.options.initSize = this.options.sizeValue;// * 25;
      this.options.textSize = this.options.sizeValue;// + 15;
      this.options.iconSize = this.options.sizeValue * 10;
    },
    closeModal() {
      this.$store.commit("modals/hideModal", "editLink");
    },
    notLoged(){
      this.closeModal();
      this.showLogin();
    },
    cancelEdit(){
      //this.options = this.tempOptions;
      this.closeModal();
    },
    reset() {
      //this.didAdd = false;
      this.options = {
        isText: true,
        hasCustom: false,
        text: null,
        sizeValue: 3,
        initSize: 3,
        textSize: 3,
        iconSize: 4,
        textColor: "#ffffff",
        bgColor: "#000000",
        iconColor: 0,
        bgImage: "images/px.png",
        icon: null,
        //url: null,
        title: null,
      };
    },
    deleteIcon() {
      this.addIcon = null;
      this.options.isText = true;
    },
    deleteBg() {
      this.addBg = null;
    },
    warningDeleteIcon() {
      this.$buefy.dialog.confirm({
        title: "Deleting Icon",
        message:
          "Are you sure you want to remove icon?",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        icon: "trash",
        onConfirm: () => this.deleteUploadedIcon()
      });
    },
    deleteUploadedIcon() {
      let fileURL = this.options.icon.split("/");
      let detected = fileURL.includes("usericons");
      let image = fileURL[fileURL.length - 1];
      if(!detected){
        console.log("Deleting file from server:" + image);
        this.deleteImage(image);
      }
      this.addIcon = null;
      this.options.isText = true;
      this.options.icon = null;
      this.showImages();
    },
    warningDeleteBg() {
      this.$buefy.dialog.confirm({
        title: "Deleting Background",
        message:
          "Are you sure you want to remove background?",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        icon: "trash",
        onConfirm: () => this.deleteUploadedBg()
      });
    },
    deleteUploadedBg() {
      let fileURL = this.options.bgImage.split("/");
      let image = fileURL[fileURL.length - 1];
      this.deleteImage(image);
      this.addBg = null;
      this.options.bgImage = "images/px.png";
      this.showImages();
    },
    showFile(index) {
      if (index) {
        return URL.createObjectURL(index);
      }
    },
    addBgFromUpload(){
      this.showBg = this.showFile(this.addBg);
    },
    addIconFromUpload(){
      this.options.isText = false;
      this.showIcon = this.showFile(this.addIcon);
    },
    addBgFromURL(){
      console.log();
      this.options.bgImage = this.addBgURL;
      this.showBg = this.addBgURL;
    },
    showImages(){
      console.log("loading images...");
      this.showBg = this.options.bgImage;
      this.showIcon = this.options.icon;
    },
    addURL() {
      console.log("adding url...");
      if (this.formCheck()) {
        let cleanURL = this.extractHostname(this.result.data);
        console.log(cleanURL);
        if(cleanURL=="localhost"){
          //this.didAdd = true;
          this.options.url = this.prepareURL(this.result.data); //this.prepareURL(this.result.data);
          this.options.title = cleanURL;
          this.options.text = cleanURL;
          console.log("good URL");
        }else if(this.urlCheck(cleanURL)) {
          //this.didAdd = true;
          this.options.url = this.prepareURL(this.result.data); //this.prepareURL(this.result.data);
          this.options.title = cleanURL;
          this.options.text = cleanURL;
          console.log("good URL");
        }
      }
    },
    formCheck() {
      if (!this.result.data) {
        this.result.status = "is-danger";
        this.result.message = "Please enter a valid URL";
        return false;
      } else {
        return true;
      }
    },
    urlCheck(e) {
      if (!this.validURL(e)) {
        this.result.status = "is-danger";
        this.result.message = "Please enter a valid URL";
        return false;
      } else {
        this.result.status = null;
        this.result.status = "is-success";
        this.result.message = "";
        return true;
      }
    },
    uploadFile(file, type) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("action", type);
      formData.append("token", localStorage.grtoken);

      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: "wasabi/uploadimage_aws.php",
        data: formData,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            if(type == "bg"){
              this.addBg = null;
              this.options.bgImage = response.data.url;
            }
            if(type == "icon"){ 
              this.options.icon = response.data.url;
              this.addIcon = null;
            }
          } else {
            if(type == "bg"){
              this.addBg = null;
            }
            if(type == "icon"){ 
              this.addIcon = null;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        })
        .finally(() => {
          this.checkUploadStatus();
        });
    },
    deleteImage(image) {
      console.log("deleteing!" + image);
      this.isLoading = true; 
      if(image){
        //let imageId = image.split("/").pop();
        console.log("deleting image:", image);
        this.axios.post('wasabi/manage_images_aws.php', {
          token: localStorage.grtoken,
          fileName: image,
          action: "delete",
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.saveAfterDeletedImage();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
      }
    },
    checkUploadStatus() {
      //console.log(this.options);
      this.isLoading = true;
      console.log("checking uploads ..");
      if (this.addBg) {
        this.uploadFile(this.addBg, "bg");
      } else if (this.addIcon) {
        this.uploadFile(this.addIcon, "icon");
      } else {
        this.saveItem();
      }
    },
    saveAfterDeletedImage(){         
      console.log("saving link after an image deletion..");
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Updated", "is-success");
      }).catch((err) => {
        console.log(err);
      });
    },
    saveItem(){         
      console.log("saving site..");
      this.options.bgColor = this.options.bgColor.toString('hex');
      this.options.textColor = this.options.textColor.toString('hex');
      if(this.options.text){
        this.options.text = this.sanitizeQuotes(this.options.text);
      }
      if(this.options.title){
        this.options.title = this.sanitizeQuotes(this.options.title);
      }
      //this.$store.commit("layouts/saveItem", {id: this.editingItemId, options: this.options});
      //this.toast("Item Saved", "is-success");
      //this.closeModal();
      this.$store.dispatch("layouts/gridAction", {
        action: "saveItem",
        data: {id: this.editingItemId, options: this.options},
      }).then(() => {
        this.isLoading = false;
        if(this.isType > 0){
          this.$store.dispatch("layouts/saveSharedGrid");
        }
        this.toast("Item Saved", "is-success");
        //this.reset();
        this.closeModal();
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
    //...mapState(["isDark", "isLoged", "editingItemId", "layout"]),
    //...mapState(["isLoged", "editingItemId", "layout"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["layouts", "isBoard", "editingItemId", "isType"]),
          inOptions: {
             get: function () {
              if(this.editingItemId){
                console.log("getting options");
                return this.$store.getters["layouts/getById"](this.editingItemId).options;
              }else{
                return {
                  isText: true,
                  hasCustom: false,
                  text: null,
                  sizeValue: 3,
                  initSize: 3,
                  textSize: 3,
                  iconSize: 4,
                  textColor: "#ffffff",
                  bgColor: "#000000",
                  iconColor: 0,
                  bgImage: "images/px.png",
                  icon: null,
                  url: null,
                  title: null,
                };
              }
             },
                 //   // setter
            set: function () {
              return false;
            },
          },
    isActive: {
      get: function () {
        return this.$store.state.modals.editLink;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "editLink");
        return false;
      },
    },
  },
  filters: {
    shortName: function(val){
      if(!val) return;
      if(val.length>20){
        return val.substring(0,17)+"...";
      }else{
        return val;
      }
    },
    initial(val) {
      if (val) return val[0];
    },
    cutName: function (value) {
      if(!value) return;
      if (value.length > 17) {
        return value.substring(0, 14) + "...";
      } else {
        return value;
      }
    },
  },
  watch: {
    isActive: function (value) { 
      if(value){
        this.options = { ...this.inOptions};
        this.showImages();
        this.result.data = this.options.url;
        //this.didAdd = true;
      }
     }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
  position: unset;
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}
.modal-card-body {
  overflow: visible !important;
}
.options{
  min-width: 250px;
}
.uploadOverlay{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload {
  width: 100% !important;
}
.upload .button {
  width: 100% !important;
} 
.bt-list {
  flex-direction: column;
}
.optionName {
  font-size: 12px;
  text-align: center;
}
.siteName {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  background-color: rgb(48, 48, 48);
  color: #cacaca;
  margin-top: auto;
  width: 100%;
}
.option {
  width: 240px;
  height: 220px;
  border: 2px solid #fff;
  outline: 2px solid #00bb8f;
  padding: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  overflow: hidden;
}
.optionText {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nopad {
  padding: 0;
}
.nomar {
  margin: 0;
}
.typeText {
  text-align: center;
  /* font-weight: 600; */
}
.typeInitial {
  text-align: center;
  font-weight: bold;
}

.site-title {
  padding-top:5px;
  padding-bottom:5px; 
  font-size:12px;
  background-color: rgb(48, 48, 48);
  color: #cacaca;
  margin-top: auto;
  width: 100%;
  text-align: center;
}

.filter-white {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.filter-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}
</style>

