<template>
    <div class="connection-status">
      <div v-if="isType>0" class="ws">
        <!-- <b-tooltip :type="wsTooltipType()" :label="wsTooltip()" position="is-right" size="is-small"><div @click="connectWS()" class="ws-connection" :class="isWSConnected()"></div></b-tooltip> -->
        <b-button size="is-small" @click="connectWS()" class="ws-bt" :class="isWSConnected()">
          {{wsTooltip()}}
          <b-tooltip type="is-dark" position="is-bottom" multilined v-if="wsStatus && onlineUsersCount">
            <b-icon class="connected-users-icon" icon="user"></b-icon>{{onlineUsersCount}}
            <template v-slot:content>
                {{ onlineUsers }}
            </template>
          </b-tooltip>
        </b-button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import toastMixin from "@/mixins/toastMixin";
export default {
  name: "ConnectionStatus",
  mixins: [toastMixin],
  data() {
    return {
    }
  },
  computed: {
    ...mapState("layouts", ["layouts","pages","isBoard", "isType", "isBoardCode"]),
    ...mapState("ws", ["wsStatus", "onlineUsers"]),
    ...mapState("users", ["user"]),
    ...mapState(["isEditable", "isLoadingBoard"]),
    onlineUsersCount(){
      return this.$store.getters["ws/getOnlineUsersCount"];
    },
    onlineUsers(){
      let onlineUsers = this.$store.getters["ws/getOnlineUsers"];
      if(onlineUsers){
        return onlineUsers.join(" ");
      }
    },
  },
  methods: {
    connectWS(){
      if(!this.wsStatus && !this.isLoadingBoard){
        //this.$store.dispatch('ws/connectWS', {"grcode":this.isGRcode, "type": "getLayout"});
    
        console.log("trying to connect WS...");

        let isType = this.$store.getters['layouts/getCurrentType'];
        let isGRcode = this.$store.getters['layouts/getCurrentGRcode'];

        console.log("isType:", isType);
        console.log("isGRcode:", isGRcode);
        if(isType > 0){
          this.$store.dispatch('ws/connectWS', {"grcode":isGRcode, "type": "getLayout", "email":this.user.email});
        }
        //this.$store.dispatch("ws/connectWS");
      }
    },
    isWSConnected(){
        let style = "";
        if(this.isLoadingBoard){
          style = "ws-blue";
        }else{
          if(this.wsStatus){
            style = "ws-green";
          }else{
            style = "ws-red";
          }
        }
        return style;
      },
      wsTooltip(){
        let text = "";
        if(this.isLoadingBoard){
          text = "Connecting...";
        }else{
          if(this.wsStatus){
            text = "Online";
          }else{
            text = "Connect?";
          }
        }
        return text;
      },
      wsTooltipType(){
        let type = "";
        if(this.isLoadingBoard){
          type = "is-primary";
        }else{
          if(this.wsStatus){
            type = "is-success";
          }else{
            type = "is-danger";
          }
        }
        return type;
      },
  },
};
</script>

<style scoped>

.connection-status{
  float: right;
  margin-left: 10px;
}

.connected-users{
  margin-left: 5px;
}
.connected-users-icon{
  margin-left: 10px!important;
  margin-right: 1px!important;
  color:#fff;
}
.ws{
  display: flex;
  align-items: center;
  /* height: 36px;
  width: 26px; */
}
.ws-connection{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.ws-red{
  background-color: #c40000;
}

.ws-green{
  background-color: #00ad00;
}
.ws-blue{
  background-color: #008cff;
}

.ws-red {
  animation: pulse-animation 2s infinite;
}

.ws-blue {
  animation: pulse-animation-blue 2s infinite !important;
}

.ws-red:hover {
  animation: unset;
}

.ws-bt{
  border: none;
  color: #fff;
}
.ws-bt:hover{
  color: #fff;
}

.ws-bt:active{
  color: #fff;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #c40000;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-animation-blue {
  0% {
    box-shadow: 0 0 0 0px #008cff;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@keyframes border-pulse-animation-blue {
  0% {
    border-bottom:#008cff 4px solid;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  50% {
    border-bottom:#008cff00 4px solid;
  }
  100% {
    border-bottom:#008cff 4px solid;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
}
</style>
