
import _ from "lodash";

export default{
  namespaced: true,
  state: {
    gridTabs:[],
  },
  getters: {
    isPresent: (state) => (grcode) => {
      return state.gridTabs.some((item) => item.grcode === grcode);
    }
  },
  mutations: {
    toggleTab(state, payload) {
      let index = _.findIndex(state.gridTabs, function(o) { return o.grcode == payload.grcode; });
      if(index != -1){
        state.gridTabs.splice(index, 1);
      }else{
        state.gridTabs.push(payload);
      }
      localStorage.grtabs = JSON.stringify(state.gridTabs);
      //{id: 1, name: "test", type: 0, grcode: "1234567890"},
    },
    removeTab(state, payload) {
      let index = _.findIndex(state.gridTabs, function(o) { return o.grcode == payload.grcode; });
      if(index != -1){
        state.gridTabs.splice(index, 1);
        localStorage.grtabs = JSON.stringify(state.gridTabs);
      }
    },
    renameTab(state, payload) {
      let index = _.findIndex(state.gridTabs, function(o) { return o.grcode == payload.grcode; });
      if(index != -1){
        state.gridTabs[index].name = payload.name;
      }
      localStorage.grtabs = JSON.stringify(state.gridTabs);
      //{id: 1, name: "test", type: 0, grcode: "1234567890"},
    },
    setLocalTabs(state) {
      //state.gridSettings = browser.storage.local.grsettings;
      state.gridTabs = JSON.parse(localStorage.grtabs);
    },
    checkTabs(state) {
      console.log("checkTabs");
      let commonLayouts = JSON.parse(localStorage.grcommon);
      if(commonLayouts.length > 0){
        // _.remove(x, function(n) {return n % 2 == 0;});
        //state.gridTabs = tabs;
        for (let i = 0; i < state.gridTabs.length; i++) {
          if(state.gridTabs[i].type == 2){
            let index = _.findIndex(state.commonLayouts, function(o) { return o.grcode == state.gridTabs[i].grcode; });
            if(index == -1){
              //remove tab
              state.gridTabs.splice(index, 1);
            }
            //state.gridTabs.push(commonLayouts[index]);
          }
        }
      }else{
        state.gridTab = [];
      }
      localStorage.grtabs = JSON.stringify(state.gridTabs);
    },
    // checkTabs(state) {
    //   console.log("checkTabs");
    //   let tabs = JSON.parse(localStorage.grtabs);
    //   let commonLayouts = JSON.parse(localStorage.grcommon);
    //   if(tabs.length > 0){
    //     // _.remove(x, function(n) {return n % 2 == 0;});
    //     //state.gridTabs = tabs;
    //     for (let i = 0; i < tabs.length; i++) {
    //       if(tabs[i].type == 2){
    //         let index = _.findIndex(commonLayouts, function(o) { return o.grcode == tabs[i].grcode; });
    //         if(index == -1){
    //           let secondIndex = _.findIndex(state.gridTabs, function(o) { return o.grcode == tabs[i].grcode; });
    //           if(secondIndex != -1){
    //             //remove tab
    //             state.gridTabs.splice(secondIndex, 1);
    //           }
    //           //state.gridTabs.push(commonLayouts[index]);
    //         }
    //       }
    //     }
    //   }else{
    //     state.gridTab = [];
    //   }
    //   localStorage.grtabs = JSON.stringify(state.gridTabs);
    // },
    saveTabs(state) {
      localStorage.removeItem('grtabs');
      localStorage.grtabs = JSON.stringify(state.gridTabs);
    },
    updateTabs(state, payload) {
      localStorage.removeItem('grtabs');
      state.gridTabs = _.cloneDeep(payload);
      localStorage.grtabs = JSON.stringify(state.gridTabs);
    },
    clearAll(state) {
      state.gridTabs = [];
      localStorage.removeItem('grtabs');
      localStorage.grtabs = JSON.stringify(state.gridTabs);
    },
  },
  actions: {
    restoreSettings({commit}, payload) {
      commit("setSettings", payload);
      commit("saveSettings");
    }
  },
  modules: {
  }
}
