<template>
  <section class="grid-backups">
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: 600px">
        <header class="modal-card-head">
          <p class="modal-card-title">Backup Personal Grids</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">

            <div class="column">
              <div class="separator has-text-grey-light mb-3">Export / Import Personal Grids</div>

              <b-button
                @click="exportLayout()"
                type="is-success mb-2"
                expanded
                outlined
                ><i class="file-export"></i> Local Export</b-button
              >

              <b-field>
                <b-upload
                  @input="warningImport()"
                  v-model="importData"
                  accept=".gridsup"
                >
                  <a class="button is-primary is-outlined"
                    ><i class="file-import icom"></i> Import Local</a
                  >
                </b-upload>
              </b-field>

              <b-field>
                <b-upload
                  @input="warningImportOutdated()"
                  v-model="importData"
                  accept=".gridsup"
                >
                  <a class="button is-dark is-outlined"
                    ><i class="file-import icom"></i> Import Outdated Local</a
                  >
                </b-upload>
              </b-field>

            </div>

            <div class="column mr-3">
              <div class="separator has-text-grey-light mb-3">Cloud Backup<span v-if="lastBackup">&nbsp;last: {{lastBackup}}</span></div>
              <b-button
                  @click="warningBackup()"
                  type="is-success mb-2"
                  :loading="isLoading"
                  expanded
                  outlined
                  ><i class="cloud-arrow-up"></i> Backup</b-button
                >

              <b-button
                  @click="warningRestore()"
                  type="is-primary"
                  :loading="isLoading"
                  expanded
                  outlined
                  ><i class="cloud-arrow-down"></i> Restore</b-button
                >

            </div>

          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Close
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import toastMixin from "@/mixins/toastMixin";
import randomMixin from "@/mixins/randomMixin";

export default {
  name: "BackupModal",
  mixins: [toastMixin, randomMixin],
  data() {
    return {
      isLoading: false,
      importData: null,
      lastBackup: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      //this.ignoreFirstWatch = false;
    });
  },
  methods: {
    setLastBK(){
      if(localStorage.grlastbk){
        this.lastBackup = new Date(parseInt(localStorage.grlastbk));
        this.lastBackup = this.lastBackup.toLocaleString("en-GB",{day:"numeric", month:"short", year:"numeric", hour:"2-digit", minute:"2-digit"});  
      }
    },
    //######################################################
    restoreLayout() {
      console.log("backing up layout...");
      this.$store.dispatch("layouts/restorePersonalLayouts");
      this.closeModal();
      //end post-------------------------------------------------
    },
    warningRestore() {
      this.$buefy.dialog.confirm({
        title: "Restoring Layout",
        message:
          "Are you sure you want to <b>restore</b> this Layout? It will replace your current Layout with the new one. This action cannot be undone.",
        confirmText: "Restore",
        type: "is-danger",
        hasIcon: true,
        icon: "cloud-arrow-up",
        onConfirm: () => this.restoreLayout(),
      });
    },
    //--------------------------------------------------------
    backupLayout() {
      console.log("backing up layout...");
      let now = new Date().getTime();
      localStorage.grlastbk = now;
      this.$store.dispatch("layouts/backupPersonalLayouts");
    },
    warningBackup() {
      this.$buefy.dialog.confirm({
        title: "Backup Layout",
        message: "This action will overwrite the previous backup.",
        confirmText: "Backup",
        type: "is-danger",
        hasIcon: true,
        icon: "cloud-arrow-up",
        onConfirm: () => this.backupLayout(),
      });
    },
    //######################################################
    exportLayout() {
      let exportLayout = {
        layouts: this.layoutsPersonal,
        version: "2.0.0",
      };
      let now = new Date();
      let nowString = now.toLocaleString("en-GB",{day:"numeric", month:"short", year:"numeric"});
      nowString = nowString.replace(/ /g,"_");

      let prepareExport = JSON.stringify(exportLayout);
      var fileURL = window.URL.createObjectURL(new Blob([prepareExport])); //add settings
      var fileLink = document.createElement("a");
      fileLink.setAttribute("id", "dlink");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "backup_"+nowString+".gridsup");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode.removeChild(fileLink);
    },
    //---------------------------------------
    getFile(index) {
      if (index) {
        return URL.createObjectURL(index);
      }
    },
    importLayout() {
      console.log("importiong data...");
      const fileUrl = this.getFile(this.importData); // provide file location

      fetch(fileUrl)
        .then((result) => result.text())
        .then((layoutString) => {
          console.log(layoutString);
          this.importData = null;
          let prepareImport = JSON.parse(layoutString);
          if(prepareImport.version == "2.0.0"){
            localStorage.grpersonal = JSON.stringify(prepareImport.layouts);
            this.$store.dispatch("importExternal");
          }else{
            this.toast("Bad file version or the backup file is corrupt!", "is-danger");
          }
        });
    },
    warningImport() {
      this.$buefy.dialog.confirm({
        title: "Importing Layout",
        message:
          "Are you sure you want to <b>import</b> this Layout? It will replace your current Layout with the new one. This action cannot be undone.",
        confirmText: "Import",
        type: "is-danger",
        hasIcon: true,
        icon: "file-import",
        onConfirm: () => this.importLayout(),
      });
    },
    //######################################################
    importOutdatedLayout() {
      console.log("importiong data...");
      const fileUrl = this.getFile(this.importData); // provide file location

      fetch(fileUrl)
        .then((result) => result.text())
        .then((layoutString) => {
          console.log(layoutString);
          this.importData = null;
          let prepareImport = JSON.parse(layoutString);
         
            console.log("importing old type of layouts");
            this.toast("Importing old type of layouts", "is-warning");
            console.log();
            localStorage.grpage = 0;
              let layouts = prepareImport.layouts;
              //deal wilth old type of layouts
              if(layouts.length>0) {
              console.log("setting grcode for old type of layouts");
              layouts.forEach((item) => {
                item.grcode = this.makeRandomCode(16); //same as makeGRCode imported from randomMixin
                item.layout.forEach(function (value, i) {
                  value.i = i+1;
                });
              });
              localStorage.grpersonal = JSON.stringify(layouts);
              this.$store.commit("layouts/setLocalLayout");
              this.$store.commit("layouts/setLocalPersonalGrisLayout");
              }else{
                this.toast("Could not import grids.", "is-danger");
              }
          });
    },
    warningImportOutdated() {
      this.$buefy.dialog.confirm({
        title: "Importing Layout",
        message:
          "Are you sure you want to <b>import</b> this Layout? It will replace your current Layout with the new one. This action cannot be undone.",
        confirmText: "Import",
        type: "is-danger",
        hasIcon: true,
        icon: "file-import",
        onConfirm: () => this.importOutdatedLayout(),
      });
    },
    //######################################################
    closeModal() {
      this.$store.commit("modals/hideModal", "backups");
    },
  },
  computed: {
    //...mapState("settings", ["gridSettings"]),
    ...mapState("users", ["user"]),
    ...mapState("layouts", ["layoutsPersonal"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.backups;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "backups");
        return false;
      },
    },
  },
  watch: {
    isActive: function (val) {
      if (val) {
        //
        this.setLastBK();
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: flex-end;
  /* flex-end; */
}

.theme-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.tooltip-icon{
  margin-top:-5px;
  font-size: 1.2em;
  cursor: pointer;
}

.bg-preview {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-preview {
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
}

.tile-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-title {
  /* padding-top:2px;
  padding-bottom:2px;  */
  font-size:12px;
  }

.pixelfont{
  font-size:8px;
  font-family: Pixel;
}

.neumorphism {
  border: 1px solid var(--item-primary);
  transition: all .3s;
  box-shadow: 6px 6px 12px var(--item-darker),
             -6px -6px 12px var(--item-lighter);
}

.neumorphism .tile-icon{
  width: 93%;
  height: 93%;
  /* background-color: var(--item-darker)!important; */
}

.glassmorphism{
  backdrop-filter: blur(4px) saturate(100%);
  -webkit-backdrop-filter: blur(6px) saturate(100%);
  background-color: var(--item-color-t);
  /* background-color: rgba(17, 25, 40, 0.46); */
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: #FFFFFF;
  box-shadow: 6px 6px 12px #00000050;

}

/* .glassmorphism .sample-icon{
filter:brightness(200%);
} */

/* .glassmorphism .sample-icon {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.neumorphism .sample-icon {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
} */

.filter-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.filter-white {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.icon{
  width:80px;
}

.bg-selector{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}
</style>
