<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div
        class="modal-card"
        style="width: 400px"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Move to ...</p>
        </header>
        <section class="modal-card-body">          
            <div class="separator mb-3">Select page</div>
            <div class="scroll-grids">  <div class="buttons">
                    <b-button type="is-light" expanded v-for="(item, index) in layoutsPersonal" :key="index" :id="item.id" @click="doCopy(item.id)">{{item.name}}</b-button>
                  </div></div>
                
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>          
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import toastMixin from "@/mixins/toastMixin";
import { mapState } from "vuex";
export default {
  name: "CopytoModal",
  mixins: [toastMixin],
  data() {
    return {
      isLoading: false,     
      newBoard: null     
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("modals/hideModal", "copyTo");
    },
    doCopy(pageId){
      //this.$store.commit("layouts/copyTo", pageId);
      this.$store.dispatch("layouts/gridAction", {
        action: "copyTo",
        data: pageId,
      });
      this.closeModal();
    }
  },
  computed: {
    ...mapState("layouts", ["layoutsPersonal", "isType"]),
    isActive: {
      get: function() {
        return this.$store.state.modals.copyTo;
      },
      // setter
      set: function() {
        this.$store.commit("modals/hideModal", "copyTo");
        return false;
      }
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.scroll-grids {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  scrollbar-color: rgba(71, 249, 204);
  scrollbar-width: 4px;
}
.scroll-grids::-webkit-scrollbar-track {
  background-color: #e9e9e9 !important;
}

.scroll-grids::-webkit-scrollbar {
  width: 4px;
}

.scroll-grids::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    rgba(71, 249, 204, 1) 0%,
    rgba(2, 120, 232, 1) 100%
  ) !important;
}
</style>
