
import _ from "lodash";

let forSrceen = 0;
if(screen.width){
if(screen.width <= 1024){
  forSrceen = 1;
}else if(screen.width <= 1280){
  forSrceen = 2;
}else if(screen.width <= 1440){
  forSrceen = 3;
}else{
  forSrceen = 0;
}}

const defaultLayout = require("@/data/defaultLayout"+forSrceen+".json");
// "settings": {
//   "tiles":{
//       "rounded": true,
//       "titles": true,
//       "clasic": true,
//       "pseudo3D": true,
//       "neumorphism": false,
//       "glassmorphism": false
//   },
//   "grid": {
//       "wCell": 15,
//       "hCell": 30,
//       "color": "#ffffff",
//       "isDragable": false,
//       "isResizeble": false,
//       "isVertical": false,
//       "isMirrored": false,
//       "isCollision": true,
//       "space": [10, 10]
//   },
//   "gridBG":{
//       "bgType": "pattern",
//       "bgColor": "#000000",
//       "pattern": "cartographer.png"
//   },
//   "interface":{
//       "isDark": false,
//       "fastAdd": true,
//       "minBoards": false,
//       "minShares": false
//   }
// }

const defaultSettings = {
  version: "2.0.0",
  tiles:{
    rounded: 10,
    title: true,
    pixelfont: false,
    theme: "classic",//classic,pseudo3D,neumorphism,glassmorphism
  },
  grid: {
      wCell: 15,
      hCell: 30,
      color: "#ffffff",
      isDragable: false,
      isResizeble: false,
      isVertical: false,
      isMirrored: false,
      isCollision: true,
      space: 10,
      maxWidth: 0,
      minWidth: 1600,
  },
  gridBG:{
      type: "pattern",
      color: "#000000",
      imagePattern: "cartographer.png",
      imageWallpaper: "px.png",
      imageCustom: "images/px.png",
  },
  interface:{
      webLink: true,
      linkList: true,
      youTubeList: true,
      note: true,
      codeNote: true,
      noteList: false,//not used
      codeNoteList: false,//not used
      todoList: true,
      calendar: false,
      clock: false,
      calculator: false,
      weather: false,
      image: false,
      search: false,
      ambiance: false,
      iframe: false,
      rss: false,
      fileTransfer: true,
      isDark: false
  },
  general:{
    isNewTab: true,
    isAutoBackup: false,
  }
};

export default{
  namespaced: true,
  state: {
    gridSettings: _.cloneDeep(defaultSettings),
  },
  getters: {
    getCurrentwCell: (state) => {
      return state.gridSettings.grid.wCell;
    },
  },
  mutations: {
    setBG(state, payload) {
      state.gridSettings.gridBG.color = payload;
    },
    setBGImage(state) {
      if(localStorage.grbg){
        state.gridSettings.gridBG.image = localStorage.grbg;
      }
    },
    setLocalSettings(state) {
      //state.gridSettings = browser.storage.local.grsettings;
      let grsettings = JSON.parse(localStorage.grsettings);
      if(grsettings.version != state.gridSettings.version){
        state.gridSettings = _.cloneDeep(defaultSettings);
        localStorage.grsettings = JSON.stringify(state.gridSettings);
      }else{
        state.gridSettings = _.cloneDeep(grsettings);
      }
    },
    setSettings(state, payload) {
      state.gridSettings = _.cloneDeep(payload);
    },
    saveSettings(state) {
      localStorage.removeItem('grsettings');
      localStorage.grsettings = JSON.stringify(state.gridSettings);

      //browser.storage.local.remove(grsettings);
      //browser.storage.local.set({grsettings: state.gridSettings});
    },
    dropAllSettings(state){
      localStorage.removeItem("grsettings");
      state.gridSettings = _.cloneDeep(defaultSettings);
      localStorage.grsettings = JSON.stringify(state.gridSettings);
    },
    restoreSettings(state, payload) {
      state.gridSettings = _.cloneDeep(payload);;
      localStorage.removeItem('grsettings');
      localStorage.grsettings = JSON.stringify(state.gridSettings);
    },
  },
  actions: {
    restoreSettings({commit}, payload) {
      console.log("store: restoreSettings");
      commit("setSettings", payload);
      commit("saveSettings");
    }
  },
  modules: {
  }
}
