<template>
  <div class="ibox">
    <div v-if="isEditable" class="box-overlay" :style="boxOverlay"></div>
    <LinkItem :options="options" :elementID="elementID" v-if="type == 0" />
    <ClockItem :options="options" :elementID="elementID" v-if="type == 1" />
    <CalcItem :elementID="elementID" v-if="type == 2" />
    <NoteItem :options="options" :elementID="elementID" v-if="type == 3" @isState="setElementState" />
    <TodoItem :options="options" :elementID="elementID" v-if="type == 4" @isState="setElementState" />
    <WeatherItem :options="options" :elementID="elementID" v-if="type == 5" />
    <ImageItem :options="options" :elementID="elementID" v-if="type == 6" />
    <SearchItem :options="options" :elementID="elementID" v-if="type == 7" />
    <CalendarItem :options="options" :elementID="elementID" v-if="type == 11" />
    <LinksItem :options="options" :elementID="elementID" v-if="type == 12" @isState="setElementState"/>
    <RSSItem :options="options" :elementID="elementID" v-if="type == 13" />
    <CodeNoteItem :options="options" :elementID="elementID" v-if="type == 14" @isState="setElementState"/>
    <AmbianceItem :options="options" :elementID="elementID" v-if="type == 15" />
    <FrameItem :options="options" :elementID="elementID" v-if="type == 16" />
    <YouTubeItem :options="options" :elementID="elementID" v-if="type == 17" @isState="setElementState"/>
    <FileTransferItem :options="options" :elementID="elementID" v-if="type == 18" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import LinkItem from "@/components/items/LinkItem.vue";
import ClockItem from "@/components/items/ClockItem.vue";
import CalcItem from "@/components/items/CalcItem.vue";
import NoteItem from "@/components/items/NoteItem.vue";
import TodoItem from "@/components/items/TodoItem.vue";
import WeatherItem from "@/components/items/WeatherItem.vue";
import ImageItem from "@/components/items/ImageItem.vue";
import SearchItem from "@/components/items/SearchItem.vue";
import CalendarItem from "@/components/items/CalendarItem.vue";
import LinksItem from "@/components/items/LinksItem.vue";
import RSSItem from "@/components/items/RSSItem.vue";
import CodeNoteItem from "@/components/items/CodeNoteItem.vue";
import AmbianceItem from "@/components/items/AmbianceItem.vue";
import FrameItem from "@/components/items/FrameItem.vue";
import YouTubeItem from "@/components/items/YouTubeItem.vue";
import FileTransferItem from "@/components/items/FileTransferItem.vue";

export default {
  name: "BoxItem",
  props: {
    options: Object,
    type: Number,
    elementID: Number,
  },
  components: {
    LinkItem,
    ClockItem,
    CalcItem,
    NoteItem,
    TodoItem,
    WeatherItem,
    ImageItem,
    SearchItem,
    CalendarItem,
    LinksItem,
    RSSItem,
    CodeNoteItem,
    AmbianceItem,
    FrameItem,
    YouTubeItem,
    FileTransferItem,
  },
  computed: {
    ...mapState("settings", ["gridSettings"]),
    ...mapState(["isEditable"]),
    boxOverlay() {
      let style = {};
      style.borderRadius = this.gridSettings.tiles.rounded + "px";
      return style;
    },
  },
  methods: {
    setElementState(state) {
      this.$emit("isState", {"status":state, "elementID":this.elementID});
    },
  },
};
</script>
<style scoped>
.ibox {
  height: 100%;
}
.box-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000050;
  z-index: 1;
  position: absolute;
}
</style>
